import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExpertFile } from '../models/expert-file.model';

@Injectable({ providedIn: 'root' })
export class ExpertFileService {
  private readonly baseUrl = '/experts';

  constructor(private http: HttpClient) {}

  getFiles(expertId: string): Observable<ExpertFile[]> {
    return this.http.get<ExpertFile[]>(`${this.baseUrl}/${expertId}/files`);
  }

  updateFile(
    fileUpdate: Partial<ExpertFile> & { fileId: string; expertId: string }
  ): Observable<ExpertFile> {
    return this.http.patch<ExpertFile>(
      `${this.baseUrl}/${fileUpdate.expertId}/files/${fileUpdate.fileId}`,
      fileUpdate
    );
  }

  putFile(
    fileKey: string,
    fileName: string,
    expertId: string
  ): Observable<ExpertFile> {
    return this.http.post<ExpertFile>(`${this.baseUrl}/${expertId}/files`, {
      fileName,
      fileKey,
    });
  }

  removeFile(fileId: string, expertId: string): Observable<unknown> {
    return this.http.delete<unknown>(
      `${this.baseUrl}/${expertId}/files/${fileId}`
    );
  }
}
