import { Routes } from '@angular/router';
import { AuthGuard as Auth0Guard } from '@auth0/auth0-angular';
import { BetaGroupGuard, RedirectGuard } from '@techspert-io/auth';
import { SearchMetaDataResolver } from '@techspert-io/search';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { ClientOpportunitySearchesComponent } from './admin-portal/clients/client-opportunity-searches/client-opportunity-searches.component';
import { ConferenceManagementComponent } from './admin-portal/conference-management/conference-management.component';
import { DashboardComponent } from './admin-portal/dashboard/dashboard.component';
import { PaymentsComponent } from './admin-portal/payments/payments.component';
import { SearchComponent } from './admin-portal/search/search.component';
import { AuthorisationErrorPageComponent } from './core/authorisation-error-page/authorisation-error-page.component';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  {
    path: 'error',
    component: AuthorisationErrorPageComponent,
  },
  {
    path: 'admin',
    component: AdminPortalComponent,
    canActivate: [BetaGroupGuard, RedirectGuard, Auth0Guard],
    children: [
      { path: '*', redirectTo: 'dashboard' },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'search', component: SearchComponent },
      {
        path: 'search/:opportunityId',
        component: SearchComponent,
        resolve: {
          metaData: SearchMetaDataResolver,
        },
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./features/clients-dashboard/clients-dashboard.module').then(
            (m) => m.ClientsDashboardModule
          ),
      },
      {
        path: 'clients/:clientId/opportunity-kick-off',
        loadChildren: () =>
          import(
            './features/opportunity-kick-off/opportunity-kick-off.module'
          ).then((m) => m.OpportunityKickOffModule),
      },
      {
        path: 'clients/:clientId/opportunity-kick-off-template',
        loadChildren: () =>
          import(
            './features/opportunity-kick-off-template/opportunity-kick-off-template.module'
          ).then((m) => m.OpportunityKickOffTemplateModule),
      },
      {
        path: 'client/:clientId/opportunity/:opportunityId/dashboard',
        loadChildren: () =>
          import(
            './features/opportunity-dashboard/opportunity-dashboard.module'
          ).then((m) => m.OpportunityDashboardModule),
      },
      {
        path: 'client/:id/opportunity/:opportunity/search/:search/phase/:phase',
        component: ClientOpportunitySearchesComponent,
      },
      {
        path: 'client/:id/opportunity/:opportunity',
        redirectTo:
          'client/:id/opportunity/:opportunity/search/all-searches/phase/identified',
      },
      {
        path: 'campaigns',
        loadChildren: () =>
          import(
            './features/campaigns-dashboard/campaigns-dashboard.module'
          ).then((m) => m.CampaignsDashboardModule),
      },
      {
        path: 'experts',
        loadChildren: () =>
          import('./features/expert-management/expert-management.module').then(
            (m) => m.ExpertManagementModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./features/users/users.modules').then((m) => m.UsersModule),
      },
      {
        path: 'feasibility',
        loadChildren: () =>
          import('./features/feasibility/feasibility.module').then(
            (m) => m.FeasibilityModule
          ),
      },
      {
        path: 'expert-outreach-status',
        loadChildren: () =>
          import(
            './features/expert-outreach-status/expert-outreach-status.module'
          ).then((m) => m.ExpertOutreachStatusModule),
      },
      {
        path: 'manual-upload',
        loadChildren: () =>
          import('./features/manual-upload/manual-upload.module').then(
            (m) => m.ManualUploadModule
          ),
      },
      { path: 'project-payments', component: PaymentsComponent },
      {
        path: 'conference-management',
        component: ConferenceManagementComponent,
      },
      {
        path: 'outreach-templates',
        loadChildren: () =>
          import(
            './features/outreach-templates/outreach-templates.module'
          ).then((m) => m.OutreachTemplatesModule),
      },
      {
        path: 'payments',
        loadChildren: () =>
          import('./features/payments/out-of-project-payments.module').then(
            (m) => m.OutOfProjectPaymentsModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: '' },
];
