import { IUserBase } from '@techspert-io/auth';

const houston = '2500 E T C Jester BLVD, Suite 258, Houston, USA, TX 77008';
const cambridge = '52 Burleigh St, CB1 1DJ, Cambridge, UK';
const santiago =
  'Av. Apoquindo 5950, 7560949 Las Condes, Región Metropolitana, Chile';

export const getOfficeLocationAddress = (
  office: IUserBase['profile']['officeLocation']
): string => ({ houston, cambridge, santiago }[office || 'cambridge']);
