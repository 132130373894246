<div class="template-menu">
  <div class="template-menu-header">
    <h3 data-test-id="template-menu-title">Templates</h3>
    <mat-icon class="close-icon" (click)="closeMenu()" data-test-id="template-menu-close">
      close
    </mat-icon>
  </div>
  <div class="template-tabs">
    <button class="thin-button" [ngClass]="tabForDisplay === 'adminTemplates' ? 'active' : ''"
      (click)="openTab('adminTemplates')" data-test-id="template-menu-my-templates">
      My templates
    </button>
    <button class="thin-button" [ngClass]="tabForDisplay === 'allAdminTemplates' ? 'active' : ''"
      (click)="openTab('allAdminTemplates')" data-test-id="template-menu-all-templates">
      All templates
    </button>
  </div>
  <div class="search-input">
    <mat-icon class="search-input-icon">search</mat-icon>
    <input class="search-input-input" type="text" placeholder="Search templates..." [(ngModel)]="searchTerm"
      (keyup)="filterTemplates($event.target)" data-test-id="template-menu-search" />
  </div>
  <ul class="template-list">
    <li *ngFor="let template of filteredTemplates"
      [ngClass]="template.templateId === solicitationService.activeTemplate?.templateId ? 'active-template' : ''"
      (click)="openTemplate(template)" class="template-list-item" data-test-id="template-menu-item">
      <div class="template-info">
        <span class="template-title" data-test-id="template-menu-template-title">{{ template.title }}</span>
        <span class="template-description" title="{{template.description}}"
          data-test-id="template-menu-template-description">{{template.description}}</span>
      </div>
      <div class="action-items">
        <button mat-icon-button [disabled]="template.editDisabled" (click)="emitOpenEditTemplateForm(template)">
          <mat-icon [matTooltipDisabled]="!template.editDisabled"
            matTooltip="Only email template admins can edit master templates"
            data-test-id="template-menu-template-edit">
            edit
          </mat-icon>
        </button>
        <button mat-icon-button [disabled]="template.cloneDisabled" (click)="duplicateTemplate(template)">
          <mat-icon [matTooltipDisabled]="!template.cloneDisabled" matTooltip="Only master templates can be cloned"
            data-test-id="template-menu-template-clone">
            file_copy
          </mat-icon>
        </button>
      </div>
    </li>
  </ul>
  <button class="button button--danger" (click)="emitOpenNewTemplateForm()"
    matTooltip="Please use a MASTER template instead if at all possible" data-test-id="template-menu-create">
    Create new template
  </button>
</div>