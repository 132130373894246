import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { EmailType } from '../components/schedule/schedule-models';

@Directive({
  selector: '[appSchedulingModalTimer]',
})
export class SchedulingModalTimerDirective implements OnInit, OnDestroy {
  @Input('appSchedulingModalTimer') opportunity: IOpportunity;
  @Input() emailType: EmailType;

  private emailSent: boolean;
  private callUpdated: boolean;

  private timeStart: number;

  constructor(private gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.timeStart = performance.now();
  }

  setEmailSent(): void {
    this.emailSent = true;
  }

  setCallUpdated(): void {
    this.callUpdated = true;
  }

  ngOnDestroy(): void {
    if (this.emailSent && this.callUpdated) {
      this.gaService.event(
        'timing',
        `schedule_${this.emailType}`,
        this.opportunity.opportunityName,
        Math.round(performance.now() - this.timeStart)
      );
    }
  }
}
