import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectUser } from '@techspert-io/auth';
@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss'],
})
export class UsersFormComponent {
  @Input() usersList: ConnectUser[];
  @Output() submitUser = new EventEmitter<ConnectUser[]>();

  userForm = new FormGroup({
    member: new FormControl(null, Validators.required),
  });

  get memberControl(): FormControl {
    return this.userForm.get('member') as FormControl;
  }

  onSubmit(): void {
    if (this.userForm.valid) {
      this.submitUser.emit(
        this.usersList.filter((user) =>
          this.memberControl.value.includes(user.connectId)
        )
      );
    }
  }
}
