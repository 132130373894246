import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';
import { OmnisearchQueryResponse } from '../../models/omnisearch.models';
import { OmnisearchService } from '../../services/omnisearch.service';

@Component({
  selector: 'app-omnisearch',
  templateUrl: './omnisearch.component.html',
  styleUrls: ['./omnisearch.component.scss'],
})
export class OmnisearchComponent implements OnInit {
  private searchTerm$ = new Subject<string>();
  results$: Observable<OmnisearchQueryResponse[]>;
  searchTerm: string = '';

  constructor(
    private searchService: OmnisearchService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((e) => e instanceof ActivationEnd))
      .subscribe(() => {
        this.clearSearch();
      });

    this.results$ = this.searchTerm$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((value) => this.searchService.search(value))
    );
  }

  submitSearch(searchTerm: string): void {
    this.searchTerm$.next(searchTerm);
  }

  clearSearch(): void {
    this.submitSearch('');
  }
}
