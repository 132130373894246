import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BtxSharedModule } from '../../shared/btx-shared.module';
import { MaterialModule } from '../../shared/patterns/material-module';
import { ClientOpportunitySearchesComponent } from './client-opportunity-searches/client-opportunity-searches.component';
import { OpportunityModule } from './client-opportunity-searches/opportunity-results/opportunity.module';

@NgModule({
  declarations: [ClientOpportunitySearchesComponent],
  imports: [
    BtxSharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ScrollingModule,
    OpportunityModule,
  ],
  exports: [ClientOpportunitySearchesComponent, OpportunityModule],
})
export class ClientOpportunityModule {}
