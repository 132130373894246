import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

export interface ICalendarEvent {
  title: string;
  date: string;
  id: string;
  extendedProps: unknown;
  backgroundColor?: string;
  className?: string;
}

@Component({
  selector: 'app-shared-calendar',
  templateUrl: './shared-calendar.component.html',
  styleUrls: ['./shared-calendar.component.scss'],
})
export class SharedCalendarComponent implements OnChanges {
  @Input() events: ICalendarEvent[];
  @Output() eventClickSignal = new EventEmitter();
  public options: CalendarOptions = {
    height: '100%',
    eventClick: this.emitEventClickSignal.bind(this),
    plugins: [dayGridPlugin, interactionPlugin],
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events) {
      this.options = {
        ...this.options,
        events: changes.events.currentValue,
      };
    }
  }

  private emitEventClickSignal(e): void {
    this.eventClickSignal.emit(e.event);
  }
}
