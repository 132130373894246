import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  ISearchesDeleteRequest,
  ISearchesSplitRequest,
  ISearchesUpdateRequest,
} from '../models/opportunity-searches.models';
import { IOpportunity } from '../models/opportunity.models';

@Injectable({
  providedIn: 'root',
})
export class OpportunitySearchesService {
  private readonly baseUrl = '/opportunities';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  update(payload: ISearchesUpdateRequest): Observable<IOpportunity> {
    return this.http
      .patch<IOpportunity>(
        `${this.buildUrl(payload.opportunityId, payload.oldSearchName)}`,
        { newSearchName: payload.newSearchName }
      )
      .pipe(
        tap(() =>
          this.sendSuccessToast(
            `Updated ${payload.oldSearchName} to ${payload.newSearchName}`
          )
        ),
        catchError((err) => this.handleError(err))
      );
  }

  delete(payload: ISearchesDeleteRequest): Observable<IOpportunity> {
    return this.http
      .delete<IOpportunity>(
        this.buildUrl(payload.opportunityId, payload.searchName)
      )
      .pipe(
        tap(() =>
          this.sendSuccessToast(`Deleted ${payload.searchName} and all experts`)
        ),
        catchError((err) => this.handleError(err))
      );
  }

  split(payload: ISearchesSplitRequest): Observable<IOpportunity> {
    return this.http
      .post<IOpportunity>(
        `${this.buildUrl(
          payload.opportunityId,
          payload.searchIdToSplit
        )}/split`,
        payload
      )
      .pipe(
        tap(() =>
          this.sendSuccessToast(
            `Split half of experts from ${payload.searchIdToSplit} to ${payload.newSearchId}`
          )
        ),
        catchError((err) => this.handleError(err))
      );
  }

  private buildUrl(opportunityId: string, searchName: string): string {
    return `${this.baseUrl}/${opportunityId}/searches/${encodeURIComponent(
      searchName
    )
      .replace(/[!'()]/g, escape)
      .replace(/\*/g, '%2A')}`;
  }

  private sendSuccessToast(message: string): void {
    this.toastService.sendMessage(message, 'success');
  }

  private handleError(err: Error): Observable<IOpportunity> {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 400) {
        this.toastService.sendMessage(err.error.message, 'error');
        return throwError(err.error);
      }
    }
    return throwError(err);
  }
}
