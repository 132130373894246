import { createActionGroup, props } from '@ngrx/store';
import {
  IExpert,
  IExpertCreateRequest,
  IExpertUpdateRequest,
  IQueryExpertSearchRequest,
} from '../models/experts.models';
import { SearchQueryResponse } from '../services/experts-query.service';

export const ExpertActions = createActionGroup({
  source: 'Experts',
  events: {
    'Fetch Expert Searches': props<{ query: IQueryExpertSearchRequest }>(),
    'Fetch Expert': props<{ expertId: string }>(),
    'Patch Expert': props<{ expert: IExpertUpdateRequest }>(),
    'Create Experts': props<{
      experts: IExpertCreateRequest[];
    }>(),
    'Update Expert': props<{
      update: IExpertUpdateRequest;
    }>(),
    'Block Expert': props<{
      update: IExpertUpdateRequest;
    }>(),
    'Update Experts': props<{
      update: IExpertUpdateRequest[];
      resetIsSelected?: boolean;
    }>(),
    'Set Selected': props<{
      update: { expertId: string; isSelected: boolean }[];
    }>(),
  },
});

export const ExpertAPIActions = createActionGroup({
  source: 'Experts API',
  events: {
    'Fetch Expert Searches Success': props<{
      response: SearchQueryResponse[];
    }>(),
    'Fetch Expert Searches Failure': props<{ error: string[] }>(),
    'Fetch Expert Success': props<{ expert: IExpert }>(),
    'Fetch Expert Failure': props<{ error: string[]; expertId: string }>(),
    'Create Experts Success': props<{ experts: IExpert[] }>(),
    'Create Experts Failure': props<{ error: string[] }>(),
    'Block Expert Success': props<{ expert: IExpert }>(),
    'Block Expert Failure': props<{ error: string[]; expertId: string }>(),
    'Update Expert Success': props<{ expert: IExpert }>(),
    'Update Expert Failure': props<{ error: string[]; expertId: string }>(),
    'Update Experts Success': props<{
      experts: IExpert[];
      resetIsSelected?: boolean;
    }>(),
    'Update Experts Failure': props<{ error: string[]; expertIds: string[] }>(),
  },
});
