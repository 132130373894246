import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as CC from 'currency-codes';
import { CurrencyService } from '../../../shared/services/currency.service';

type OnFunction = (value: string) => void;

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => CurrencyInputComponent),
      multi: true,
    },
  ],
})
export class CurrencyInputComponent implements ControlValueAccessor {
  @Input() isInvalid: boolean;
  filteredCurrencies: CC.CurrencyCodeRecord[] =
    this.currencyService.currencyData;

  onChange: OnFunction = () => void 0;
  onTouch: OnFunction = () => void 0;

  val = ''; // this is the updated value that the class accesses
  set value(val: string) {
    this.val = val || '';
    this.onChange(val);
    this.onTouch(val);
  }

  constructor(public currencyService: CurrencyService) {}

  registerOnChange(fn: OnFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnFunction): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.filteredCurrencies = this.currencyService.currencyFilter(value);

    this.value = value;
  }

  onInputChange(val: string): void {
    this.filteredCurrencies = this.currencyService.currencyFilter(val);

    this.value = val;
  }
}
