import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  NotificationsDialogComponent,
  NotificationService,
} from '@techspert-io/notifications';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  @Output() toggleFullScreenSidebar = new EventEmitter();
  @Input() sidebarOpen: boolean;
  filteredNotifications$ = this.notificationService.notifications$.pipe(
    map((notifs) => notifs.filter((n) => !n.archived))
  );

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  emitToggleFullScreenSidebar(): void {
    this.toggleFullScreenSidebar.emit();
  }

  openNotifications(): void {
    this.dialog
      .open(NotificationsDialogComponent, {
        width: '375px',
        height: '100vh',
        position: { right: '0' },
      })
      .afterClosed()
      .subscribe();
  }
}
