import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISolicitationHistory } from '../models/solicitation-outreach.models';

@Injectable({
  providedIn: 'root',
})
export class ExpertsSolicationOutreachService {
  private readonly baseUrl = '/experts';

  constructor(private http: HttpClient) {}

  get(expertId: string): Observable<ISolicitationHistory[]> {
    return this.http.get<ISolicitationHistory[]>(
      `${this.baseUrl}/${expertId}/solicitationOutreach`
    );
  }
}
