import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  ISearchKnowledgeGraphNode,
  ISearchTaxonomyHighlight,
} from '../../models/knowledge-graph.models';
import { SearchKnowledgeGraphService } from '../../services/search-knowledge-graph.service';

@Component({
  selector: 'app-search-taxonomy-node',
  templateUrl: './search-taxonomy-node.component.html',
  styleUrls: ['./search-taxonomy-node.component.scss'],
})
export class SearchTaxonomyNodeComponent implements OnInit, OnChanges {
  @Input() node: ISearchKnowledgeGraphNode;
  @Input() highlight?: ISearchTaxonomyHighlight;
  @Input() isRoot?: boolean;
  @Output() addNodeSignal = new EventEmitter<ISearchKnowledgeGraphNode>();

  isExpanded?: boolean;
  shouldHighlight?: boolean;

  children$: Observable<ISearchKnowledgeGraphNode[]>;

  constructor(private searchTaxonomyService: SearchKnowledgeGraphService) {}

  ngOnInit(): void {
    this.children$ = this.searchTaxonomyService
      .getChildren(this.node.id)
      .pipe(
        map((children) => children.sort((a, b) => a.name.localeCompare(b.name)))
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.highlight &&
      changes.highlight.currentValue !== changes.highlight.previousValue
    ) {
      if (!changes.highlight.currentValue) {
        this.shouldHighlight = false;
        return;
      }

      const curr: ISearchTaxonomyHighlight = changes.highlight.currentValue;

      if (curr.targetId === this.node.id) {
        this.shouldHighlight = true;
      } else if (curr.parentIds.includes(this.node.id)) {
        this.shouldHighlight = true;
        this.isExpanded = true;
      } else {
        this.shouldHighlight = false;
      }
    }
  }

  onAddNode(node: ISearchKnowledgeGraphNode): void {
    this.addNodeSignal.emit(node);
  }
}
