<div class="flex flex-col gap-4">
  <app-breadcrumbs backLink="../" [isLoading]="isLoading" location="Users" title="Create user">
  </app-breadcrumbs>

  <mat-card *ngIf="vmData$ | async as vmData">
    <mat-card-content>
      <app-user-form [clients]="vmData.clients" [authRoles]="vmData.authRoles" [isLoading]="isLoading"
        (submitUser)="inviteUser($event)"></app-user-form>
    </mat-card-content>
  </mat-card>
</div>