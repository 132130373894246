import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { concatMap, map, mergeMap, reduce, tap } from 'rxjs/operators';
import { AppService } from '../../../shared/services/app.service';
import {
  ISearchKnowledgeGraphLookupRes,
  ISearchKnowledgeGraphNode,
  ISearchKnowledgeGraphNodeBase,
} from '../models/knowledge-graph.models';
import { SEARCH_API_BASE_2_URL } from './search.service';

export interface ISearchKnowledgeGraphGetReturn
  extends ISearchKnowledgeGraphNodeBase {
  synonyms: string[];
  children: ISearchKnowledgeGraphGetReturnChild[];
}
export interface ISearchKnowledgeGraphGetReturnChild
  extends ISearchKnowledgeGraphNodeBase {
  synonyms: string[];
  children: ISearchKnowledgeGraphNodeBase[];
}

@Injectable({
  providedIn: 'root',
})
export class SearchKnowledgeGraphService {
  private cache: Record<string, ISearchKnowledgeGraphNode> = {};

  constructor(
    private http: HttpClient,
    @Inject(SEARCH_API_BASE_2_URL) private baseUrl: string,
    private appService: AppService
  ) {}

  get(
    nodeId: string,
    ignoreCache = false
  ): Observable<ISearchKnowledgeGraphNode> {
    if (!ignoreCache && this.cache[nodeId]) {
      return of(this.cache[nodeId]);
    }

    const params = new URLSearchParams({
      id: nodeId,
    }).toString();

    return this.http
      .get<ISearchKnowledgeGraphGetReturn>(
        `${this.baseUrl}/knowledgegraph/get?${params}`
      )
      .pipe(
        tap((node) => {
          for (const child of node.children) {
            this.cache[child.id] = {
              id: child.id,
              name: child.name,
              synonyms: child.synonyms || [],
              children: child.children.map((c) => c.id),
            };
          }
        }),
        map((node) => ({
          id: node.id,
          name: node.name,
          synonyms: node.synonyms,
          children: node.children.map((c) => c.id),
        })),
        tap((node) => (this.cache[node.id] = node))
      );
  }

  batchGet(nodeIds: string[]): Observable<ISearchKnowledgeGraphNode[]> {
    return from(this.appService.chunkArray(15, nodeIds)).pipe(
      concatMap((batch) => from(batch).pipe(mergeMap((id) => this.get(id)))),
      reduce<ISearchKnowledgeGraphNode, ISearchKnowledgeGraphNode[]>(
        (acc, curr) => [...acc, curr],
        []
      )
    );
  }

  getChildren(nodeId: string): Observable<ISearchKnowledgeGraphNode[]> {
    return this.get(nodeId).pipe(
      concatMap((node) =>
        node.children.every((id) => this.cache[id])
          ? this.batchGet(node.children)
          : this.get(nodeId, true).pipe(
              concatMap((node) => this.batchGet(node.children))
            )
      )
    );
  }

  lookup(term: string): Observable<ISearchKnowledgeGraphLookupRes> {
    const params = new URLSearchParams({ term }).toString();
    return this.http.get<ISearchKnowledgeGraphLookupRes>(
      `${this.baseUrl}/knowledgegraph/search?${params}`
    );
  }
}
