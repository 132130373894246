import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IExpertSource } from '../models/expert-sources.models';

export interface IExpertSourcesState extends EntityState<IExpertSource> {
  selectedExpertSourceId: string | null;
  selectedOpportunityId: string | null;
  isLoading: boolean;
  isLoaded: boolean;
  loadError?: string;
  updateErrors?: string[];
}

const selectId = (a: IExpertSource) => a.expertSourceId;

const sortComparer = (a: IExpertSource, b: IExpertSource) =>
  a.searchName.localeCompare(b.searchName);

export const expertSourcesAdapter: EntityAdapter<IExpertSource> =
  createEntityAdapter<IExpertSource>({ selectId, sortComparer });
