import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@techspert-io/notifications';
import { OmnisearchModule } from '@techspert-io/omnisearch';
import { MaterialModule } from '../shared/patterns/material-module';
import { AuthorisationErrorPageComponent } from './authorisation-error-page/authorisation-error-page.component';
import { NavMenuLinkComponent } from './sidebar/nav-menu-link/nav-menu-link.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';

@NgModule({
  declarations: [
    SidebarComponent,
    NavMenuLinkComponent,
    TopbarComponent,
    AuthorisationErrorPageComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    NotificationsModule.forRoot(),
    OmnisearchModule,
  ],
  exports: [SidebarComponent, TopbarComponent],
})
export class CoreModule {}
