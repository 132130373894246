import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectUser } from '@techspert-io/auth';
import { IOpportunitySegment } from '@techspert-io/opportunity-segments';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { SegmentKickOff } from '../../../models/segment-data.models';

export interface ISegmentDialogData {
  segment?: SegmentKickOff;
  assignees: ConnectUser[];
  defaultConferenceTopic: string;
  defaultPitch: string;
  isNDAssignment: boolean;
}

@Component({
  selector: 'app-segment-dialog',
  templateUrl: './segment-dialog.component.html',
  styleUrls: ['./segment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentDialogComponent implements OnInit {
  users$: Observable<ConnectUser[]>;

  ownersForm = new FormGroup({
    owner: new FormControl<string[]>([]),
    cover: new FormControl<string[]>([]),
    conferenceTopic: new FormControl<string>(null),
    pitch: new FormControl<string>(null),
    availabilityStart: new FormControl<string>(null),
    availabilityEnd: new FormControl<string>(null),
    referralCurrency: new FormControl<string>(null),
    referralFee: new FormControl<number>(null),
    referralHonoraria: new FormControl<number>(null),
  });

  get assignees(): ConnectUser[] {
    return this.data.assignees;
  }

  get segmentName(): string {
    return this.data.segment
      ? `${this.data.segment.geography} ${this.data.segment.segment}`
      : 'Bulk Update';
  }

  get ownerArray(): string[] {
    return Array.isArray(this.ownersForm.value.owner)
      ? this.ownersForm.value.owner
      : [this.ownersForm.value.owner].filter(Boolean);
  }

  constructor(
    private dialogRef: MatDialogRef<
      SegmentDialogComponent,
      Omit<ISegmentDialogData['segment'], keyof IOpportunitySegment>
    >,
    @Inject(MAT_DIALOG_DATA) public data: ISegmentDialogData
  ) {}

  ngOnInit(): void {
    const { isNDAssignment, segment, defaultConferenceTopic, defaultPitch } =
      this.data;

    this.ownersForm.patchValue({
      conferenceTopic:
        segment?.conferenceTopic || defaultConferenceTopic || null,
      pitch: segment?.pitch || defaultPitch || null,
    });

    if (segment) {
      const availabilityStart = segment.availabilityStart
        ? moment(segment.availabilityStart).format('YYYY-MM-DD')
        : null;
      const availabilityEnd = segment.availabilityEnd
        ? moment(segment.availabilityEnd).format('YYYY-MM-DD')
        : null;

      this.ownersForm.patchValue({
        owner: (segment.owner || []).filter(Boolean),
        cover: segment.cover || [],
        availabilityStart,
        availabilityEnd,
        referralCurrency: segment.referralCurrency || null,
        referralFee: segment.referralFee || null,
        referralHonoraria: segment.referralHonoraria || null,
      });
    }

    // JACK: to be uncommented with ticket ENG-1826
    // if (isNDAssignment) {
    this.ownersForm.get('owner').setValidators(Validators.required);
    this.ownersForm.get('conferenceTopic').setValidators(Validators.required);
    // } else {
    //   this.ownersForm.get('owner').clearValidators();
    //   this.ownersForm.get('conferenceTopic').clearValidators();
    // }

    this.ownersForm.get('owner').updateValueAndValidity();
    this.ownersForm.get('conferenceTopic').updateValueAndValidity();

    this.ownersForm.markAllAsTouched();
  }

  onSubmit(): void {
    if (this.ownersForm.valid) {
      this.dialogRef.close({
        ...this.ownersForm.value,
        owner: this.ownerArray,
        cover: this.ownersForm.value.cover,
        salesforceId: this.data.segment?.salesforceId,
        ...(this.ownersForm.value.availabilityStart &&
        this.ownersForm.value.availabilityEnd
          ? {
              availabilityStart: moment(this.ownersForm.value.availabilityStart)
                .endOf('d')
                .toISOString(),
              availabilityEnd: moment(this.ownersForm.value.availabilityEnd)
                .endOf('d')
                .toISOString(),
            }
          : {}),
      });
    }
  }
}
