import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { IOpportunitySegmentConfigExternalSurvey } from '@techspert-io/opportunity-segments';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

type SurveyHost = 'Decipher' | 'ConfirmIT' | 'unknown';
type ValidationType = 'always' | 'hasValue';

@Component({
  selector: 'opp-survey-settings-form',
  templateUrl: './survey-settings-form.component.html',
  styleUrls: ['./survey-settings-form.component.scss'],
})
export class SurveySettingsFormComponent implements OnInit, OnDestroy {
  @Input() validationType: ValidationType = 'always';

  private destroy$ = new Subject();

  get oppForm(): FormGroup {
    return this.rootFormGroup.control;
  }

  get defaultSurveyProviderCtrl(): FormControl {
    return this.oppForm.get('defaultSurveyProvider') as FormControl;
  }

  get isExternal(): boolean {
    return this.defaultSurveyProviderCtrl.value === 'External';
  }

  get surveyConfigForm(): FormGroup {
    return this.rootFormGroup.control.get('externalSurveyConfig') as FormGroup;
  }

  get linkErrors(): string {
    return this.surveyConfigForm.get('linkInput').errors?.urlError;
  }

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.surveyConfigForm.addControl('linkInput', new FormControl());
    this.surveyConfigForm.addValidators(
      this.surveyFormValidator(this.validationType)
    );
    this.setupLinkInputCtrl();

    this.defaultSurveyProviderCtrl.valueChanges
      .pipe(
        tap(() => this.surveyConfigForm.updateValueAndValidity()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private surveyFormValidator(validationType: ValidationType): ValidatorFn {
    return (ctrl): ValidationErrors => {
      const formValues = ctrl.value as IOpportunitySegmentConfigExternalSurvey;
      const linkInput = ctrl.get('linkInput');

      if (this.isExternal && (validationType === 'always' || linkInput.value)) {
        if (!linkInput.value) {
          linkInput.setErrors({ urlError: 'Enter a URL' });
          return {};
        }

        try {
          new URL(linkInput.value);
        } catch (error) {
          linkInput.setErrors({ urlError: 'Enter a valid URL' });
          return {};
        }

        if (
          !Object.values(formValues.linkParams || {}).some(
            (v) => v === '[action_id]'
          )
        ) {
          linkInput.setErrors({
            urlError: "Cannot find '[action_id]' (eg techID, user_id)",
          });
          return {};
        }
      }

      linkInput.setErrors(null);
      return null;
    };
  }

  private setupLinkInputCtrl(): void {
    const linkInputCtrl = this.surveyConfigForm.get('linkInput');

    linkInputCtrl.valueChanges
      .pipe(
        map((val) => {
          try {
            return new URL(val);
          } catch (error) {
            return new URL('http://a.example');
          }
        }),
        tap((url) => this.updateForm(url)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    linkInputCtrl.setValue(this.buildLinkInput(this.surveyConfigForm.value));
  }

  private buildLinkInput(opp: IOpportunitySegmentConfigExternalSurvey): string {
    if (!opp.linkBase) {
      return null;
    }
    const params = new URLSearchParams(opp.linkParams);
    return `${opp.linkBase}?${params}`;
  }

  private updateForm(url: URL): void {
    const surveyType = this.getSurveyType(url.hostname);
    const surveyId = this.getSurveyId(surveyType, url.pathname);

    const paramsMap = [...url.searchParams.entries()].reduce(
      (prev, [id, val]) => ({
        ...prev,
        [id]: ['techID', 'user_id'].includes(id) ? '[action_id]' : val,
      }),
      {}
    );

    if (Object.values(paramsMap).some((p) => p === '[action_id]')) {
      this.surveyConfigForm.patchValue(
        {
          type: surveyType,
          linkBase: `${url.origin}${url.pathname}`,
          linkParams: paramsMap,
          surveyId: surveyId,
        },
        { emitEvent: false }
      );
    } else {
      this.surveyConfigForm.patchValue(
        {
          type: null,
          linkBase: null,
          linkParams: null,
          surveyId: null,
        },
        { emitEvent: false }
      );
    }
  }

  private getSurveyId(surveyType: SurveyHost, pathname: string): string {
    if (surveyType === 'Decipher') {
      return pathname.substring('/survey/'.length);
    }
    if (surveyType === 'ConfirmIT') {
      return pathname.substring('/wix/'.length).split('.').find(Boolean);
    }
    return 'unknown';
  }

  private getSurveyType(hostname: string): SurveyHost {
    if (hostname.includes('decipherinc')) {
      return 'Decipher';
    }
    if (hostname.includes('confirmit')) {
      return 'ConfirmIT';
    }
    return 'unknown';
  }
}
