import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OutreachTemplatesCreatePageComponent } from './components/pages/outreach-templates-create-page/outreach-templates-create-page.component';
import { OutreachTemplatesEditPageComponent } from './components/pages/outreach-templates-edit-page/outreach-templates-edit-page.component';
import { OutreachTemplatesLandingPageComponent } from './components/pages/outreach-templates-landing-page/outreach-templates-landing-page.component';
import { OutreachTemplatesPageComponent } from './components/pages/outreach-templates-page/outreach-templates-page.component';

const routes: Routes = [
  {
    path: '',
    component: OutreachTemplatesLandingPageComponent,
    children: [
      {
        path: '',
        component: OutreachTemplatesPageComponent,
      },
      {
        path: 'create',
        component: OutreachTemplatesCreatePageComponent,
      },
      {
        path: ':templateId',
        component: OutreachTemplatesEditPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OutreachTemplatesRoutingModule {}
