import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MaterialModule } from '../../shared/patterns/material-module';
import { ExpertProfileLocationFormComponent } from './components/expert-profile-location-form/expert-profile-location-form.component';
import { ExpertSpecialismFormComponent } from './components/expert-specialism-form/expert-specialism-form.component';
import { ExpertProfilesEffects } from './reducers/expert-profiles.effects';
import { expertProfilesReducer } from './reducers/expert-profiles.reducer';
import { ExpertProfilesService } from './services/expert-profiles.service';

const declarations = [
  ExpertProfileLocationFormComponent,
  ExpertSpecialismFormComponent,
];

@NgModule({
  declarations,
  exports: declarations,
  imports: [
    CommonModule,
    MaterialModule,
    StoreModule.forFeature('expertProfiles', expertProfilesReducer),
    EffectsModule.forFeature(ExpertProfilesEffects),
  ],
  providers: [ExpertProfilesService],
})
export class ExpertProfilesModule {}
