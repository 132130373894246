import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConnectUser } from '@techspert-io/auth';
import { IClient } from '@techspert-io/clients';
import {
  IOpportunity,
  OpportunitiesService,
} from '@techspert-io/opportunities';
import { UserService } from '@techspert-io/users';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

export interface IConferenceFilter {
  clientId: string;
  opportunityId: string;
  ownerUserId: string;
}

@Component({
  selector: 'app-conference-filter',
  templateUrl: './conference-filter.component.html',
  styleUrls: ['./conference-filter.component.scss'],
})
export class ConferenceFilterComponent implements OnInit {
  @Input() clients: IClient[];
  @Output() onFilterChange = new EventEmitter<IConferenceFilter>();
  filteredOpportunities$: Observable<IOpportunity[]>;
  users$: Observable<ConnectUser[]>;

  filterForm: FormGroup = new FormGroup({
    client: new FormControl(null),
    opportunity: new FormControl(null),
    user: new FormControl(null),
  });

  constructor(
    public opportunityService: OpportunitiesService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.filteredOpportunities$ = this.filterForm
      .get('client')
      .valueChanges.pipe(
        switchMap(
          (client): Observable<IOpportunity[]> =>
            client && client.clientId
              ? this.opportunityService.query({ clientIds: [client.clientId] })
              : of([])
        ),
        map((opportunities) =>
          opportunities.sort((a, b) =>
            a.opportunityName.localeCompare(b.opportunityName)
          )
        )
      );

    this.users$ = combineLatest([
      this.userService.getAll({ userTypes: ['PM', 'ND', 'ND-Manager'] }),
      this.filterForm.get('user').valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([users, searchValue]) =>
        users.filter((user) =>
          `${user.firstName} ${user.lastName}`
            .toLowerCase()
            .includes((searchValue || '').toLowerCase())
        )
      )
    );

    this.filterForm.valueChanges.subscribe(({ client, opportunity, user }) => {
      this.onFilterChange.emit({
        clientId: client ? client?.clientId : null,
        opportunityId: opportunity ? opportunity?.opportunityId : null,
        ownerUserId: user ? user?.connectId : null,
      });
    });
  }

  selectedOpportunityName(opportunity: IOpportunity): string {
    return opportunity ? opportunity.opportunityName : '';
  }

  selectedClientName(client: IClient): string {
    return client ? client.clientName : '';
  }

  selectedUser(user: ConnectUser): string {
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  resetFilter(): void {
    this.filterForm.reset();
  }
}
