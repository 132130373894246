import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ExpertSourceDialogComponent } from '../components/expert-source-dialog/expert-source-dialog.component';
import { ExpertSourcesActions } from '../reducers/expert-sources.actions';

@Directive({
  selector: '[txpOpenExpertSource]',
})
export class OpenExpertSourceDirective {
  @Input('txpOpenExpertSource') expertSourceId: string;

  constructor(private dialog: MatDialog, private store: Store) {}

  @HostListener('click')
  openModel(): void {
    this.store.dispatch(
      ExpertSourcesActions.fetchExpertSource({
        expertSourceId: this.expertSourceId,
      })
    );

    this.dialog.open(ExpertSourceDialogComponent, {
      minWidth: '780px',
      height: 'max-content',
    });
  }
}
