import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CognitoAuthService } from '@techspert-io/auth';
import { FileStoreService } from '@techspert-io/file-store';
import { ICommercialUploadProfile } from '@techspert-io/search';
import { ToastService } from '@techspert-io/user-alerts';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';
import { EMPTY, forkJoin } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppService } from '../../../shared/services/app.service';
import {
  CountryService,
  ICountry,
} from '../../../shared/services/country.service';
import {
  IFalconSearchQuery,
  IField,
  ILinkedInEnrichmentQuery,
  INPIEnrichmentQuery,
  ISearchCondition,
  ISearchQuery,
} from '../models/query';

type SearchServices =
  | 'linkedInUpload'
  | 'NPIUpload'
  | 'falcon-search'
  | 'cognisearch';

export interface IQueryCreatorCondition {
  terms: string[];
  fieldValue: string;
  fieldDisplay: string;
  operator: string;
  id: string;
  level?: string;
}

@Component({
  selector: 'app-query-creator',
  templateUrl: './query-creator.component.html',
  styleUrls: ['./query-creator.component.scss'],
})
export class QueryCreatorComponent implements AfterViewInit, OnChanges {
  @ViewChild('UploadFileInput') uploadFileInputRef: ElementRef;
  @ViewChild('UploadFileInputSearch') uploadFileInputSearchRef: ElementRef;
  @ViewChild('FileInput') uploadCsvInputRef: ElementRef;
  @Output() payloadSignal = new EventEmitter<
    | ISearchQuery
    | INPIEnrichmentQuery
    | ILinkedInEnrichmentQuery
    | IFalconSearchQuery
  >();
  @Output() serviceChangeSignal = new EventEmitter();
  @Input() showSearchLoader: boolean;
  @Input() initQuery: ISearchQuery;
  @Input() expertise: string[];
  @Input() hideServiceAndCount = false;
  @Input() addTerms: string[];
  @Input() services: SearchServices[] = [
    'cognisearch',
    'linkedInUpload',
    'NPIUpload',
    'falcon-search',
  ];
  service: SearchServices;
  field: IField;
  fields: IField[] = [];
  conditions: IQueryCreatorCondition[] = [];
  expertTargetNumber: number;
  countries: ICountry[] = [];
  selectedCountries: string[] = [];
  subdivisions: string[] = [];
  selectedSubdivisions: string[] = [];
  selectedTerms: string[] = [];
  searchExpansion: boolean = false;
  disable: boolean = false;
  npiNumbers: string[] = [];
  commercialProfiles: ICommercialUploadProfile[] = [];
  targetConditionId: string;
  private clipboard: Clipboard = navigator.clipboard;

  constructor(
    public toastService: ToastService,
    public countryService: CountryService,
    public appService: AppService,
    private ngxCsvParser: NgxCsvParser,
    private cdref: ChangeDetectorRef,
    private fileStore: FileStoreService,
    private cognitoAuthService: CognitoAuthService
  ) {}

  ngAfterViewInit(): void {
    this.countries = this.countryService.countryList;
    this.subdivisions = this.countryService.getUSStateNames();
    this.reset();
    this.setupConditionFields();

    this.initQuery && this.deconstructUploadedSearch(this.initQuery);
    this.cdref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addTerms?.currentValue.length) {
      if (this.targetConditionId) {
        for (const term of changes.addTerms.currentValue) {
          this.addConditionTerm(this.targetConditionId, term);
        }
      } else {
        this.toastService.sendMessage(
          'Please select a condition to add terms to',
          'error'
        );
      }
    }

    if (changes.initQuery?.currentValue) {
      this.deconstructUploadedSearch(changes.initQuery.currentValue);
    }

    if (changes.expertise?.currentValue.length) {
      if (!(this.conditions || []).some((c) => c.fieldValue === 'expertise')) {
        this.conditions = [
          ...this.conditions,
          {
            id: this.appService.createUUID(),
            terms: changes.expertise.currentValue,
            fieldValue: 'expertise',
            fieldDisplay: 'Expertise',
            operator: 'or',
          },
        ];
      } else {
        const idx = this.conditions.findIndex(
          (c) => c.fieldValue === 'expertise'
        );

        this.conditions[idx].terms = [
          ...new Set([
            ...this.conditions[idx].terms,
            ...changes.expertise.currentValue,
          ]),
        ];
      }
    }
  }

  public onServiceChange(newService: SearchServices): void {
    this.conditions = [];
    this.service = newService;
    this.searchExpansion = false;
    this.serviceChangeSignal.emit();
  }

  public reset(): void {
    this.service = 'cognisearch';
    this.disable = false;
  }

  public triggerUploadCsvToSearch(): void {
    this.uploadFileInputSearchRef.nativeElement.click();
  }

  public triggerUploadSearch(): void {
    this.uploadFileInputRef.nativeElement.click();
  }

  public triggerUploadCSV(): void {
    this.uploadCsvInputRef.nativeElement.click();
  }

  public addCondition(): void {
    const id = this.appService.createUUID();

    this.conditions.unshift({
      id,
      terms: [],
      ...this.field,
    });
    this.targetConditionId = id;
  }

  public removeCondition(id: string): void {
    if (!this.disable) {
      this.conditions = this.conditions.filter(
        (conditionObj) => conditionObj.id !== id
      );
    }

    if (this.targetConditionId === id) {
      this.targetConditionId = undefined;
    }
  }

  public addConditionTerm(id: string, term: string): void {
    const formattedTerm = term.toLowerCase().trim();

    if (formattedTerm) {
      this.conditions = this.conditions.map((condition) => {
        if (condition.id === id && !condition.terms.includes(formattedTerm)) {
          condition.terms = [...condition.terms, formattedTerm];
        }
        return condition;
      });
    }
  }

  public removeConditionTerm(id: string, removalTerm: string): void {
    this.conditions = this.conditions.map((condition) => {
      if (condition.id === id) {
        condition.terms = condition.terms.filter(
          (term) => term !== removalTerm
        );
      }
      return condition;
    });
  }

  public copy(condition: IQueryCreatorCondition): void {
    const terms = condition.terms.join('| ');
    this.clipboard.writeText(terms).then(() => {
      this.toastService.sendMessage(
        'Successfully copied search terms to clipboard',
        'success'
      );
    });
  }

  public onListChange(event: string[], id: string): void {
    this.conditions = this.conditions.map((condition) => {
      if (condition.id === id) {
        condition.terms = event;
      }
      return condition;
    });
  }

  public assignSelectedCountry(country: ICountry): void {
    this.selectedCountries = [...this.selectedCountries, country.abbreviation];
  }

  public removeCountry(removalAlpha2Code: string): void {
    this.selectedCountries = this.selectedCountries.filter(
      (alpha2Code) => alpha2Code !== removalAlpha2Code
    );
  }

  public assignSelectedSubdivision(subdivision: string): void {
    this.selectedSubdivisions = [...this.selectedSubdivisions, subdivision];
  }

  public removeSubdivision(subdivision: string): void {
    this.selectedSubdivisions = this.selectedSubdivisions.filter(
      (s) => s !== subdivision
    );
  }

  public assignSelectedTerms(term: string): void {
    this.selectedTerms = [...this.selectedTerms, term];
  }

  public removeTerm(term: string): void {
    this.selectedTerms = this.selectedTerms.filter((s) => s !== term);
  }

  public toggleSearchExpansion(): void {
    this.searchExpansion = !this.searchExpansion;
  }

  public uploadSearch(target: HTMLInputElement): void {
    const file = target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (): void => {
      const result = JSON.parse(reader.result as string);
      this.deconstructUploadedSearch(result);
    };
  }

  public parseCsv(target: HTMLInputElement): void {
    if (this.service === 'NPIUpload') {
      this.parseCsvForNPI(target.files);
    }

    if (this.service === 'linkedInUpload') {
      this.parseForCommercialUpload(target.files);
    }
  }

  private parseForCommercialUpload(files: FileList) {
    const sources = Array.from(files).map((file) =>
      this.ngxCsvParser.parse(file, {
        header: true,
        delimiter: ',',
      })
    );

    forkJoin(sources)
      .pipe(
        map((res: ICommercialUploadProfile[][]) => res.flat()),
        tap((res) => {
          this.commercialProfiles = res.filter((e) =>
            e.profileUrl?.includes('linkedin.com')
          );
          if (!res.length) {
            throw new Error('No profiles found in the uploaded file');
          }

          if (!this.commercialProfiles.length) {
            throw new Error(
              'No LinkedIn URLs found in the uploaded file, please ensure a profileUrl header is present'
            );
          }

          if (this.commercialProfiles.length < res.length) {
            this.toastService.sendMessage(
              `Skipping ${
                res.length - this.commercialProfiles.length
              } profiles without profileUrl`,
              'error'
            );
          }

          this.submitQuery();
        }),
        catchError((error) => {
          error.message
            ? this.toastService.sendMessage(error.message, 'error')
            : null;

          return EMPTY;
        })
      )
      .subscribe();
  }

  private parseCsvForNPI(files: FileList) {
    const sources = Array.from(files).map((file) =>
      this.ngxCsvParser.parse(file, {
        header: false,
        delimiter: ',',
      })
    );

    forkJoin(sources)
      .pipe(
        map((res: string[][]) => res.flat()),
        switchMap((result) => this.uploadNPIToSearch(files, result)),
        tap((result: string[]) => {
          this.npiNumbers = [...new Set(result.flat().filter(Boolean))].filter(
            (e) => /^\d{10}$/.test(e)
          );

          if (!this.npiNumbers.length) {
            this.toastService.sendMessage(
              `Experts could not be uploaded because no NPI numbers could be found`,
              'error'
            );
          } else if (this.npiNumbers.length <= 5000) {
            this.submitQuery();
          } else {
            this.toastService.sendMessage(
              'You can only insert 5000 attributes per upload',
              'error'
            );
          }
        }),
        catchError((error) => {
          error.message
            ? this.toastService.sendMessage(error.message, 'error')
            : null;

          return EMPTY;
        })
      )
      .subscribe();
  }

  public exportSearch(): void {
    const date = new Date().toISOString().slice(0, 10);
    if (this.conditions.length > 0) {
      let exportPayload: ISearchQuery;
      if (this.service === 'cognisearch') {
        exportPayload = this.createSearchPayload();
      }
      const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(exportPayload)
      )}`;
      const dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute('href', dataStr);
      dlAnchorElem.setAttribute('download', `search-query-${date}.json`);
      dlAnchorElem.click();
    }
  }

  public isSearchValid(): boolean {
    if (this.service === 'falcon-search') {
      return !!(
        this.expertTargetNumber > 0 &&
        this.expertTargetNumber <= 1000 &&
        this.selectedCountries.length &&
        this.selectedTerms.length
      );
    }

    return (
      this.expertTargetNumber > 0 &&
      this.expertTargetNumber <= 10000 &&
      this.countries &&
      this.conditions.length &&
      this.conditions.every((c) => c.terms.length)
    );
  }

  public deconstructUploadedSearch(
    query: ISearchQuery & { countries?: string[] }
  ): void {
    if (query.service !== 'cognisearch') {
      return;
    }

    this.service = query.service;
    this.expertTargetNumber = query.count;
    this.selectedCountries = query.locations
      ? [...new Set(query.locations?.map((l) => l.country))]
      : query.countries
      ? query.countries
      : [];
    this.selectedSubdivisions =
      [...new Set(query.locations?.map((l) => l.subdivision))].filter(
        Boolean
      ) || [];
    this.selectedTerms = query.searchTerms || [];
    this.conditions = this.deconstructQueryConditions(query.conditions || []);
    if (query.service === 'cognisearch') {
      this.searchExpansion = query.searchExpansion;
    }
  }

  public submitQuery(): void {
    if (this.service === 'linkedInUpload') {
      this.payloadSignal.emit({
        service: 'pdl-enrichment',
        attributes: this.commercialProfiles,
        pdlEnrichmentService: 'linkedin_urls',
      });
    } else if (this.service === 'NPIUpload') {
      this.payloadSignal.emit({
        service: 'pdl-enrichment',
        attributes: this.npiNumbers,
        pdlEnrichmentService: 'npi_numbers',
      });
    } else if (this.service === 'cognisearch') {
      const payload = this.createSearchPayload();

      if (this.payloadValidator(payload)) {
        this.payloadSignal.emit(payload);
      }
    } else if (this.service === 'falcon-search') {
      this.payloadSignal.emit({
        service: 'falcon-search',
        size: this.expertTargetNumber,
        countries: this.selectedCountries,
        searchTerms: this.selectedTerms,
      });
    }
  }

  private setupConditionFields(): void {
    const fields: IField[] = [
      { fieldValue: 'expertise', fieldDisplay: 'Expertise', operator: 'or' },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Affiliation',
        operator: 'or',
      },
      { fieldValue: 'role', fieldDisplay: 'Role', operator: 'or' },
      { fieldValue: 'name', fieldDisplay: 'Name', operator: 'or' },
    ];

    const cogniFields: IField[] = [
      {
        fieldValue: 'current_affiliation',
        fieldDisplay: 'Current affiliation',
        operator: 'or',
      },
      {
        fieldValue: 'past_affiliation',
        fieldDisplay: 'Past affiliation',
        operator: 'or',
      },
      {
        fieldValue: 'current_role',
        fieldDisplay: 'Current role',
        operator: 'or',
      },
      {
        fieldValue: 'past_role',
        fieldDisplay: 'Past role',
        operator: 'or',
      },
    ];

    const deprioritiseFields: IField[] = [
      {
        fieldValue: 'expertise',
        fieldDisplay: 'Deprioritise expertise',
        operator: 'not',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Deprioritise affiliation',
        operator: 'not',
      },
      {
        fieldValue: 'role',
        fieldDisplay: 'Deprioritise role',
        operator: 'not',
      },
    ];

    const excludeFields: IField[] = [
      {
        fieldValue: 'expertise',
        fieldDisplay: 'Exclude expertise',
        operator: 'exclude',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Exclude affiliation',
        operator: 'exclude',
      },
      {
        fieldValue: 'role',
        fieldDisplay: 'Exclude role',
        operator: 'exclude',
      },
      {
        fieldValue: 'current_affiliation',
        fieldDisplay: 'Exclude current affiliation',
        operator: 'exclude',
      },
      {
        fieldValue: 'past_affiliation',
        fieldDisplay: 'Exclude past affiliation',
        operator: 'exclude',
      },
      {
        fieldValue: 'current_role',
        fieldDisplay: 'Exclude current role',
        operator: 'exclude',
      },
      {
        fieldValue: 'past_role',
        fieldDisplay: 'Exclude past role',
        operator: 'exclude',
      },
    ];

    this.fields = [
      ...fields,
      ...cogniFields,
      ...deprioritiseFields,
      ...excludeFields,
    ];

    this.field = this.fields[0];
  }

  private deconstructQueryConditions(
    conditions: ISearchCondition[]
  ): IQueryCreatorCondition[] {
    return conditions.map((condition) => {
      if (condition.operator === 'not') {
        const field = this.fields.find(
          (field) =>
            field.fieldValue === condition.field && field.operator === 'not'
        );
        return {
          id: this.appService.createUUID(),
          terms: condition.terms,
          ...field,
        };
      }
      if (condition.operator === 'exclude') {
        const field = this.fields.find(
          (field) =>
            field.fieldValue === condition.field && field.operator === 'exclude'
        );
        return {
          id: this.appService.createUUID(),
          terms: condition.terms,
          ...field,
        };
      }
      const field = this.fields.find(
        (field) =>
          field.fieldValue === condition.field && field.operator === 'or'
      );
      return {
        id: this.appService.createUUID(),
        terms: condition.terms,
        ...field,
      };
    });
  }

  private payloadValidator(payload: ISearchQuery): boolean {
    if (this.hasNoTerms(payload.conditions)) {
      this.toastService.sendMessage('No terms have been specified', 'error');
      return false;
    }

    if (payload.service === 'cognisearch') {
      if (this.hasOnlyAvoidConditions(payload.conditions)) {
        this.toastService.sendMessage(
          'You can not run an exclude only query',
          'error'
        );
        return false;
      }
    }

    return true;
  }

  private hasNoTerms(conditions: { terms: string[] }[]): boolean {
    return conditions.every((condition) => !condition.terms.length);
  }

  private hasOnlyAvoidConditions(conditions: { operator: string }[]): boolean {
    return conditions.every((condition) => condition.operator === 'exclude');
  }

  private createSearchPayload(): ISearchQuery {
    return {
      service: 'cognisearch',
      count: this.expertTargetNumber,
      locations: this.selectedSubdivisions.length
        ? this.selectedCountries
            .filter((c) => c !== 'US')
            .map((c) => ({ country: c }))
            .concat(
              this.selectedSubdivisions.map((s) => ({
                country: 'US',
                subdivision: s,
              }))
            )
        : this.selectedCountries.map((c) => ({ country: c })),
      conditions: this.formatConditions(),
      searchExpansion: this.searchExpansion,
    };
  }

  private formatConditions(): ISearchCondition[] {
    return this.conditions.map((condition) => ({
      operator: condition.operator,
      field: condition.fieldValue,
      terms: condition.terms.map((term) => term.toLowerCase().trim()),
    }));
  }

  private uploadNPIToSearch(
    files: FileList,
    result: unknown[] | NgxCSVParserError
  ) {
    return this.fileStore
      .uploadFiles(
        Array.from(files),
        'commercial-upload',
        this.cognitoAuthService.loggedInUser.connectId
      )
      .pipe(
        tap((fileEvents) => {
          if (fileEvents.some((e) => e.status === 'failed')) {
            this.sendFileUploadFailedToast();
          }
        }),
        filter((fileEvents) =>
          fileEvents.every((d) => d.status === 'complete')
        ),
        catchError(() => {
          this.sendFileUploadFailedToast();

          return EMPTY;
        }),
        map(() => result)
      );
  }

  private sendFileUploadFailedToast() {
    this.toastService.sendMessage(
      'Upload could not be completed - please ask tech-support for help',
      'error'
    );
  }
}
