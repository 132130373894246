import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromExperProfiles from './expert-profiles.reducer';
import { IExpertProfileState } from './expert-profiles.state';

const selectState =
  createFeatureSelector<IExpertProfileState>('expertProfiles');

export const selectAllExpertProfiles = createSelector(selectState, (state) =>
  fromExperProfiles.selectAllExpertProfiles(state)
);

export const selectSelectedExpertId = createSelector(
  selectState,
  fromExperProfiles.getSelectedExpertProfileId
);

export const selectExpertProfileEntities = createSelector(
  selectState,
  fromExperProfiles.selectExpertProfileEntities
);

export const selectCurrentExpertProfile = createSelector(
  selectExpertProfileEntities,
  selectSelectedExpertId,
  (entities, id) => entities[id]
);

export const selectExpertProfileLoadingState = createSelector(
  selectState,
  (state) => ({
    isLoaded: state.isLoaded,
    isLoading: state.isLoading,
    loadError: state.loadError,
    updateErrors: state.updateErrors,
  })
);
