<div class="ct-page-container flex flex-col gap-5">
  <h1>Calls</h1>

  <mat-card>
    <mat-card-content>
      <div *ngIf="showLoader; else calander_render" [style.height]="'500px'"
        class="flex justify-center content-center items-center flex-col">
        <app-scintillating-loader>
        </app-scintillating-loader>
      </div>
      <ng-template #calander_render>
        <div class="flex flex-col gap-5 h-full w-full min-h-full min-w-full">
          <app-conference-filter (onFilterChange)="onFilterChange($event)" [clients]="clients">
          </app-conference-filter>

          <app-shared-calendar [events]="displayCalendarEvents" (eventClickSignal)="openViewConnectionDialog($event)">
          </app-shared-calendar>
        </div>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>