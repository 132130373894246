<form [formGroup]="oppForm" class="flex flex-col gap-2">
  <mat-form-field class="flex-1">
    <mat-label data-test-id="survey-settings-form-default-survey-provider-label">Default Survey Provider</mat-label>
    <mat-select formControlName="defaultSurveyProvider"
      data-test-id="survey-settings-form-default-survey-provider-input">
      <mat-option *ngFor="let option of ['Qualtrics', 'External']" [value]="option"
        data-test-id="survey-settings-form-default-survey-provider-option">
        {{option}}
      </mat-option>
    </mat-select>
    <mat-error data-test-id="survey-settings-form-default-survey-provider-required-error"> Required </mat-error>
  </mat-form-field>

  <form [formGroup]="surveyConfigForm" class="flex flex-col gap-2">
    <ng-container *ngIf="isExternal">

      <mat-form-field class="flex-1">
        <mat-label data-test-id="survey-settings-form-survey-link-label">Survey Link</mat-label>
        <input matInput formControlName="linkInput" data-test-id="survey-settings-form-survey-link-input">
        <mat-error data-test-id="survey-settings-form-survey-link-error"> {{linkErrors}} </mat-error>
      </mat-form-field>

      <div class="flex flex-row gap-4">
        <div class="basis-3/4">
          <h3>Base Url</h3>
          <p data-test-id="survey-settings-form-base-url">{{surveyConfigForm.value.linkBase || 'PENDING'}}</p>
          <h3>Survey Id</h3>
          <p data-test-id="survey-settings-form-survey-id">{{surveyConfigForm.value.surveyId || 'PENDING'}}</p>
        </div>

        <div>
          <h3>Keys</h3>
          <p *ngFor="let s of surveyConfigForm.value.linkParams | keyvalue"
            data-test-id="survey-settings-form-query-param">
            {{s.key}}={{s.value}}
          </p>
        </div>
      </div>

    </ng-container>
  </form>
</form>