import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService as Auth0Service, IdToken } from '@auth0/auth0-angular';
import { Observable, of, ReplaySubject } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { IAuthRole, IUserBase } from '../models/user';
import { AUTH0_DOMAIN, LOCAL_STORAGE } from './token';

type Claims = Pick<IUserBase, 'connectId' | 'roles'>;

@Injectable()
export class CognitoAuthService {
  private readonly redirectKeyName = 'redirect_url';

  loggedInUser: IUserBase;
  loggedIn$ = new ReplaySubject<boolean>(1);
  authError$ = this.auth0Service.error$;

  constructor(
    private http: HttpClient,
    private auth0Service: Auth0Service,
    @Inject(LOCAL_STORAGE) private storage: Storage,
    @Inject(AUTH0_DOMAIN) private auth0Domain: string
  ) {}

  initAuth(): Observable<IUserBase> {
    return this.auth0Service.idTokenClaims$.pipe(
      map((claims) => this.getTokenClaims(claims)),
      exhaustMap((claims) => this.getConnectUser(claims)),
      tap((user) => (this.loggedInUser = user)),
      tap((user) => this.loggedIn$.next(!!user))
    );
  }

  logOut(): Observable<void> {
    this.loggedInUser = null;
    this.loggedIn$.next(false);
    this.setRedirectUrl(null);

    return this.auth0Service.logout();
  }

  getAccessToken(): Observable<string> {
    return this.auth0Service.getAccessTokenSilently();
  }

  getRedirectUrl(): string {
    return this.storage.getItem(this.redirectKeyName);
  }

  setRedirectUrl(url: string | null): void {
    if (url) {
      this.storage.setItem(this.redirectKeyName, url);
    } else {
      this.storage.removeItem(this.redirectKeyName);
    }
  }

  listRoles(): Observable<IAuthRole[]> {
    return this.http.get<IAuthRole[]>('/users/roles');
  }

  private getTokenClaims(idToken: IdToken): Claims {
    return {
      connectId: (idToken || {})[`https://${this.auth0Domain}/connectId`],
      roles: (idToken || {})[`https://${this.auth0Domain}/roles`],
    };
  }

  private getConnectUser(claims?: Claims): Observable<IUserBase | null> {
    if (!claims.connectId) {
      return of(null);
    }

    return this.http
      .get<IUserBase>(`/users/${claims.connectId}`)
      .pipe(map((user) => ({ ...user, roles: claims.roles })));
  }
}
