import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeWhile, tap } from 'rxjs/operators';
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from '../../../../../shared/patterns/confirmation-dialog/confirmation-dialog.component';
import { AppService } from '../../../../../shared/services/app.service';
import { IOutreachTemplate } from '../../../models/outreach-templates.models';

@Component({
  selector: 'app-outreach-template-form',
  templateUrl: './outreach-template-form.component.html',
  styleUrls: ['./outreach-template-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutreachTemplateFormComponent implements OnInit {
  @Input() template?: IOutreachTemplate;
  @Input() isLoading = false;
  @Output() submitTemplate = new EventEmitter<Partial<IOutreachTemplate>>();

  templateForm = new FormGroup({
    title: new FormControl<string>(null, [Validators.required]),
    description: new FormControl<string>(null, [Validators.required]),
    subject: new FormControl<string>(null, [Validators.required]),
    preheader: new FormControl<string>(null),
    content: new FormControl<string>(null, [Validators.required]),
    parentId: new FormControl<string>(null),
  });

  get isEditMode(): boolean {
    return !!this.template?.templateId;
  }

  constructor(private appService: AppService, private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.template) {
      this.templateForm.patchValue({
        title: this.template.title,
        description: this.template.description,
        subject: this.template.subject,
        preheader: this.template.preheader,
        content: this.template.content,
        parentId: this.template.parentId,
      });
    }
  }

  submitForm(): void {
    this.templateForm.markAllAsTouched();
    if (this.templateForm.valid) {
      this.submitTemplate.emit({
        ...this.appService.removeUnchangedProps(
          this.templateForm.value,
          this.template || {}
        ),
        ...(this.template?.templateId
          ? { templateId: this.template.templateId }
          : {}),
      });
    }
  }

  deleteTemplate(): void {
    this.dialog
      .open<ConfirmationDialogComponent, IConfirmationDialogData, boolean>(
        ConfirmationDialogComponent,
        {
          data: {
            isDangerous: true,
            messages: [`Delete ${this.template.title}`],
            negativeButtonText: 'Cancel',
            positiveButtonText: 'Confirm',
          },
        }
      )
      .afterClosed()
      .pipe(
        takeWhile((res) => !!res),
        tap(() =>
          this.submitTemplate.emit({
            templateId: this.template.templateId,
            active: false,
          })
        )
      )
      .subscribe();
  }
}
