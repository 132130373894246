import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { UserJobTitle } from '@techspert-io/auth';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'jobTitle' })
export class JobTitlePipe implements PipeTransform {
  transform(jobTitle: UserJobTitle): string {
    const dict: Record<UserJobTitle, string> = {
      associate: 'Associate',
      'senior-associate': 'Senior Associate',
      'associate-project-manager': 'Associate Project Manager',
      'senior-project-manager': 'Senior Project Manager',
      internal: 'Internal',
    };

    return dict[jobTitle] || '';
  }
}
