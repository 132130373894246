import { Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { IOpportunityOptions } from '../../../models/opportunity.models';

type cbChange = (c: Partial<IOpportunityOptions>) => void;
type cbTouch = () => void;

@Component({
  selector: 'opp-settings-form-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: SettingsFormInputComponent,
      multi: true,
    },
  ],
  template: `
    <app-settings-form
      [opportunity]="oppSettings"
      [defaultConferenceProvider]="defaultConferenceProvider"
      (opportunityChange)="change($event)"
    ></app-settings-form>
  `,
})
export class SettingsFormInputComponent implements ControlValueAccessor {
  touched = false;
  oppSettings: Partial<IOpportunityOptions> = {};
  @Input() defaultConferenceProvider: string | null = null;

  set settings(val: Partial<IOpportunityOptions>) {
    this.oppSettings = val;
  }

  get settings(): FormGroup {
    return this.oppSettings as FormGroup;
  }

  onChange: cbChange = () => void 0;
  onTouch: cbTouch = () => void 0;

  writeValue(value: Partial<IOpportunityOptions>): void {
    this.settings = value;
  }

  registerOnChange(fn: cbChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: cbTouch): void {
    this.onTouch = fn;
  }

  change(settings: Partial<IOpportunityOptions>): void {
    if (!this.touched) {
      this.onTouch();
      this.touched = true;
    }
    this.onChange(settings);
  }
}
