import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MaterialModule } from '../..//shared/patterns/material-module';
import { ExpertSearchInfoComponent } from './components/expert-search-info/expert-search-info.component';
import { ExpertSourceDialogComponent } from './components/expert-source-dialog/expert-source-dialog.component';
import { OpenExpertSourceDirective } from './directives/open-expert-source.directive';
import { ExpertSourcesEffects } from './reducers/expert-sources.effects';
import { expertSourcesReducer } from './reducers/expert-sources.reducer';

@NgModule({
  declarations: [
    ExpertSearchInfoComponent,
    OpenExpertSourceDirective,
    ExpertSourceDialogComponent,
  ],
  exports: [OpenExpertSourceDirective],
  imports: [
    CommonModule,
    MaterialModule,
    StoreModule.forFeature('expertSources', expertSourcesReducer),
    EffectsModule.forFeature(ExpertSourcesEffects),
  ],
})
export class SearchModule {}
