import { Pipe, PipeTransform } from '@angular/core';
import {
  OmnisearchEntity,
  OmnisearchQueryResponse,
} from '../models/omnisearch.models';

@Pipe({
  name: 'omnisearchname',
})
export class OmnisearchNamePipe implements PipeTransform {
  transform(item: OmnisearchQueryResponse): string {
    const isHidden = 'omnisearchHidden' in item && item.omnisearchHidden;
    const prefix = isHidden ? 'HIDDEN: ' : '';

    const isOnHold =
      item.entity === OmnisearchEntity.opportunities && item.onHold;
    const postfix = isOnHold ? ' [ON-HOLD]' : '';

    if (
      item.entity === OmnisearchEntity.experts ||
      item.entity === OmnisearchEntity.solicitationExperts
    ) {
      return `${item.name} (${prefix}${item.oppName})`;
    }

    return `${prefix}${item.name}${postfix}`;
  }
}
