<form [formGroup]="outreachTemplateContentForm" class="flex flex-col gap-2">
  <mat-form-field class="flex-1">
    <mat-label data-test-id="outreach-template-content-form-subject-label">Subject</mat-label>
    <input matInput formControlName="subject" data-test-id="outreach-template-content-form-subject-input">
    <mat-error data-test-id="outreach-template-content-form-subject-error">Required</mat-error>
  </mat-form-field>
  <mat-form-field class="flex-1">
    <mat-label data-test-id="outreach-template-content-form-preheader-label">Preheader</mat-label>
    <input matInput formControlName="preheader" data-test-id="outreach-template-content-form-preheader-input">
  </mat-form-field>

  <div class="flex flex-row gap-4">
    <div class="keys-list flex flex-col gap-2 basis-[250px]">
      <h5 data-test-id="outreach-template-content-form-template-keys-title">Email Body Keys</h5>
      <ul>
        <li *ngFor="let item of defaultKeys" data-test-id="outreach-template-content-form-template-key">
          <span>{{item}}</span>
        </li>
      </ul>
    </div>

    <mat-form-field class="flex-1">
      <mat-label data-test-id="outreach-template-content-form-body-label">Body</mat-label>
      <textarea matInput formControlName="content" [style.height]="'500px'"
        data-test-id="outreach-template-content-form-body-input"></textarea>
      <mat-error data-test-id="outreach-template-content-form-body-error">Required</mat-error>
    </mat-form-field>
  </div>
</form>