import { Pipe, PipeTransform } from '@angular/core';
import { ExpertSource } from '@techspert-io/experts';

export type InputSource =
  | ExpertSource
  | 'first-thought'
  | 'falcon-search'
  | 'linkedInUpload'
  | 'NPIUpload';

@Pipe({ name: 'sourceDisplayName' })
export class SourceDisplayNamePipe implements PipeTransform {
  transform(source: InputSource, ellipsis: boolean = false): string {
    if (ellipsis) return this.ellipsisSourceName(source);
    return this.displaySourceName(source);
  }

  private ellipsisSourceName(source: InputSource): string {
    if (['pdl-commercial', 'pdl-enrichment'].includes(source)) {
      return 'Indu..';
    }
    return source.length <= 4 ? source : `${source.slice(0, 4)}..`;
  }

  private displaySourceName(source: InputSource): string {
    switch (source) {
      case 'pdl-commercial':
        return 'Industry search';
      case 'pdl-enrichment':
        return 'Industry upload';
      case 'deep-next':
        return 'Deep next';
      case 'deep3':
        return 'Deep3';
      case 'historical':
        return 'Historical';
      case 'cognisearch':
        return 'Cognisearch';
      case 'manualUpload':
        return 'Manual upload';
      case 'deep':
        return 'Deep';
      case 'network':
        return 'Network';
      case 'third-party':
        return 'Third party';
      case 'expert-referral':
        return 'Expert Referral';
      case 'first-thought':
        return 'First Thought';
      case 'falcon-search':
        return 'Falcon search';
      case 'linkedInUpload':
        return 'Industry - LinkedIn';
      case 'NPIUpload':
        return 'Industry - NPI';
    }
  }
}
