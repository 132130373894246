<form [formGroup]="templateForm" (submit)="submitForm()">
  <mat-card>
    <mat-card-content>
      <div class="flex flex-col gap-2">
        <mat-form-field class="flex-1">
          <mat-label>Template Name</mat-label>
          <input matInput formControlName="title">
          <mat-error>Required</mat-error>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>Description</mat-label>
          <input matInput formControlName="description">
          <mat-error>Required</mat-error>
        </mat-form-field>

        <app-outreach-template-content-form></app-outreach-template-content-form>

        <div class="flex flex-1 justify-end flex-row gap-4">
          <button *ngIf="isEditMode" type="button" mat-raised-button color="warn" [disabled]="isLoading"
            (click)="deleteTemplate()">
            Delete
          </button>
          <button type="submit" mat-raised-button color="primary" [disabled]="isLoading">
            {{isEditMode ? 'Update' : 'Create'}}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>