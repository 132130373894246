import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { RaygunErrorHandler } from './services/raygun-error-handler.service';
import {
  APPLICATION_VERSION,
  getRaygunFactory,
  RAY_GUN,
  RAY_GUN_API_KEY,
} from './services/tokens';

@NgModule()
export class ErrorHandlingModule {
  static forRoot(
    apiKey: string,
    version: string
  ): ModuleWithProviders<ErrorHandlingModule> {
    return {
      ngModule: ErrorHandlingModule,
      providers: [
        {
          provide: APPLICATION_VERSION,
          useValue: version,
        },
        {
          provide: RAY_GUN_API_KEY,
          useValue: apiKey,
        },
        {
          provide: RAY_GUN,
          useFactory: getRaygunFactory,
          deps: [RAY_GUN_API_KEY, APPLICATION_VERSION],
        },
        {
          provide: ErrorHandler,
          useClass: RaygunErrorHandler,
          deps: [RAY_GUN],
        },
      ],
    };
  }
}
