import { Component, EventEmitter, Output } from '@angular/core';
import {
  IAddNodeSignal,
  ISearchKnowledgeGraphNode,
  ISearchTaxonomyHighlight,
} from '../../models/knowledge-graph.models';
import { SearchKnowledgeGraphService } from '../../services/search-knowledge-graph.service';

@Component({
  selector: 'app-search-taxonomy-root',
  templateUrl: './search-taxonomy-root.component.html',
  styleUrls: ['./search-taxonomy-root.component.scss'],
})
export class SearchTaxonomyRootComponent {
  @Output() addNodeSignal = new EventEmitter<IAddNodeSignal>();

  includeSynonyms = false;
  selectedNode?: ISearchTaxonomyHighlight;

  rootNode$ = this.searchTaxonomyService.get('root');

  constructor(private searchTaxonomyService: SearchKnowledgeGraphService) {}

  onAddNode(node: ISearchKnowledgeGraphNode): void {
    this.addNodeSignal.emit({
      node,
      options: { includeSynonyms: this.includeSynonyms },
    });
  }
}
