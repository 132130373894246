import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  distinctUntilChanged,
  finalize,
  map,
  ReplaySubject,
  Subject,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isLoading: boolean;
  @Input() backLink: string | string[];
  @Input() location: string;
  @Input() paramWatch: string[] = [];
  @Input() title: string;

  private destroy$ = new Subject();

  returnLink$ = new ReplaySubject<string | string[]>(1);

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    const queryParamMap = this.activatedRoute.queryParamMap;

    queryParamMap
      .pipe(
        map((params) => params.get('breadcrumbReturn') || this.backLink),
        takeUntil(this.destroy$)
      )
      .subscribe((link) => this.returnLink$.next(link));

    combineLatest([this.activatedRoute.data, queryParamMap])
      .pipe(
        map(([params, data]) =>
          [
            'CONNECT',
            params.pageTitle || this.title,
            params.pageLocation || this.location,
            ...this.paramWatch.map((param) => data.get(param)),
          ]
            .filter(Boolean)
            .join(' | ')
        ),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        finalize(() => this.titleService.setTitle('CONNECT'))
      )
      .subscribe((title) => {
        this.titleService.setTitle(title);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.backLink &&
      !changes.backLink.isFirstChange() &&
      changes.backLink.currentValue
    ) {
      this.returnLink$.next(changes.backLink.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
