import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  IIOutreachTemplateCreate,
  IIOutreachTemplateUpdate,
  IOutreachTemplate,
} from '../models/outreach-templates.models';

@Injectable({
  providedIn: 'root',
})
export class OutreachTemplatesService {
  private readonly baseUrl = '/outreach-templates';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  getAll(): Observable<IOutreachTemplate[]> {
    return this.http.get<IOutreachTemplate[]>(`${this.baseUrl}`);
  }

  getById(templateId: string): Observable<IOutreachTemplate> {
    return this.http.get<IOutreachTemplate>(`${this.baseUrl}/${templateId}`);
  }

  create(req: IIOutreachTemplateCreate): Observable<IOutreachTemplate> {
    return this.http
      .post<IOutreachTemplate>(`${this.baseUrl}`, req)
      .pipe(catchError((err) => this.handleError(err, 'Create failed')));
  }

  update(req: IIOutreachTemplateUpdate): Observable<IOutreachTemplate> {
    const { templateId, ...payload } = req;
    return this.http
      .put<IOutreachTemplate>(`${this.baseUrl}/${templateId}`, payload)
      .pipe(catchError((err) => this.handleError(err, 'Update failed')));
  }

  private handleError(err: unknown, message: string) {
    if (err instanceof HttpErrorResponse) {
      this.toastService.sendMessage(`${message} ${err.message}`, 'error');
    } else {
      this.toastService.sendMessage(`${message} Unkown Error`, 'error');
    }
    return EMPTY;
  }
}
