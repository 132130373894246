import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { IOutreachTemplate } from '../../../models/outreach-templates.models';
import { OutreachTemplatesService } from '../../../services/outreach-templates.service';

type LoadingState = {
  isLoaded: boolean;
  isLoading: boolean;
};

@Component({
  selector: 'app-outreach-templates-page',
  templateUrl: './outreach-templates-page.component.html',
  styleUrls: ['./outreach-templates-page.component.scss'],
})
export class OutreachTemplatesPageComponent implements OnInit {
  templates$: Observable<IOutreachTemplate[]> = EMPTY;
  selectedTemplate$ = new ReplaySubject<IOutreachTemplate>(1);
  loadingState$ = new BehaviorSubject<LoadingState>({
    isLoaded: false,
    isLoading: false,
  });

  searchInput = new FormControl<string>('');

  constructor(private outreachTemplatesService: OutreachTemplatesService) {}

  ngOnInit(): void {
    this.loadingState$.next({ isLoaded: true, isLoading: true });

    const getTemplates$ = this.outreachTemplatesService.getAll().pipe(
      tap(() => this.loadingState$.next({ isLoaded: true, isLoading: false })),
      tap((d) => this.selectedTemplate$.next(d.find(Boolean)))
    );
    const filterChanges$ = this.searchInput.valueChanges.pipe(
      debounceTime(100),
      startWith(this.searchInput.value)
    );
    this.templates$ = combineLatest([getTemplates$, filterChanges$]).pipe(
      map(([d, search]) =>
        d.filter((e) => e.title.toLowerCase().includes(search.toLowerCase()))
      )
    );
  }
}
