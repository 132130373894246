<div *ngIf="callActions.length" class="conference" data-test-id="scheduled-box-info-box">
  <div class="flex flex-col">
    <div *ngFor="let ca of callActions">
      <mat-icon (click)="selectActiveAction(ca)" class="call-select"
        [ngClass]="{active: ca.callAction.expertActionId === activeCallAction.callAction.expertActionId}"
        [matTooltip]="ca.callAction.externalName">
        event_note
      </mat-icon>
    </div>
  </div>

  <ng-container *ngIf="activeCallAction">

    <ng-container [ngTemplateOutlet]="callDetails"></ng-container>

    <ng-container *ngFor="let reminder of activeCallAction.reminders">
      <app-scheduled-box-reminder [expert]="expert" [callAction]="activeCallAction.callAction"
        [conferenceReminder]="reminder">
      </app-scheduled-box-reminder>
    </ng-container>

    <ng-container *ngIf="showActionStatuses.includes(activeCallAction.callAction.status)">
      <button mat-icon-button [routerLink]="['/admin', 'experts', expert.expertId]" [queryParams]="{tab: 'Conferences'}"
        class="edit" data-test-id="scheduled-box-menu-elipsis">
        <mat-icon>more_vert</mat-icon>
      </button>
    </ng-container>

  </ng-container>
</div>

<ng-template #callDetails>
  <div class="meta-info-vertical flex justify-evenly content-stretch items-stretch flex-col">
    <div class="flex justify-between w-full flex-row gap-2.5 font-bold">
      <span [title]="activeCallAction.callAction.internalName"
        class="conference-title">{{activeCallAction.callAction.internalName}}</span>
      <span [title]="activeCallAction.callAction.externalName"
        class="conference-title">{{activeCallAction.callAction.externalName}}</span>
    </div>
    <span>{{activeCallAction.callAction.datetime | date:'medium'}}</span>
    <a target="_blank" [href]="activeCallAction.callAction.joinMetadata.weblink"
      *ngIf="activeCallAction.callAction.joinMetadata?.weblink">
      {{activeCallAction.callAction.joinMetadata.weblink}}
    </a>
    <span *ngIf="!activeCallAction.callAction.joinMetadata?.weblink">
      No weblink found for this conference
    </span>
    <span *ngIf="activeCallAction.callAction.zoomMetadata?.zoomAccount">
      Zoom account: {{activeCallAction.callAction.zoomMetadata.zoomAccount}}
    </span>
    <span>{{activeCallAction.callAction.isRecordingOn ? 'Recorded' : 'Not Recorded'}}</span>
  </div>
</ng-template>