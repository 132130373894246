<form [formGroup]="locationForm">
  <div *ngIf="options$ | async as options" class="flex flex-row gap-6">
    <mat-form-field class="flex-1">
      <mat-label [style.color]="labelFontColor"
        data-test-id="expert-profile-location-form-country-label">Country</mat-label>
      <input matInput formControlName="country" [matAutocomplete]="auto"
        data-test-id="expert-profile-location-form-country-input">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of options.countries" [value]="option"
          data-test-id="expert-profile-location-form-country-option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
      <mat-error data-test-id="expert-profile-location-form-country-error">
        {{locationForm.controls.country.errors?.country || 'Required'}}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label [style.color]="labelFontColor"
        data-test-id="expert-profile-location-form-timezone-label">Timezone</mat-label>
      <mat-select formControlName="timezoneName" data-test-id="expert-profile-location-form-timezone-input">
        <mat-option *ngFor="let option of options.timezones" [value]="option.name"
          data-test-id="expert-profile-location-form-timezone-option">
          {{option.offset}} {{option.abbr}} {{option.name}}
        </mat-option>
      </mat-select>
      <mat-error data-test-id="expert-profile-location-form-timezone-error">
        {{locationForm.controls.timezoneName.errors?.timezoneName || 'Required'}}
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="includeSubdivision" class="flex-1">
      <mat-label [style.color]="labelFontColor"
        data-test-id="expert-profile-location-form-subdivision-label">Region</mat-label>
      <input matInput formControlName="subdivision" [matAutocomplete]="auto"
        data-test-id="expert-profile-location-form-subdivision-input">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of options.subdivisions" [value]="option"
          data-test-id="expert-profile-location-form-subdivision-option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
      <mat-error data-test-id="expert-profile-location-form-subdivision-error">
        {{locationForm.controls.subdivision.errors?.subdivision || 'Required'}}
      </mat-error>
    </mat-form-field>
  </div>
</form>