import { Pipe, PipeTransform } from '@angular/core';
import { IExpert } from '@techspert-io/experts';

@Pipe({ name: 'expertName' })
export class ExpertNamePipe implements PipeTransform {
  public transform(expert: IExpert): string {
    if (expert.firstNameAlias || expert.lastNameAlias) {
      return `${expert.firstName} ${expert.lastName} (${
        expert.firstNameAlias || ''
      }${expert.firstNameAlias && expert.lastNameAlias ? ' ' : ''}${
        expert.lastNameAlias || ''
      })`;
    }

    return `${expert.firstName} ${expert.lastName}`;
  }
}
