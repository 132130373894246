import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IPendingConferenceBookingExpert } from '@techspert-io/conferences';

@Component({
  selector: 'app-dashboard-pending-conference-booking-experts',
  templateUrl: './dashboard-pending-conference-booking-experts.component.html',
})
export class DashboardPendingConferenceBookingExpertsComponent
  implements OnChanges
{
  @Input() experts: IPendingConferenceBookingExpert[];
  @Output()
  navigateToOpportunitySignal =
    new EventEmitter<IPendingConferenceBookingExpert>();
  @Output()
  navigateToSchedulerSignal =
    new EventEmitter<IPendingConferenceBookingExpert>();
  public displayedColumns: string[] = [
    'expert',
    'opportunity',
    'call_time',
    'actions',
  ];
  public dataSource: IPendingConferenceBookingExpert[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.experts) {
      this.dataSource = changes.experts.currentValue;
    }
  }

  public emitNavigateToOpportunitySignal(
    expert: IPendingConferenceBookingExpert
  ): void {
    this.navigateToOpportunitySignal.emit(expert);
  }

  public emitNavigateToSchedulerSignal(
    expert: IPendingConferenceBookingExpert
  ): void {
    this.navigateToSchedulerSignal.emit(expert);
  }
}
