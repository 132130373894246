import { DatePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import {
  CopyExpertPortalLinkDirective,
  EXPERT_PORTAL_URL,
} from './directives/copy-expert-portal-link.directive';
import { ExpertRejectionReasonPipe } from './pipes/expert-rejection-reason.pipe';
import { ExpertSegmentPipe } from './pipes/expertSegment.pipe';
import { OutreachStatusPipe } from './pipes/outreach-status.pipe';
import { PhaseNamePipe } from './pipes/phase-name.pipe';
import { ExpertsEffects } from './reducers/experts.effects';
import { expertsReducer } from './reducers/experts.reducer';
import { ExpertsCreateService } from './services/experts-create.service';
import { ExpertsQueryService } from './services/experts-query.service';
import { ExpertsUpdateService } from './services/experts-update.service';
import { ExpertValidationService } from './services/experts-validator.service';

const declarations = [
  ExpertRejectionReasonPipe,
  PhaseNamePipe,
  OutreachStatusPipe,
  ExpertSegmentPipe,
  CopyExpertPortalLinkDirective,
];

@NgModule({
  imports: [
    StoreModule.forFeature('experts', expertsReducer),
    EffectsModule.forFeature(ExpertsEffects),
  ],
  providers: [
    DatePipe,
    ExpertsUpdateService,
    ExpertsCreateService,
    ExpertsQueryService,
    ExpertValidationService,
    TitleCasePipe,
    {
      provide: EXPERT_PORTAL_URL,
      useValue: environment.expertPortalBase,
    },
  ],
  declarations: declarations,
  exports: declarations,
})
export class ExpertsModule {}
