import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ExpertActions } from '@techspert-io/experts';
import { ToastService } from '@techspert-io/user-alerts';
import { EMPTY, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import {
  ExpertScreenerActionStatusMap,
  IExpertScreenerAction,
} from '../../models/expertScreenerActions.models';
import { SurveysService } from '../../services/surveys.service';

@Component({
  selector: 'app-screener-result-dialog',
  templateUrl: './screener-result-dialog.component.html',
  styleUrls: ['./screener-result-dialog.component.scss'],
})
export class ScreenerResultDialogComponent implements OnInit {
  isLoading = false;
  screenerAction$: Observable<IExpertScreenerAction> = EMPTY;

  screenerNotes = new FormControl<string>('');

  get expertActionId(): string {
    return this.data.expertActionId;
  }

  constructor(
    private dialogRef: MatDialogRef<
      ScreenerResultDialogComponent,
      IExpertScreenerAction
    >,
    @Inject(MAT_DIALOG_DATA)
    private data: { expertActionId: string },
    private surveysService: SurveysService,
    private toastService: ToastService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.screenerAction$ = this.surveysService
      .getById(this.expertActionId)
      .pipe(tap((screener) => this.screenerNotes.patchValue(screener.notes)));
  }

  update(status?: ExpertScreenerActionStatusMap): void {
    this.isLoading = true;
    this.surveysService
      .update({
        expertActionId: this.expertActionId,
        notes: this.screenerNotes.value,
        ...(status && { status }),
      })
      .pipe(
        tap((res) => {
          if (status === ExpertScreenerActionStatusMap.CompleteFailed) {
            this.store.dispatch(
              ExpertActions.fetchExpert({ expertId: res.expertId })
            );
          }

          status &&
            this.toastService.sendMessage(
              `Screener marked ${status}`,
              'success'
            );
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((action) => this.dialogRef.close(action));
  }

  isDisabled(status?: ExpertScreenerActionStatusMap): boolean {
    return (
      this.isLoading || [ExpertScreenerActionStatusMap.Voided].includes(status)
    );
  }
}
