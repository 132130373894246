<div class="side-dialog">
    <div class="top">
        <div class="flex justify-between content-center items-center flex-row">
            <h1 class="txp-h1">Payment Correction</h1>
            <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
        </div>
        <div class="basicInput">
            <mat-form-field appearance="outline" class="basis-1/2">
                <mat-label>Date of payment</mat-label>
                <input matInput [(ngModel)]="dateOfEngagement" type="date">
            </mat-form-field>
        </div>
        <div class="basicInput">
            <app-currency-input [formControl]="currencyFormControl"
                [isInvalid]="currencyFormControl.hasError('missingCurrency') || currencyFormControl.hasError('invalidCurrency')">
            </app-currency-input>
        </div>
        <div class="basicInput">
            <mat-form-field appearance="outline" class="basis-1/2">
                <mat-label>Owed</mat-label>
                <input matInput [(ngModel)]="engagement.amountOwed" type="number">
            </mat-form-field>
        </div>
        <div class="basicInput">
            <mat-form-field appearance="fill" class="basis-[23%]">
                <mat-label>Payment Type</mat-label>
                <mat-select [(ngModel)]="engagement.paymentType">
                    <mat-option *ngFor="let type of paymentTypes" [value]="type.value">
                        {{type.display}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="basicInput">
            <mat-form-field appearance="outline" class="basis-1/2">
                <mat-label>Units used</mat-label>
                <input matInput [(ngModel)]="engagement.unitsUsedAdjustment" type="number">
            </mat-form-field>
        </div>
        <div class="basicInput" *ngIf="engagement.engagementType === 'payment'">
            <mat-form-field appearance="fill" class="basis-[23%]">
                <mat-label>Payment Reason</mat-label>
                <mat-select [(ngModel)]="engagement.reason">
                    <mat-option *ngFor="let reason of paymentReasons" [value]="reason">
                        {{reason}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="!engagement.reason">
                Please provide a payment reason
            </mat-error>
        </div>
        <div class="basicInput">
            <mat-form-field appearance="outline" class="basis-1/2">
                <mat-label>Additional information</mat-label>
                <textarea matInput [(ngModel)]="engagement.notes"
                    placeholder="Please add supporting notes here, including the chosen charity name, if funds are being donated."></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="bottom">
        <div class="button-group">
            <button class="button" (click)="closeDialog()">Close</button>
            <button class="button" (click)="addEngagement()">Add engagement</button>
        </div>
    </div>
</div>