import { Pipe, PipeTransform } from '@angular/core';
import { UserRoleType } from '@techspert-io/auth';

@Pipe({
  name: 'userRole',
})
export class UserRolePipe implements PipeTransform {
  transform(value: UserRoleType): string {
    return (
      {
        projectUser: 'User',
        clientAdmin: 'Admin',
      }[value] || 'Unknown'
    );
  }
}
