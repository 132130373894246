import { Pipe, PipeTransform } from '@angular/core';
import { OmnisearchEntity } from '../models/omnisearch.models';

@Pipe({
  name: 'category',
})
export class CategoryPipe implements PipeTransform {
  transform(entity: OmnisearchEntity): string {
    const entityDict: Record<OmnisearchEntity, string> = {
      [OmnisearchEntity.experts]: 'expert',
      [OmnisearchEntity.solicitationExperts]: 'solicitation expert',
      [OmnisearchEntity.opportunities]: 'opportunity',
      [OmnisearchEntity.clients]: 'client',
    };

    return entityDict[entity];
  }
}
