<h1 mat-dialog-title>
  <div class="flex flex-1">
    <span class="flex-1"> Expert Search </span>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>

<div mat-dialog-content>
  <app-expert-search-info></app-expert-search-info>
</div>