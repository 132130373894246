import {
  IDataAccessLogs,
  IExpertActionBase,
} from '../../expert-actions/models/expertActions.models';

export interface IExpertScreenerAction
  extends IExpertScreenerActionBase,
    IDataAccessLogs {}

export enum ExpertScreenerActionStatusMap {
  Voided = 'voided',
  Pending = 'pending',
  Complete = 'complete',
  CompletePassed = 'complete-passed',
  CompleteFailed = 'complete-failed',
  CompleteNearPass = 'complete-near-pass',
  CompleteNearPassAccepted = 'complete-near-pass-accepted',
}

export interface IExpertScreenerActionBase extends IExpertActionBase {
  actionType: 'screener';
  contactLookupId: string;
  contactId: string;
  distributionId?: string;
  dateSent?: number;
  dateCompleted?: number;
  surveyId?: string;
  reminderIds?: string[];
  distributionType?: string;
  distributionLink?: string;
  screenerResult?: IScreenerResult;
  notes?: string;
  status: ExpertScreenerActionStatusMap;
}

export type QualtricsDataType =
  | 'array'
  | 'integer'
  | 'number'
  | 'object'
  | 'string';

export interface IScreenerResult {
  responses: {
    id: string;
    question: string;
    answer: string;
    questionType: QualtricsDataType;
  }[];
  dateCompleted: number;
}
