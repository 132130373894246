import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IExpertProfile } from '../models/expert-profile.models';

export interface IExpertProfileState extends EntityState<IExpertProfile> {
  selectedExpertProfileId: string | null;
  isLoading: boolean;
  isLoaded: boolean;
  loadError?: string;
  updateErrors?: string[];
}

const selectId = (a: IExpertProfile) => a.expertProfileId;

export const expertProfileAdapter: EntityAdapter<IExpertProfile> =
  createEntityAdapter<IExpertProfile>({ selectId });
