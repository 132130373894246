<style>
  .loaderContainer {
    height: 100vh;
    width: 100%;
  }

  .loaderContainer .centered {
    margin-top: 100px;
  }

  .loaderContainer .centered .loadingAnimation {
    margin: 0 auto;
  }

  .loaderContainer .centered img {
    height: 100px;
  }

  .danger {
    color: #f44336;
  }
</style>

<div class="loaderContainer">
  <div class="centered">
    <div class="loadingAnimation">
      <img src="assets/images/Techspert-logo-blue-only.svg" alt="techspert.io logo - Loading... Please wait" />
    </div>
    <br />
    <img src="assets/images/Techspert-Text-Blue.svg" alt="techspert.io" />
    <div class="flex flex-col gap-4">
      <h1>Not Authorised</h1>
      <h2 class="danger">{{errors$ | async}}</h2>

      <div>
        <button mat-raised-button color="accent" (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
</div>