import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IUserBase, IUserTimezone } from '@techspert-io/auth';
import { map, startWith, tap } from 'rxjs/operators';
import { ITimezone } from '../../../../../shared/models/country';
import { CountryService } from '../../../../../shared/services/country.service';
import { IClientContactDialogData } from '../../../models/opportunity.models';

interface ICountry {
  abbreviation: string;
  country: string;
}

interface ICountryOptions {
  countries: string[];
  timezones: ITimezone[];
}

@Component({
  selector: 'app-client-contact-form',
  templateUrl: './client-contact-form.component.html',
  styleUrls: ['./client-contact-form.component.scss'],
})
export class ClientContactFormComponent implements AfterViewInit, OnInit {
  @Input() clientContact?: IClientContactDialogData;

  @Output() submitClientContact = new EventEmitter<IUserBase[]>();

  sendInviteEmail: boolean;
  isBulkEdit: boolean;
  showInviteToggle: boolean;

  clientContactsForm = new FormGroup({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    country: new FormControl(null, [
      Validators.required,
      this.validateCountry.bind(this),
    ]),
    timezone: new FormControl(null, Validators.required),
  });

  get emailControl(): FormControl {
    return this.clientContactsForm.get('email') as FormControl;
  }
  get countryControl(): FormControl {
    return this.clientContactsForm.get('country') as FormControl;
  }
  get timezoneControl(): FormControl {
    return this.clientContactsForm.get('timezone') as FormControl;
  }

  countryOptions$ = this.countryControl.valueChanges.pipe(
    startWith(''),
    map((countryName) => this.buildOptions(countryName)),
    tap(({ timezones }) => this.resetTimezoneControl(timezones))
  );

  constructor(
    private countryService: CountryService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isBulkEdit =
      Array.isArray(this.clientContact?.data) &&
      this.clientContact.data.length > 1;

    this.showInviteToggle =
      !this.isBulkEdit &&
      !this.clientContact?.data?.[0]?.options.hideInviteToggle;
  }

  ngAfterViewInit(): void {
    if (this.isBulkEdit) {
      // Only require country and timezone when bulk editing
      ['firstName', 'lastName', 'email'].forEach((field) => {
        this.clientContactsForm.get(field)?.clearValidators();
        this.clientContactsForm.get(field)?.updateValueAndValidity();
      });
    } else {
      this.clientContactsForm.patchValue({
        firstName: this.clientContact?.data?.[0]?.firstName || null,

        lastName: this.clientContact?.data?.[0]?.lastName || null,

        email: this.clientContact?.data?.[0]?.email || null,

        country: this.clientContact?.data?.[0]?.country
          ? this.findCountryByAbbr(this.clientContact?.data[0]?.country)
          : '',

        timezone: this.clientContact?.data?.[0]?.timezone
          ? this.clientContact.data?.[0]?.timezone.name
          : '',
      });
      if (this.clientContact?.data?.[0]?.email) {
        this.emailControl.disable();
      }

      this.sendInviteEmail = this.clientContact?.data?.[0]
        ? this.clientContact?.data?.[0].options?.sendInviteEmail
        : true;
    }
    this.changeDetector.detectChanges();
  }

  onSubmit(): void {
    if (this.clientContactsForm.valid) {
      const commonFormValues = {
        country: this.getCountryCode(this.countryControl.value),
        timezone: this.getTimezone(
          this.countryControl.value,
          this.timezoneControl.value
        ) as IUserTimezone,
      };

      let updatedContacts: IUserBase[] = [];

      if (this.isBulkEdit) {
        updatedContacts = (this.clientContact.data as IUserBase[]).map(
          (contact) => ({
            ...contact,
            ...commonFormValues,
            primary: contact.primary,
          })
        );
      } else {
        updatedContacts = [
          {
            ...(this.clientContact.data[0] as IUserBase),
            ...commonFormValues,
            firstName: `${this.clientContactsForm.value.firstName}`.trim(),
            lastName: `${this.clientContactsForm.value.lastName}`.trim(),
            email: `${this.emailControl.value}`.toLowerCase().trim(),
            options: {
              ...(this.clientContact.data[0]?.options || {}),
              sendInviteEmail: this.sendInviteEmail,
            },
          },
        ];
      }

      this.submitClientContact.emit(updatedContacts);
    }
  }

  private buildOptions(countryName: string): ICountryOptions {
    return {
      countries: this.filterCountries(countryName),
      timezones: this.filterTimezones(countryName),
    };
  }

  private filterCountries(countryName: string): string[] {
    return this.countryService.countryList
      .map((c) => c.country)
      .filter(
        (option) =>
          option.toLowerCase().indexOf(countryName?.toLowerCase()) === 0
      );
  }

  private filterTimezones(countryName: string): ITimezone[] {
    const country = this.findCountryByName(countryName);
    return country
      ? this.countryService.getTimezonesForCountry(country.abbreviation)
      : [];
  }

  private findCountryByName(name: string): ICountry | undefined {
    return this.countryService.countryList.find((c) => c.country === name);
  }

  private findCountryByAbbr(abbr: string): string {
    const country = this.countryService.countryList.find(
      (c) => c.abbreviation === abbr
    );
    return country ? country.country : '';
  }

  private getCountryCode(countryName: string): string {
    const country = this.findCountryByName(countryName);
    return country ? country.abbreviation : '';
  }

  private getTimezone(
    countryName: string,
    timezoneName: string
  ): ITimezone | {} {
    const countryCode = this.getCountryCode(countryName);
    return (
      this.countryService
        .getTimezonesForCountry(countryCode)
        .find((t) => t && t.name === timezoneName) || {}
    );
  }

  private validateCountry(ctrl: AbstractControl): ValidationErrors | null {
    const found = this.countryService.countryList.find(
      (c) => c.country === ctrl.value
    );
    return found ? null : { countryRequired: true };
  }

  private resetTimezoneControl(timezones: ITimezone[]): void {
    this.timezoneControl.patchValue(null);
    if (!timezones.length) {
      this.timezoneControl.disable();
    } else {
      this.timezoneControl.enable();
    }
  }
}
