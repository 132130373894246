import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { handleHttpError } from '@techspert-io/errorHandling';
import { map, mergeMap } from 'rxjs/operators';
import { ExpertSourcesService } from '../services/expert-sources.service';
import {
  ExpertSourcesActions,
  ExpertSourcesAPIActions,
} from './expert-sources.actions';

@Injectable()
export class ExpertSourcesEffects {
  fetchExpertSource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertSourcesActions.fetchExpertSource),
      mergeMap(({ expertSourceId }) =>
        this.expertSourcesService.getById(expertSourceId).pipe(
          map((expertSource) =>
            ExpertSourcesAPIActions.fetchExpertSourceSuccess({ expertSource })
          ),
          handleHttpError((error) =>
            ExpertSourcesAPIActions.fetchExpertSourceFailure({
              error,
            })
          )
        )
      )
    );
  });

  fetchExpertSources$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertSourcesActions.queryExpertSourcesByOpportunity),
      mergeMap(({ opportunityId }) =>
        this.expertSourcesService.getByOpportunity(opportunityId).pipe(
          map((expertSources) =>
            ExpertSourcesAPIActions.queryExpertSourcesByOpportunitySuccess({
              expertSources,
            })
          ),
          handleHttpError((error) =>
            ExpertSourcesAPIActions.queryExpertSourcesByOpportunityFailure({
              error,
            })
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private expertSourcesService: ExpertSourcesService
  ) {}
}
