<div class="flex flex-col gap-5" [attr.data-test-id]="'expert-responded-detail-expert-' + expert.expertId">
  <h1 data-test-id="expert-responded-dialog-expert-name">{{expert | expertName}}</h1>
  <div class="flex flex-col gap-[5px]">
    <label class="label" data-test-id="expert-responded-dialog-expert-primary-email-label">
      Point of contact email
    </label>
    <p class="danger" *ngIf="!expert.primaryEmail" data-test-id="expert-responded-dialog-expert-primary-email-error">
      Please select an email
    </p>
    <ul class="horizontal-list">
      <li class="email-pill horizontal-list-item" *ngFor="let email of emailList" (click)="selectPocEmail(email)">
        <app-shared-email-pill [email]="email" [selected]="expert.primaryEmail === email"
          data-test-id="expert-responded-detail-expert-email">
        </app-shared-email-pill>
      </li>
    </ul>
  </div>

  <ng-container *ngIf="targetPhaseIndex >= 5 || !expert.expertSegment">
    <mat-form-field appearance="fill" class="basis-[23%]">
      <mat-label data-test-id="expert-responded-detail-expert-segment-label">
        Expert segment
      </mat-label>
      <mat-error *ngIf="!expert.expertSegment" data-test-id="expert-responded-detail-select-expert-segment-error">
        Please select a segment
      </mat-error>
      <mat-select [formControl]="expertSegmentControl" data-test-id="expert-responded-detail-expert-segment">
        <mat-option *ngFor="let segment of segments" [value]="segment"
          data-test-id="expert-responded-detail-expert-segment-option">
          {{ segment | expertSegment }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="targetPhaseIndex >= 6">
      <app-currency-input [formControl]="currencyFormControl"
        [isInvalid]="currencyFormControl.hasError('missingCurrency') || currencyFormControl.hasError('invalidCurrency')"
        data-test-id="expert-responded-detail-expert-currency">
      </app-currency-input>

      <mat-form-field>
        <mat-label data-test-id="expert-responded-detail-cost-band-label">Cost band</mat-label>
        <mat-select [formControl]="costBandControl" data-test-id="expert-responded-detail-cost-band">
          <mat-option *ngFor="let option of costBandOptions" [value]="option"
            data-test-id="expert-responded-detail-cost-band-option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="!isCostBandStandard">
        <mat-label data-test-id="expert-responded-detail-unit-rate-label">Unit cost per hour</mat-label>
        <input matInput [formControl]="unitsPerHourControl" type="number" step="0.01" placeholder="1.00"
          data-test-id="expert-responded-detail-unit-rate">
        <mat-error *ngIf="unitsPerHourControl.invalid" data-test-id="expert-responded-detail-unit-rate-error">
          Please enter valid unit cost per hour
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div class="flex flex-col gap-[5px]">
      <label class="label" data-test-id="expert-responded-detail-expert-opportunity-segment-label">
        Geographic target and profile type
      </label>
      <p class="danger" *ngIf="!expert.geographicTarget || !expert.profileType"
        data-test-id="expert-responded-detail-expert-opportunity-segment-error">
        Please select a geographic target and profile type
      </p>
      <div class="country-zone-input">
        <div class="country-zone-input-selects">
          <div class="basicInput">
            <mat-form-field appearance="fill" class="basis-[23%]">
              <mat-label data-test-id="expert-responded-detail-expert-opportunity-segment-geography-label">
                Select geographic target
              </mat-label>
              <mat-select [(ngModel)]="expert.geographicTarget" (selectionChange)="assignTargetGeography($event.value)"
                data-test-id="expert-responded-detail-expert-opportunity-segment-geography">
                <mat-option *ngFor="let target of targetGeos" [value]="target"
                  data-test-id="expert-responded-detail-expert-opportunity-segment-geography-option">
                  {{target}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <span class="country-zone-input-chevron">
            <mat-icon>chevron_right</mat-icon>
          </span>
          <div class="basicInput" *ngIf="profileOptions">
            <mat-form-field appearance="fill" class="basis-[23%]">
              <mat-label data-test-id="expert-responded-detail-expert-opportunity-segment-profile-type-label">
                Select profile type
              </mat-label>
              <mat-select [(ngModel)]="expert.profileType"
                (selectionChange)="assignTargetGeography(expert.geographicTarget, $event.value)"
                data-test-id="expert-responded-detail-expert-opportunity-segment-profile-type">
                <mat-option *ngFor="let segment of profileOptions" [value]="segment"
                  data-test-id="expert-responded-detail-expert-opportunity-segment-profile-option">
                  {{ segment | expertSegment }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


</div>