import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-client-hosted-url-form',
  templateUrl: './client-hosted-url-form.component.html',
  styleUrls: ['./client-hosted-url-form.component.scss'],
})
export class ClientHostedURLFormComponent {
  get clientHostedURLForm(): FormGroup {
    return this.rootFormGroup.form;
  }

  constructor(private rootFormGroup: FormGroupDirective) {}
}
