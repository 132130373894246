export interface INotification {
  notificationId: string;
  type: NotificationTypeMap;
  archiveAllowed: boolean;
  archived: boolean;
  /**
   * Refers to another item depending on notification type:
   *  - expert: availabilityRequest, expertAccepted, expertRejected, expertCallCompleted
   * */
  referenceId: string;
  /** Connect User notification for */
  connectId: string;
  content: INotificationContentItem[];
  dateCreated?: number;
  lastUpdated?: number;
  groupId: string;
  ttl?: number; // check with Ryan, check format
}

type INotificationContentItem =
  | INotificationContentMessage
  | INotificationContentActionRow;

export interface INotificationContentMessage {
  contentType: 'message';
  text: INotificationText;
}

export interface INotificationContentActionRow {
  contentType: 'actionRow';
  actions: INotificationActionItem[];
}

export interface INotificationActionItem {
  type: 'button';
  text: INotificationText;
  action: {
    link: string;
    type: NotificationActionTypeMap;
  };
}

export interface INotificationText {
  type: NotificationTextTypeMap;
  text: string;
}

export enum NotificationTypeMap {
  availabilityRequest = 'availabilityRequest',
  expertAccepted = 'expertAccepted',
  expertRejected = 'expertRejected',
  expertUnrejected = 'expertUnrejected',
  expertCallCompleted = 'expertCallCompleted',
  expertFileUploadFailed = 'expertFileUploadFailed',
  surveyCompleted = 'surveyCompleted',
  expertEngagementRequested = 'expertEngagementRequested',
  solicationCampaignSent = 'solicationCampaignSent',
  expertNoShow = 'expertNoShow',
  expertOnHold = 'expertOnHold',
  expertUnhold = 'expertUnhold',
}

export enum NotificationTextTypeMap {
  plainText = 'plain_text',
  markDown = 'mrkdwn',
}

export enum NotificationActionTypeMap {
  url = 'url',
  routerLinkConnect = 'routerLinkConnect',
}
