<mat-card class="h-full" data-test-id="query-creator-construct-query-form">
  <mat-card-content [style.overflow]="'hidden'" class="h-full">
    <div class="flex flex-col gap-5 h-full">
      <div class="flex justify-start content-center items-center w-full flex-row">
        <h2>Construct query</h2>
        <button *ngIf="service !== 'linkedInUpload' && service !== 'NPIUpload' && service !== 'falcon-search'"
          style="margin-left: auto;" mat-icon-button [matMenuTriggerFor]=" menu"
          data-test-id="query-creator-options-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="triggerUploadSearch()" mat-menu-item data-test-id="query-creator-upload-query">
            <span>Upload query</span>
          </button>
          <button (click)="exportSearch()" [disabled]="!conditions.length || !expertTargetNumber" mat-menu-item
            data-test-id="query-creator-download-query">
            <span>Download query</span>
          </button>
        </mat-menu>
      </div>

      <div class="query-scroll-container">
        <div *ngIf="!hideServiceAndCount" class="flex justify-start content-center items-center flex-row gap-5">
          <mat-form-field appearance="outline" class="basis-1/2">
            <mat-label>Service</mat-label>
            <mat-select [ngModel]="service" (selectionChange)="onServiceChange($event.value)" [disabled]="disable"
              data-test-id="query-creator-select-service">
              <mat-option *ngFor="let service of services" [value]="service"
                data-test-id="query-creator-service-option">
                {{ service | sourceDisplayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="checkbox-bar" [ngClass]="{'checkbox-bar-active': searchExpansion }"
            *ngIf="service === 'cognisearch'">

            <mat-checkbox [(ngModel)]="searchExpansion" labelPosition="before"
              data-test-id="query-creator-include-term-enrichment">
              Term enrichment
            </mat-checkbox>
          </div>
        </div>

        <div *ngIf="!hideServiceAndCount && service !== 'linkedInUpload' && service !== 'NPIUpload'">
          <mat-form-field appearance="outline" data-test-id="query-creator-expert-target" class="shrink-[50] basis-0">
            <mat-label>Expert target</mat-label>
            <input [(ngModel)]="expertTargetNumber" matInput type="number" [disabled]="service === 'pdl-commercial'"
              min="1" max="10000">
            <mat-hint *ngIf="newQueryActions < 1 || expertTargetNumber > 10000" style="color: indianred">
              Please enter a number between 1 and 10,000</mat-hint>
          </mat-form-field>
        </div>

        <ct-chip-list *ngIf="service === 'falcon-search'" [label]="'Search terms'" [list]="selectedTerms"
          [disabled]="disable" (addItemSignal)="assignSelectedTerms($event)" (removeItemSignal)="removeTerm($event)"
          data-test-id="query-creator-select-search-terms"></ct-chip-list>

        <ct-chip-list *ngIf="service === 'cognisearch' || service === 'falcon-search'" [label]="'Countries'"
          [list]="selectedCountries" [disabled]="disable" [autoCompleteOptions]="countries" [allowOptionsOnly]="true"
          [optionDisplayPropKey]="'country'" (addItemSignal)="assignSelectedCountry($event)"
          (removeItemSignal)="removeCountry($event)" data-test-id="query-creator-select-country"></ct-chip-list>

        <ct-chip-list *ngIf="service === 'cognisearch'" [label]="'US states'" [list]="selectedSubdivisions"
          [disabled]="disable" [autoCompleteOptions]="subdivisions" [allowOptionsOnly]="true"
          (addItemSignal)="assignSelectedSubdivision($event)" (removeItemSignal)="removeSubdivision($event)"
          data-test-id="query-creator-select-subdivision"></ct-chip-list>

        <ng-container *ngIf="service !== 'falcon-search' && service !== 'linkedInUpload' && service !== 'NPIUpload'">
          <div class="flex justify-start content-stretch items-stretch flex-row gap-5">
            <mat-form-field appearance="outline" class="basis-full">
              <mat-label>Add conditions</mat-label>
              <mat-select [(ngModel)]="field" [disabled]="disable">
                <mat-option *ngFor="let field of fields; let i = index;" [value]="field"
                  [attr.data-test-id]="'query-creator-add-condition-field'+ i">
                  {{field.fieldDisplay}}
                </mat-option>
              </mat-select>
              <mat-hint *ngIf="!conditions.length" style="color: indianred">Please enter conditions</mat-hint>
            </mat-form-field>
            <button mat-mini-fab color="accent" (click)="addCondition()" [disabled]="disable"
              data-test-id="query-creator-add-condition">
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <div class="flex flex-col gap-5">
            <h3>Conditions:</h3>
            <ul *ngIf="conditions.length" data-test-id="query-creator-conditions-list">
              <li *ngFor="let condition of conditions" class="flex flex-row">
                <ng-container>
                  <ct-chip-list
                    *ngIf="!['experience_level', 'industry', 'expertise', 'affiliation'].includes(condition.fieldValue)"
                    [list]="condition.terms" [label]="condition.fieldDisplay" [placeholder]="'Enter terms...'"
                    [disabled]="disable" (addItemSignal)="addConditionTerm(condition.id, $event)"
                    (removeItemSignal)="removeConditionTerm(condition.id, $event)"
                    (listChange)="onListChange($event, condition.id)" [splitByPipe]="true"
                    data-test-id="query-creator-role-conditions" class="flex-1">
                  </ct-chip-list>
                  <app-affiliation-chip-list *ngIf="condition.fieldValue === 'affiliation'" [terms]="condition.terms"
                    [fieldDisplay]="condition.fieldDisplay" [disabled]="disable"
                    data-test-id="query-creator-affiliation-conditions-list"
                    (addItemSignal)="addConditionTerm(condition.id, $event)"
                    (removeItemSignal)="removeConditionTerm(condition.id, $event)"
                    (listChange)="onListChange($event, condition.id)" class="flex-1">
                  </app-affiliation-chip-list>
                  <app-expertise-chip-list *ngIf="condition.fieldValue === 'expertise'" [terms]="condition.terms"
                    [fieldDisplay]="condition.fieldDisplay" [disabled]="disable"
                    data-test-id="query-creator-expertise-conditions-list"
                    (addItemSignal)="addConditionTerm(condition.id, $event)"
                    (removeItemSignal)="removeConditionTerm(condition.id, $event)"
                    (listChange)="onListChange($event, condition.id)" class="flex-1">
                  </app-expertise-chip-list>
                  <ct-chip-list *ngIf="condition.fieldValue === 'experience_level'" [list]="condition.terms"
                    [label]="condition.fieldDisplay" [placeholder]="'Enter terms...'" [disabled]="disable"
                    [autoCompleteOptions]="commercialOptions.experienceLevels" [allowOptionsOnly]="true"
                    (addItemSignal)="addConditionTerm(condition.id, $event)"
                    (removeItemSignal)="removeConditionTerm(condition.id, $event)"
                    (listChange)="onListChange($event, condition.id)" [splitByPipe]="true"
                    data-test-id="query-creator-experience-conditions-list" class="flex-1">
                  </ct-chip-list>
                  <ct-chip-list *ngIf="condition.fieldValue === 'industry'" [list]="condition.terms"
                    [label]="condition.fieldDisplay" [placeholder]="'Enter terms...'" [disabled]="disable"
                    [autoCompleteOptions]="commercialOptions.industries" [allowOptionsOnly]="true"
                    (addItemSignal)="addConditionTerm(condition.id, $event)"
                    (removeItemSignal)="removeConditionTerm(condition.id, $event)"
                    (listChange)="onListChange($event, condition.id)" [splitByPipe]="true"
                    data-test-id="query-creator-industry-conditions-list" class="flex-1">
                  </ct-chip-list>
                  <div class="action-container flex flex-row gap-[5px]">
                    <div class="flex flex-col gap-[5px]">
                      <mat-icon matTooltip="clear search terms" class="ct-icon ct-icon--selectable"
                        (click)="removeCondition(condition.id)" data-test-id="query-creator-remove-conditions">
                        clear
                      </mat-icon>
                      <mat-icon matTooltip="copy search terms" class="ct-icon ct-icon--selectable"
                        (click)="copy(condition)" data-test-id="query-creator-copy-conditions">
                        content_copy
                      </mat-icon>
                    </div>
                    <div class="flex flex-col gap-[5px]">
                      <mat-icon
                        [matTooltip]="targetConditionId === condition.id ? 'Target condition for taxonomy' : 'Set as target condition for taxonomy'"
                        class="ct-icon ct-icon--selectable" (click)="targetConditionId = condition.id"
                        data-test-id="query-creator-target-condition">
                        {{targetConditionId === condition.id ? 'track_changes' : 'adjust'}}
                      </mat-icon>
                    </div>
                  </div>
                </ng-container>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>

      <span *ngIf="service === 'NPIUpload' && npiNumbers.length"
        data-test-id="query-creator-industry-import-count">{{npiNumbers.length}}
        experts imported
      </span>

      <div class="flex justify-end content-end items-end w-full flex-row gap-2.5">
        <ng-content></ng-content>

        <button *ngIf="service === 'linkedInUpload' || service === 'NPIUpload'" (click)="triggerUploadCSV()"
          class="button button--primary" data-test-id="query-creator-upload-csv" [disabled]="showSearchLoader">
          <app-shared-btn-loader *ngIf="showSearchLoader"></app-shared-btn-loader>
          <span data-test-id="search-query-upload-profiles">Upload Profiles</span>
        </button>

        <button *ngIf="service !== 'linkedInUpload' && service !== 'NPIUpload'"
          (click)="!showSearchLoader ? submitQuery() : ''" [disabled]="!isSearchValid() || showSearchLoader"
          class="button button--primary" id="check-feasibility" data-test-id="query-creator-run-search">
          <span *ngIf="!showSearchLoader">Run search</span>
          <app-shared-btn-loader *ngIf="showSearchLoader"></app-shared-btn-loader>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<input #FileInput id="file-input" type="file" name="name" multiple style="display: none;"
  (change)="parseCsv($event.target)" />
<input style="visibility: hidden" #UploadFileInput type="file" (change)="uploadSearch($event.target)" />

<a id="downloadAnchorElem" style="display:none"></a>