import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-delete-confirmation-dialog',
  template: `<div
    data-test-id="file-delete-dialog"
    class="flex flex-col gap-3 p-6"
  >
    <h3 data-test-id="file-delete-confirmation-dialog-message">
      You are about to delete {{ fileName | filenameFormat }}. Are you sure?
    </h3>
    <div mat-dialog-actions>
      <div class="flex flex-1 justify-end">
        <button
          (click)="revoke()"
          color="warn"
          data-test-id="file-delete-confirmation-dialog-cancel"
          mat-flat-button
        >
          No
        </button>
        <button
          (click)="save()"
          color="primary"
          data-test-id="file-delete-confirmation-dialog-submit"
          mat-flat-button
        >
          Yes
        </button>
      </div>
    </div>
  </div>`,
})
export class FileDeleteConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public fileName: string,
    private dialogRef: MatDialogRef<FileDeleteConfirmationDialogComponent>
  ) {}

  save(): void {
    this.dialogRef.close(true);
  }

  revoke(): void {
    this.dialogRef.close(false);
  }
}
