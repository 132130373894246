import { Component } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'txp-toast-messages',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  messages$ = this.toastService.getMessages();

  constructor(private toastService: ToastService) {}
}
