import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createSelector, Store } from '@ngrx/store';
import {
  ClientActions,
  selectClientLoadingState,
  selectCurrentClient,
} from '@techspert-io/clients';
import {
  OpportunityActions,
  selectCurrentOpportunity,
  selectOpportunityLoadingState,
} from '@techspert-io/opportunities';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

const selectPageData = createSelector(
  selectClientLoadingState,
  selectOpportunityLoadingState,
  selectCurrentOpportunity,
  selectCurrentClient,
  (clients, opps, opportunity, client) => ({
    loadingState: {
      isLoaded: clients.isLoaded && opps.isLoaded,
      isLoading: clients.isLoading || opps.isLoading,
      loadError: clients.loadError || opps.loadError,
      updateErrors: clients.updateErrors || opps.updateErrors,
    },
    opportunity,
    client,
  })
);

@Component({
  selector: 'app-client-opportunity-searches',
  templateUrl: './client-opportunity-searches.component.html',
})
export class ClientOpportunitySearchesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  vmData$ = this.store.select(selectPageData);
  activePhase: string = 'identified';

  constructor(private activatedRoute: ActivatedRoute, private store: Store) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        tap((params) => {
          this.activePhase = params.phase;
        }),
        distinctUntilChanged(
          (a, b) => a.opportunity === b.opportunity && a.id === b.id
        ),
        tap(({ id }) => {
          this.store.dispatch(
            ClientActions.fetchClient({
              clientId: id,
            })
          );
        }),
        tap(({ opportunity }) => {
          this.store.dispatch(
            OpportunityActions.fetchOpportunity({
              opportunityId: opportunity,
              options: { fetchSearches: true },
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
