import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid);
  }
}

@Component({
  selector: 'app-clone-survey-form',
  templateUrl: './clone-survey-form.component.html',
  styleUrls: ['./clone-survey-form.component.scss'],
  providers: [{ provide: ErrorStateMatcher, useClass: FormErrorStateMatcher }],
})
export class CloneSurveyFormComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  surveyId$: Observable<string> = EMPTY;

  get cloneSurveyForm(): FormGroup {
    return this.rootFormGroup.form;
  }

  get initSurveyIdCtrl(): AbstractControl<string> {
    return this.cloneSurveyForm.controls.initSurveyId;
  }

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.surveyId$ = this.initSurveyIdCtrl.valueChanges.pipe(
      map((val) => `${val || ''}`.trim()),
      map((val) => {
        if (!val) {
          this.initSurveyIdCtrl.setErrors(null);
          return '';
        }

        const surveyId = (val.match(/SV_.{15}/) || []).find(Boolean);
        if (!surveyId) {
          this.initSurveyIdCtrl.setErrors({ surveyId: 'invalid' });
          return '';
        }

        return surveyId;
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
