import { createActionGroup, props } from '@ngrx/store';
import {
  IOpportunity,
  IOpportunityUpdateRequest,
} from '../models/opportunity.models';
import { IOpportunityQueryParams } from '../services/opportunities.service';

interface IFetchOpportunityOptions {
  fetchSearches: boolean;
}

export const OpportunityActions = createActionGroup({
  source: 'Opportunities',
  events: {
    'Fetch Opportunity': props<{
      opportunityId: string;
      options?: IFetchOpportunityOptions;
    }>(),
    'Query Opportunities': props<{ query: IOpportunityQueryParams }>(),
    'Update Opportunity': props<{ update: IOpportunityUpdateRequest }>(),
    'Append Opportunity Search': props<{
      searches: {
        opportunityId: string;
        searches: IOpportunity['searches'];
      };
    }>(),
  },
});

export const OpportunityAPIActions = createActionGroup({
  source: 'Opportunities API',
  events: {
    'Fetch Opportunity Success': props<{
      opportunity: IOpportunity;
      options?: IFetchOpportunityOptions;
    }>(),
    'Fetch Opportunity Failure': props<{
      error: string[];
      opportunityId: string;
    }>(),
    'Query Opportunities Success': props<{ opportunities: IOpportunity[] }>(),
    'Query Opportunities Failure': props<{ error: string[] }>(),
    'Update Opportunity Success': props<{ opportunity: IOpportunity }>(),
    'Update Opportunity Failure': props<{
      error: string[];
      opportunityId: string;
    }>(),
    'Append Opportunity Search Success': props<{ opportunity: IOpportunity }>(),
    'Append Opportunity Search Failure': props<{
      error: string[];
      opportunityId: string;
    }>(),
  },
});
