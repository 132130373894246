<div class="progress-container">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="geography">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Geography </th>
            <td mat-cell *matCellDef="let element">{{element.geography}}</td>
            <td mat-footer-cell *matFooterCellDef><b>Project totals</b></td>
        </ng-container>
        <ng-container matColumnDef="segment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Profile Type </th>
            <td mat-cell *matCellDef="let element">{{element.segment}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="screening">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Screening </th>
            <td mat-cell *matCellDef="let element"
                [ngStyle]="{'background-color': fetchColourCode(1, element.screening, element.target)}">
                {{element.screening}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <b>{{getTotal('screening')}}</b> </td>
        </ng-container>
        <ng-container matColumnDef="inPortal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> In Portal </th>
            <td mat-cell *matCellDef="let element"
                [ngStyle]="{'background-color': fetchColourCode(2, element.inPortal, element.target)}">
                {{element.inPortal}}
            </td>
            <td mat-footer-cell *matFooterCellDef> <b>{{getTotal('inPortal')}}</b> </td>
        </ng-container>
        <ng-container matColumnDef="connectionsScheduled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Connections Scheduled </th>
            <td mat-cell *matCellDef="let element"
                [ngStyle]="{'background-color': fetchColourCode(3, element.connectionsScheduled, element.target)}">
                {{element.connectionsScheduled}}</td>
            <td mat-footer-cell *matFooterCellDef> <b>{{getTotal('connectionsScheduled')}}</b> </td>
        </ng-container>
        <ng-container matColumnDef="connectionsCompleted">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Connections Completed </th>
            <td mat-cell *matCellDef="let element"
                [ngStyle]="{'background-color': fetchColourCode(4, element.connectionsCompleted, element.target)}">
                {{element.connectionsCompleted}}</td>
            <td mat-footer-cell *matFooterCellDef> <b>{{getTotal('connectionsCompleted')}}</b> </td>
        </ng-container>
        <ng-container matColumnDef="target">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Target </th>
            <td mat-cell *matCellDef="let element">{{element.target}}</td>
            <td mat-footer-cell *matFooterCellDef> <b>{{getTotal('target')}}</b></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="header-body"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns" class="header-row"></tr>
    </table>
    <button [disabled]="!dataSource" class="button button--secondary" (click)="downloadProgressCsv()">
        Download as CSV
    </button>
</div>