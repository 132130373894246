<form [formGroup]="filterForm"
  class="flex justify-start content-center items-center flex-row flex-wrap gap-5 h-full w-full min-h-full min-w-full">
  <mat-form-field appearance="outline" class="flex-1">
    <mat-label>Select client</mat-label>
    <input type="text" placeholder="Search client..." matInput formControlName="client" [matAutocomplete]="clientAuto">
    <mat-autocomplete #clientAuto="matAutocomplete" [displayWith]="selectedClientName">
      <mat-option *ngFor="let client of clients" [value]="client">
        {{client.clientName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="outline" class="flex-1">
    <mat-label>Opportunity</mat-label>
    <input type="text" placeholder="Search opportunity..." matInput formControlName="opportunity"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="selectedOpportunityName">
      <mat-option *ngFor="let opportunity of filteredOpportunities$ | async" [value]="opportunity">
        {{opportunity.opportunityName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="outline" class="flex-1">
    <mat-label>Owned By</mat-label>
    <input type="text" placeholder="Search user..." matInput [matAutocomplete]="bookerAuto" formControlName="user">
    <mat-autocomplete #bookerAuto="matAutocomplete" [displayWith]="selectedUser">
      <mat-option *ngFor="let user of users$ | async" [value]="user">
        {{user.firstName}} {{user.lastName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button class="button button--secondary" (click)="resetFilter()">
    RESET
  </button>
</form>