import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CognitoAuthService } from '../services/cognito-auth.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard {
  constructor(private cognitoAuthService: CognitoAuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.cognitoAuthService.loggedIn$.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          this.cognitoAuthService.setRedirectUrl(state.url);
        }
      }),
      map(() => true)
    );
  }
}
