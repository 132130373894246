import {
  IOpportunityExpertRejectionRecord,
  RejectionCodesMap,
} from '../models/experts.models';

export const getExpertRejectionMock = (
  rejectedOn?: Date | string | number
): IOpportunityExpertRejectionRecord => ({
  code: RejectionCodesMap.Money,
  source: 'unknown',
  rejectedOn: new Date(rejectedOn).getTime(),
});
