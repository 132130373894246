import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { handleHttpError } from '@techspert-io/errorHandling';
import { exhaustMap, map, mergeMap } from 'rxjs/operators';
import { ClientsService } from '../services/clients.service';
import { ClientAPIActions, ClientActions } from './clients.actions';

@Injectable()
export class ClientsEffects {
  fetchAllClients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.fetchAllClients),
      exhaustMap(() =>
        this.clientsService.getAll().pipe(
          map((clients) =>
            ClientAPIActions.fetchAllClientsSuccess({
              clients,
            })
          ),
          handleHttpError((error) =>
            ClientAPIActions.fetchAllClientsFailure({
              error,
            })
          )
        )
      )
    );
  });

  fetchClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.fetchClient),
      mergeMap(({ clientId }) =>
        this.clientsService.get(clientId).pipe(
          map((client) =>
            ClientAPIActions.fetchClientSuccess({
              client,
            })
          ),
          handleHttpError((error) =>
            ClientAPIActions.fetchClientFailure({
              error,
              clientId,
            })
          )
        )
      )
    );
  });

  updateClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.updateClient),
      mergeMap(({ client }) =>
        this.clientsService.update(client).pipe(
          map((client) =>
            ClientAPIActions.updateClientSuccess({
              client,
            })
          ),
          handleHttpError((error) =>
            ClientAPIActions.updateClientFailure({
              error,
              clientId: client.clientId,
            })
          )
        )
      )
    );
  });

  buildContractReportForClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.buildContractReportForClient),
      mergeMap(({ clientId }) =>
        this.clientsService.buildContractReports(clientId).pipe(
          map(() => ClientAPIActions.buildContractReportForClientSuccess()),
          handleHttpError((error) =>
            ClientAPIActions.buildContractReportForClientFailure({
              error,
              clientId,
            })
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private clientsService: ClientsService
  ) {}
}
