import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ExpertsModule } from '@techspert-io/experts';
import { BtxSharedModule } from '../../shared/btx-shared.module';
import { AffiliationChipListComponent } from './components/affilitation-chip-list/affiliation-chip-list.component';
import { ExpertiseChipListComponent } from './components/expertise-chip-list/expertise-chip-list.component';
import { ExpertEmailMaskedModePipe } from './pipes/expert-email-masked-mode.pipe';
import { ExpertNameMaskedModePipe } from './pipes/expert-name-masked-mode.pipe';
import { QueryCreatorComponent } from './query-creator/query-creator.component';
import { ResultsDisplayFalconComponent } from './results-display-falcon/results-display-falcon.component';
import { ResultsDisplayFilterComponent } from './results-display-filter/results-display-filter.component';
import { ResultsDisplayFilterService } from './results-display-filter/results-display-filter.service';
import { ResultsDisplayComponent } from './results-display/results-display.component';
import { SearchTaxonomyLookupComponent } from './search-taxonomy-list/search-taxonomy-lookup/search-taxonomy-lookup.component';
import { SearchTaxonomyNodeComponent } from './search-taxonomy-list/search-taxonomy-node/search-taxonomy-node.component';
import { SearchTaxonomyRootComponent } from './search-taxonomy-list/search-taxonomy-root/search-taxonomy-root.component';
import { SearchComponent } from './search.component';
import { SelectOpportunityDialogComponent } from './select-opportunity-dialog/select-opportunity-dialog.component';
import { SearchKnowledgeGraphService } from './services/search-knowledge-graph.service';
import { SearchService } from './services/search.service';

@NgModule({
  imports: [CommonModule, RouterModule, BtxSharedModule, ExpertsModule],
  declarations: [
    SearchComponent,
    QueryCreatorComponent,
    ResultsDisplayComponent,
    SelectOpportunityDialogComponent,
    ExpertEmailMaskedModePipe,
    ExpertNameMaskedModePipe,
    ResultsDisplayFilterComponent,
    ExpertiseChipListComponent,
    AffiliationChipListComponent,
    ResultsDisplayFalconComponent,
    SearchTaxonomyRootComponent,
    SearchTaxonomyNodeComponent,
    SearchTaxonomyLookupComponent,
  ],
  exports: [
    SearchComponent,
    QueryCreatorComponent,
    ResultsDisplayComponent,
    SelectOpportunityDialogComponent,
    ResultsDisplayFilterComponent,
  ],
  providers: [
    SearchService,
    SearchKnowledgeGraphService,
    ResultsDisplayFilterService,
  ],
})
export class SearchModule {}
