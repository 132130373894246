import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromClients from './clients.reducer';
import { IClientState } from './clients.state';

const selectState = createFeatureSelector<IClientState>('clients');

export const selectAllClients = createSelector(selectState, (state) =>
  fromClients.selectAllClients(state)
);

export const selectClientEntities = createSelector(
  selectState,
  fromClients.selectClientEntities
);

export const selectCurrentClientId = createSelector(
  selectState,
  fromClients.getSelectedClientId
);

export const selectClientLoadingState = createSelector(
  selectState,
  (state) => ({
    isLoaded: state.ids.some((s) => state.selectedClientId === s),
    isLoading: state.isLoading.includes(state.selectedClientId),
    loadError: state.loadError,
    updateErrors: state.updateErrors,
  })
);

export const selectCurrentClient = createSelector(
  selectClientEntities,
  selectCurrentClientId,
  (entities, id) => id && entities[id]
);
