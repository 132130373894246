import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-key',
  templateUrl: './key.component.html',
  styleUrls: ['./key.component.scss'],
})
export class KeyComponent {
  @Output() closeMenuSignal = new EventEmitter();

  public closeMenu(): void {
    this.closeMenuSignal.emit();
  }
}
