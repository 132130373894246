import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ExpertActions,
  IDisplayExpert,
  IExpertUpdateRequest,
  IQueryExpertSearchRequest,
  selectLoadingStateForOpportunity,
  selectOpportunityExperts,
  selectTotalsForOpportunity,
} from '@techspert-io/experts';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpportunityExpertsService {
  private cancelDownload$ = new Subject();

  downloadedSearches$ = this.store.select(selectLoadingStateForOpportunity);

  experts$ = this.store.select(selectOpportunityExperts);

  expertTotals$ = this.store.select(selectTotalsForOpportunity);

  constructor(private store: Store) {}

  getSearches(query: IQueryExpertSearchRequest): void {
    this.store.dispatch(
      ExpertActions.fetchExpertSearches({
        query: { ...query, fromPhase: query.fromPhase || 'identified' },
      })
    );
  }

  updateExpert(expert: IDisplayExpert): void {
    this.store.dispatch(ExpertActions.updateExpert({ update: expert }));
  }

  updateMany(
    experts: (IExpertUpdateRequest & Partial<IDisplayExpert>)[],
    resetIsSelected: boolean = true
  ): void {
    this.store.dispatch(
      ExpertActions.updateExperts({
        update: experts,
        resetIsSelected,
      })
    );
  }

  blockExpert(update: IExpertUpdateRequest & Partial<IDisplayExpert>): void {
    this.store.dispatch(ExpertActions.blockExpert({ update }));
  }

  patchExpert(expert: IExpertUpdateRequest & Partial<IDisplayExpert>): void {
    this.store.dispatch(ExpertActions.patchExpert({ expert }));
  }

  setSelected(experts: { expertId: string; isSelected: boolean }[]): void {
    this.store.dispatch(ExpertActions.setSelected({ update: experts }));
  }

  cancelDownloads(): void {
    this.cancelDownload$.next(null);
  }
}
