<div [style.width]="'100%'" *ngIf="screenerAction$ | async as screenerAction; else loadingTmpl">

  <h1 mat-dialog-title>
    <div class="flex flex-1">
      <div class="flex flex-1 content-center items-center">
        <ng-container [ngSwitch]="screenerAction.status">
          <mat-icon [class]="screenerAction.status" fontSet="material-icons-outlined" *ngSwitchCase="'voided'">
            block
          </mat-icon>
          <mat-icon [class]="screenerAction.status" fontSet="material-icons-outlined" *ngSwitchCase="'complete-passed'">
            thumb_up
          </mat-icon>
          <mat-icon [class]="screenerAction.status" fontSet="material-icons-outlined" *ngSwitchCase="'complete-failed'">
            thumb_down
          </mat-icon>
          <mat-icon [class]="screenerAction.status" fontSet="material-icons-outlined" *ngSwitchCase="'complete'">
            pending
          </mat-icon>
          <mat-icon [class]="screenerAction.status" fontSet="material-icons-outlined"
            *ngSwitchCase="'complete-near-pass'">
            call_missed
          </mat-icon>
          <mat-icon [class]="screenerAction.status" fontSet="material-icons-outlined"
            *ngSwitchCase="'complete-near-pass-accepted'">
            thumb_up
          </mat-icon>
          <mat-icon [class]="screenerAction.status" fontSet="material-icons-outlined" *ngSwitchDefault>
            send
          </mat-icon>
        </ng-container>
        <span data-test-id="screener-result-dialog-title">&nbsp; Screener results </span>
      </div>
      <button mat-icon-button mat-dialog-close>
        <mat-icon data-test-id="screener-result-dialog-close-button">close</mat-icon>
      </button>
    </div>
  </h1>

  <div mat-dialog-content>
    <mat-form-field appearance="outline" class="h-full w-full min-h-full min-w-full">
      <mat-label data-test-id="screener-result-dialog-notes-label">Notes</mat-label>
      <textarea matInput [style.resize]="'none'" [formControl]="screenerNotes" placeholder="Notes"
        [style.height]="'100px'" data-test-id="screener-result-dialog-notes-input"></textarea>
    </mat-form-field>

    <div class="question flex flex-col gap-1" *ngFor="let question of (screenerAction.screenerResult?.responses || [])">
      <div class="q" data-test-id="screener-result-dialog-question">{{question.question}}</div>

      <ul *ngIf="question.questionType === 'array' else singleResponse"
        data-test-id="screener-result-dialog-answer-list">
        <li *ngFor="let answer of question.answer.split(',')" data-test-id="screener-result-dialog-answer-item">
          {{answer}}
        </li>
      </ul>

      <ng-template #singleResponse data-test-id="screener-result-dialog-single-answer">{{question.answer}}</ng-template>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="flex flex-1 justify-between gap-2">
      <button mat-stroked-button class="failedBtn" [disabled]="isLoading || screenerAction.status === 'voided'"
        (click)="update('voided')" data-test-id="screener-result-dialog-void-screener-button">
        <mat-icon fontSet="material-icons-outlined">
          block
        </mat-icon> &nbsp;
        <span data-test-id="screener-result-dialog-void-survey-button-label">Void screener</span>
      </button>
      <button mat-stroked-button class="failedBtn"
        [disabled]="isDisabled(screenerAction.status) || screenerAction.status === 'complete-failed'"
        (click)="update('complete-failed')" data-test-id="screener-result-dialog-fail-screener-button">
        <mat-icon fontSet="material-icons-outlined">
          thumb_down
        </mat-icon> &nbsp;
        <span data-test-id="screener-result-dialog-fail-screener-button-label">Mark as failed</span>
      </button>
      <button mat-stroked-button class="nearPassBtn"
        [disabled]="isDisabled(screenerAction.status) || screenerAction.status === 'complete-near-pass'"
        (click)="update('complete-near-pass')" data-test-id="screener-result-dialog-near-pass-screener-button">
        <mat-icon fontSet="material-icons-outlined">
          flip
        </mat-icon> &nbsp;
        <span data-test-id="screener-result-dialog-near-pass-screener-button-label">Mark as near pass</span>
      </button>
      <button mat-flat-button cdkFocusInitial color="primary"
        [disabled]="isDisabled(screenerAction.status) || screenerAction.status === 'complete-passed'"
        (click)="update('complete-passed')" data-test-id="screener-result-dialog-pass-screener-button">
        <mat-icon fontSet="material-icons-outlined">
          thumb_up
        </mat-icon> &nbsp;
        <span data-test-id="screener-result-dialog-pass-screener-button-label">Mark as passed</span>
      </button>
      <button mat-flat-button cdkFocusInitial color="primary" [disabled]="isLoading || !screenerNotes.dirty"
        (click)="update()" data-test-id="screener-result-dialog-save-notes-button">
        <mat-icon fontSet="material-icons-outlined">
          check
        </mat-icon> &nbsp;
        <span data-test-id="screener-result-dialog-save-notes-button-label">Save notes</span>
      </button>
    </div>
  </div>

</div>

<ng-template #loadingTmpl>
  <div class="flex justify-center content-center items-center flex-1 h-full">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>