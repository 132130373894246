import { Pipe, PipeTransform } from '@angular/core';
import { ExpertSegment } from '../models/experts.models';

@Pipe({
  name: 'expertSegment',
})
export class ExpertSegmentPipe implements PipeTransform {
  transform(value: ExpertSegment): string {
    return (
      {
        'Commercial - C-Suite / executive': 'Industry - C-Suite / executive',
        'Commercial - VP / Director': 'Industry - VP / Director',
        'Commercial - Manager / Senior': 'Industry - Manager / Senior',
        'Commercial - Entry-level': 'Industry - Entry-level',
      }[value] || value
    );
  }
}
