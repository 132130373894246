<div *ngIf="source$ | async as source; else loadingTmpl" class="flex flex-col flex-1">
  <div class="flex flex-row">
    <h2 class="flex-1">{{ source.searchName }}</h2>
    <div>{{source.query.service}} - {{source.dateCreated * 1000 | date:'medium' }}</div>
  </div>

  <div *ngIf="source.query.conditions" class="flex flex-col gap-4">
    <div *ngFor="let c of source.query.conditions">
      <div class="font-bold">{{c.field}} ({{c.operator}})</div>
      <p>{{c.terms.join(', ')}}</p>
    </div>
  </div>
</div>

<ng-template #loadingTmpl>
  <div [style.padding]="'16px'" class="flex justify-center content-center items-center flex-1">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>