import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import { IOpportunity } from '../models/opportunity.models';

export const PORTAL_URL = new InjectionToken('PORTAL_URL');

@Pipe({
  name: 'portalLinks',
})
export class PortalLinksPipe implements PipeTransform {
  constructor(@Inject(PORTAL_URL) private portalUrl: string) {}

  public transform(opportunity: IOpportunity): string {
    const baseUrl = `${this.portalUrl}/connect`;
    const nameEnconded = encodeURIComponent(
      `${opportunity.opportunityName}`.toLowerCase()
    )
      .replace(/[!'()]/g, escape)
      .replace(/\*/g, '%2A');
    return `${baseUrl}/${opportunity.publicDisplayId}/${nameEnconded}`;
  }
}
