import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-country-box',
  templateUrl: './country-box.component.html',
  styleUrls: ['./country-box.component.scss'],
})
export class CountryBoxComponent {
  @Input() public countries: string[];
  @Input() public verifiedCountry: string;
}
