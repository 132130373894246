import { createActionGroup, props } from '@ngrx/store';
import { IExpertSource } from '../models/expert-sources.models';

export const ExpertSourcesActions = createActionGroup({
  source: 'ExpertSources',
  events: {
    'Fetch Expert Source': props<{ expertSourceId: string }>(),
    'Query Expert Sources By Opportunity': props<{ opportunityId: string }>(),
  },
});

export const ExpertSourcesAPIActions = createActionGroup({
  source: 'ExpertSources API',
  events: {
    'Fetch Expert Source Success': props<{ expertSource: IExpertSource }>(),
    'Fetch Expert Source Failure': props<{ error: string[] }>(),
    'Query Expert Sources By Opportunity Success': props<{
      expertSources: IExpertSource[];
    }>(),
    'Query Expert Sources By Opportunity Failure': props<{ error: string[] }>(),
  },
});
