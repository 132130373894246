<div class="navMenu" data-test-id="sidebar-menu">
  <ng-template *ngIf="showSanta; then santa_logo else base_logo"></ng-template>

  <div class="navMenu__list">
    <app-nav-menu-link class="nav-list-item" *ngFor="let route of routes" [route]="route"></app-nav-menu-link>
  </div>
  <div class="navMenu__footer">
    <button mat-raised-button color="accent" (click)="logout()">Logout</button>
  </div>
</div>

<ng-template #santa_logo>
  <img class="navMenu__img" src="../../../assets/images/connect-christmas/logo-christmas.png">
</ng-template>
<ng-template #base_logo>
  <img class="navMenu__img" src="../../../assets/images/Techspert_Logo_horizontal.png">
</ng-template>