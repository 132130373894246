import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpertSourceQuery } from '@techspert-io/search';
import { Observable } from 'rxjs';
export interface IFalconSearchExpert {
  expertId: string;
  firstName: string;
  lastName: string;
  affiliations: string[];
  roles: string[];
  expertise: string[];
  opportunityEmails: string[];
  expertSegment: string;
  opportunitySegment: string;
  opportunityName: string;
  stage: string;
  expertSource: {
    expertSourceId: string;
    searchName: string;
    query: ExpertSourceQuery;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ExpertFalconSearchService {
  private readonly baseUrl = '/experts-search';

  constructor(private http: HttpClient) {}

  get(req: {
    searchTerms: string[];
    countries: string[];
    size: number;
  }): Observable<IFalconSearchExpert[]> {
    const params = Object.entries(req)
      .filter(([, value]) => (Array.isArray(value) ? value.length : value))
      .reduce(
        (acc, [key, value]) =>
          acc.append(
            key,
            Array.isArray(value)
              ? value.map(encodeURIComponent).join(',')
              : encodeURIComponent(value)
          ),
        new HttpParams()
      );

    return this.http.get<IFalconSearchExpert[]>(
      `${this.baseUrl}/falcon-search`,
      { params }
    );
  }
}
