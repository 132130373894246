<div class="topbar">
  <div class="topbar__menuIcons" (click)="emitToggleFullScreenSidebar()">
    <mat-icon *ngIf="sidebarOpen" class="material-icons-round">menu_open
    </mat-icon>
    <mat-icon *ngIf="!sidebarOpen" class="menu-icon material-icons-round">menu</mat-icon>
  </div>
  <app-omnisearch></app-omnisearch>


  <ng-container *ngIf="filteredNotifications$ | async as notifs">
    <mat-icon (click)="openNotifications()" class="bell" matBadgeSize="small" [matBadge]="notifs.length"
      matBadgeColor="warn" [matBadgeHidden]="!notifs.length" data-test-id="topbar-notifications-icon"
      aria-hidden="false">
      notifications</mat-icon>
  </ng-container>
</div>