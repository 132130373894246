<div class="timezone-selector-input-container">
    <input placeholder="Search..." *ngIf="!timezone" type="text" class="timezone-selector-input"
        (keyup)="filterTimezones($event)" data-test-id="timezone-selector-input" />
    <div *ngIf="timezone" class="selected-tz-input">
        <div class="ct-generic-chip">
            <span class="ct-generic-chip__text" data-test-id="timezone-selector-selected-option">{{timezone}}</span>
            <mat-icon class="ct-generic-chip__icon" (click)="clearTimezone()"
                data-test-id="timezone-selector-remove-selected-option">
                remove_circle_outline
            </mat-icon>
        </div>
    </div>
    <ul style="max-height: 200px; overflow-y: scroll;" *ngIf="filteredTimezoneList && filteredTimezoneList.length"
        class="drop-down">
        <li (click)="emitTimezoneAndCountry(timezone)" class="tz-list-item"
            *ngFor="let timezone of filteredTimezoneList" data-test-id="timezone-selector-option">
            <span class="tz-list-item-country">{{timezone.country}}</span>
            <span class="tz-list-item-timezone">{{timezone.offset}} {{timezone.abbr}} ({{timezone.name}})</span>
        </li>
    </ul>
</div>