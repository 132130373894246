import {
  Directive,
  HostListener,
  Inject,
  InjectionToken,
  Input,
} from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';

export const EXPERT_PORTAL_URL = new InjectionToken<string>(
  'EXPERT_PORTAL_URL'
);

@Directive({
  selector: '[appCopyExpertPortalLink]',
})
export class CopyExpertPortalLinkDirective {
  @Input('appCopyExpertPortalLink') expertId: string;

  constructor(
    private toastService: ToastService,
    @Inject(EXPERT_PORTAL_URL) private expertPortalUrl: string
  ) {}

  @HostListener('click', ['$event'])
  async copyAvailability(event: MouseEvent): Promise<void> {
    event.preventDefault();

    await navigator.clipboard.writeText(
      `${this.expertPortalUrl}/${this.expertId}`
    );

    this.toastService.sendMessage(
      `Successfully copied to clipboard`,
      'success'
    );
  }
}
