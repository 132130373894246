import { Component } from '@angular/core';
import { IRecipient } from '@techspert-io/solicitation';
import { SolicitationService } from '../solicitation.service';

@Component({
  selector: 'app-recipient-list',
  templateUrl: './recipient-list.component.html',
  styleUrls: ['./recipient-list.component.scss'],
})
export class RecipientListComponent {
  public title = 'Email recipients';

  constructor(public solicitationService: SolicitationService) {}

  public removeFromList(removeRecipient: IRecipient): void {
    this.solicitationService.recipients =
      this.solicitationService.recipients.filter((recipient: IRecipient) => {
        return recipient.id !== removeRecipient.id;
      });
    this.solicitationService.updateCounts();
  }
}
