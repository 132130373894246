<cdk-virtual-scroll-viewport itemSize="75" class="expert-list-viewport" data-test-id="opportunity-expert-list-table">
  <app-expert class="example-item" *cdkVirtualFor="let expert of experts; trackBy:trackByFn; let i = index"
    [client]="client" [opportunity]="opportunity" [expert]="expert" [openFilter]="openFilter" [index]="i"
    (selectExpertSignal)="emitSelectExpertSignal($event)"
    (toggleBlockExpertSignal)="emitToggleBlockExpertSignal($event)"
    (toggleAdminRejectStatusSignal)="emitToggleAdminRejectStatusSignal($event)"
    (navigateToExpertSignal)="emitNavigateToExpertSignal($event)"
    (showExpertScreenerSignal)="emitShowScreenerResults(expert)"
    (addExpertiseToSearchSignal)="emitAddExpertiseToSearchSignal($event)" [showAffiliations]="showAffiliations">
  </app-expert>
</cdk-virtual-scroll-viewport>

<mat-icon (click)="goToTop()" class="go-to-top" title="Go to top" *ngIf="experts.length && experts.length > 10">
  keyboard_capslock
</mat-icon>