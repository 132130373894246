<form [formGroup]="cloneSurveyForm">
  <mat-form-field class="w-full">
    <mat-label data-test-id="clone-survey-form-survey-link-label">Survey Link/Id</mat-label>
    <input matInput formControlName="initSurveyId" data-test-id="clone-survey-form-survey-link-input">
    <mat-hint *ngIf="surveyId$ | async as surveyId" data-test-id="clone-survey-form-survey-link-hint">
      Survey Id: {{surveyId}}
    </mat-hint>
    <mat-error data-test-id="clone-survey-form-survey-link-error">
      Survey Id is invalid
    </mat-error>
  </mat-form-field>
</form>