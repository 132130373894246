import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IExpert } from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';

@Component({
  selector: 'app-opportunity-actions-menu',
  templateUrl: './opportunity-actions-menu.component.html',
  styleUrls: ['./opportunity-actions-menu.component.scss'],
})
export class OpportunityActionsMenuComponent {
  @Input() experts: IExpert[];
  @Input() opportunity: IOpportunity;
  @Output() openSearch = new EventEmitter();

  moreActionsToggle = false;

  toggleMoreActionsMenu(): void {
    this.moreActionsToggle = !this.moreActionsToggle;
  }

  closeMoreActionsMenu(): void {
    this.moreActionsToggle = false;
  }
}
