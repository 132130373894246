import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { CountryService } from '../../../shared/services/country.service';
import { IOpportunityClientContact } from '../models/opportunity.models';

@Pipe({
  name: 'clientContactColumn',
})
export class ClientContactColumnPipe implements PipeTransform {
  constructor(private countryService: CountryService) {}

  transform(
    contact: IOpportunityClientContact,
    prop: string,
    isHeader?: boolean
  ): string {
    const getDefaultProp = (): string => contact && (contact[prop] ?? prop);
    const titleCase = (str: string): string =>
      str
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');

    switch (prop) {
      case 'timezone':
        return isHeader ? 'Timezone' : this.getTimezone(contact);
      case 'country':
        return isHeader ? 'Country' : this.getCountry(contact[prop]);
      case 'name':
        return isHeader ? 'Name' : `${contact.firstName} ${contact.lastName}`;
      default:
        return isHeader ? titleCase(prop) : getDefaultProp();
    }
  }

  private getCountry(value: IOpportunityClientContact['country']): string {
    const count = this.countryService.countryList.find(
      (d) => d.abbreviation === value
    );
    return count ? count.country : 'NOT YET SET';
  }

  private getTimezone(contact: IOpportunityClientContact): string {
    if (!contact.timezone?.name) {
      return 'NOT YET SET';
    }

    return `${moment().tz(contact.timezone.name).format('Z z')} ${
      contact.timezone.name
    }`;
  }
}
