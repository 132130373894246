import { Component, Input } from '@angular/core';
import { IExpertComplianceTraining } from '@techspert-io/experts';

@Component({
  selector: 'app-compliance-training-box',
  templateUrl: './compliance-training-box.component.html',
  styleUrls: ['./compliance-training-box.component.scss'],
})
export class ComplianceTrainingBoxComponent {
  @Input() public complianceTraining?: IExpertComplianceTraining;
}
