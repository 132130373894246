import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDisplayExpertCallActions } from '@techspert-io/experts';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConferencesConferenceRemindersService {
  private readonly baseUrl = '/schedule';

  constructor(private http: HttpClient) {}

  public getDisplayExpertCallActions(
    opportunityId: string
  ): Observable<Record<string, IDisplayExpertCallActions>> {
    return this.http.get<Record<string, IDisplayExpertCallActions>>(
      `${this.baseUrl}/displayExpertCallActions/${opportunityId}`,
      {}
    );
  }
}
