<div *ngIf="!verifiedCountry; else ShowVerifiedCountry" class="country">
  <span class="country-label">Countries</span>
  <span class="country-value">{{countries && countries.length ? countries.join(', ') : ''}}</span>
</div>

<ng-template #ShowVerifiedCountry>
  <div class="country">
    <span class="country-label">Verified Country</span>
    <span class="country-value">{{verifiedCountry}}</span>
  </div>
</ng-template>