import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConferencesConferenceRemindersService } from '@techspert-io/conferences';
import { EngagementsService, IEngagement } from '@techspert-io/engagements';
import { handleHttpError } from '@techspert-io/errorHandling';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, combineLatest, from, of, throwError } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  reduce,
  switchMap,
  tap,
} from 'rxjs/operators';
import { EmailService } from '../../../shared/services/email.service';
import { IExpert } from '../models/experts.models';
import { ExpertsCreateService } from '../services/experts-create.service';
import { ExpertsQueryService } from '../services/experts-query.service';
import { ExpertsUpdateService } from '../services/experts-update.service';
import { ExpertAPIActions, ExpertActions } from './experts.actions';

@Injectable()
export class ExpertsEffects {
  fetchExpertSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertActions.fetchExpertSearches),
      exhaustMap(({ query }) =>
        combineLatest([
          this.expertsQueryService.query(query),
          this.conferencesConferenceRemindersService.getDisplayExpertCallActions(
            query.opportunityId
          ),
          this.getEngagements(query.opportunityId),
        ]).pipe(
          mergeMap(([experts, callActions, engagements]) =>
            from(experts).pipe(
              map((r) => ({
                totals: r.totals,
                searchId: r.searchId,
                experts: r.experts.map((e) => ({
                  ...e,
                  engagements: engagements[e.expertId] || [],
                  callActions: callActions[e.expertId],
                })),
              })),
              reduce((prev, curr) => [...prev, curr], [])
            )
          ),
          map((response) =>
            ExpertAPIActions.fetchExpertSearchesSuccess({
              response,
            })
          ),
          handleHttpError((error) =>
            ExpertAPIActions.fetchExpertSearchesFailure({ error })
          )
        )
      )
    );
  });

  fetchExpert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertActions.fetchExpert),
      mergeMap(({ expertId }) =>
        this.expertsQueryService.getById(expertId).pipe(
          map((expert) =>
            ExpertAPIActions.fetchExpertSuccess({
              expert,
            })
          ),
          handleHttpError((error) =>
            ExpertAPIActions.fetchExpertFailure({ error, expertId })
          )
        )
      )
    );
  });

  createExperts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertActions.createExperts),
      mergeMap(({ experts }) =>
        this.expertsCreateService.createMany(experts).pipe(
          map((experts) =>
            ExpertAPIActions.createExpertsSuccess({
              experts: experts.success,
            })
          ),
          handleHttpError((error) =>
            ExpertAPIActions.createExpertsFailure({ error })
          )
        )
      )
    );
  });

  updateExpert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertActions.updateExpert),
      mergeMap(({ update }) =>
        this.expertsUpdateService.updateOne(update).pipe(
          map((expert) =>
            ExpertAPIActions.updateExpertSuccess({
              expert,
            })
          ),
          handleHttpError((error) =>
            ExpertAPIActions.updateExpertFailure({
              error,
              expertId: update.expertId,
            })
          )
        )
      )
    );
  });

  blockExpert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertActions.blockExpert),
      mergeMap(({ update }) =>
        this.expertsUpdateService.updateOne(update).pipe(
          switchMap((expert) => this.blockEmails(expert)),
          map((expert) =>
            ExpertAPIActions.blockExpertSuccess({
              expert,
            })
          ),
          handleHttpError((error) =>
            ExpertAPIActions.blockExpertFailure({
              error,
              expertId: update.expertId,
            })
          )
        )
      )
    );
  });

  updateExperts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertActions.updateExperts),
      mergeMap(({ update, resetIsSelected }) =>
        this.expertsUpdateService.updateMany(update).pipe(
          map((experts) =>
            ExpertAPIActions.updateExpertsSuccess({
              experts: experts.success,
              resetIsSelected,
            })
          ),
          handleHttpError((error) =>
            ExpertAPIActions.updateExpertsFailure({
              error,
              expertIds: update.map((e) => e.expertId),
            })
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private expertsQueryService: ExpertsQueryService,
    private expertsCreateService: ExpertsCreateService,
    private expertsUpdateService: ExpertsUpdateService,
    private engagementsService: EngagementsService,
    private conferencesConferenceRemindersService: ConferencesConferenceRemindersService,
    private emailService: EmailService,
    private toastService: ToastService
  ) {}

  private getEngagements(
    opportunityId: string
  ): Observable<Record<string, IEngagement[]>> {
    return this.engagementsService.query({ opportunityId }).pipe(
      switchMap((engagements) => engagements),
      reduce<IEngagement, Record<string, IEngagement[]>>(
        (acc, engagement) =>
          Object.assign(acc, {
            [engagement.expertId]: [
              ...(acc[engagement.expertId] || []),
              engagement,
            ],
          }),
        {}
      )
    );
  }

  private blockEmails(expert: IExpert): Observable<IExpert> {
    if (!expert.blocked) {
      return of(expert);
    }

    const emails = [
      ...new Set([expert.primaryEmail, ...expert.opportunityEmails]),
    ].filter(Boolean);

    return this.emailService.block(emails).pipe(
      tap(() =>
        this.toastService.sendMessage(
          `Unsubscribed emails: ${emails.join(', ')}`,
          'success'
        )
      ),
      catchError(() => {
        this.toastService.sendMessage(
          `Failed to unsubscribe emails: ${emails.join(', ')}`,
          'error'
        );
        return throwError(() => new Error('Failed to unsubscribe emails'));
      }),
      map(() => expert)
    );
  }
}
