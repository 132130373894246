import { ConnectPhase } from '@techspert-io/experts';

export enum OmnisearchEntity {
  experts = 'experts',
  solicitationExperts = 'solicitationExperts',
  opportunities = 'opportunities',
  clients = 'clients',
}

export interface IOmnisearchExpert {
  id: string;
  entity: OmnisearchEntity.experts;
  name: string;
  expertise: string[];
  currency: string;
  emailAddress: string;
  oppId: string;
  oppName: string;
  clientId: string;
  affiliations: string[];
  profileType: string;
  geographicTarget: string;
  bio: string;
  connectPhase: ConnectPhase;
  searchExpertId: string;
  omnisearchHidden: boolean;
}

export interface IOmnisearchSolicitationExpert {
  id: string;
  entity: OmnisearchEntity.solicitationExperts;
  name: string;
  emailAddress: string;
  allEmails: string[];
  oppId: string;
  oppName: string;
  clientId: string;
  profileType: string;
  geographicTarget: string;
}

export interface IOmnisearchClient {
  id: string;
  entity: OmnisearchEntity.clients;
  name: string;
}

export interface IOmnisearchOpportunity {
  id: string;
  entity: OmnisearchEntity.opportunities;
  name: string;
  publicDisplayId: string;
  clientId: string;
  omnisearchHidden: boolean;
  onHold: boolean;
  opportunityAssigneeIds: string[];
}

export type OmnisearchQueryResponse =
  | IOmnisearchExpert
  | IOmnisearchSolicitationExpert
  | IOmnisearchOpportunity
  | IOmnisearchClient;
