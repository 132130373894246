<div class="flex flex-col gap-4 flex-1">
  <mat-card class="p-2">
    <div class="flex flex-row justify-between items-center gap-20">
      <div class="flex flex-row items-center gap-10">
        <h2>Search taxonomy</h2>

        <app-search-taxonomy-lookup (selectedNode)="selectedNode = $event"></app-search-taxonomy-lookup>
      </div>

      <mat-checkbox [(ngModel)]="includeSynonyms" labelPosition="before"
        matTooltip="Include synonyms in when adding terms to query conditions" class="cursor-pointer"
        data-test-id="search-taxonomy-root-include-synonyms-toggle">
        Include synonyms
      </mat-checkbox>
    </div>
  </mat-card>

  <mat-card class="p-2 overflow-y-auto h-full">
    <ng-container *ngIf="rootNode$ | async as node; else loading">
      <app-search-taxonomy-node (addNodeSignal)="onAddNode($event)" [node]="node" [highlight]="selectedNode"
        [isRoot]="true"></app-search-taxonomy-node>
    </ng-container>
  </mat-card>
</div>

<ng-template #loading>
  <div class="flex justify-center content-center items-center flex-col">
    <app-scintillating-loader></app-scintillating-loader>
  </div>
</ng-template>