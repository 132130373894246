import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@techspert-io/auth';
import { IRecipient } from '@techspert-io/solicitation';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

type FooterFile =
  | 'clinical_deep-next'
  | 'clinical_historical'
  | 'commercial_deep-next'
  | 'commercial_historical'
  | 'generic';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  private cache$: Record<string, Observable<string>> = {};

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window
  ) {}

  footerLookUp(recipient: IRecipient): Observable<string> {
    const segment = recipient.segment;
    const source = recipient.source;
    const templateSources = {
      'Clinical - KOL': {
        'deep-next': 'clinical_deep-next',
        deep3: 'clinical_deep-next',
        historical: 'clinical_historical',
      },
      'Clinical - Prescriber': {
        'deep-next': 'clinical_deep-next',
        deep3: 'clinical_deep-next',
        historical: 'clinical_historical',
      },
      'Commercial - C-Suite / executive': {
        'deep-next': 'commercial_deep-next',
        deep3: 'commercial_deep-next',
        historical: 'commercial_historical',
      },
      'Commercial - Entry-level': {
        'deep-next': 'commercial_deep-next',
        deep3: 'commercial_deep-next',
        historical: 'commercial_historical',
      },
      'Commercial - Manager / Senior': {
        'deep-next': 'commercial_deep-next',
        deep3: 'commercial_deep-next',
        historical: 'commercial_historical',
      },
      'Commercial - VP / Director': {
        'deep-next': 'commercial_deep-next',
        deep3: 'commercial_deep-next',
        historical: 'commercial_historical',
      },
    };

    const segmentAndSourceMatches = (): boolean =>
      templateSources[segment] && templateSources[segment][source];
    const onlySegmentMatches = (): boolean =>
      templateSources[segment] && !templateSources[segment][source];
    const onlySourceMatches = (): boolean =>
      !templateSources[segment] && templateSources['Clinical - KOL'][source];

    if (segmentAndSourceMatches()) {
      return this.getCachedFooter(templateSources[segment][source]);
    }
    if (onlySegmentMatches()) {
      return this.getCachedFooter(templateSources[segment]['deep-next']);
    }
    if (onlySourceMatches()) {
      return this.getCachedFooter(templateSources['Clinical - KOL'][source]);
    }

    return this.getCachedFooter('generic');
  }

  private getCachedFooter(file: FooterFile): Observable<string> {
    if (!this.cache$[file]) {
      this.cache$[file] = this.getFooter(file).pipe(
        publishReplay(1),
        refCount()
      );
    }
    return this.cache$[file];
  }

  private getFooter(file: FooterFile): Observable<string> {
    return this.http.get(
      `${this.window.location.protocol}//${this.window.location.host}/assets/templates/footers/${file}.html`,
      {
        responseType: 'text',
      }
    );
  }
}
