<div *ngIf="!primaryEmail; else ShowPocEmail" class="email">
  <span class="email-label">Emails</span>
  <ng-container *ngIf="emails.length">
    <app-shared-email-pill [email]="emails[0]">
    </app-shared-email-pill>
    <div class="ellipse-box email-hover" *ngIf="emails.length > 1">
      <span>...</span>
      <div class="drop-down">
        <div class="drop-down-item flex" *ngFor="let email of emails.slice(1)">
          <app-shared-email-pill [email]="email">
          </app-shared-email-pill>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #ShowPocEmail>
  <div class="email">
    <app-shared-email-pill [email]="primaryEmail" [poc]="true"></app-shared-email-pill>
  </div>
</ng-template>