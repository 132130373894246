import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CognitoAuthService, IUserBase } from '@techspert-io/auth';
import {
  IOpportunity,
  IOpportunityClientContact,
  IOpportunityOptions,
  IOpportunitySegmentConfigs,
  IOpportunitySegments,
  OpportunitiesService,
  OpportunityConferenceProviders,
} from '@techspert-io/opportunities';
import { IOpportunitySegmentConfigExternalSurvey } from '@techspert-io/opportunity-segments';
import { Subject, takeUntil, tap } from 'rxjs';
import { SegmentKickOff } from '../../../features/opportunity-kick-off/models/segment-data.models';

export interface IKickoffOpportunity extends IOpportunity {
  createSlackChannel: boolean;
  initSurveyId: string;
  initClientURL: string;
}

export interface ISubmitOpportunity
  extends Omit<IKickoffOpportunity, 'clientContacts'> {
  clientContacts: IUserBase[];
}

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrls: ['./opportunity-details.component.scss'],
})
export class OpportunityDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() opportunity: Partial<IKickoffOpportunity>;
  @Input() isLoading: boolean;
  @Input() isReadOnly: boolean = false;
  @Output() submitOpportunity = new EventEmitter<Partial<ISubmitOpportunity>>();

  private destroy$ = new Subject<void>();

  defaultConferenceProvider: string;

  ndAssignmentDisableFields = [
    'defaultConferenceProvider',
    'defaultConferenceTopic',
    'notes',
    'defaultProjectPitch',
    'anticipatedCallTime',
  ];

  kickoffForm = new FormGroup({
    projectType: new FormControl<IKickoffOpportunity['projectType']>(null, [
      Validators.required,
    ]),
    clientContacts: new FormControl<IUserBase[]>(null, [
      Validators.required,
      this.clientContactsValidator(),
    ]),
    opportunityAssignees: new FormControl<IUserBase[]>(null, [
      Validators.required,
    ]),
    segments: new FormControl<SegmentKickOff[]>(null, [
      Validators.required,
      this.segmentsValidator(),
    ]),
    // JACK: to be uncommented with ticket ENG-1826
    // segments: new FormControl<SegmentKickOff[]>(
    //   null,
    //   [
    //     this.isReadOnly ? Validators.required : null,
    //     this.segmentsValidator(),
    //   ].filter(Boolean)
    // ),
    segmentConfig: new FormControl<IOpportunitySegmentConfigs>(null),

    anticipatedCallTime: new FormControl<number>(null),
    defaultConferenceTopic: new FormControl<string>(null, Validators.required),
    defaultConferenceProvider:
      new FormControl<OpportunityConferenceProviders | null>(null),
    defaultProjectPitch: new FormControl<string>(null),
    notes: new FormControl<string>(null, Validators.required),
    createSlackChannel: new FormControl<boolean>(true),
    settings: new FormControl<Partial<IOpportunityOptions>>({}),
    defaultSurveyProvider: new FormControl<
      IKickoffOpportunity['defaultSurveyProvider']
    >(null),
    externalSurveyConfig: new FormGroup({
      type: new FormControl<IOpportunitySegmentConfigExternalSurvey['type']>(
        'unknown'
      ),
      linkBase: new FormControl<string>(null),
      linkInput: new FormControl<string>(null),
      linkParams: new FormControl<Record<string, string>>(null),
      surveyId: new FormControl<string>(null),
    }),
    initSurveyId: new FormControl<string>(null),
    initClientURL: new FormControl<string>(null),
    redirectLinkConfig: new FormGroup({
      link: new FormControl<string>(null),
    }),
  });

  segmentSelection = new SelectionModel<SegmentKickOff>(true, []);
  isSubmitted = false;

  get isScreenerEnabled(): boolean {
    return !!this.kickoffForm.controls.settings.value.screenerEnabled;
  }

  get segmentsCtrl(): FormControl<SegmentKickOff[]> {
    return this.kickoffForm.controls.segments;
  }

  get clientContactsCtrl(): FormControl<IUserBase[]> {
    return this.kickoffForm.controls.clientContacts;
  }

  get opportunityAssigneesCtrl(): FormControl<IUserBase[]> {
    return this.kickoffForm.controls.opportunityAssignees;
  }

  constructor(
    private cognitoAuthService: CognitoAuthService,
    private opportunitiesService: OpportunitiesService
  ) {}

  ngOnInit(): void {
    // JACK: to be uncommented with ticket ENG-1826
    // if (!this.isReadOnly) {
    //   this.kickoffForm.get('segments')?.clearValidators();
    //   this.kickoffForm.get('segments')?.updateValueAndValidity();
    // }

    this.setupFormDefaults(this.opportunity);

    this.ndAssignmentDisableFields.forEach((field) => {
      const control = this.kickoffForm.get(field);
      this.isReadOnly ? control?.disable() : control?.enable();
    });

    this.kickoffForm
      .get('defaultConferenceProvider')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((values) => (this.defaultConferenceProvider = values))
      )
      .subscribe();
  }

  ngOnChanges(): void {
    this.setupFormDefaults(this.opportunity);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    this.kickoffForm.markAllAsTouched();
    this.isSubmitted = true;
    if (this.kickoffForm.valid) {
      const {
        settings,
        segments,
        externalSurveyConfig,
        projectType,
        defaultSurveyProvider,
        initSurveyId,
        initClientURL,
        clientContacts,
        redirectLinkConfig,
        ...rest
      } = this.kickoffForm.value;

      this.submitOpportunity.emit({
        ...rest,
        ...settings,
        ...this.mapProjectConfig(
          externalSurveyConfig,
          settings.screenerEnabled,
          projectType,
          defaultSurveyProvider,
          redirectLinkConfig
        ),
        ...this.getSegmentFields(segments),
        stageName: this.opportunity.stageName,
        clientContacts,
        initSurveyId: this.getSurveyId(initSurveyId),
        initClientURL: initClientURL,
        opportunityId: this.opportunity.opportunityId,
        closeDate: this.opportunity.closeDate,
        opportunityOwner: this.opportunity.opportunityOwner,
        countries: this.opportunity.countries,
        opportunityName: this.opportunity.opportunityName,
        clientId: this.opportunity.clientId,
      });
      this.isSubmitted = false;
    }
  }

  private getSurveyId(initSurveyId: string): string {
    return ((initSurveyId || '').match(/SV_.{15}/) || []).find(Boolean);
  }

  private setupFormDefaults(opp: Partial<IKickoffOpportunity>): void {
    this.kickoffForm.setValue({
      projectType: opp.projectType,

      defaultSurveyProvider: opp.defaultSurveyProvider,

      externalSurveyConfig: {
        type: null,
        linkBase: null,
        linkInput: null,
        linkParams: {},
        surveyId: null,
      },

      anticipatedCallTime: opp?.anticipatedCallTime || 60,

      defaultConferenceTopic: opp.defaultConferenceTopic || '',

      defaultConferenceProvider: opp?.defaultConferenceProvider || null,

      defaultProjectPitch: opp.defaultProjectPitch || '',

      notes: opp.notes || '',

      segments: this.getSegmentsArray(opp.segments),

      clientContacts: this.setupClientContacts(opp.clientContacts || []),

      opportunityAssignees: this.setupOpportunityAssignees(
        opp.opportunityAssignees || []
      ),

      createSlackChannel: !!opp?.createSlackChannel,

      initSurveyId: null,

      initClientURL: null,

      redirectLinkConfig: { link: null },

      segmentConfig: opp.segmentConfig || {},

      settings: {
        callRecording: opp?.callRecording,

        blind: opp?.blind,

        automatedScheduling: opp?.automatedScheduling,

        screenerComparisonEnabled: opp?.screenerComparisonEnabled,

        sendExpertPaymentEmail: opp?.sendExpertPaymentEmail,

        enrichedSchedulerInvite: opp?.enrichedSchedulerInvite,

        inProjectReferralEnabled: opp?.inProjectReferralEnabled,

        profileEditEnabled: opp?.profileEditEnabled,

        expertBlockProfileOutreachEnabled:
          opp?.expertBlockProfileOutreachEnabled,

        excludeExpertPIIForClientEmails: opp?.excludeExpertPIIForClientEmails,

        isClientHostEnabled: opp?.isClientHostEnabled,

        screenerEnabled: opp.screenerEnabled,

        conferenceReminderDefaultSettings:
          opp.conferenceReminderDefaultSettings,

        completedScreenerEmailSend: opp.completedScreenerEmailSend,

        failedScreenerEmailSend: opp.failedScreenerEmailSend,

        omnisearchHidden: opp.omnisearchHidden,

        omnisearchExpertNotification: opp.omnisearchExpertNotification,

        createExpertTranscriptSummary: opp.createExpertTranscriptSummary,

        enhancedTranscriptionEnabled: opp.enhancedTranscriptionEnabled,

        createSegmentTranscriptSummary: opp.createSegmentTranscriptSummary,

        cepLog: opp.cepLog,

        screenshare: opp.screenshare,

        callTranscripts: opp.callTranscripts,

        closedEmailSend: opp.closedEmailSend,
      },
    });
  }

  private setupOpportunityAssignees(
    assignees: IUserBase[]
  ): Partial<IUserBase[]> {
    const { loggedInUser } = this.cognitoAuthService;
    if (assignees.find((user) => user.connectId === loggedInUser.connectId)) {
      return this.opportunitiesService.sortPeople(assignees);
    }
    return (
      this.opportunitiesService.sortPeople([...assignees, loggedInUser]) || []
    );
  }

  private setupClientContacts(
    contacts: IOpportunityClientContact[]
  ): IUserBase[] {
    const formContacts = (contacts || []).map((c) => ({
      ...c,
      options: {
        sendInviteEmail: this.opportunity?.projectType === 'Qual',
      },
    }));

    return this.opportunitiesService.sortPeople(formContacts) || [];
  }

  private getSegmentsArray(segments: IOpportunitySegments): SegmentKickOff[] {
    return Object.entries(segments)
      .map(([k, v]) => ({
        ...v,
        salesforceId: k,
        owner: [],
        cover: [],
      }))
      .filter((s) => s.active)
      .sort(
        (a, b) =>
          a.geography.toLowerCase().localeCompare(b.geography.toLowerCase()) ||
          a.segment.toLowerCase().localeCompare(b.segment.toLowerCase())
      );
  }

  private mapProjectConfig(
    surveyForm: Partial<IOpportunity['externalSurveyConfig']>,
    isScreenerEnabled: boolean,
    projectType: IOpportunity['projectType'],
    surveyProvider: IOpportunity['defaultSurveyProvider'],
    redirectLinkConfig: Partial<IOpportunity['redirectLinkConfig']>
  ): Partial<IOpportunity> {
    const screenerEnabled =
      isScreenerEnabled ||
      (projectType === 'Quant' && surveyProvider === 'Qualtrics');

    const externalSurveyConfig =
      projectType === 'Quant' && surveyProvider === 'External'
        ? {
            externalSurveyConfig: {
              surveyId: surveyForm.surveyId,
              linkBase: surveyForm.linkBase,
              linkParams: surveyForm.linkParams,
              type: surveyForm.type,
            },
          }
        : {};

    const defaultSurveyProvider =
      projectType === 'Quant' || (projectType === 'Qual' && isScreenerEnabled)
        ? { defaultSurveyProvider: surveyProvider }
        : {};

    const redirectConfig =
      projectType === 'Registration'
        ? { redirectLinkConfig: { link: redirectLinkConfig.link || '' } }
        : {};

    return {
      screenerEnabled,
      projectType,
      ...redirectConfig,
      ...externalSurveyConfig,
      ...defaultSurveyProvider,
    };
  }

  private getSegmentFields(segs: SegmentKickOff[]): {
    segments: IOpportunitySegments;
    segmentConfig: IOpportunitySegmentConfigs;
  } {
    return segs.reduce(
      (prev, curr) => {
        const {
          salesforceId,
          owner,
          cover,
          conferenceTopic,
          pitch,
          availabilityStart,
          availabilityEnd,
          referralCurrency,
          referralFee,
          referralHonoraria,
          ...segment
        } = curr;

        return {
          segments: {
            ...prev.segments,
            [salesforceId]: segment,
          },
          segmentConfig: {
            ...prev.segmentConfig,
            [salesforceId]: {
              owner,
              cover,
              conferenceTopic,
              pitch,
              templateKeys: {
                availabilityStart,
                availabilityEnd,
                referralCurrency,
                referralFee,
                referralHonoraria,
              },
            },
          },
        };
      },
      { segments: {}, segmentConfig: {} }
    );
  }

  private segmentsValidator(): ValidatorFn {
    return (ctrl): ValidationErrors => {
      const segs = ctrl.value as SegmentKickOff[];

      const noOwners = (segs || [])
        .filter((s) => !s.owner.length)
        .map((s) => `${s.geography} ${s.segment} missing owner`);

      const noTopic = (segs || [])
        .filter((s) => !s.conferenceTopic)
        .map((s) => `${s.geography} ${s.segment} missing topic`);

      const errs = [...noOwners, ...noTopic];

      return errs.length ? { segmentsOwnerValidator: errs } : null;
    };
  }

  private clientContactsValidator(): ValidatorFn {
    return (ctrl): ValidationErrors => {
      const contacts = (ctrl.value || []) as IOpportunityClientContact[];

      const missingCountry = contacts.some((s) => !(s.country || s.timezone));
      const hasDuplicates =
        new Set(contacts.map((d) => d.email)).size !== contacts.length;

      return hasDuplicates || missingCountry
        ? {
            ...(hasDuplicates ? { hasDuplicates: 'Duplicate Contacts' } : {}),
            ...(missingCountry ? { missingCountry: 'Missing timezones' } : {}),
          }
        : null;
    };
  }
}
