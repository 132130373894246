import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@techspert-io/notifications';
import { BtxSharedModule } from '../../shared/btx-shared.module';
import { AccountSummaryComponent } from './account-summary/account-summary.component';
import { DashboardOpportunityListComponent } from './dashboard-opportunity-list/dashboard-opportunity-list.component';
import { DashboardPendingConferenceBookingExpertsComponent } from './dashboard-pending-conference-booking-experts/dashboard-pending-conference-booking-experts.component';
import { DashboardUpcomingConferencesComponent } from './dashboard-upcoming-conferences/dashboard-upcoming-conferences.component';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardUpcomingConferencesComponent,
    DashboardOpportunityListComponent,
    AccountSummaryComponent,
    DashboardPendingConferenceBookingExpertsComponent,
  ],
  imports: [
    BtxSharedModule,
    RouterModule,
    CommonModule,
    FormsModule,
    NotificationsModule,
    ReactiveFormsModule,
    ScrollingModule,
  ],
  exports: [
    DashboardComponent,
    DashboardUpcomingConferencesComponent,
    DashboardOpportunityListComponent,
    AccountSummaryComponent,
  ],
})
export class DashboardModule {}
