<button class="thin-button" [appExpertClone]="experts" [opportunityId]="opportunity.opportunityId"
  data-test-id="opportunity-actions-menu-clone">
  CLONE
</button>
<button class="thin-button" (click)="openSearch.emit()" data-test-id="opportunity-actions-menu-search">
  SEARCH
</button>
<button class="thin-button" [routerLink]="['/admin', 'manual-upload', opportunity.opportunityId]"
  data-test-id="opportunity-actions-menu-upload-experts">
  UPLOAD
</button>
<button class="thin-button lg:hidden" data-test-id="opportunity-actions-menu-opportunity-dashboard">
  <a routerLink="../../../../dashboard"> VIEW DETAILS </a>
</button>
<button class="thin-button lg:hidden" data-test-id="opportunity-actions-menu-client-portal">
  <a [href]="opportunity | portalLinks" target="_blank">PORTAL</a>
</button>
<button class="thin-button lg:hidden" data-test-id="opportunity-actions-menu-salesforce">
  <a [href]="opportunity | salesforceLinks" target="_blank">SALESFORCE</a>
</button>

<div (appClickOutside)="closeMoreActionsMenu()" class="more-actions">
  <mat-icon (click)="toggleMoreActionsMenu()" data-test-id="opportunity-actions-menu-overflow">more_vert</mat-icon>
  <ul *ngIf="moreActionsToggle" class="drop-down">
    <li class="drop-down-item lg:flex xl:hidden" routerLink="../../../../dashboard">
      <mat-icon class="drop-down-item-icon">subject</mat-icon>
      <span data-test-id="opportunity-actions-menu-opportunity-dashboard">View details</span>
    </li>
    <li class="drop-down-item flex" [routerLink]="['/admin', 'campaigns', opportunity.opportunityId]">
      <mat-icon class="drop-down-item-icon">campaign</mat-icon>
      <span data-test-id="opportunity-actions-menu-campaigns">Campaigns</span>
    </li>
    <li class="drop-down-item flex">
      <mat-icon class="drop-down-item-icon">language</mat-icon>
      <span data-test-id="opportunity-actions-menu-client-portal">
        <a [href]="opportunity | portalLinks" target="_blank">Portal</a></span>
      <mat-icon matTooltip="Copy to clipboard" class="interactable" [copy-clipboard]="opportunity | portalLinks">
        content_copy
      </mat-icon>
    </li>
    <li class="drop-down-item flex">
      <mat-icon class="drop-down-item-icon">backup</mat-icon>
      <span data-test-id="opportunity-actions-menu-salesforce">
        <a [href]="opportunity | salesforceLinks" target="_blank">Salesforce</a></span>
      <mat-icon matTooltip="Copy to clipboard" class="interactable" [copy-clipboard]="opportunity | salesforceLinks">
        content_copy
      </mat-icon>
    </li>
  </ul>
</div>