import {
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Directive({
  selector: '[appOpportunityTimer]',
})
export class OpportunityTimerDirective implements OnInit, OnDestroy {
  @Input('appOpportunityTimer') label: string;
  timerStart: number;
  isClicked = false;

  constructor(private gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.timerStart = performance.now();
  }

  ngOnDestroy(): void {
    if (this.isClicked) {
      this.gaService.event(
        'timing',
        'opp_kick_off',
        this.label,
        Math.round(performance.now() - this.timerStart)
      );
    }
  }

  @HostListener('click')
  public onClick(): void {
    this.isClicked = true;
    this.gaService.event('submit', 'opp_kick_off', this.label);
  }
}
