import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  ICreateSolicitationResponse,
  IPrepEmail,
} from '../../../shared/models/email';

export interface IOutreachSendRequest {
  emails: IPrepEmail[];
}

@Injectable({
  providedIn: 'root',
})
export class OutreachService {
  private readonly baseUrl = '/outreach';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  send(payload: IOutreachSendRequest): Observable<ICreateSolicitationResponse> {
    return this.http
      .post<ICreateSolicitationResponse>(`${this.baseUrl}/send`, payload)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            this.showErrorToast(err.error.message);
          } else {
            this.showErrorToast(err.message);
          }
          return throwError(err);
        })
      );
  }

  private showErrorToast(message: string) {
    this.toastService.sendMessage(message, 'error');
  }
}
