import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { IOutreachTemplate } from '../../../models/outreach-templates.models';
import { OutreachTemplatesService } from '../../../services/outreach-templates.service';

@Component({
  selector: 'app-outreach-templates-create-page',
  templateUrl: './outreach-templates-create-page.component.html',
  styleUrls: ['./outreach-templates-create-page.component.scss'],
})
export class OutreachTemplatesCreatePageComponent {
  isLoading$ = new BehaviorSubject(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private outreachTemplatesService: OutreachTemplatesService
  ) {}

  onCreate(template: IOutreachTemplate): void {
    this.isLoading$.next(true);

    this.outreachTemplatesService
      .create(template)
      .pipe(
        tap(() => this.isLoading$.next(false)),
        switchMap(() =>
          this.router.navigate(['../'], { relativeTo: this.route })
        )
      )
      .subscribe();
  }
}
