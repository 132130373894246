import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Moment from 'moment';
import * as MomentTz from 'moment-timezone';
import { CountryService } from '../../services/country.service';

@Component({
  selector: 'app-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss'],
})
export class TimezoneSelectorComponent implements OnInit, OnChanges {
  @Input() public timezoneName: string;
  @Output() public timezoneAndCountrySignal = new EventEmitter();
  public timezone;
  public moment = Moment;
  public momentTz = MomentTz;
  public parsedTimezoneList;
  public filteredTimezoneList;
  constructor(public countryService: CountryService) {}

  public ngOnInit(): void {
    this.parsedTimezoneList = this.adjustPotentialDSTTimezoneVarients(
      this.countryService.timezoneLibrary
    );
    if (this.timezoneName) {
      this.timezone = this.timezoneName;
    }
  }

  private adjustPotentialDSTTimezoneVarients(timezoneLibrary): any[] {
    return timezoneLibrary.map((tzObj) => {
      tzObj.offset = this.moment(this.moment()).tz(tzObj.name).isDST()
        ? tzObj.dstOffsetStr
        : tzObj.utcOffsetStr;
      tzObj.abbr = this.momentTz.tz(tzObj.name).zoneAbbr();
      return tzObj;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.timezoneName.currentValue !== changes.timezoneName.previousValue
    ) {
      this.timezone = this.timezoneName;
    }
  }

  public filterTimezones(event: any): void {
    if (event.target.value) {
      this.filteredTimezoneList = this.parsedTimezoneList.filter((tzObj) => {
        return Object.values(tzObj).some((value) =>
          JSON.stringify(value)
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      });
    } else {
      this.filteredTimezoneList = [];
    }
  }

  public clearTimezone(): void {
    this.timezone = '';
    const formatTzObj = {
      timezone: null,
      country: null,
    };
    this.timezoneAndCountrySignal.emit(formatTzObj);
  }

  public emitTimezoneAndCountry(selectedTz: any): void {
    this.timezoneAndCountrySignal.emit({
      timezone: selectedTz.name,
      country: selectedTz.alphaTwoCode,
    });
    this.filteredTimezoneList = [];
  }
}
