import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, EMPTY, map, Observable, of, switchMap } from 'rxjs';
import {
  IKnowledgeGraphLookupParent,
  ISearchKnowledgeGraphLookup,
  ISearchTaxonomyHighlight,
} from '../../models/knowledge-graph.models';
import { SearchKnowledgeGraphService } from '../../services/search-knowledge-graph.service';

@Component({
  selector: 'app-search-taxonomy-lookup',
  templateUrl: './search-taxonomy-lookup.component.html',
  styleUrls: ['./search-taxonomy-lookup.component.scss'],
})
export class SearchTaxonomyLookupComponent implements OnInit {
  @Output() selectedNode = new EventEmitter<ISearchTaxonomyHighlight>();

  lookupControl = new FormControl(null);
  lookupOptions$: Observable<ISearchKnowledgeGraphLookup[]> = EMPTY;

  constructor(private searchTaxonomyService: SearchKnowledgeGraphService) {}

  ngOnInit(): void {
    this.lookupOptions$ = this.lookupControl.valueChanges.pipe(
      debounceTime(200),
      switchMap((value) => {
        if (!value) {
          this.selectedNode.emit(null);
          return of([]);
        }

        if (value.length < 3) {
          return EMPTY;
        }

        return this.searchTaxonomyService
          .lookup(value)
          .pipe(map((res) => res.results));
      })
    );
  }

  select(node: ISearchKnowledgeGraphLookup): void {
    const highlight: ISearchTaxonomyHighlight = {
      targetId: node.id,
      parentIds: this.getHighlight(node, true),
    };

    this.selectedNode.emit(highlight);
  }

  clear(): void {
    this.lookupControl.setValue(null);
  }

  private getHighlight(
    node?: IKnowledgeGraphLookupParent,
    ignoreId = false
  ): string[] {
    if (!node) {
      return [];
    }

    const parentIds = node.parents
      .map((parent) => this.getHighlight(parent))
      .flat();

    return ignoreId ? parentIds : [node.id].concat(parentIds);
  }
}
