import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IOpportunity } from '../models/opportunity.models';

export interface IOpportunityState extends EntityState<IOpportunity> {
  selectedOpportunityId: string | null;
  isLoading: string[];
  loadError?: string;
  updateErrors?: string[];
}

const selectId = (a: IOpportunity) => a.opportunityId;

const sortComparer = (a: IOpportunity, b: IOpportunity) =>
  a.opportunityName.localeCompare(b.opportunityName);

export const opportunitiesAdapter: EntityAdapter<IOpportunity> =
  createEntityAdapter<IOpportunity>({ selectId, sortComparer });
