import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IFeasibility,
  IFeasibilityCreate,
  IFeasibilityOppSegmentResponse,
  IFeasibilitySegmentResponse,
} from '../models/feasibility.models';

@Injectable({ providedIn: 'root' })
export class FeasibilityService {
  private readonly baseUrl = '/feasibility';

  constructor(private feasibility: HttpClient) {}

  getPreviousSegmentSearches(
    terms: string[],
    countries: string[]
  ): Observable<IFeasibilitySegmentResponse[]> {
    const params = this.createBaseParams(terms, countries);

    return this.feasibility.get<IFeasibilitySegmentResponse[]>(
      `${this.baseUrl}/calculate`,
      { params }
    );
  }

  getOppFeasibility(
    terms: string[],
    countries: string[],
    opportunityId: string,
    segmentId: string
  ): Observable<IFeasibilityOppSegmentResponse[]> {
    const params = this.createBaseParams(terms, countries)
      .set('opportunityId', opportunityId)
      .set('segmentId', segmentId);

    return this.feasibility.get<IFeasibilityOppSegmentResponse[]>(
      `${this.baseUrl}/calculate`,
      { params }
    );
  }

  getBySfId(sfId: string): Observable<IFeasibility[]> {
    return this.feasibility.get<IFeasibility[]>(
      `${this.baseUrl}/salesforceId/${sfId}`
    );
  }

  save(feasibility: IFeasibilityCreate): Observable<IFeasibility> {
    return this.feasibility.post<IFeasibility>(this.baseUrl, feasibility);
  }

  private createBaseParams(terms: string[], countries: string[]): HttpParams {
    return new HttpParams()
      .set('terms', terms.join(','))
      .set('countries', countries.join(','));
  }
}
