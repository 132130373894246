import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ClientsService, IClient } from '@techspert-io/clients';
import { FeasibilityService } from '@techspert-io/feasibility';
import {
  IOpportunity,
  OpportunitiesService,
} from '@techspert-io/opportunities';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ISearchQuery } from '../../../admin-portal/search/models/query';

interface ISearchMetaData {
  opportunity: IOpportunity;
  client: IClient;
  query?: ISearchQuery;
}

@Injectable({
  providedIn: 'root',
})
export class SearchMetaDataResolver {
  constructor(
    private opportunitiesService: OpportunitiesService,
    private clientsService: ClientsService,
    private feasibilityService: FeasibilityService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ISearchMetaData> {
    const opportunityId = route.paramMap.get('opportunityId');
    const salesforceId = route.queryParamMap.get('salesforceId');
    const feasibilityId = route.queryParamMap.get('feasibilityId');

    return this.opportunitiesService.get(opportunityId).pipe(
      switchMap((opportunity) =>
        this.clientsService.get(opportunity.clientId).pipe(
          map((client) => ({
            client,
            opportunity,
          }))
        )
      ),
      switchMap((d) =>
        salesforceId && feasibilityId
          ? this.getFeasabilityQuery(salesforceId, feasibilityId).pipe(
              map((query) => ({ ...d, query }))
            )
          : of(d)
      )
    );
  }

  private getFeasabilityQuery(
    salesforceId: string,
    feasibilityId: string
  ): Observable<ISearchQuery> {
    return this.feasibilityService
      .getBySfId(salesforceId)
      .pipe(
        switchMap((feas) =>
          feas
            .filter((f) => f.feasibilityId === feasibilityId)
            .map((f) => f.query)
        )
      );
  }
}
