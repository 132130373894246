import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastComponent } from './components/alert/toast.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatProgressBarModule],
  declarations: [ToastComponent],
  exports: [ToastComponent],
})
export class UserAlertsModule {}
