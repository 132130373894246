<div *ngIf="isRoot" class="flex flex-col gap-2">
  <ng-container *ngTemplateOutlet="children"></ng-container>
</div>

<div *ngIf="!isRoot" class="flex flex-col gap-2">
  <div class="flex flex-row gap-5">
    <div class="flex flex-row gap-2">
      <div (click)="onAddNode(node)" data-test-id="search-taxonomy-node-add-term" class="cursor-pointer"
        matTooltip="Add term">
        <mat-icon>
          playlist_add
        </mat-icon>
      </div>

      <div [copy-clipboard]="node | json" class="cursor-copy" matTooltip="Diagnostic data">
        <mat-icon>
          info
        </mat-icon>
      </div>
    </div>


    <div class="flex flex-row gap-[2px] cursor-copy" [copy-clipboard]="node.name"
      [matTooltip]="node.synonyms.length ? 'Synonyms: ' + node.synonyms : ''" data-test-id="search-taxonomy-node-data">
      <div *ngIf="shouldHighlight"><b>{{node.name}}</b></div>
      <div *ngIf="!shouldHighlight">{{node.name}}</div>
    </div>

    <div *ngIf="node.children.length" (click)="isExpanded = !isExpanded" data-test-id="search-taxonomy-node-expand"
      class="cursor-pointer">
      <mat-icon>
        {{isExpanded ? 'expand_less' : 'expand_more'}}
      </mat-icon>
    </div>
  </div>

  <div *ngIf="isExpanded" class="flex flex-row gap-10">
    <div class="ml-1 border-l border-dashed"></div>

    <ng-container *ngTemplateOutlet="children"></ng-container>
  </div>
</div>

<ng-template #children>
  <div *ngIf="children$ | async as children; else loading">
    <div *ngFor="let childNode of children">
      <app-search-taxonomy-node (addNodeSignal)="onAddNode($event)" [node]="childNode"
        [highlight]="highlight"></app-search-taxonomy-node>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div *ngIf="isRoot" class="flex justify-center content-center items-center flex-col">
    <app-scintillating-loader></app-scintillating-loader>
  </div>
  <div *ngIf="!isRoot">
    ...loading
  </div>
</ng-template>