<div class="confirm-email-container">
  <div class="top-section-email">
    <div class="flex-column">
      <div class="row-flex" *ngIf="users$ | async as users">
        <span class="tag" data-test-id="scheduler-email-from-user-label">From:</span>
        <select [value]="fromUser" (change)="setFromUser($event.target.value)" data-test-id="scheduler-email-from-user">
          <option *ngFor="let user of users" [value]="user | json" [selected]="fromUser.email === user.email"
            data-test-id="scheduler-email-from-user-option">
            {{user.firstName}} {{user.lastName}}
          </option>
        </select>
      </div>
      <div class="row-flex">
        <span class="tag" data-test-id="scheduler-email-direct-recipient-label">To:</span>
        <div class="recipients">
          <ul>
            <li *ngIf="directRecipient">
              <div class="email-pill" [ngClass]="{'highlight-danger': !directRecipient.isContact}"
                data-test-id="scheduler-email-direct-recipient">
                <span class="email-pill-text" data-test-id="scheduler-email-direct-recipient-email-address">
                  {{ directRecipient.address }}
                </span>
                <span class="email-pill-close-icon" (click)="removeRecipientEmail()"
                  data-test-id="scheduler-email-remove-direct-recipient">
                  &times;
                </span>
              </div>
            </li>
          </ul>
          <input class="email-input" (keyup.enter)="addRecipientEmail($event)"
            (keyup.backspace)="removeRecipientEmail()" type="text"
            data-test-id="scheduler-email-add-direct-recipient" />
        </div>
      </div>
      <div class="row-flex">
        <span class="tag" data-test-id="scheduler-email-cc-recipient-label">CC:</span>
        <div class="recipients">
          <ul>
            <li *ngFor="let recipientEmail of ccRecipientArray">
              <div class="email-pill" [ngClass]="{'highlight-danger': !recipientEmail.isContact}"
                data-test-id="scheduler-email-cc-recipient">
                <span class="email-pill-text" data-test-id="scheduler-email-cc-recipient-email-address">
                  {{ recipientEmail.address }}
                </span>
                <span class="email-pill-close-icon" (click)="removeEmailFromArray('cc', recipientEmail.address)"
                  data-test-id="scheduler-email-remove-cc-recipient">
                  &times;
                </span>
              </div>
            </li>
          </ul>
          <input class="email-input" (keyup.enter)="addEmailToRecipientList('cc', $event)"
            (keyup.Tab)="addEmailToRecipientList('cc', $event)" (blur)="addEmailToRecipientList('cc',$event)"
            (keydown.backspace)="removeLastEmail('cc', $event)" type="text"
            data-test-id="scheduler-email-remove-direct-recipient" />
        </div>
      </div>
      <div class="row-flex">
        <span class="tag" data-test-id="scheduler-email-subject-label">Subj.</span>
        <input [(ngModel)]="emailSubject" class="subject-input" type="text" data-test-id="scheduler-email-subject" />
      </div>
    </div>
    <div class="icon-div"></div>
  </div>

  <div class="editor-container" *ngIf="emailTemplate">
    <app-textarea-editor [(editorValue)]="emailTemplate" [resize]="false"></app-textarea-editor>
  </div>

  <div class="schedule-form-actions">
    <span *ngIf="emailSentFailure" data-test-id="scheduler-email-send-failed">Email failed. Please try again.</span>

    <div class="check-valid-recipients" *ngIf="!hasValidRecipients">
      <span data-test-id="scheduler-email-confirm-non-client-recipients-checked-label">
        I have checked highlighted non-client contact recipients
      </span>
      <input type="checkbox" [(ngModel)]="confirm" data-test-id="scheduler-email-confirm-non-client-recipients-checked">
    </div>

    <button class="button button--primary" [ngClass]="emailSentSuccess ? 'success-btn' : ''" *ngIf="!showBtnLoader"
      (click)="!showBtnLoader && !emailSentSuccess ? sendEmail() : ''" [disabled]="!confirm && !hasValidRecipients"
      data-test-id="scheduler-email-send">
      {{emailSentSuccess ? 'Email Sent' : 'Send'}}
      <mat-icon *ngIf="emailSentSuccess">done</mat-icon>
    </button>
    <button class="button button--primary" *ngIf="showBtnLoader">
      <app-shared-btn-loader></app-shared-btn-loader>
    </button>
  </div>
</div>