import { Directive, HostListener, Input } from '@angular/core';
import { IExpertAction } from '@techspert-io/expert-actions';
import { IExpert } from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';

@Directive({ selector: '[expert-diagnostic-data]' })
export class ExpertDiagnosticDataDirective {
  @Input('expert-context') expert: IExpert;
  @Input('opportunity-context') opportunity: IOpportunity;
  @Input('expert-action-context') expertAction?: IExpertAction;

  constructor(private toastService: ToastService) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();

    if (!this.expert) {
      console.warn('Insufficient context to provide diagnostic data');
      return;
    }

    const connectExpertName = `${this.expert.firstName || ''} ${
      this.expert.lastName || ''
    }`.trim();
    navigator.clipboard
      .writeText(JSON.stringify(this.getDiagnosticData(), undefined, 2))
      .then(() =>
        this.toastService.sendMessage(
          `Copied ${connectExpertName} diagnostic data`,
          'success'
        )
      );
  }

  private getDiagnosticData(): Record<string, unknown> {
    const expertFields: (keyof IExpert)[] = [
      'expertId',
      'firstName',
      'lastName',
      'searchId',
      'opportunitySegmentId',
      'campaignId',
      'expertSourceId',
      'expertProfileId',
      'ownerConnectId',
      'source',
    ];
    const oppFields: (keyof IOpportunity)[] = [
      'opportunityId',
      'opportunityName',
      'clientId',
    ];
    const actionFields: (keyof IExpertAction)[] = [
      'expertActionId',
      'actionType',
      'status',
      'ownerUserId',
    ];

    return {
      ...(this.opportunity ? this.getProps(this.opportunity, oppFields) : {}),
      ...this.getProps(this.expert, expertFields),
      ...(this.expertAction
        ? { expertAction: this.getProps(this.expertAction, actionFields) }
        : {}),
    };
  }

  private getProps<T>(obj: T, fields: (keyof T)[]): Partial<T> {
    return fields.sort().reduce(
      (prev, curr) => ({
        ...prev,
        ...(obj[curr] ? { [curr]: obj[curr] } : {}),
      }),
      {}
    );
  }
}
