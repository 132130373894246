import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expertEmailMaskedMode',
})
export class ExpertEmailMaskedModePipe implements PipeTransform {
  transform(email: string, maskedMode: boolean): string {
    return maskedMode ? 'xxxxx@xxxxx.xx' : email;
  }
}
