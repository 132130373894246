import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class EmailService {
  private readonly baseUrl = '/messenger';

  constructor(private http: HttpClient) {}

  public block(emails: string[]): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/block_email`, { emails });
  }
}
