import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { handleHttpError } from '@techspert-io/errorHandling';
import { map, mergeMap } from 'rxjs/operators';
import { ExpertProfilesService } from '../services/expert-profiles.service';
import {
  ExpertProfileAPIActions,
  ExpertProfileActions,
} from './expert-profiles.actions';

@Injectable()
export class ExpertProfilesEffects {
  fetchExpertProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertProfileActions.fetchExpertProfile),
      mergeMap(({ expertProfileId }) =>
        this.expertProfilesService.getById(expertProfileId).pipe(
          map((expertProfile) =>
            ExpertProfileAPIActions.fetchExpertProfileSuccess({
              expertProfile: { ...expertProfile, expertProfileId },
            })
          ),
          handleHttpError((error) =>
            ExpertProfileAPIActions.fetchExpertProfileFailure({
              error,
            })
          )
        )
      )
    );
  });

  updateExpertProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExpertProfileActions.updateExpertProfile),
      mergeMap(({ expertProfile }) =>
        this.expertProfilesService.update(expertProfile).pipe(
          map((expertProfile) =>
            ExpertProfileAPIActions.updateExpertProfileSuccess({
              expertProfile,
            })
          ),
          handleHttpError((error) =>
            ExpertProfileAPIActions.updateExpertProfileFailure({
              error,
            })
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private expertProfilesService: ExpertProfilesService
  ) {}
}
