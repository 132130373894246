import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IIOutreachTemplateCreate,
  IOutreachTemplate,
  OutreachTemplatesService,
} from '@techspert-io/outreachTemplates';
import { ITemplateForm } from '../../send-solicitation-dialog.component';
import { SolicitationService } from '../../solicitation.service';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
})
export class TemplateFormComponent implements OnChanges {
  @Output() selectedItem = new EventEmitter<IOutreachTemplate>();
  @Output() closeFormSignal = new EventEmitter<void>();
  @Output() refreshTemplateMenuSignal = new EventEmitter();
  @Input() inputTemplateFormData: ITemplateForm;
  public template: IOutreachTemplate;
  public formType: string;
  public showActionConfirmOverlay: boolean = false;
  public overlayText: string;

  get activeTemplate(): IOutreachTemplate {
    return this.solicitationService.activeTemplate;
  }
  constructor(
    private outreachTemplatesService: OutreachTemplatesService,
    private solicitationService: SolicitationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.inputTemplateFormData.currentValue !==
      changes.inputTemplateFormData.previousValue
    ) {
      this.template = this.inputTemplateFormData.template;
      this.formType = this.inputTemplateFormData.formType;
    }
  }

  public saveTemplate(): void {
    const newTemplate = this.createTemplate();
    this.outreachTemplatesService.create(newTemplate).subscribe((data) => {
      this.overlayText = 'Saved';
      this.showActionConfirmOverlay = true;
      this.updateSolicitationService(data.templateId);
      setTimeout(() => {
        this.refreshTemplateMenuSignal.emit();
      }, 800);
    });
  }

  public updateTemplate(): void {
    const newTemplate = this.createTemplate();
    this.outreachTemplatesService
      .update({
        ...newTemplate,
        templateId: this.template.templateId,
      })
      .subscribe(() => {
        this.overlayText = 'Updated';
        this.showActionConfirmOverlay = true;
        this.updateSolicitationService();
        setTimeout(() => {
          this.refreshTemplateMenuSignal.emit();
        }, 800);
      });
  }

  public deleteTemplate(): void {
    this.outreachTemplatesService
      .update({
        templateId: this.template.templateId,
        active: false,
      })
      .subscribe(() => {
        this.overlayText = 'Deleted';
        this.showActionConfirmOverlay = true;
        setTimeout(() => {
          this.refreshTemplateMenuSignal.emit();
        }, 800);
      });
  }

  public closeForm(): void {
    this.closeFormSignal.emit();
  }

  private createTemplate(): IIOutreachTemplateCreate {
    return {
      title: this.template.title,
      description: this.template.description,
      subject: this.template.subject,
      content: this.template.content,
      preheader: this.template.preheader,
    };
  }

  private updateSolicitationService(templateId?: string): void {
    this.solicitationService.activeTemplate.content = this.template.content;
    this.solicitationService.activeTemplate.description =
      this.template.description;
    this.solicitationService.activeTemplate.subject = this.template.subject;
    this.solicitationService.activeTemplate.preheader = this.template.preheader;

    this.solicitationService.subject = this.template.subject;
    this.solicitationService.preheader = this.template.preheader;

    if (templateId) {
      this.solicitationService.activeTemplate.templateId = templateId;
    }
  }
}
