import { createActionGroup, props } from '@ngrx/store';
import {
  IExpertProfile,
  IExpertProfileBase,
} from '../models/expert-profile.models';

export const ExpertProfileActions = createActionGroup({
  source: 'ExpertProfiles',
  events: {
    'Fetch Expert Profile': props<{ expertProfileId: string }>(),
    'Update Expert Profile': props<{
      expertProfile: Partial<IExpertProfileBase> & { expertProfileId: string };
    }>(),
  },
});

export const ExpertProfileAPIActions = createActionGroup({
  source: 'ExpertProfiles API',
  events: {
    'Fetch Expert Profile Success': props<{ expertProfile: IExpertProfile }>(),
    'Fetch Expert Profile Failure': props<{ error: string[] }>(),
    'Update Expert Profile Success': props<{ expertProfile: IExpertProfile }>(),
    'Update Expert Profile Failure': props<{ error: string[] }>(),
  },
});
