import { Pipe, PipeTransform } from '@angular/core';
import { OmnisearchEntity } from '../models/omnisearch.models';
import { CategoryPipe } from './category.pipe';

@Pipe({
  name: 'urlTooltip',
})
export class UrlTooltipPipe implements PipeTransform {
  transform(entity: OmnisearchEntity): string {
    const pipe = new CategoryPipe();

    return `Go to ${pipe.transform(entity)}`;
  }
}
