<form [formGroup]="referralForm" class="flex flex-col">

  <div class="flex flex-row gap-2">
    <mat-form-field data-test-id="segment-owners-form-referral-currency" class="flex-1">
      <mat-label data-test-id="segment-owners-form-referral-currency-label">Referral currency</mat-label>
      <mat-select formControlName="referralCurrency" data-test-id="segment-owners-form-referral-currency-input">
        <mat-option value="" data-test-id="segment-owners-form-referral-currency-option">
          - No Currency -
        </mat-option>
        <mat-option *ngFor="let currency of currencies" [value]="currency"
          data-test-id="segment-owners-form-referral-currency-option">
          {{currency}}
        </mat-option>
      </mat-select>
      <mat-hint data-test-id="segment-owners-form-referral-currency-help-text">
        Currency we show in the expert portal and use for the engagement
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label data-test-id="segment-owners-form-referral-fee-label">Base referral fee</mat-label>
      <input matInput type="number" formControlName="referralFee" min="0"
        data-test-id="segment-owners-form-referral-fee-input">
      <mat-hint data-test-id="segment-owners-form-referral-fee-help-text">
        Fee we show in the expert portal and use for the engagement
      </mat-hint>
      <mat-error data-test-id="segment-owners-form-referral-fee-error-text">Must be at least 0</mat-error>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label data-test-id="segment-owners-form-referral-honoraria-label">Base honoraria rate</mat-label>
      <input matInput type="number" formControlName="referralHonoraria" min="0"
        data-test-id="segment-owners-form-referral-honoraria-input">
      <mat-hint data-test-id="segment-owners-form-referral-honoraria-help-text">
        Honoraria we offer to referred experts who are not already on the project
      </mat-hint>
      <mat-error data-test-id="segment-owners-form-referral-honoraria-error-text">Must be at least 0</mat-error>
    </mat-form-field>
  </div>
</form>