<div class="availability" *ngIf="!callTime; else ShowRequestedCallTime">
  <span class="availability-label">Availabilities</span>
  <ul class="availability-list" *ngIf="timezoneName">
    <li class="new-requested-pill availability-pill" *ngIf="requestedAnotherTime">
      <span class="material-icons-outlined">warning</span>
      <span class="availability-pill-text">New date requested</span>
    </li>
    <li class="availability-pill"
      *ngFor="let availability of availabilities.slice(0, requestedAnotherTime ? 1 : 2); let i = index">
      <span [title]="moment(availability.start).tz(timezoneName).format('ddd Do MMM LT (z)')"
        class="availability-pill-text">
        {{moment(availability.start).tz(timezoneName).format('ddd Do MMM LT (z)')}}
      </span>
    </li>
    <li class="ellipse-box" *ngIf="availabilities.length > (requestedAnotherTime ? 1 : 2)">
      <span>...</span>
      <div class="drop-down">
        <div class="drop-down-item flex availability-pill"
          *ngFor="let availability of availabilities.slice(2); let i = index">
          <span class="availability-pill-text">
            {{moment(availability.start).tz(timezoneName).format('ddd Do MMM LT (z)')}}
          </span>
        </div>
      </div>
    </li>
  </ul>
  <p *ngIf="!timezoneName">Please assign a country and a timezone to this expert</p>
</div>

<ng-template #ShowRequestedCallTime>
  <div class="availability">
    <span class="availability-label">Requested Call Time</span>
    <div class="availability-pill requested-call-time">
      <span class="availability-pill-text" *ngIf="timezoneName"
        [title]="moment(callTime).tz(timezoneName).format('ddd Do MMM LT (z)')">
        {{moment(callTime).tz(timezoneName).format('ddd Do MMM LT (z)')}}
      </span>
      <span class="availability-pill-text" *ngIf="!timezoneName"
        [title]="moment(callTime).tz('UTC').format('ddd Do MMM LT (z)')">
        {{moment(callTime).tz('UTC').format('ddd Do MMM LT (z)')}}
      </span>
    </div>
  </div>
</ng-template>