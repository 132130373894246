import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-omnisearch-icons',
  templateUrl: './omnisearch-icons.component.html',
  styles: [
    `
      svg {
        vertical-align: middle;
      }
      mat-icon {
        font-size: 28px;
        color: #b4becb;
      }
    `,
  ],
})
export class OmnisearchIconsComponent {
  @Input() icon: string;
}
