import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { OpportunityExpertsService } from '../services/opportunity-experts.service';

type SearchGroups = Record<
  string,
  {
    total: number;
    searches: Record<string, number>;
  }
>;

export interface IOpportunitySearchSelectDialogData {
  searches: string[];
  opportunityId: string;
}

@Component({
  selector: 'app-opportunity-search-select-dialog',
  templateUrl: './opportunity-search-select-dialog.component.html',
  styleUrls: ['./opportunity-search-select-dialog.component.scss'],
})
export class OpportunitySearchSelectDialogComponent implements OnInit {
  downloadState$: Observable<{
    downloads: Record<string, string>;
    loading: boolean;
    loaded: boolean;
  }> = EMPTY;
  searches$: Observable<SearchGroups> = EMPTY;

  searchForm = new FormGroup({
    searches: new FormControl<string[]>([]),
    searchTerm: new FormControl<string>(''),
  });

  constructor(
    private dialogRef: MatDialogRef<
      OpportunitySearchSelectDialogComponent,
      string[]
    >,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: IOpportunitySearchSelectDialogData,
    private opportunityExpertsService: OpportunityExpertsService
  ) {}

  ngOnInit(): void {
    this.downloadState$ =
      this.opportunityExpertsService.downloadedSearches$.pipe(
        startWith<Record<string, string>>({}),
        map((downloads) => ({
          downloads,
          loading: Object.values(downloads).some((e) =>
            ['init', 'loading'].includes(e)
          ),
          loaded: Object.values(downloads).some((e) =>
            ['partial', 'loaded'].includes(e)
          ),
        }))
      );

    this.searches$ = combineLatest([
      this.opportunityExpertsService.expertTotals$,
      this.searchForm
        .get('searchTerm')
        .valueChanges.pipe(debounceTime(100), startWith<string>('')),
    ]).pipe(
      map(([totals, searchTerm]) =>
        Object.entries(totals)
          .filter(([key]) => key.includes(searchTerm))
          .reduce<Record<string, number>>(
            (prev, [phase, totals]) => ({
              ...prev,
              [phase]: Object.values(totals).reduce((p, c) => p + c, 0),
            }),
            {}
          )
      ),
      map((d) => Object.entries(d).reduce(this.groupSearches, {}))
    );

    this.searchForm.setValue({
      searches: this.dialogData.searches,
      searchTerm: '',
    });
  }

  submitSearches({ searches }: { searches: string[] }): void {
    this.dialogRef.close(searches);
  }

  downloadSearch(event: Event, searchId: string): void {
    event.preventDefault();
    event.stopPropagation();

    this.opportunityExpertsService.getSearches({
      opportunityId: this.dialogData.opportunityId,
      searchIds: [searchId],
    });
  }

  cancelDownloads(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.opportunityExpertsService.cancelDownloads();
  }

  downloadGroup(searches: Record<string, number>): void {
    this.opportunityExpertsService.getSearches({
      opportunityId: this.dialogData.opportunityId,
      searchIds: Object.keys(searches),
    });
  }

  toggleSelectAll(searches: SearchGroups): void {
    this.searchForm.patchValue({
      searches: Object.values(searches).flatMap((d) => Object.keys(d.searches)),
    });
  }

  private groupSearches(
    prev: SearchGroups,
    [searchName, searchCount]: [string, number]
  ): SearchGroups {
    const groupNames = searchName.toUpperCase().split('/');
    const groupName =
      groupNames.length > 1 ? groupNames.find(Boolean) : ' Other Searches';
    return {
      ...prev,
      [groupName]: {
        total: (prev[groupName]?.total || 0) + searchCount,
        searches: {
          ...(prev[groupName]?.searches || {}),
          [searchName]: searchCount,
        },
      },
    };
  }
}
