<ng-container *ngIf="notifications$ | async as notifications">
  <div class="top-section flex justify-start content-center items-center flex-row">
    <mat-icon class="bell" matBadgeSize="small" matBadgeColor="warn" [matBadge]="notifications.count"
      [matBadgeHidden]="!notifications.count" data-test-id="notifications-dialog-unread-count">
      notifications
    </mat-icon>
    <h2 data-test-id="notifications-dialog-title">My notifications</h2>
    <mat-icon class="close" (click)="closeModal()" data-test-id="notifications-dialog-close">
      close
    </mat-icon>
  </div>
  <div class="archive-container flex justify-between content-stretch items-stretch">
    <mat-button-toggle-group [ngModel]="searchFilter$ | async" (ngModelChange)="filterNotifications($event)">
      <mat-button-toggle [value]="false" data-test-id="notifications-dialog-toggle-current">
        Current
      </mat-button-toggle>
      <mat-button-toggle [value]="true" data-test-id="notifications-dialog-toggle-archived">
        Archived
      </mat-button-toggle>
    </mat-button-toggle-group>
    <button mat-button (click)="clearNotifications()" *ngIf="(searchFilter$ | async) === false && (notifications.count)"
      data-test-id="notifications-dialog-clear-all">
      Clear all notifications
    </button>
  </div>
  <div class="notifs" data-test-id="notifications-dialog-notification-list">
    <app-notification *ngFor="let notif of notifications.notifs" class="notif-container flex flex-col gap-4"
      [notification]="notif" (archiveNotification)="archiveNotification($event)" (routeAction)="routeAction($event)"
      data-test-id="notifications-dialog-notification-list-item"></app-notification>
  </div>
</ng-container>