<ng-container *ngIf="loadingState$ | async as loadingState">
  <ng-container *ngIf="template$ | async as template; else isLoadingTmpl">

    <div *ngIf="loadingState.isLoaded" class="flex flex-col gap-4 h-full w-full min-h-full min-w-full">
      <app-breadcrumbs backLink="../" [isLoading]="loadingState.isLoading" location="Outreach Templates"
        [title]="template.title">
      </app-breadcrumbs>

      <app-outreach-template-form [template]="template" [isLoading]="loadingState.isLoading"
        (submitTemplate)="onEdit($event)"></app-outreach-template-form>

      <app-outreach-templates-diagnostics [template]="template"></app-outreach-templates-diagnostics>
    </div>

  </ng-container>
</ng-container>

<ng-template #isLoadingTmpl>
  <div [style.height]="'500px'" class="flex justify-center content-center items-center flex-col flex-1">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>