<form [formGroup]="ownersForm" class="flex flex-col">

  <mat-form-field class="flex-1">
    <mat-label data-test-id="segment-owners-form-owner-label">Owner</mat-label>
    <mat-select formControlName="owner" data-test-id="segment-owners-form-owner-input">
      <mat-option *ngFor="let option of podMembersOptions" [value]="option[userIdProp]"
        data-test-id="segment-owners-form-owner-option">
        {{option.firstName}} {{option.lastName}}
      </mat-option>
    </mat-select>
    <mat-error data-test-id="segment-owners-form-owner-required-error">Required</mat-error>
  </mat-form-field>

  <mat-form-field class="flex-1">
    <mat-label data-test-id="segment-owners-form-cover-label">Cover</mat-label>
    <mat-select formControlName="cover" multiple data-test-id="segment-owners-form-cover-input">
      <mat-option *ngFor="let option of coverOptions" [value]="option[userIdProp]"
        data-test-id="segment-owners-form-cover-option">
        {{option.firstName}} {{option.lastName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="flex flex-col gap-4">
    <mat-form-field class="flex-1">
      <mat-label data-test-id="segment-owners-form-conference-topic-label">Conference Topic</mat-label>
      <input matInput formControlName="conferenceTopic" data-test-id="segment-owners-form-conference-topic-input">
      <mat-error data-test-id="segment-owners-form-conference-topic-required-error">Required</mat-error>
      <mat-hint data-test-id="segment-owners-form-conference-topic-help-text">
        This field will take precedence over opportunity conference topic
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label data-test-id="segment-owners-form-project-pitch-label">Pitch</mat-label>
      <textarea matInput formControlName="pitch" data-test-id="segment-owners-form-project-pitch-input"></textarea>
      <mat-hint data-test-id="segment-owners-form-project-pitch-help-text">
        This field will take precedence over opportunity pitch
      </mat-hint>
    </mat-form-field>

    <div class="flex flex-row gap-2">
      <mat-form-field class="flex-1">
        <mat-label data-test-id="segment-owners-form-interview-period-start-label">Availabilities Start</mat-label>
        <input matInput type="date" formControlName="availabilityStart"
          data-test-id="segment-owners-form-interview-period-start-input">
        <mat-error data-test-id="segment-owners-form-interview-period-start-error"> {{startError}} </mat-error>
      </mat-form-field>

      <mat-form-field class="flex-1">
        <mat-label data-test-id="segment-owners-form-interview-period-end-label">Availabilities End</mat-label>
        <input matInput type="date" formControlName="availabilityEnd"
          data-test-id="segment-owners-form-interview-period-end-input">
        <mat-error data-test-id="segment-owners-form-interview-period-end-error"> {{endError}} </mat-error>
      </mat-form-field>
    </div>


  </div>

</form>