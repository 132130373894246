import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IExpert } from '../models/experts.models';
import * as fromExperts from './experts.reducer';
import { ExpertsLoadingState, IExpertState } from './experts.state';

const selectState = createFeatureSelector<IExpertState>('experts');

export const selectAllExperts = createSelector(selectState, (state) =>
  fromExperts.selectAllExperts(state)
);

export const selectExpertEntities = createSelector(
  selectState,
  fromExperts.selectExpertEntities
);

export const selectCurrentExpertId = createSelector(
  selectState,
  fromExperts.getSelectedExpertId
);

export const selectCurrentOpportunityId = createSelector(
  selectState,
  fromExperts.getSelectedOpportunityId
);

export const selectTotalsForOpportunity = createSelector(
  selectState,
  (state) => state.totals[state.selectedOpportunityId] || {}
);

export const selectExpertLoadingState = createSelector(
  selectState,
  (state) => ({
    isLoaded: state.ids.some((s) => state.selectedExpertId === s),
    isLoading: state.isLoading.includes(state.selectedExpertId),
    loadError: state.loadError,
    updateErrors: state.updateErrors,
  })
);

export const selectLoadingStateForOpportunity = createSelector(
  selectState,
  selectCurrentOpportunityId,
  (state, id) =>
    state.loadingState
      .filter((s) => s.opportunityId === id)
      .reduce<Record<string, ExpertsLoadingState['status']>>(
        (prev, curr) => ({ ...prev, [curr.searchId]: curr.status }),
        {}
      )
);

export const selectCurrentExpert = createSelector(
  selectExpertEntities,
  selectCurrentExpertId,
  (entities, id) => id && entities[id]
);

const defaultSort = (a: IExpert, b: IExpert) =>
  a.firstName.localeCompare(b.firstName) ||
  a.lastName.localeCompare(b.lastName) ||
  a.expertId.localeCompare(b.expertId);

export const selectOpportunityExperts = createSelector(
  selectExpertEntities,
  selectCurrentOpportunityId,
  (entities, id) =>
    id
      ? Object.values(entities)
          .filter((e) => e.opportunityId === id)
          .sort((a, b) =>
            a.connectPhase === 'scheduled' && a.callTime
              ? a.callTime.localeCompare(b.callTime) || defaultSort(a, b)
              : defaultSort(a, b)
          )
      : []
);
