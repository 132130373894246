import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ExpertActionStatusMap } from '@techspert-io/expert-actions';
import {
  IDisplayExpert,
  IDisplayExpertCallAction,
} from '@techspert-io/experts';
import 'moment-timezone';

@Component({
  selector: 'app-scheduled-box',
  templateUrl: './scheduled-box.component.html',
  styleUrls: ['./scheduled-box.component.scss'],
})
export class ScheduledBoxComponent implements OnChanges {
  @Input() expert: IDisplayExpert;

  callActions: IDisplayExpertCallAction[] = [];
  activeCallAction?: IDisplayExpertCallAction;

  showRescheduleActionStatuses = [
    ExpertActionStatusMap.Request,
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Complete,
  ];
  showEmailUpdateActionStatuses = [ExpertActionStatusMap.Pending];
  showCancelActionStatuses = [
    ExpertActionStatusMap.Request,
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Complete,
  ];
  showConfirmCompleteActionStatuses = [
    ExpertActionStatusMap.Request,
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Complete,
  ];

  showActionStatuses = [
    this.showRescheduleActionStatuses,
    this.showEmailUpdateActionStatuses,
    this.showCancelActionStatuses,
    this.showConfirmCompleteActionStatuses,
  ].flat();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.expert &&
      (!changes.expert.previousValue ||
        changes.expert.currentValue['connectPhase'] !==
          changes.expert.previousValue['connectPhase'] ||
        JSON.stringify(changes.expert.currentValue['callActions']) !==
          JSON.stringify(changes.expert.previousValue['callActions']))
    ) {
      switch (this.expert.connectPhase) {
        case 'scheduled':
          this.callActions = this.expert.callActions?.pending || [];
          break;
        case 'completed':
          this.callActions = this.expert.callActions?.complete || [];
          break;
        default:
          this.callActions = [];
      }

      if (!this.expert.conferences?.length) {
        this.activeCallAction = this.callActions[0];
      } else {
        this.activeCallAction =
          this.callActions.find(
            (ca) => ca.callAction.expertActionId === this.expert.conferences[0]
          ) || this.callActions[0];
      }
    }
  }

  selectActiveAction(action: IDisplayExpertCallAction): void {
    this.activeCallAction = action;
  }
}
