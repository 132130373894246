<div class="dashboard">
  <div class="page-header" data-test-id="dashboard-header">
    <h1 data-test-id="dashboard-title">Dashboard</h1>
  </div>
  <app-account-summary [activeProjectCount]="opportunities.length" [openConnectionsForUser]="openConnectionsForUser"
    [emailStats]="emailStats" [upcomingConferences]="pendingCallActions.length" *ngIf="opportunities?.length">
  </app-account-summary>
  <div class="flex flex-row gap-[30px]">
    <div class="card basis-2/5">
      <div class="card__header">
        <h2 data-test-id="dashboard-my-projects-title">My projects</h2>
      </div>
      <div class="card__content card__content--overflow">
        <app-dashboard-opportunity-list [opportunities]="filteredOpportunities"
          (filterOpportunities)="filterOpportunities($event)" [showLoader]="showOpportunitiesListLoader">
        </app-dashboard-opportunity-list>
      </div>
    </div>
    <div class="card basis-3/5">
      <div class="card__header">
        <h2>Events</h2>
      </div>
      <div class="card__content card__content--overflow">
        <mat-tab-group>
          <mat-tab label="Actions">
            <app-notification-actions>
            </app-notification-actions>
          </mat-tab>
          <mat-tab label="My upcoming calls">
            <app-dashboard-upcoming-conferences [callActions]="todaysCallActions"
              (viewConnection)="viewConnection($event)" [showLoader]="showConferenceListLoader">
            </app-dashboard-upcoming-conferences>
          </mat-tab>
          <mat-tab label="All calls">
            <app-dashboard-upcoming-conferences [callActions]="pendingCallActions"
              (viewConnection)="viewConnection($event)" [showLoader]="showConferenceListLoader">
            </app-dashboard-upcoming-conferences>
          </mat-tab>
          <mat-tab label="My unscheduled experts">
            <app-dashboard-pending-conference-booking-experts (navigateToSchedulerSignal)="navigateToScheduler($event)"
              (navigateToOpportunitySignal)="navigateToOpportunity($event)" [experts]="userSchedulePendingExperts">
            </app-dashboard-pending-conference-booking-experts>
          </mat-tab>
          <mat-tab label="All unscheduled experts">
            <app-dashboard-pending-conference-booking-experts (navigateToSchedulerSignal)="navigateToScheduler($event)"
              (navigateToOpportunitySignal)="navigateToOpportunity($event)" [experts]="schedulePendingExperts">
            </app-dashboard-pending-conference-booking-experts>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>