<form [formGroup]="userForm" (ngSubmit)="onSubmit()" autocomplete="off">

  <div class="flex flex-row gap-[30px]">
    <mat-form-field appearance="outline" class="basis-full">
      <mat-label data-test-id="users-form-select-user-label">Select User</mat-label>
      <mat-select multiple formControlName="member" required data-test-id="users-form-select-user">
        <mat-option *ngFor="let user of usersList" [value]="user.connectId"
          data-test-id="users-form-select-user-option">
          {{user.firstName}} {{user.lastName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="margin-bottom: 10px" class="flex justify-between content-stretch items-stretch flex-row">
    <button mat-raised-button mat-dialog-close data-test-id="users-form-close">Close</button>
    <button mat-raised-button color="primary" type="submit" data-test-id="users-form-save">Save</button>
  </div>

</form>