import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpertCallActionService } from '@techspert-io/conferences';
import { ToastService } from '@techspert-io/user-alerts';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  IScheduleDialogClosePayload,
  IScheduleDialogInput,
} from './schedule-models';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
})
export class ScheduleComponent implements OnInit {
  public closePayload: IScheduleDialogClosePayload;
  title = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IScheduleDialogInput,
    public dialogRef: MatDialogRef<ScheduleComponent>,
    private callActionsService: ExpertCallActionService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    switch (this.data.action) {
      case 'schedule':
        this.title = 'Schedule Call';
        break;
      case 'reschedule':
        this.title = 'Reschedule Call';
        break;
      case 'cancel':
        this.title = 'Cancel Call';
        break;
      case 'updateEmail':
        this.title = 'Update Email';
        break;
      case 'complete':
        this.title = 'Confirm Call Completion';
        break;
    }
  }

  public confirmCallComplete(expertActionId: string): void {
    this.callActionsService
      .confirmCallCompleted(expertActionId)
      .pipe(
        tap((callAction) =>
          this.dialogRef.close({
            expert: this.data.expert,
            callAction,
          })
        ),
        catchError((err) => {
          this.toastService.sendMessage(
            err?.error?.message || err?.message || err || 'Unknown error',
            'error'
          );
          return EMPTY;
        })
      )
      .subscribe();
  }

  public async assignUpdatePayload(
    payload: IScheduleDialogClosePayload
  ): Promise<void> {
    this.closePayload = payload;
  }

  public closeScheduler(): void {
    this.dialogRef.close(this.closePayload);
  }
}
