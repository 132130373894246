import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-shared-btn-loader',
  templateUrl: './shared-btn-loader.component.html',
  styleUrls: ['./shared-btn-loader.component.scss'],
})
export class SharedBtnLoaderComponent implements AfterViewInit {
  @ViewChild('Loader') public loader: ElementRef;
  @Input() loaderColor: string;

  ngAfterViewInit(): void {
    if (this.loaderColor) {
      this.loader.nativeElement.style.color = this.loaderColor;
    }
  }
}
