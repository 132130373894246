import { Component, Input } from '@angular/core';

export interface ISideBarRoute {
  label: string;
  route: string;
  icon: string;
}

@Component({
  selector: 'app-nav-menu-link',
  templateUrl: 'nav-menu-link.component.html',
  styleUrls: ['./nav-menu-link.component.scss'],
})
export class NavMenuLinkComponent {
  @Input() route: ISideBarRoute;
}
