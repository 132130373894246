import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IClientContractReport } from '../models/client-contract-reports.models';
import { IClient, IClientFeatures } from '../models/clients.models';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  private readonly baseUrl = '/clients';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  getAll(): Observable<IClient[]> {
    return this.http.get<IClient[]>(this.baseUrl);
  }

  get(clientId: string): Observable<IClient> {
    return this.http.get<IClient>(`${this.baseUrl}/${clientId}`);
  }

  update(
    client: Partial<IClient> & {
      clientId: string;
      features?: Partial<IClientFeatures>;
    }
  ): Observable<IClient> {
    return this.http
      .patch<IClient>(`${this.baseUrl}/${client.clientId}`, client)
      .pipe(
        tap(() =>
          this.toastService.sendMessage('Client feature updated', 'success')
        )
      );
  }

  getContractReports(clientId: string): Observable<IClientContractReport[]> {
    return this.http.get<IClientContractReport[]>(
      `${this.baseUrl}/contract-reports/${clientId}`
    );
  }

  buildContractReports(clientId: string): Observable<IClient> {
    return this.http
      .post<IClient>(`${this.baseUrl}/contract-reports/build/${clientId}`, {})
      .pipe(
        tap(() =>
          this.toastService.sendMessage(
            'Build contract reports started - can take up to 10 minutes to complete',
            'success'
          )
        )
      );
  }
}
