import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  IExpertAction,
  IExpertActionBase,
  IExpertActionQuery,
} from '../models/expertActions.models';

export const typeOfAction =
  <T extends IExpertActionBase>(
    ...types: ('call' | 'screener' | 'availabilities')[]
  ): OperatorFunction<T[], T[]> =>
  (source): Observable<T[]> =>
    source.pipe(
      map((actions) => actions.filter((e) => types.includes(e.actionType)))
    );

@Injectable({
  providedIn: 'root',
})
export class ExpertActionService {
  private readonly baseUrl = '/expert-actions';

  constructor(private http: HttpClient) {}

  getById(expertActionId: string): Observable<IExpertAction> {
    return this.query({ expertActionId }).pipe(switchMap((actions) => actions));
  }

  queryByExpertId(expertId: string): Observable<IExpertAction[]> {
    return this.query({ expertId }).pipe(
      map((actions) => actions.sort((a, b) => b.lastUpdated - a.lastUpdated))
    );
  }

  private query(query: IExpertActionQuery = {}): Observable<IExpertAction[]> {
    const params = Object.entries(query).reduce(
      (prev, [key, value]) => (value ? prev.append(key, value) : prev),
      new HttpParams()
    );
    return this.http.get<IExpertAction[]>(
      `${this.baseUrl}/${query.actionType || 'all'}`,
      { params }
    );
  }
}
