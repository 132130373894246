import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-outreach-template-content-form',
  templateUrl: './outreach-template-content-form.component.html',
  styleUrls: ['./outreach-template-content-form.component.scss'],
})
export class OutreachTemplateContentFormComponent {
  @Input() groupName: string;

  defaultKeys = [
    '[expert_first_name]',
    '[expert_last_name]',
    '[admin_first_name]',
    '[admin_last_name]',
    '[phone_number]',
    '[job_title]',
    '[honorarium]',
    '[currency]',
    '[expert_portal_link]',
    '[expert_edit_profile_link]',
  ];

  get outreachTemplateContentForm(): FormGroup {
    return this.groupName
      ? (this.rootFormGroup.form.controls[this.groupName] as FormGroup)
      : this.rootFormGroup.form;
  }

  constructor(private rootFormGroup: FormGroupDirective) {}
}
