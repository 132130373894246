import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ToastService } from '@techspert-io/user-alerts';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

export type ChipListStyle = 'solid-border' | 'dashed-border';

@Component({
  selector: 'ct-chip-list',
  template: `
    <mat-form-field
      class="w-full"
      [class.mat-form-field-invalid]="!!errorMessage"
    >
      <mat-label data-test-id="chip-list-label">
        {{ label }}
      </mat-label>
      <mat-chip-grid #chipList class="ct-chipList">
        <mat-chip-row
          data-test-id="chip-list-item"
          *ngFor="let item of list"
          [ngClass]="{
            'base-border': chipStyles && chipStyles[item],
            'solid-border': chipStyles && chipStyles[item] === 'solid-border',
            'dashed-border': chipStyles && chipStyles[item] === 'dashed-border'
          }"
          (click)="selectItem(item)"
          (removed)="removeItem(item)"
          [matTooltip]="
            getChipText(item)?.length > 60 ? getChipText(item) : null
          "
        >
          <span data-test-id="chip-list-item-text">
            {{ getChipText(item) }}
          </span>
          <button
            matChipRemove
            (click)="removeItem(item)"
            data-test-id="chip-list-item-remove"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        #input
        [placeholder]="placeholder"
        [matChipInputFor]="chipList"
        [matAutocomplete]="auto"
        [formControl]="optionsControl"
        (matChipInputTokenEnd)="addItem($event)"
        (paste)="onPaste($event)"
        data-test-id="chip-list-input"
      />
      <mat-hint
        *ngIf="errorMessage"
        class="mat-error"
        data-test-id="chip-list-error"
      >
        {{ errorMessage }}
      </mat-hint>
      <mat-hint *ngIf="hint">
        {{ hint }}
      </mat-hint>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="addSelectedOption($event)"
      >
        <mat-option
          *ngFor="let option of autoCompleteOptions$ | async"
          [value]="option"
          data-test-id="chip-list-option"
        >
          {{ optionDisplayPropKey ? option[optionDisplayPropKey] : option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styles: [
    `
      .mat-form-field {
        display: block;
      }
      .base-border {
        border-width: 1px;
        border-radius: 16px;
        border-color: #0071ec;
      }
      .solid-border {
        border-style: solid;
      }
      .dashed-border {
        border-style: dashed;
      }
    `,
  ],
})
export class ChipListComponent implements OnInit, OnDestroy, OnChanges {
  private clipboard = navigator.clipboard;
  private destroy$ = new Subject();
  private autoComplete$ = new BehaviorSubject<unknown[]>([]);

  @Input() label: string;
  @Input() list: unknown[];
  @Input() placeholder: string;
  @Input() displayPropKey: string;
  @Input() optionDisplayPropKey: string;
  @Input() autoCompleteOptions: unknown[] = [];
  @Input() filterAutoCompleteOptions = true;
  @Input() allowOptionsOnly: boolean;
  @Input() disabled: boolean;
  @Input() splitByPipe: boolean = false;
  @Input() errorMessage?: string;
  @Input() chipStyles?: Record<string, ChipListStyle> = {};
  @Input() validators?: ValidatorFn | ValidatorFn[];
  @Input() hint?: string;
  @Input() debounce = 500;

  @Output() selectItemSignal = new EventEmitter<unknown>();
  @Output() addItemSignal = new EventEmitter<unknown>();
  @Output() removeItemSignal = new EventEmitter<unknown>();
  @Output() listChange = new EventEmitter<unknown[]>();
  @Output() inputChange = new EventEmitter<unknown>();

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  autoCompleteOptions$: Observable<unknown>;

  optionsControl = new FormControl();

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.optionsControl.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(this.debounce),
        tap((value) => this.inputChange.emit(value)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.autoCompleteOptions$ = combineLatest([
      this.optionsControl.valueChanges,
      this.autoComplete$,
    ]).pipe(
      map(([searchTerm, options]) =>
        typeof searchTerm === 'string'
          ? this.filterOptions(searchTerm, options)
          : options
      ),
      takeUntil(this.destroy$)
    );

    if (this.validators) {
      this.optionsControl.setValidators(this.validators);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.autoCompleteOptions) {
      this.autoComplete$.next(changes.autoCompleteOptions.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  addItem(event: MatChipInputEvent): void {
    if (!this.allowOptionsOnly) {
      if (this.optionsControl.valid) {
        const input = event.input;
        const value = event.value;
        this.addItemSignal.emit(value);
        this.optionsControl.setValue(null);
        input.value = '';
      } else {
        this.toastService.sendMessage(
          `Invalid ${this.displayPropKey}`,
          'error'
        );
      }
    }
  }

  selectItem(item: unknown): void {
    this.selectItemSignal.emit(item);
  }

  removeItem(item: unknown): void {
    this.removeItemSignal.emit(item);
  }

  onPaste(event: ClipboardEvent): void {
    if (this.splitByPipe) {
      event.preventDefault();
      this.clipboard.readText().then((text: string) => {
        const terms = Array.from(new Set([...this.list, ...text.split('| ')]));
        this.list = terms;
        this.listChange.emit(terms);
        this.toastService.sendMessage(
          'Attempted to convert pasted content',
          'success'
        );
      });
    }
  }

  addSelectedOption(event: MatAutocompleteSelectedEvent): void {
    this.addItemSignal.emit(event.option.value);
    this.input.nativeElement.value = '';
    this.optionsControl.setValue(null);
  }

  getChipText(item: unknown): string {
    return this.displayPropKey ? item[this.displayPropKey] : item;
  }

  private filterOptions(searchTerm: string, options: unknown[]): unknown[] {
    if (!this.filterAutoCompleteOptions) {
      return options;
    }

    return options.filter((option) =>
      JSON.stringify(option)
        .toLowerCase()
        .includes(searchTerm.toLowerCase().trim())
    );
  }
}
