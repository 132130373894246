<div class="flexRow flexRow--marginBottomLg gap-4">
  <div class="statCard">
    <div class="statCard__metric">
      <span class="statCard__value">{{activeProjectCount}}</span>
      <mat-icon class="statCard__icon statCard__icon--marginLeftMd">content_copy</mat-icon>
    </div>
    <span class="statCard__label">Assigned projects</span>
  </div>
  <div class="statCard">
    <div class="statCard__metric">
      <span class="statCard__value">{{openConnectionsForUser}}</span>
      <mat-icon class="statCard__icon statCard__icon--marginLeftMd">assignment</mat-icon>
    </div>
    <span class="statCard__label">Your current open connections</span>
  </div>
  <div class="statCard">
    <div class="statCard__metric">
      <span class="statCard__value">{{upcomingConferences}}</span>
      <mat-icon class="statCard__icon statCard__icon--marginLeftMd">call</mat-icon>
    </div>
    <span class="statCard__label">All upcoming calls</span>
  </div>
  <div class="statCard">
    <div class="statCard__metric">
      <!-- <span>{{emailStats.bouncePercentage}}<small>%</small>Bounced</span> -->
      <span class="statCard__value">N/A</span>
      <mat-icon class="statCard__icon statCard__icon--marginLeftMd">email</mat-icon>
    </div>
    <span class="statCard__label">Bounce rate</span>
  </div>
</div>