<div class="flex flex-col gap-4">
  <div class="flex flex-row justify-start content-center items-center w-full gap-4">
    <div class="basis-14">
      <button mat-fab color="primary" [routerLink]="returnLink$ | async" [disabled]="isLoading"
        data-test-id="breadcrumbs-back-button">
        <mat-icon [ngClass]="{loading: isLoading}">
          {{ isLoading ? 'autorenew' :'arrow_back' }}
        </mat-icon>
      </button>
    </div>
    <div>
      <div class="header-location" data-test-id="breadcrumbs-location">
        {{location}}
      </div>
      <div class="header-title" data-test-id="breadcrumbs-title">
        {{title}}
      </div>
    </div>
    <div class="flex flex-row flex-1 justify-end content-start items-start h-full gap-4">
      <ng-content></ng-content>
    </div>
  </div>

  <mat-divider></mat-divider>

</div>