import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChipListStyle } from '../../../../shared/patterns/chip-list/chip-list.component';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-affiliation-chip-list',
  template: `
    <ct-chip-list
      [list]="terms"
      [label]="fieldDisplay"
      [placeholder]="'Enter terms...'"
      [chipStyles]="styles"
      [disabled]="disabled"
      (addItemSignal)="addTerm($event)"
      (removeItemSignal)="removeItemSignal.emit($event)"
      (listChange)="listChange.emit($event)"
      (inputChange)="inputChange($event)"
      [debounce]="200"
      [splitByPipe]="true"
      [autoCompleteOptions]="autoCompleteOptions"
      data-test-id="query-creator-affiliation-conditions-list"
    >
    </ct-chip-list>
  `,
})
export class AffiliationChipListComponent {
  @Output() addItemSignal = new EventEmitter<string>();
  @Output() removeItemSignal = new EventEmitter<string>();
  @Output() listChange = new EventEmitter<string[]>();

  @Input() terms: string[] = [];
  @Input() disabled: boolean;
  @Input() fieldDisplay: string;

  styles: Record<string, ChipListStyle> = {};
  autoCompleteOptions: string[] = [];

  constructor(private searchService: SearchService) {}

  addTerm(term: string): void {
    this.autoCompleteOptions = [];
    this.addItemSignal.emit(term);
  }

  inputChange(event: string): void {
    if (!event || event.length < 4) {
      this.autoCompleteOptions = [];
      return;
    }

    this.searchService
      .autocomplete(event, 'affiliations')
      .subscribe((autoCompleteOptions) => {
        this.autoCompleteOptions = autoCompleteOptions;
      });
  }
}
