<form>
  <mat-form-field class="flex-1" subscriptSizing="dynamic">
    <mat-label>Find taxonomy term</mat-label>
    <input matInput [formControl]="lookupControl" [matAutocomplete]="auto">
    <button matSuffix mat-icon-button (click)="clear()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of lookupOptions$ | async" [value]="option.name"
        (onSelectionChange)="select(option)">
        {{option.name}}
        <i *ngIf="option.matchName !== option.name">
          ({{option.matchName}})
        </i>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>