import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClientsEffects } from './reducers/clients.effects';
import { clientsReducer } from './reducers/clients.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('clients', clientsReducer),
    EffectsModule.forFeature(ClientsEffects),
  ],
})
export class ClientsModule {}
