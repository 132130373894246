import {
  ActionEmailRecipientType,
  ActionEmailType,
} from '../../../shared/services/conference.service';

export enum ExpertActionStatusMap {
  Request = 'request',
  RequestCancelled = 'request-cancelled',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Complete = 'complete',
  CompleteConfirmed = 'complete-confirmed',
}

export interface IExpertAction extends IExpertActionBase, IDataAccessLogs {}

export interface IDataAccessLogs {
  dateCreated: number;
  lastUpdated: number;
  createdBy?: string;
  lastUpdatedBy?: string;
}

export interface IExpertActionBase {
  expertActionId: string;
  clientId: string;
  opportunityId: string;
  expertId: string;
  ownerUserId: string;
  actionType: 'call' | 'screener' | 'availabilities';
  status: string;
}

export interface IExpertActionQuery {
  clientId?: string;
  opportunityId?: string;
  expertId?: string;
  ownerUserId?: string[];
  actionType?: 'call' | 'screener';
  status?: ExpertActionStatusMap[];
  expertActionId?: string;
}

export interface IExpertActionEmail extends IDataAccessLogs {
  emailId: string;
  expertActionId: string;
  type: ActionEmailType;
  recipientType: ActionEmailRecipientType;
  actionVersion: IExpertAction;
}
