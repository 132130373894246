import { Injectable } from '@angular/core';
import { IClient } from '@techspert-io/clients';
import { IExpert } from '@techspert-io/experts';
import { IOutreachTemplate } from '@techspert-io/outreachTemplates';
import { IRecipient, IRecipientEmail } from '@techspert-io/solicitation';
import { IEmailAddress } from '../../../../../shared/models/email';

@Injectable()
export class SolicitationService {
  emailTemplate: string = '';
  experts: IExpert[] = [];
  activeTemplate: IOutreachTemplate;
  subject: string = '';
  preheader: string = '';
  client: IClient;
  recipients: IRecipient[] = [];
  replyToEmail: IEmailAddress;
  recipientCount: number = 0;
  emailCount: number = 0;

  updateCounts(): void {
    this.emailCount = this.recipients
      .map((recipient) => recipient.emails)
      .reduce((acc, req) => {
        return acc + req.length;
      }, 0);
    this.recipientCount = Array.from(
      new Set(this.recipients.map((recipient) => recipient.id))
    ).length;
  }

  getValidEmails(experts: IExpert[]): IRecipient[] {
    return experts
      .filter(
        (expert) =>
          Object.values(expert.rejection).every((r) => !r) && !expert.blocked
      )
      .filter(
        (expert) => expert.opportunityEmails.length || expert.primaryEmail
      )
      .map((expert) => {
        const expertEmails: IRecipientEmail[] = expert.primaryEmail
          ? [{ address: expert.primaryEmail, type: 'poc' }]
          : expert.opportunityEmails.map((email) => ({
              address: email,
              type: 'standard',
            }));
        return {
          emails: expertEmails,
          firstName: expert.firstName,
          lastName: expert.lastName,
          id: expert.expertId,
          connectPhase: expert.connectPhase,
          source: expert.source,
          segment: expert.expertSegment,
        };
      });
  }
}
