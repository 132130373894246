<div class="ct-modalContainer flex flex-col gap-2.5 flex-1" data-test-id="responded-dialog-container">
  <div class="flex justify-between content-center items-center w-full flex-row">
    <h2 data-test-id="responded-dialog-title">Responded experts</h2>
    <mat-icon class="close-icon" (click)="closeDialog()" data-test-id="responded-dialog-close">close</mat-icon>
  </div>

  <div class="flex flex-row gap-[7.5px]">
    <div class="flex flex-row justify-start content-center items-center h-full">
      <mat-icon class="ct-emailChip__icon ct-emailChip__icon--valid">check_circle</mat-icon>
      <p>Valid</p>
    </div>
    <div class="flex flex-row justify-start content-center items-center h-full">
      <mat-icon class="ct-emailChip__icon ct-emailChip__icon--invalid">add_circle</mat-icon>
      <p>Invalid</p>
    </div>
    <div class="flex flex-row justify-start content-center items-center h-full">
      <mat-icon class="ct-emailChip__icon ct-emailChip__icon--pending">alarm</mat-icon>
      <p>Pending</p>
    </div>
  </div>

  <div style="overflow-y: scroll">
    <div class="list flex flex-col gap-[30px]">
      <app-expert-responded-detail (expertEmitter)="ensureValidForm()" [opportunity]="opportunity" [expert]="expert"
        [targetPhaseIndex]="targetPhaseIndex" class="expert-item" *ngFor="let expert of experts"
        data-test-id="responded-dialog-expert">
      </app-expert-responded-detail>
    </div>
  </div>

  <div class="flex flex-1 justify-end">
    <div matTooltipPosition="above" matTooltip="Please ensure all experts have required fields"
      [matTooltipDisabled]="formValid">
      <button class="button button--primary" data-test-id="responded-dialog-submit" [disabled]="!formValid"
        (click)="submit()">Accept and move</button>
    </div>
  </div>
</div>