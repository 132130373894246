import { createReducer, on } from '@ngrx/store';
import {
  OpportunityActions,
  OpportunityAPIActions,
} from './opportunities.actions';
import { IOpportunityState, opportunitiesAdapter } from './opportunities.state';

export const initialState: IOpportunityState =
  opportunitiesAdapter.getInitialState({
    selectedOpportunityId: null,
    isLoading: [],
  });

export const opportunitiesReducer = createReducer(
  initialState,
  on(OpportunityActions.fetchOpportunity, (state, { opportunityId }) => ({
    ...state,
    loadError: null,
    updateErrors: null,
    selectedOpportunityId: opportunityId,
    isLoading: state.isLoading.concat(opportunityId),
  })),
  on(OpportunityActions.updateOpportunity, (state, { update }) => ({
    ...state,
    loadError: null,
    updateErrors: null,
    isLoading: state.isLoading.concat(update.opportunityId),
  })),
  on(OpportunityActions.appendOpportunitySearch, (state, { searches }) => ({
    ...state,
    loadError: null,
    updateErrors: null,
    isLoading: state.isLoading.concat(searches.opportunityId),
  })),
  on(
    OpportunityAPIActions.fetchOpportunitySuccess,
    OpportunityAPIActions.updateOpportunitySuccess,
    OpportunityAPIActions.appendOpportunitySearchSuccess,
    (state, { opportunity }) =>
      opportunitiesAdapter.upsertOne(opportunity, {
        ...state,
        isLoading: state.isLoading.filter(
          (id) => opportunity.opportunityId !== id
        ),
      })
  ),
  on(
    OpportunityAPIActions.queryOpportunitiesSuccess,
    (state, { opportunities }) =>
      opportunitiesAdapter.upsertMany(opportunities, state)
  ),
  on(OpportunityAPIActions.queryOpportunitiesFailure, (state, { error }) => ({
    ...state,
    loadError: error.join(', '),
  })),
  on(
    OpportunityAPIActions.fetchOpportunityFailure,
    (state, { error, opportunityId }) => ({
      ...state,
      loadError: error.join(', '),
      isLoading: state.isLoading.filter((id) => opportunityId !== id),
    })
  ),
  on(
    OpportunityAPIActions.updateOpportunityFailure,
    OpportunityAPIActions.appendOpportunitySearchFailure,
    (state, { error, opportunityId }) => ({
      ...state,
      isLoading: state.isLoading.filter((id) => opportunityId !== id),
      updateErrors: error,
    })
  )
);

export const getSelectedOpportunityId = (state: IOpportunityState): string =>
  state.selectedOpportunityId;

const { selectIds, selectEntities, selectAll, selectTotal } =
  opportunitiesAdapter.getSelectors();

export const selectOpportunityIds = selectIds;
export const selectOpportunityEntities = selectEntities;
export const selectAllOpportunityies = selectAll;
export const selectOpportunityTotal = selectTotal;
