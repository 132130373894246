import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IExpertCallAction } from '@techspert-io/conferences';
import { IDisplayExpert } from '@techspert-io/experts';
import 'moment-timezone';
import { IConferenceReminder } from '../../../../../../../../features/conferences/models/conference-reminders.models';

@Component({
  selector: 'app-scheduled-box-reminder',
  templateUrl: './scheduled-box-reminder.component.html',
  styleUrls: ['./scheduled-box-reminder.component.scss'],
})
export class ScheduledBoxReminderComponent implements OnChanges {
  @Input() expert: IDisplayExpert;
  @Input() callAction: IExpertCallAction;
  @Input() conferenceReminder?: IConferenceReminder;

  conferenceReminderIconColour: string;
  conferenceReminderTooltipTimestamp: number;
  conferenceReminderTooltipPreTimestamp: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conferenceReminder) {
      if (
        this.conferenceReminder &&
        this.conferenceReminder.emails &&
        this.conferenceReminder.timetoBeSent &&
        this.conferenceReminder.emails[0].recipient
      ) {
        this.conferenceReminderIconColour = this.conferenceReminder.dateSent
          ? '#95d145'
          : '#f5c600';

        this.conferenceReminderTooltipTimestamp =
          (this.conferenceReminder.dateSent ||
            this.conferenceReminder.timetoBeSent) * 1000;

        this.conferenceReminderTooltipPreTimestamp = `Reminder ${
          this.conferenceReminder.dateSent ? 'was' : 'will be'
        } sent to ${this.conferenceReminder.emails[0].recipient} at `;
      } else {
        this.conferenceReminderTooltipPreTimestamp = '';
      }
    }
  }
}
