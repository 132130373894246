import { IExpertCallAction } from '@techspert-io/conferences';
import { IEngagement } from '@techspert-io/engagements';
import { IExpertAvailabilitiesActionTimeSlot } from '@techspert-io/expert-actions';
import { IExpertProfileSpecialisms } from '@techspert-io/expertProfiles';
import { IExpertScreenerActionBase } from '@techspert-io/surveys';
import { IConferenceReminder } from '../../../features/conferences/models/conference-reminders.models';
import { IResponseFailItem } from '../../../shared/models/api';

export const emailRegex = /^[^@\s]+@[^@\s]+$/;

export const expertSegmentList: ExpertSegment[] = [
  'Clinical - KOL',
  'Clinical - Prescriber',
  'Academic - KOL',
  'Academic - Researcher',
  'Commercial - C-Suite / executive',
  'Commercial - VP / Director',
  'Commercial - Manager / Senior',
  'Commercial - Entry-level',
  'Payer',
  'Healthcare admin',
  'Nurse',
  'Patient',
  'Caregiver',
  'Community Physician',
  'Pharmacist',
  'Lab Director',
];

export const expertSourceList: ExpertSource[] = [
  'cognisearch',
  'historical',
  'deep3',
  'deep-next',
  'pdl-commercial',
  'pdl-enrichment',
  'manualUpload',
  'third-party',
  'expert-referral',
];

export const connectPhaseList: IExpert['connectPhase'][] = [
  'identified',
  'firstFollowUp',
  'secondFollowUp',
  'outreachComplete',
  'outreach',
  'screener',
  'sentToClient',
  'accepted',
  'scheduled',
  'completed',
];

export type ReasonForRejection =
  | 'NULL'
  | 'Wrong geography'
  | 'Irrelevant expertise'
  | 'Scope change'
  | 'Project cancellation'
  | 'Quota reached'
  | 'Client dropped off'
  | 'Client rejected'
  | 'Other'
  | 'Emails bounced'
  | 'Failed screener';

export type ConnectPhase =
  | 'identified'
  | 'firstFollowUp'
  | 'secondFollowUp'
  | 'outreachComplete'
  | 'outreach'
  | 'screener'
  | 'sentToClient'
  | 'accepted'
  | 'scheduled'
  | 'completed';

export type ExpertSegment =
  | 'Clinical - KOL'
  | 'Clinical - Prescriber'
  | 'Academic - KOL'
  | 'Academic - Researcher'
  | 'Commercial - C-Suite / executive'
  | 'Commercial - VP / Director'
  | 'Commercial - Manager / Senior'
  | 'Commercial - Entry-level'
  | 'Payer'
  | 'Healthcare admin'
  | 'Nurse'
  | 'Patient'
  | 'Caregiver'
  | 'Community Physician'
  | 'Pharmacist'
  | 'Lab Director';

export type ExpertSource =
  | 'pdl-enrichment'
  | 'manualUpload'
  | 'deep-next'
  | 'deep3'
  | 'pdl-commercial'
  | 'historical'
  | 'deep'
  | 'network'
  | 'third-party'
  | 'cognisearch'
  | 'expert-referral';

export type ExpertEmailStatus =
  | 'unverified'
  | 'valid'
  | 'pending'
  | 'invalid'
  | 'unknown';

export type ExpertEmailService = 'sendgrid' | 'neverbounce';

export interface IExpertEmail {
  address: string;
  history?: {
    messageStatus: string;
  }[];
  status: ExpertEmailStatus;
  service?: ExpertEmailService;
  isVerified?: boolean;
}

export interface ISearchExpertData {
  id: string;
  trackingId?: string;
  sourceOpportunityId?: string;
  relevance?: number;
  responsiveness?: number;
}

export type QualtricsDataType =
  | 'array'
  | 'integer'
  | 'number'
  | 'object'
  | 'string';

export interface IExpertScreenerResult {
  responses: {
    id: string;
    question: string;
    answer: string;
    questionType: QualtricsDataType;
  }[];
  dateCompleted: number;
}

export interface IExpertScreenerConfig {
  contactLookupId: string;
  contactId: string;
  distributionId?: string;
  distributionLink?: string;
  dateSent?: number;
  dateCompleted?: number;
}

export enum ExpertComplianceType {
  Training = 'training',
  Terms = 'terms',
}

export interface IExpertCompliance {
  completed: boolean;
  dateCreated: number;
  createdBy: string;
  emailAddress?: string;
}

export interface IExpert {
  bio: string;
  blocked: boolean;
  callTime: string;
  campaignId?: string;
  clientAccepted?: boolean;
  clientApproved?: boolean;
  clientNotes?: string;
  conferences: string[];
  connectPhase: ConnectPhase;
  currency: string;
  expertId: string;
  firstName: string;
  firstNameAlias?: string;
  /**
   * @deprecated use 'opportunitySegmentId' instead
   */
  geographicTarget: string;
  lastName: string;
  lastNameAlias?: string;
  lastUpdated?: number;
  onHold?: boolean;
  opportunityId: string;
  opportunitySegmentId?: string;
  ownerConnectId: string;
  phoneNumbers?: string[];
  primaryPhoneNumber?: string;
  portalAffiliations: string[];
  prevOpportunities: unknown;
  /**
   * @deprecated use 'opportunitySegmentId' instead
   */
  profileType: string;
  reasonForRejection: ReasonForRejection;
  recordingAuthorised: boolean;
  requestedAnotherTime?: boolean;
  screenerResponse?: string;
  /** @deprecated */
  searchExpertData?: ISearchExpertData;
  searchId: string;
  seenByClient: boolean;
  favourited?: boolean;
  source: ExpertSource;
  tagline: string;
  qualifications?: string[];
  unitsPerHour: number;
  costBand?: 'Discounted' | 'Standard' | 'Premium' | 'Premium+';
  expertSourceId: string;
  expertProfileId: string;
  expertVisibility?: Record<'clientPortal', boolean>;

  opportunityEmails: string[];
  primaryEmail?: string;
  portalExpertise: string[];
  expertSegment: ExpertSegment;
  rejection: Partial<
    Record<'admin' | 'client' | 'expert', IOpportunityExpertRejectionRecord>
  >;
  compliance?: Partial<Record<ExpertComplianceType, IExpertCompliance>>;
  training?: IExpertComplianceTraining;
  highlightNote?: string;
  requestedHonoraria?: number;
  clientAcceptedLedger?: IExpertUpdatesLedger<ExpertFieldMap.ClientAccepted>;
  screenerConfig?: IExpertScreenerActionBase;
  availabilities?: IExpertAvailabilitiesActionTimeSlot[];
  country?: string;
  subdivision?: string;
  timezoneName?: string;
}

export interface IExpertComplianceTraining {
  profile_id: string;
  type: 'training';
  document: string;
  created_at: string;
  created_by: string;
  is_valid: boolean;
}

export interface IDisplayExpert extends IExpert {
  isSelectable?: boolean;
  isSelected?: boolean;
  engagements?: IEngagement[];
  callActions?: IDisplayExpertCallActions;
}

export interface IDisplayExpertCallActions {
  request: IDisplayExpertCallAction[];
  pending: IDisplayExpertCallAction[];
  complete: IDisplayExpertCallAction[];
}

export interface IDisplayExpertCallAction {
  reminders: IConferenceReminder[];
  callAction: IExpertCallAction;
}

export interface IExpertCreateRequest
  extends Partial<IExpert>,
    Partial<IExpertProfileSpecialisms> {
  firstName: string;
  lastName: string;
  ownerConnectId: string;
  country?: string;
  timezoneName?: string;
  subdivision?: string;
  expertSourceId: string;
}

export interface IExpertUpdateRequest extends Partial<IExpert> {
  expertId: string;
}

export interface IQueryExpertSearchRequest {
  opportunityId: string;
  searchIds: string[];
  fromPhase?: ConnectPhase;
}

export interface IExtendedExpert extends IExpert {
  opportunityName: string;
  clientId: string;
}

export interface IExpertProp {
  field: string;
  value: IExpert[keyof IExpert];
}

export enum RejectionCodesMap {
  Unknown = 'unknown',
  Other = 'other',
  Duplicate = 'duplicate',
  Mismatch = 'mismatch',
  Money = 'insufficient-reimbursement',
  Time = 'busy',
  Compliance = 'compliance',
  Screener = 'screener',
}

export interface IOpportunityExpertRejectionRecord {
  source:
    | 'unknown'
    | 'script'
    | 'admin-addon'
    | 'expert-portal'
    | 'client-portal'
    | 'email-categories';
  code: RejectionCodesMap;
  message?: string;
  rejectedOn: number;
}

export enum ExpertFieldMap {
  ClientApproved = 'clientApproved',
  ClientAccepted = 'clientAccepted',
  ClientVisible = 'clientVisible',
  ClientRejected = 'clientRejected',
  ExpertRejected = 'expertRejected',
  AdminRejected = 'adminRejected',
  ComplianceTrainingAccepted = 'complianceTrainingAccepted',
  ComplianceTermsAccepted = 'complianceTermsAccepted',
  HonorariumChange = 'honorariumChange',
}

export interface IExpertUpdatesLedger<T extends ExpertFieldMap> {
  expertUpdateLedgerId: string;
  expertId: string;
  field: T;
  value: boolean | string | number;
  metadata?: Record<string, string | number | boolean>;
  expertUpdatedDate: number;
  expertUpdatedBy: string | 'unknown';
  dateCreated: number;
  lastUpdated: number;
  createdBy: string;
  lastUpdatedBy: string;
}

export class ValidationErrors extends Error {
  constructor(public errors: IResponseFailItem<IExpert>[]) {
    super('Validation Failure');

    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class ValidationError extends Error {
  constructor(public error: IResponseFailItem<IExpert>) {
    super('Validation Failure');

    Object.setPrototypeOf(this, new.target.prototype);
  }
}
