import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  EngagementPaymentReasons,
  engagementPaymentTypeSelectList,
  IEngagement,
  paymentReasonsList,
} from '@techspert-io/engagements';
import { ToastService } from '@techspert-io/user-alerts';
import { CurrencyService } from '../../../shared/services/currency.service';

@Component({
  templateUrl: './engagements-dialog.component.html',
  styleUrls: ['./engagements-dialog.component.scss'],
})
export class EngagementsDialogComponent implements OnInit {
  engagement: IEngagement;
  public dateOfEngagement: string;
  public paymentReasons: EngagementPaymentReasons[] = paymentReasonsList;
  public paymentTypes = engagementPaymentTypeSelectList.filter(
    (p) => p.value !== 'thirdParty'
  );

  public currencyFormControl = new FormControl(
    null,
    this.currencyService.validateCurrency.bind(this.currencyService)
  );

  constructor(
    public toastService: ToastService,
    public currencyService: CurrencyService,
    public dialogRef: MatDialogRef<EngagementsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { engagement: IEngagement }
  ) {}

  ngOnInit(): void {
    this.engagement = this.data.engagement;
    this.setupCurrencyForm();
    if (this.engagement.dateOfEngagement) {
      this.dateOfEngagement = new Date(this.engagement.dateOfEngagement)
        .toISOString()
        .split('T')[0];
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public addEngagement(): void {
    if (this.dateOfEngagement) {
      this.engagement.dateOfEngagement = new Date(
        this.dateOfEngagement
      ).toISOString();
    }
    if (
      !this.currencyService.currencyCodes.includes(this.engagement.currency)
    ) {
      this.toastService.sendMessage('Please provide a valid currency', 'error');
    } else if (
      this.engagement.engagementType === 'payment' &&
      !this.engagement.reason
    ) {
      this.toastService.sendMessage(
        'Please select a reason for payment adjustment',
        'error'
      );
    } else {
      this.dialogRef.close({
        action: 'create',
        engagement: this.engagement,
      });
    }
  }

  private setupCurrencyForm(): void {
    this.currencyFormControl.setValue(this.engagement.currency);

    this.currencyFormControl.valueChanges.subscribe((currency) => {
      this.engagement.currency = currency;
    });
  }
}
