<div class="ct-page-container flex flex-col gap-5">
  <div class="flex justify-between w-full">
    <h1 data-test-id="search-title" class="flex justify-start content-center items-center w-full flex-row gap-1">
      <span>Search {{opportunityName}}</span>
      <mat-icon *ngIf="!maskedMode"
        matTooltip="Press Ctrl+Shift+S to enter masked mode (enabling masked mode will hide this prompt)">
        info_outline</mat-icon>
    </h1>
    <div class="flex flex-row gap-2">
      <button class="button button--secondary" (click)="viewQuery = !viewQuery" data-test-id="search-toggle-query-view">
        {{viewQuery ? 'hide' : 'show'}} Query
      </button>
      <button class="button button--secondary" (click)="showTaxonomy = !showTaxonomy"
        data-test-id="search-toggle-taxonomy-view">
        {{showTaxonomy ? 'hide' : 'show'}} Taxonomy
      </button>
      <button class="button button--secondary" [disabled]="!filteredExperts.length" (click)="viewSummary = !viewSummary"
        data-test-id="search-toggle-summary-view">
        Toggle Summary View &nbsp; <span>{{filteredExperts.length}}/{{experts.length}}</span>
      </button>
    </div>
  </div>

  <div class="flex flex-row gap-5">
    <app-query-creator *ngIf="viewQuery" style="height: calc(100vh - 185px)" (payloadSignal)="runSearch($event)"
      (serviceChangeSignal)="clearExperts()" [showSearchLoader]="showSearchLoader" [initQuery]="query"
      [addTerms]="addTerms" class="min-w-[465px]">
      <button *ngIf="erroredUrls.length" class="button button--primary" [disabled]="showSearchLoader"
        (click)="reRunPdlEnrichment()">
        Try again
      </button>
    </app-query-creator>

    <ng-container *ngIf="showTaxonomy">
      <app-search-taxonomy-root style="height: calc(100vh - 185px)" class="flex flex-1"
        (addNodeSignal)="onAddNode($event)"></app-search-taxonomy-root>
    </ng-container>

    <ng-container *ngIf="!showTaxonomy">
      <ng-container *ngIf="query?.service === 'falcon-search'">
        <div *ngIf="showSearchLoader" [style.padding]="'150px'"
          class="flex justify-center content-center items-center flex-1">
          <mat-spinner></mat-spinner>
        </div>
        <app-results-display-falcon *ngIf="!showSearchLoader" style="height: calc(100vh - 185px)"
          [experts]="falconSearchExperts" (expertSelected)="openSendExpertsDialogForFalconSearch($event)"
          class="flex flex-1"></app-results-display-falcon>
      </ng-container>

      <ng-container *ngIf="query?.service !== 'falcon-search'">
        <app-results-display style="height: calc(100vh - 185px)" *ngIf="!viewSummary" [selectedCount]="selectedCount"
          [totalCount]="queryExpertTotal" (toggleSelectSignal)="toggleSelectExpert($event)"
          (filterTermSignal)="filterExperts($event)" (downloadExpertListCsvSignal)="downloadExpertListCsv()"
          (sendExpertsDialogSignal)="openSendExpertsDialog()" (toggleSelectAllSignal)="toggleSelectExperts()"
          [selectAllToggle]="selectAllToggle" [experts]="filteredExperts" [maskedMode]="maskedMode"
          (selectionConfigurationSignal)="assignSelectionConfiguration($event)" class="flex-1">
        </app-results-display>

        <app-results-display-filter style="height: calc(100vh - 185px)" *ngIf="viewSummary" [experts]="experts"
          (toggleFitler)="filterExpertsAdv($event)" class="flex flex-1">
        </app-results-display-filter>
      </ng-container>
    </ng-container>
  </div>
</div>