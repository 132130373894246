import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastNotification } from './toast-notification';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toastMessages: ToastNotification[] = [];

  getMessages(): Observable<ToastNotification[]> {
    return of(this.toastMessages);
  }

  sendMessage(content: string, style: 'success' | 'error'): void {
    const message = new ToastNotification(content, style);
    this.toastMessages.push(message);
  }
}
