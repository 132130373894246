import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IClient } from '../models/clients.models';

export interface IClientState extends EntityState<IClient> {
  selectedClientId: string | null;
  isLoading: string[];
  loadError?: string;
  updateErrors?: string[];
}

const selectId = (a: IClient) => a.clientId;

const sortComparer = (a: IClient, b: IClient) =>
  a.clientName.localeCompare(b.clientName);

export const clientsAdapter: EntityAdapter<IClient> =
  createEntityAdapter<IClient>({ selectId, sortComparer });
