<div class="flex flex-col gap-4">
  <app-breadcrumbs backLink="../../" [isLoading]="isLoading" location="Users" title="Edit User">
  </app-breadcrumbs>

  <mat-card *ngIf="vmData$ | async as vmData">
    <mat-card-content>
      <app-user-form [userData]="vmData.user" [clients]="vmData.clients" [authRoles]="vmData.authRoles"
        [isLoading]="isLoading" (submitUser)="editUser($event)"></app-user-form>
    </mat-card-content>
  </mat-card>
</div>