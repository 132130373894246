import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  IEngagement,
  IExtendedPaymentEngagement,
} from '../models/engagements.models';

export interface IPaymentQueryOptions {
  paymentStatus?: string;
  dateMin?: string;
  dateMax?: string;
  opportunityId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EngagementsService {
  private readonly baseUrl = '/payments';

  constructor(private http: HttpClient) {}

  queryExtended(
    queryOptions: IPaymentQueryOptions
  ): Observable<IExtendedPaymentEngagement[]> {
    if (!queryOptions.paymentStatus) {
      return of([]);
    }

    const params = Object.entries(queryOptions)
      .filter(([, v]) => !!v)
      .reduce((acc, [k, v]) => acc.set(k, v), new HttpParams());

    return this.http
      .get<IExtendedPaymentEngagement[]>(this.baseUrl, {
        params,
      })
      .pipe(catchError(() => of([])));
  }

  query(query: {
    expertId?: string;
    opportunityId?: string;
  }): Observable<IEngagement[]> {
    const params = Object.entries(query).reduce(
      (prev, [key, value]) => prev.append(key, value),
      new HttpParams()
    );

    return this.http.get<IEngagement[]>(`${this.baseUrl}/engagements`, {
      params,
    });
  }

  getById(engagementId: string): Observable<IEngagement> {
    return this.http.get<IEngagement>(`${this.baseUrl}/${engagementId}`);
  }

  create(engagement: IEngagement): Observable<IEngagement> {
    return this.http.post<IEngagement>(this.baseUrl, engagement);
  }

  /** Bypasses checks on date of engagement */
  updateLegacy(engagement: Partial<IEngagement>): Observable<IEngagement> {
    const {
      engagementId,
      opportunityName,
      opportunityId,
      expertId,
      ...safeProps
    } = engagement;

    return this.http.patch<IEngagement>(
      `${this.baseUrl}/legacy/${engagementId}`,
      safeProps
    );
  }

  update(engagement: Partial<IEngagement>): Observable<IEngagement> {
    const {
      engagementId,
      opportunityName,
      opportunityId,
      expertId,
      ...safeProps
    } = engagement;

    return this.http.patch<IEngagement>(
      `${this.baseUrl}/${engagementId}`,
      safeProps
    );
  }

  delete(engagementId: string): Observable<string> {
    return this.http.delete<string>(`${this.baseUrl}/${engagementId}`);
  }
}
