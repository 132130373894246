import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-authorisation-error-page',
  templateUrl: './authorisation-error-page.component.html',
  styleUrls: ['./authorisation-error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationErrorPageComponent implements OnInit {
  errors$: Observable<string> = EMPTY;

  constructor(
    private route: ActivatedRoute,
    private cognitoAuthService: CognitoAuthService
  ) {}

  ngOnInit(): void {
    this.errors$ = this.route.queryParams.pipe(
      map((p) => p.message || 'Unknown Error')
    );
  }

  logout(): void {
    this.cognitoAuthService.logOut().subscribe();
  }
}
