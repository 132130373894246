import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../shared/patterns/material-module';
import { ScreenerResultDialogComponent } from './components/screener-results-dialog/screener-result-dialog.component';
import { ScreenerStatusPipe } from './pipes/screener-status.pipe';

@NgModule({
  exports: [ScreenerResultDialogComponent, ScreenerStatusPipe],
  declarations: [ScreenerResultDialogComponent, ScreenerStatusPipe],
  imports: [CommonModule, MaterialModule],
})
export class SurveysModule {}
