<div *ngIf="!showPreviewEmail" class="solicitation" data-test-id="send-solicitation-dialog-preview-email">
  <div class="solicitation-header" data-test-id="send-solicitation-dialog-header">
    <div class="solicitation-header-title">
      <h2 data-test-id="send-solicitation-dialog-title">
        New email from
      </h2>
      <div class="basicInput">
        <select [(ngModel)]="solicitationService.replyToEmail" class="basicInput__input"
          data-test-id="send-solicitation-dialog-sender">
          <option *ngFor="let email of allUserEmails" [ngValue]="email"
            data-test-id="send-solicitation-dialog-sender-option">
            {{email.email}}
          </option>
        </select>
      </div>
      <h2 data-test-id="send-solicitation-dialog-title-expert-email-count">
        to {{solicitationService.recipientCount}} experts ({{solicitationService.emailCount}} emails)
      </h2>
    </div>
    <mat-icon class="close-icon" (click)="dialogRef.close()" data-test-id="send-solicitation-dialog-close">
      close
    </mat-icon>
  </div>
  <div class="solicitation-recipients" data-test-id="send-solicitation-dialog-recipients">
    <app-recipient-list></app-recipient-list>
  </div>
  <div class="basicInput subject-input flex flex-col gap-2.5">
    <input placeholder="subject" type="text" ngDefaultControl [(ngModel)]="solicitationService.subject"
      *ngIf="!editingTemplate" class="basicInput__input" data-test-id="send-solicitation-dialog-subject" />
    <input placeholder="preheader" type="text" ngDefaultControl [(ngModel)]="solicitationService.preheader"
      *ngIf="!editingTemplate" class="basicInput__input" data-test-id="send-solicitation-dialog-preheader" />
  </div>
  <div class="solicitation-body">
    <div [className]="sideMenuToShow ? 'show-menu-div' : 'hide-menu-div'">
      <app-template-menu *ngIf="sideMenuToShow === 'templates'" (filterTemplatesSignal)="filterTemplates($event)"
        [initSearchTerm]="initSearchTerm" [filteredTemplates]="filteredTemplates"
        (openTemplateSignal)="openTemplate($event)" (openEditTemplateFormSignal)="openEditTemplateForm($event)"
        (openNewTemplateFormSignal)="openNewTemplateForm()" (closeMenuSignal)="handleMenuClose()"
        (duplicateTemplateSignal)="duplicateTemplate($event)" data-test-id="send-solicitation-dialog-template-menu">
      </app-template-menu>
      <app-key *ngIf="sideMenuToShow === 'keys'" class="key-menu" (closeMenuSignal)="handleMenuClose()">
      </app-key>
    </div>
    <div class="editor" #editor>
      <pre class="ngx-pre">
        <pre class="ngx-pre">
          <textarea class="editor-textarea" id="editor-1" *ngIf="solicitationService.activeTemplate" ngDefaultControl [(ngModel)]="solicitationService.activeTemplate.content"
          data-test-id="send-solicitation-dialog-body"></textarea>
        </pre>
      </pre>
      <div #formContainer class="template-form-container">
        <app-template-form *ngIf="showTemplateForm" class="template-menu" [inputTemplateFormData]="templateFormData"
          (refreshTemplateMenuSignal)="initialiseViewChange()" (closeFormSignal)="closeTemplateForm()">
        </app-template-form>
      </div>
    </div>
  </div>
  <div class="solicitation-footer">
    <div class="button-group">
      <button class="button button--secondary" (click)="toggleKeys()">
        <mat-icon class="button-icon" data-test-id="send-solicitation-dialog-lookup-keys">
          vpn_key
        </mat-icon>
        <span data-test-id="send-solicitation-dialog-lookup-keys-label">
          Lookup keys
        </span>
      </button>
      <button class="button button--secondary" (click)="toggleTemplates()" title="Templates">
        <mat-icon class="button-icon" data-test-id="send-solicitation-dialog-templates">
          library_books_outline
        </mat-icon>
        <span data-test-id="send-solicitation-dialog-templates-label">
          Templates
        </span>
      </button>
    </div>
    <div class="button-group">
      <button class="button button--secondary" (click)="dialogRef.close()" [disabled]="previewEmail"
        data-test-id="send-solicitation-dialog-cancel">
        Cancel
      </button>
      <button class="button button--primary" (click)="createAndDisplayPreviewEmail()"
        data-test-id="send-solicitation-dialog-submit">
        Send all
      </button>
    </div>
  </div>
  <div [ngStyle]="{'display': !previewEmail ? 'none' : 'block' }" class="preview noselect">
    <section *ngIf="previewEmail">
      <div class="message">
        <span>
          <mat-icon>warning</mat-icon>
          <h5 data-test-id="send-solicitation-dialog-preview-info-message">
            This is a preview of the email that you are going to send out.
          </h5>
        </span>
        <mat-icon class="pull-right" (click)="clearPreviewEmail()"
          data-test-id="send-solicitation-dialog-preview-close">
          clear
        </mat-icon>
      </div>
      <div class="inner-preview-container">
        <div class="left" data-test-id="send-solicitation-dialog-preview-recipients-label">
          Recipients
        </div>
        <div class="right" data-test-id="send-solicitation-dialog-preview-recipients">
          <app-recipient-list></app-recipient-list>
        </div>
      </div>
      <div class="inner-preview-container">
        <div class="left topBorder" data-test-id="send-solicitation-dialog-preview-subject-label">
          Subject
        </div>
        <div class="right topBorder" data-test-id="send-solicitation-dialog-preview-subject">
          {{previewEmail.subject}}
        </div>
      </div>
      <div class="inner-preview-container" *ngIf="previewPreheader">
        <div class="left topBorder" data-test-id="send-solicitation-dialog-preview-preheader-label">
          Preheader
        </div>
        <div class="right topBorder" data-test-id="send-solicitation-dialog-preview-preheader">
          {{previewPreheader}}
        </div>
      </div>
    </section>
    <div class="preview-body">
      <article [innerHTML]="previewEmail?.htmlContent" class="previewBody"
        data-test-id="send-solicitation-dialog-preview-body">
      </article>
    </div>
    <footer>
      <button class="button button--secondary" (click)="clearPreviewEmail()"
        data-test-id="send-solicitation-dialog-preview-cancel">
        Cancel
      </button>
      <button class="button button--primary" (click)="confirmSendAllClickHandler()"
        data-test-id="send-solicitation-dialog-preview-submit" [disabled]="sendingEmail">
        {{sendingEmail ? 'sending' : 'Confirm and send all'}}
      </button>
    </footer>
  </div>
</div>