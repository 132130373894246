import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  IEmailIdentity,
  IEmailIdentityCreate,
  IEmailIdentityDeletePayload,
} from '../models/email-identity.models';
import {
  IExpertProfile,
  IExpertProfileBase,
} from '../models/expert-profile.models';

type SpecialismFields = Pick<
  IExpertProfile,
  'affiliations' | 'expertise' | 'emails'
>;

export type ExpertProfileUpdateRequest = Partial<IExpertProfileBase> & {
  expertProfileId: string;
};

@Injectable({
  providedIn: 'root',
})
export class ExpertProfilesService {
  private readonly baseUrl = '/experts/profile';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  getById(expertProfileId: string): Observable<IExpertProfile> {
    return this.http
      .get<IExpertProfile>(`${this.baseUrl}/${expertProfileId}`)
      .pipe(map((p) => this.mapProfile(p)));
  }

  update(
    expertProfile: ExpertProfileUpdateRequest
  ): Observable<IExpertProfile> {
    const { expertProfileId, ...rest } = expertProfile;
    return this.http
      .patch<IExpertProfile>(`${this.baseUrl}/${expertProfileId}`, rest)
      .pipe(
        map((p) => this.mapProfile(p)),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            this.showErrorToast(err.error.message);
          } else {
            this.showErrorToast(err.message);
          }
          return throwError(err);
        })
      );
  }

  createEmailIdentity(payload: IEmailIdentityCreate) {
    return this.http.post<IEmailIdentity>(`/experts/email-identity`, payload);
  }

  deleteEmailIdentity(payload: IEmailIdentityDeletePayload) {
    return this.http.delete<IEmailIdentity>(
      `/experts/email-identity/${payload.emailIdentityId}`,
      { body: { deletedReason: payload.deletedReason } }
    );
  }

  private showErrorToast(message: string): void {
    this.toastService.sendMessage(message, 'error');
  }

  private mapProfile(profile: IExpertProfile): IExpertProfile {
    const specialisms: (keyof SpecialismFields)[] = [
      'emails',
      'expertise',
      'affiliations',
    ];

    return specialisms.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: (profile[curr] || []).sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      }),
      {
        ...profile,
        affiliations: [],
        expertise: [],
        emails: [],
      }
    );
  }
}
