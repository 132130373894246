import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPendingConferenceBookingExpert } from '../models/conferences.models';
import {
  ICreateCallActionReq,
  IExpertCallAction,
  IExpertCallActionQuery,
  IPatchCallActionReq,
  IUpdateCallActionReq,
} from '../models/expertCallActions.models';

@Injectable({
  providedIn: 'root',
})
export class ExpertCallActionService {
  private readonly baseUrl = '/schedule';

  constructor(private http: HttpClient) {}

  queryCallActions(
    query: IExpertCallActionQuery = {}
  ): Observable<IExpertCallAction[]> {
    const params = Object.entries(query).reduce(
      (prev, [key, value]) => (key ? prev.append(key, value) : prev),
      new HttpParams()
    );

    return this.http.get<IExpertCallAction[]>(`${this.baseUrl}/query`, {
      params,
    });
  }

  getRequests(): Observable<IPendingConferenceBookingExpert[]> {
    return this.http.get<IPendingConferenceBookingExpert[]>(
      `${this.baseUrl}/expertCallAction/requests`
    );
  }

  create(callAction: ICreateCallActionReq): Observable<IExpertCallAction> {
    return this.http.post<IExpertCallAction>(
      `${this.baseUrl}/expertCallAction`,
      callAction
    );
  }

  update(callAction: IUpdateCallActionReq): Observable<IExpertCallAction> {
    const { expertActionId, ...payload } = callAction;
    return this.http.put<IExpertCallAction>(
      `${this.baseUrl}/expertCallAction/${expertActionId}`,
      payload
    );
  }

  patch(callAction: IPatchCallActionReq): Observable<IExpertCallAction> {
    const { expertActionId, ...payload } = callAction;
    return this.http.patch<IExpertCallAction>(
      `${this.baseUrl}/expertCallAction/${expertActionId}`,
      payload
    );
  }

  cancel(expertActionId: string): Observable<IExpertCallAction> {
    return this.http.delete<IExpertCallAction>(
      `${this.baseUrl}/expertCallAction/${expertActionId}`
    );
  }

  confirmCallCompleted(expertActionId: string): Observable<IExpertCallAction> {
    return this.http.patch<IExpertCallAction>(
      `${this.baseUrl}/expertCallAction/${expertActionId}/confirmCompletion`,
      {}
    );
  }
}
