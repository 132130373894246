import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IUserBase } from '@techspert-io/auth';
import { IClientContactDialogData } from '../../../models/opportunity.models';

@Component({
  selector: 'app-client-contact-dialog',
  templateUrl: './client-contact-dialog.component.html',
  styleUrls: ['./client-contact-dialog.component.scss'],
})
export class ClientContactDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ClientContactDialogComponent, IUserBase[]>,
    @Inject(MAT_DIALOG_DATA) public clientContact?: IClientContactDialogData
  ) {}

  submitClientContact(clientContacts: IUserBase[]): void {
    this.dialogRef.close(clientContacts);
  }
}
