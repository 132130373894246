<form [formGroup]="clientContactsForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div *ngIf="!isBulkEdit" class="flex flex-row gap-[30px]">
    <mat-form-field appearance="outline" class="basis-1/2">
      <mat-label data-test-id="client-contact-form-first-name-label">First name</mat-label>
      <input matInput formControlName="firstName" placeholder="First name"
        data-test-id="client-contact-form-first-name">
    </mat-form-field>

    <mat-form-field appearance="outline" class="basis-1/2">
      <mat-label data-test-id="client-contact-form-last-name-label">Last name</mat-label>
      <input matInput formControlName="lastName" placeholder="Last name" data-test-id="client-contact-form-last-name">
    </mat-form-field>
  </div>

  <div *ngIf="!isBulkEdit" class="flex flex-row gap-[30px]">
    <mat-form-field appearance="outline" class="basis-full">
      <mat-label data-test-id="client-contact-form-email-label">Email</mat-label>
      <input matInput formControlName="email" type="email" placeholder="email@site.com"
        data-test-id="client-contact-form-email">
      <mat-error *ngIf="emailControl.hasError('email')" data-test-id="client-contact-form-invalid-email-error">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="countryOptions$ | async as countryOptions" class="flex flex-row gap-[30px]">
    <mat-form-field appearance="outline" class="basis-1/2">
      <mat-label data-test-id="client-contact-form-country-label">Country</mat-label>
      <input type="text" placeholder="Pick a country..." matInput required formControlName="country"
        [matAutocomplete]="auto" data-test-id="client-contact-form-country">
      <mat-error *ngIf="countryControl.hasError('countryRequired')"
        data-test-id="client-contact-form-country-required-error">
        Please enter a valid country
      </mat-error>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of countryOptions.countries" [value]="option"
          data-test-id="client-contact-form-country-list-item">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" class="basis-1/2">
      <mat-label data-test-id="client-contact-form-time-zone-label">Timezone</mat-label>
      <mat-select formControlName="timezone" required data-test-id="client-contact-form-time-zone">
        <mat-option *ngFor="let option of countryOptions.timezones" [value]="option.name"
          data-test-id="client-contact-form-time-zone-list-item">
          {{option.offset}} {{option.abbr}} {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="showInviteToggle" class="flex flex-row gap-[30px]">
    <ct-slide-toggle [value]="sendInviteEmail" label="Invite Email" tooltip="Send email invite to the Project Portal"
      (toggleChangeSignal)="sendInviteEmail = !sendInviteEmail" data-test-id="client-contact-form-send-invite-email">
    </ct-slide-toggle>
  </div>

  <div style="margin-bottom: 10px" class="flex justify-between content-stretch items-stretch flex-row">
    <button mat-raised-button mat-dialog-close data-test-id="client-contact-form-close">Close</button>
    <button mat-raised-button color="primary" type="submit" data-test-id="client-contact-form-submit">Save</button>
  </div>

</form>