import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ExpertActions,
  ExpertAPIActions,
  IDisplayExpert,
} from '@techspert-io/experts';
import {
  OpportunityActions,
  OpportunityAPIActions,
} from '@techspert-io/opportunities';
import { ExpertSourcesActions } from '@techspert-io/search';
import { filter, map, tap } from 'rxjs/operators';
import { OpportunityExpertsService } from './opportunity-experts.service';

@Injectable({
  providedIn: 'root',
})
export class OpportunityExpertsEffects {
  getSearchesExpertsUpdate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ExpertAPIActions.updateExpertsSuccess),
        map(({ experts }) =>
          experts.reduce<Record<string, IDisplayExpert[]>>(
            (prev, curr) => ({
              ...prev,
              [curr.opportunityId]: [...(prev[curr.opportunityId] || []), curr],
            }),
            {}
          )
        ),
        tap((experts) =>
          Object.entries(experts).forEach(([opportunityId, experts]) =>
            this.opportunityExpertsService.getSearches({
              opportunityId,
              searchIds: [...new Set(experts.map((e) => e.searchId))],
              fromPhase: 'screener',
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  getSources$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.fetchOpportunity),
      filter(({ options }) => options?.fetchSearches),
      map(({ opportunityId }) =>
        ExpertSourcesActions.queryExpertSourcesByOpportunity({
          opportunityId,
        })
      )
    );
  });

  getSearches$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityAPIActions.fetchOpportunitySuccess),
      filter(({ options }) => options?.fetchSearches),
      map(({ opportunity }) =>
        ExpertActions.fetchExpertSearches({
          query: {
            opportunityId: opportunity.opportunityId,
            searchIds: Object.keys(opportunity.searches),
            fromPhase: 'outreach',
          },
        })
      )
    );
  });

  constructor(
    private actions$: Actions,
    private opportunityExpertsService: OpportunityExpertsService
  ) {}
}
