<div class="screener-box" [class]="screenerConfig.status">

  <ng-container [ngSwitch]="screenerConfig.status">
    <mat-icon class="icon" fontSet="material-icons-outlined" *ngSwitchCase="'complete-near-pass-accepted'">
      thumb_up
    </mat-icon>
    <mat-icon class="icon" fontSet="material-icons-outlined" *ngSwitchCase="'complete-passed'">
      thumb_up
    </mat-icon>
    <mat-icon class="icon" fontSet="material-icons-outlined" *ngSwitchCase="'complete-failed'">
      thumb_down
    </mat-icon>
    <mat-icon class="icon" fontSet="material-icons-outlined" *ngSwitchCase="'complete-near-pass'">
      call_missed
    </mat-icon>
    <mat-icon class="icon" fontSet="material-icons-outlined" *ngSwitchCase="'complete'">
      pending
    </mat-icon>
    <mat-icon class="icon" fontSet="material-icons-outlined" *ngSwitchDefault>
      send
    </mat-icon>
  </ng-container>

  <div class="screener-info">
    <div>
      <span>Sent On:</span>
      {{screenerConfig.dateSent | date:'mediumDate'}}
      {{screenerConfig.dateSent | date:'shortTime'}}
    </div>
    <div *ngIf="screenerConfig.dateCompleted">
      <span>Completed On:</span>
      {{screenerConfig.dateCompleted | date:'mediumDate'}}
      {{screenerConfig.dateCompleted | date:'shortTime'}}
    </div>
  </div>

  <mat-icon *ngIf="screenerConfig.dateCompleted" class="eye">
    visibility
  </mat-icon>
  <mat-icon *ngIf="!screenerConfig.dateCompleted" class="eye">
    visibility_off
  </mat-icon>
</div>