export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  base: 'https://staging.connect.techspert.com',
  connectApiBaseUrl: 'https://staging.api.connect.techspert.io',
  searchApiBaseUrl: 'https://staging.api.search.techspert.io',
  searchApiBase2Url: 'https://staging.api.search.techspert.com',
  portalBase: 'https://staging.portal.techspert.com',
  expertPortalBase: 'https://staging.expert.techspert.com',
  salesforceBase: 'https://techspert--staging.sandbox.lightning.force.com',
  envName: 'stag',
  gaMeasurementId: 'G-10ZHLPBG1T',
  raygunApiKey: 'cf752SFgtikY142FE4YbA',
  auth0: {
    domain: 'staging.id.techspert.com',
    clientId: 'KulXpS3jNOJl7VM9KtRRXIcNMUe06RWr',
    authorizationParams: {
      connection: 'email',
      audience: 'https://staging.api.techspert.io',
      scope: 'openid profile email offline_access',
    },
  },
};
