import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shared-email-pill',
  templateUrl: './shared-email-pill.component.html',
})
export class SharedEmailPillComponent {
  @Input() email: string;
  @Input() poc?: boolean;
  @Input() selected: boolean = false;
}
