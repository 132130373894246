import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ConnectPhase,
  IDisplayExpert,
  IExpert,
} from '../models/experts.models';

export type ExpertsLoadingState = {
  searchId: string;
  opportunityId: string;
  phase: ConnectPhase;
  status: 'init' | 'partial' | 'loading' | 'loaded';
};

export interface IExpertState extends EntityState<IDisplayExpert> {
  totals: Record<string, Record<string, Record<ConnectPhase, number>>>;
  selectedOpportunityId: string | null;
  selectedExpertId: string | null;
  loadingState: ExpertsLoadingState[];
  isLoading: string[];
  loadError?: string;
  updateErrors?: string[];
}

const selectId = (a: IExpert) => a.expertId;

const sortComparer = (a: IExpert, b: IExpert) =>
  a.firstName.localeCompare(b.firstName) ||
  a.lastName.localeCompare(b.lastName) ||
  a.expertId.localeCompare(b.expertId);

export const expertsAdapter: EntityAdapter<IDisplayExpert> =
  createEntityAdapter<IDisplayExpert>({ selectId, sortComparer });
