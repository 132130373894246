<div class="flex flex-col gap-2.5">
  <div class="search-input">
    <mat-icon class="search-input-icon">search</mat-icon>
    <input *ngIf="!showLoader" class="search-input-input" placeholder="Search your projects..."
      (keyup)="filterOpportunities.emit(searchTerm)" [(ngModel)]="searchTerm"
      data-test-id="opportunity-list-search-input" />
  </div>
  <app-scintillating-loader class="card__loader" *ngIf="showLoader"></app-scintillating-loader>
  <mat-accordion data-test-id="dashboard-opportunity-list-opportunity-list">
    <app-opportunity-tile *ngFor="let opportunity of opportunities" [opportunity]="opportunity"
      data-test-id="opportunity-list-item">
    </app-opportunity-tile>
  </mat-accordion>
</div>