import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BtxSharedModule } from '../../shared/btx-shared.module';
import { OmnisearchIconsComponent } from './components/omnisearch-icons/omnisearch-icons.component';
import { OmnisearchResultComponent } from './components/omnisearch-result/omnisearch-result.component';
import { OmnisearchComponent } from './components/omnisearch/omnisearch.component';
import { CategoryPipe } from './pipes/category.pipe';
import { DetailsTooltipPipe } from './pipes/details-tooltip.pipe';
import { OmnisearchNamePipe } from './pipes/omnisearch-name.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SearchHighlightPipe } from './pipes/search-highlight.pipe';
import { UrlTooltipPipe } from './pipes/url-tooltip.pipe';
import { UrlPipe } from './pipes/url.pipe';

@NgModule({
  declarations: [
    OmnisearchComponent,
    OmnisearchResultComponent,
    UrlPipe,
    UrlTooltipPipe,
    DetailsTooltipPipe,
    SearchHighlightPipe,
    OmnisearchIconsComponent,
    CategoryPipe,
    OmnisearchNamePipe,
    SafeHtmlPipe,
  ],
  imports: [
    BtxSharedModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ScrollingModule,
  ],
  exports: [OmnisearchComponent],
})
export class OmnisearchModule {}
