<ng-container *ngIf="callActions">
  <table style="width: 100%" mat-table [dataSource]="callActions">
    <ng-container matColumnDef="internalName">
      <th mat-header-cell *matHeaderCellDef [style.width]="'35%'"> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.internalName}} </td>
    </ng-container>

    <ng-container matColumnDef="datetime">
      <th mat-header-cell *matHeaderCellDef [style.width]="'25%'"> Time </th>
      <td mat-cell *matCellDef="let element"> {{element.datetime | date:'long'}}</td>
    </ng-container>

    <ng-container matColumnDef="callType">
      <th mat-header-cell *matHeaderCellDef [style.width]="'15%'"> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.callType}} </td>
    </ng-container>

    <ng-container matColumnDef="emergencyContactEmail">
      <th mat-header-cell *matHeaderCellDef [style.width]="'25%'"> Emergency contact </th>
      <td mat-cell *matCellDef="let element"> {{element.emergencyContactEmail}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onClick(row)">
    </tr>
  </table>
</ng-container>
<ng-container *ngIf="!callActions || !callActions.length">
  <h3>No booked conferences</h3>
</ng-container>