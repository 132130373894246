import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDisplayExpert } from '@techspert-io/experts';
import { ISelectOpportunityDialogData } from '../../../../../admin-portal/search/models/search-models';
import { SelectOpportunityDialogComponent } from '../../../../../admin-portal/search/select-opportunity-dialog/select-opportunity-dialog.component';

@Directive({
  selector: 'button[appExpertClone]',
})
export class ExpertCloneDirective {
  @Input('appExpertClone') experts: IDisplayExpert[];
  @Input() opportunityId: string;

  constructor(private dialog: MatDialog) {}

  @HostListener('click')
  cloneExperts(): void {
    const expertIds = this.experts
      .filter((e) => e.isSelected)
      .map((e) => e.expertId);

    if (expertIds.length) {
      this.dialog.open<
        SelectOpportunityDialogComponent,
        ISelectOpportunityDialogData
      >(SelectOpportunityDialogComponent, {
        data: {
          selectedCount: expertIds.length,
          experts: [],
          dialogMode: false,
          query: {
            service: 'expert-clone',
            sourceOpportunityId: this.opportunityId,
            size: expertIds.length,
            expertIds,
          },
        },
        width: '600px',
        height: 'max-content',
      });
    }
  }
}
