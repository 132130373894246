import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromExpertSources from './expert-sources.reducer';
import { IExpertSourcesState } from './expert-sources.state';

const selectState = createFeatureSelector<IExpertSourcesState>('expertSources');

export const selectAllExpertSources = createSelector(selectState, (state) =>
  fromExpertSources.selectAllExpertSources(state)
);

export const selectCurrentOpportunityId = createSelector(
  selectState,
  fromExpertSources.getSelectedOpportunityId
);

export const selectCurrentExpertSourceId = createSelector(
  selectState,
  fromExpertSources.getSelectedExpertSourceId
);

export const selectExpertSourceEntities = createSelector(
  selectState,
  fromExpertSources.selectExpertSourcesEntities
);

export const selectCurrentExpertSource = createSelector(
  selectExpertSourceEntities,
  selectCurrentExpertSourceId,
  (entities, id) => entities[id]
);

export const selectExpertSourcesForCurrentOpportunity = createSelector(
  selectAllExpertSources,
  selectCurrentOpportunityId,
  (sources, id) => sources.filter((e) => e.opportunityId === id)
);

export const selectExpertSourcesLoadingState = createSelector(
  selectState,
  (state) => ({
    isLoaded: state.isLoaded,
    isLoading: state.isLoading,
    loadError: state.loadError,
    updateErrors: state.updateErrors,
  })
);
