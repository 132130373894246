import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditorChangeContent, QuillModules } from 'ngx-quill';

@Component({
  selector: 'app-textarea-editor',
  templateUrl: './textarea-editor.component.html',
  styleUrls: ['./textarea-editor.component.css'],
})
export class TextareaEditorComponent {
  @Input() editorValue = '';
  @Output() editorValueChange = new EventEmitter<string>();

  modules: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block', 'formula'],

      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],

      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ align: [] }],

      ['clean'],

      ['link'],
    ],
  };

  onChange(change: EditorChangeContent): void {
    this.editorValueChange.emit(change.html || '');
  }
}

@Component({
  selector: 'app-textarea-editor',
  template: `<span>Text Area</span>`,
})
export class TextareaEditorMockComponent {
  @Input() editorValue = '';
  @Output() editorValueChange = new EventEmitter<string>();
}
