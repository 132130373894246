import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { CognitoAuthService } from '@techspert-io/auth';
import { IOutreachTemplate } from '@techspert-io/outreachTemplates';
import { ITemplateFilter } from '../../send-solicitation-dialog.component';
import { SolicitationService } from '../../solicitation.service';

type TemplateVm = IOutreachTemplate & {
  cloneDisabled?: boolean;
  editDisabled?: boolean;
};

@Component({
  selector: 'app-template-menu',
  templateUrl: './template-menu.component.html',
  styleUrls: ['./template-menu.component.scss'],
})
export class TemplateMenuComponent implements OnInit, OnChanges {
  @Output() closeMenuSignal = new EventEmitter();
  @Output() openEditTemplateFormSignal: EventEmitter<IOutreachTemplate> =
    new EventEmitter();
  @Output() openNewTemplateFormSignal: EventEmitter<void> = new EventEmitter();
  @Output() openTemplateSignal: EventEmitter<IOutreachTemplate> =
    new EventEmitter();
  @Output() duplicateTemplateSignal: EventEmitter<IOutreachTemplate> =
    new EventEmitter();
  @Output() filterTemplatesSignal: EventEmitter<ITemplateFilter> =
    new EventEmitter();
  @Input() public filteredTemplates: TemplateVm[];
  @Input() public initSearchTerm: string = '';
  public adminName: string;
  public tabForDisplay: 'adminTemplates' | 'allAdminTemplates' =
    'allAdminTemplates';
  public searchTerm = '';

  masterTemplatePrefix = 'MASTER COPY:';
  emailTemplateAdmins = [
    'luke@techspert.io',
    'lydia@techspert.io',
    'faris@techspert.io',
  ];

  constructor(
    public cognitoAuthService: CognitoAuthService,
    public solicitationService: SolicitationService
  ) {}

  public ngOnInit(): void {
    this.searchTerm = this.initSearchTerm;
    this.adminName = `${this.cognitoAuthService.loggedInUser.firstName} ${this.cognitoAuthService.loggedInUser.lastName}`;
  }

  public ngOnChanges(): void {
    const isUserEmailTemplateAdmin = this.emailTemplateAdmins.includes(
      this.cognitoAuthService.loggedInUser.email
    );

    this.filteredTemplates = this.filteredTemplates.map((t) => ({
      ...t,
      cloneDisabled:
        this.tabForDisplay === 'adminTemplates' ||
        !`${t.title}`.toLowerCase().includes(this.initSearchTerm.toLowerCase()),
      editDisabled:
        !isUserEmailTemplateAdmin &&
        `${t.title}`.includes(this.masterTemplatePrefix),
    }));
  }

  public openTab(tab: 'adminTemplates' | 'allAdminTemplates'): void {
    this.tabForDisplay = tab;
    this.filterTemplatesSignal.emit({
      searchTerm: this.searchTerm,
      configuration: this.tabForDisplay,
    });
  }

  public filterTemplates({ value }: HTMLInputElement): void {
    this.searchTerm = value;
    this.filterTemplatesSignal.emit({
      searchTerm: this.searchTerm,
      configuration: this.tabForDisplay,
    });
  }

  public closeMenu(): void {
    this.closeMenuSignal.emit();
  }

  public emitOpenEditTemplateForm(template: IOutreachTemplate): void {
    this.openEditTemplateFormSignal.emit(template);
  }

  public emitOpenNewTemplateForm(): void {
    this.openNewTemplateFormSignal.emit();
  }

  public openTemplate(template: IOutreachTemplate): void {
    this.openTemplateSignal.emit(template);
  }

  public duplicateTemplate(template: IOutreachTemplate): void {
    this.duplicateTemplateSignal.emit(template);
  }
}
