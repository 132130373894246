import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface IGenerateExpertBioRequest {
  prompt: string;
  bio: string;
  name: string;
  model?: 'gpt-3.5-turbo' | 'gpt-3.5-turbo-16k' | 'gpt-4';
  topP?: number;
  temperature?: number;
}
export interface IGenerateExpertBioRequestRet {
  jobId: string;
}

export interface IGeneratedExpertBio {
  name: string;
  bio: string;
  isError?: boolean;
}

export interface IGetGeneratedExpertBiosRet {
  status: 'pending' | 'completed';
  bios?: IGeneratedExpertBio[];
}

@Injectable({
  providedIn: 'root',
})
export class ExpertsBioService {
  private readonly baseUrl = '/experts';
  private lastJobId: string;

  constructor(private http: HttpClient, private toastService: ToastService) {}

  generateBios(
    expertId: string,
    requests: IGenerateExpertBioRequest[]
  ): Observable<IGenerateExpertBioRequestRet> {
    return this.http
      .post<IGenerateExpertBioRequestRet>(
        `${this.baseUrl}/${expertId}/generateBio`,
        requests
      )
      .pipe(
        tap((ret) => (this.lastJobId = ret.jobId)),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            this.showErrorToast(err.error.message);
          } else {
            this.showErrorToast(err.message);
          }
          return throwError(err);
        })
      );
  }

  getGeneratedBios(
    expertId: string,
    jobId?: string
  ): Observable<IGetGeneratedExpertBiosRet> {
    if (!jobId && !this.lastJobId) {
      return of(undefined);
    }

    return this.http
      .get<IGetGeneratedExpertBiosRet>(
        `${this.baseUrl}/${expertId}/generateBio/${jobId || this.lastJobId}`
      )
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            this.showErrorToast(err.error.message);
          } else {
            this.showErrorToast(err.message);
          }
          return throwError(err);
        })
      );
  }

  private showErrorToast(message: string) {
    this.toastService.sendMessage(message, 'error');
  }
}
