import { Directive, HostListener, Input } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {
  @Input('copy-clipboard')
  public payload: string;

  constructor(private toastService: ToastService) {}

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent): Promise<void> {
    event.preventDefault();

    if (!this.payload) {
      return;
    }

    await navigator.clipboard.writeText(this.payload);

    this.toastService.sendMessage(
      `Successfully copied to clipboard`,
      'success'
    );
  }
}
