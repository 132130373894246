import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as CC from 'currency-codes';

@Injectable()
export class CurrencyService {
  currencyData: CC.CurrencyCodeRecord[];
  currencyCodes: string[] = CC.codes();

  constructor() {
    const priorityCodes = ['USD', 'GBP', 'EUR'];
    this.currencyData = [
      ...priorityCodes.map((cc) => CC.code(cc)),
      ...CC.data.filter((data) => !priorityCodes.includes(data.code)),
    ];
  }

  currencyFilter(value?: string): CC.CurrencyCodeRecord[] {
    const filterValue = (value || '').toLowerCase();
    return this.currencyData.filter((c) =>
      `${c.code}${c.currency}`.toLowerCase().includes(filterValue)
    );
  }

  validateCurrency(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return {
        missingCurrency: true,
      };
    }
    const found = this.currencyCodes.find((c) => c === control.value);
    return found
      ? null
      : {
          invalidCurrency: true,
        };
  }
}
