import { createReducer, on } from '@ngrx/store';
import { ClientAPIActions, ClientActions } from './clients.actions';
import { IClientState, clientsAdapter } from './clients.state';

export const initialState: IClientState = clientsAdapter.getInitialState({
  isLoading: [],
  selectedClientId: null,
});

export const clientsReducer = createReducer(
  initialState,
  on(
    ClientActions.fetchAllClients,
    (state): IClientState => ({
      ...state,
      loadError: null,
    })
  ),
  on(ClientAPIActions.fetchAllClientsSuccess, (state, { clients }) =>
    clientsAdapter.addMany(clients, state)
  ),
  on(ClientAPIActions.fetchAllClientsFailure, (state, { error }) => ({
    ...state,
    loadError: error.join(', '),
  })),
  on(ClientActions.fetchClient, (state, { clientId }) => ({
    ...state,
    isLoading: state.isLoading.concat(clientId),
    loadError: null,
  })),
  on(ClientAPIActions.fetchClientSuccess, (state, { client }) =>
    clientsAdapter.addOne(client, {
      ...state,
      selectedClientId: client.clientId,
      isLoading: state.isLoading.filter((id) => client.clientId !== id),
    })
  ),
  on(ClientAPIActions.fetchClientFailure, (state, { error, clientId }) => ({
    ...state,
    loadError: error.join(', '),
    isLoading: state.isLoading.filter((id) => clientId !== id),
  })),
  on(ClientActions.updateClient, (state, { client }) => ({
    ...state,
    isLoading: state.isLoading.concat(client.clientId),
    updateErrors: null,
  })),
  on(ClientAPIActions.updateClientSuccess, (state, { client }) =>
    clientsAdapter.upsertOne(client, {
      ...state,
      isLoading: state.isLoading.filter((id) => client.clientId !== id),
    })
  ),
  on(ClientAPIActions.updateClientFailure, (state, { error, clientId }) => ({
    ...state,
    updateErrors: error,
    isLoading: state.isLoading.filter((id) => clientId !== id),
  })),
  on(
    ClientAPIActions.buildContractReportForClientFailure,
    (state, { error, clientId }) => ({
      ...state,
      updateErrors: error,
      isLoading: state.isLoading.filter((id) => clientId !== id),
    })
  )
);

export const getSelectedClientId = (state: IClientState): string =>
  state.selectedClientId;

const { selectIds, selectEntities, selectAll, selectTotal } =
  clientsAdapter.getSelectors();

export const selectClientIds = selectIds;
export const selectClientEntities = selectEntities;
export const selectAllClients = selectAll;
export const selectClientTotal = selectTotal;
