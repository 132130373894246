<div class="ct-modalContainer flex flex-col gap-5">
  <div class="flex justify-between w-full flex-row">
    <h2>Send ({{selectedCount}}) experts to opportunity</h2>
    <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  </div>
  <div *ngIf="!data.dialogMode" class="flex flex-col gap-5">
    <div class="basicInput" *ngIf="clientsList$ | async as clientsList">
      <label class="basicInput__label">Select client</label>
      <input class="basicInput__input" (change)="assignClientAndGetOpportunities($event, clientsList)" list="clients"
        name="clients" data-test-id="select-opportunity-dialog-assign-client">
      <datalist id="clients" data-test-id="select-opportunity-dialog-select-client">
        <option *ngFor="let client of clientsList; let i = index;" [value]="client.clientName"
          [attr.data-test-id]="'select-opportunity-dialog-client-option'+ i">
      </datalist>
    </div>

    <div class="basicInput" *ngIf="opportunityList$ | async as opportunityList">
      <label class="basicInput__label">Select opportunity</label>
      <input class="basicInput__input" #OpportunityInput (change)="assignOpportunity($event, opportunityList)"
        list="opportunities" name="opportunities" data-test-id="select-opportunity-dialog-assign-opportunity">
      <datalist id="opportunities" data-test-id="select-opportunity-dialog-select-opportunity">
        <option *ngFor="let opportunity of opportunityList; let i = index;" [value]="opportunity.opportunityName"
          [attr.data-test-id]="'select-opportunity-dialog-opportunity-option'+ i">
      </datalist>
    </div>
  </div>
  <div class="basicInput">
    <label class="basicInput__label">Name search</label>
    <input class="basicInput__input" #SearchInput (change)="assignSearch($event)" list="searches" name="searches"
      data-test-id="select-opportunity-dialog-assign-search">
    <datalist id="searches" data-test-id="select-opportunity-dialog-select-search">
      <option *ngFor="let search of searchList" [value]="search">
    </datalist>
  </div>
  <div class="basicInput">
    <label class="basicInput__label">Expert segment</label>
    <select [(ngModel)]="segment" class="basicInput__input">
      <option value="">--Select segment--</option>
      <option *ngFor="let segment of segments; let i = index;" [value]="segment"
        [attr.data-test-id]="'select-opportunity-dialog-segment-option'+ i">
        {{ segment | expertSegment }}
      </option>
    </select>
  </div>
  <div class="basicInput">
    <label class="basicInput__label">Expert target</label>
    <select #ExpertTargetInput [disabled]="!opportunitySegments?.length" (change)="assignExpertTarget($event)"
      class="basicInput__input">
      <option value="">--Select geography / profile type--</option>
      <option [value]="target.id" *ngFor="let target of opportunitySegments; let i = index;"
        [attr.data-test-id]="'select-opportunity-dialog-target-option'+ i">
        {{target.geography}} - {{target.segment}} </option>
    </select>
  </div>
  <div class="basicInput" *ngIf="connectUsers$ | async as connectUsers">
    <label class="basicInput__label">Owner</label>
    <select [(ngModel)]="expertOwner" [compareWith]="expertOwnerCompare" class="basicInput__input">
      <option [ngValue]="user" *ngFor="let user of connectUsers; let i = index;"
        [attr.data-test-id]="'select-opportunity-dialog-user-option'+ i">
        {{user.firstName}} {{user.lastName}}
      </option>
    </select>
  </div>

  <div class="flex flex-row gap-5">
    <button class="button button--secondary" (click)="sendExperts('remain')" id="submitAndRemain"
      [ngStyle]="{'pointer-events': showSendAndRemainButtonLoader || showSendAndGoButtonLoader || showSendAndRemainButtonSuccessMessage ? 'none' : 'initial'}"
      [disabled]="!client || !opportunity || !newSearchName || !segment || !opportunitySegment"
      data-test-id="select-opportunity-dialog-send-and-remain">
      <span *ngIf="!showSendAndRemainButtonSuccessMessage">Send &amp; remain</span>
      <span *ngIf="showSendAndRemainButtonSuccessMessage">Sent!</span>
      <app-shared-btn-loader [loaderColor]="'black'" *ngIf="showSendAndRemainButtonLoader">
      </app-shared-btn-loader>
    </button>
    <button class="button button--primary" (click)="sendExperts('go')" id="submitAndGo"
      [ngStyle]="{'pointer-events': showSendAndRemainButtonLoader || showSendAndGoButtonLoader || showSendAndRemainButtonSuccessMessage ? 'none' : 'initial'}"
      [disabled]="!client || !opportunity || !newSearchName || !segment || !opportunitySegment"
      data-test-id="select-opportunity-dialog-send-and-go">
      <span>Send &amp; go</span>
      <app-shared-btn-loader *ngIf="showSendAndGoButtonLoader"></app-shared-btn-loader>
    </button>
  </div>
</div>