export enum EmailIdentityMethod {
  Email = 'email',
  Video = 'video',
  ProviderIdenfy = 'provider-idenfy',
  Behavioural = 'behavioural',
  Other = 'other',
}

export interface IEmailIdentity {
  emailIdentityId: string;
  emailAddress: string;
  method: EmailIdentityMethod;
  firstName: string;
  lastName: string;
  verifiedOtherEmail?: string;
  notes?: string;
  isDeleted: boolean;
  deletedBy?: string;
  deletedOn?: number;
  deletedReason?: string;
  dateCreated: number;
  createdBy: string;
}

export interface IEmailIdentityCreate
  extends Omit<
    IEmailIdentity,
    'emailIdentityId' | 'isDeleted' | 'dateCreated' | 'createdBy'
  > {
  emailIdentityId?: string;
  isDeleted?: boolean;
}

export interface IEmailIdentityUpdate extends Partial<IEmailIdentity> {
  emailIdentityId: string;
}

export interface IEmailIdentityDeletePayload {
  emailIdentityId: string;
  deletedReason: string;
}
