<form [formGroup]="searchFilterForm" class="search-filter-form flex gap-0.5">
  <section [formGroup]="countriesCtl" class="flex flex-col basis-[15%]">
    <div class="search-filter-header">Countries</div>

    <cdk-virtual-scroll-viewport itemSize="40" class="scroll-viewport">
      <div *cdkVirtualFor="let ctrl of countriesCtl.controls | keyvalue; trackBy: trackByFn" class="scroll-item">
        <mat-checkbox [formControl]="ctrl.value" labelPosition="before">
          <span class="search-filter-name">{{ctrl.key | uppercase}}</span>
          <span class="search-filter-count">{{countryCounts[ctrl.key] || 0 }}</span>
        </mat-checkbox>
      </div>
    </cdk-virtual-scroll-viewport>
  </section>

  <section [formGroup]="expertiseCtl" class="flex flex-col basis-full">
    <div class="search-filter-header">Expertise</div>

    <cdk-virtual-scroll-viewport #expertiseScroll itemSize="40" class="scroll-viewport">
      <div *cdkVirtualFor="let ctrl of expertiseCtl.controls | keyvalue; trackBy: trackByFn" class="scroll-item">
        <mat-checkbox [formControl]="ctrl.value" labelPosition="before">
          <span class="search-filter-name">{{ctrl.key | titlecase}} </span>
          <span class="search-filter-count">{{expertiseCounts[ctrl.key] || 0 }}</span>
        </mat-checkbox>
      </div>
    </cdk-virtual-scroll-viewport>
  </section>

  <section [formGroup]="affiliationsCtl" class="flex flex-col basis-full">
    <div class="search-filter-header">Affiliations</div>

    <cdk-virtual-scroll-viewport #affiliationsScroll itemSize="40" class="scroll-viewport">
      <div *cdkVirtualFor="let ctrl of affiliationsCtl.controls | keyvalue; trackBy: trackByFn" class="scroll-item">
        <mat-checkbox [formControl]="ctrl.value" labelPosition="before">
          <span class="search-filter-name">{{ctrl.key | titlecase}}</span>
          <span class="search-filter-count">{{affiliationsCounts[ctrl.key] || 0}}</span>
        </mat-checkbox>
      </div>
    </cdk-virtual-scroll-viewport>
  </section>
</form>