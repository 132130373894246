import { Pipe, PipeTransform } from '@angular/core';
import { MessageStatusMap } from '../../../shared/models/email';
import { ISolicitationHistory } from '../models/solicitation-outreach.models';

@Pipe({
  name: 'outreachStatus',
})
export class OutreachStatusPipe implements PipeTransform {
  transform(value: ISolicitationHistory['status']): unknown {
    const statusMap: Record<ISolicitationHistory['status'], string> = {
      [MessageStatusMap.connectUnsubscribed]: 'Connect: unsubscribed',
      [MessageStatusMap.connectBlockedDomain]: 'Connect: blocked domain',
      [MessageStatusMap.connectBounced]: 'Connect: bounced',
      [MessageStatusMap.connectPermissionRejected]:
        'Connect: permissions rejected',
      [MessageStatusMap.connectSendFailure]: 'Connect: send failure',
      [MessageStatusMap.connectError]: 'Connect: error',
      [MessageStatusMap.connectDeferred]: 'Connect: deferred',
      [MessageStatusMap.connectSendElapsed]: 'Connect: send elapsed',
      [MessageStatusMap.connectSent]: 'Connect: sent',
      [MessageStatusMap.providerProcessing]: 'Provider: processing',
      [MessageStatusMap.providerDropped]: 'Provider: dropped',
      [MessageStatusMap.providerDeferred]: 'Provider: deferred',
      [MessageStatusMap.providerBounce]: 'Provider: bounced',
      [MessageStatusMap.providerDelivered]: 'Provider: delivered',
      [MessageStatusMap.providerOpen]: 'Provider: open',
      [MessageStatusMap.providerClick]: 'Provider: click',
      [MessageStatusMap.providerSpam]: 'Provider: spam report',
      [MessageStatusMap.providerUnsubscribe]: 'Provider: unsubscribe',
      [MessageStatusMap.providerResubscribe]: 'Provider: resubscribe',
      [MessageStatusMap.userUnsubscribe]: 'User unsubscribed',
      cnPendingValidation: 'Pending validation',
      cnNotSentDuplicate: 'Duplicate',
      cnPendingSend: 'Pending send',
      cnNotSentUnengaged: 'Unengaged',
      cnNotSentLessEngaged: 'Less engaged',
      cnClientBlockedDomain: 'Client blocked domain',
      bounce: 'Bounced',
      spam: 'Spam',
      open: 'Open',
      delivered: 'Delivered',
      blocked: 'Blocked',
      rejected: 'Rejected',
      accepted: 'Accepted',
      ['send - Email type']: 'Processing',
    };
    return statusMap[value] || value;
  }
}
