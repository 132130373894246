import { Injectable } from '@angular/core';

export interface ISearchFilter {
  country: Record<string, boolean>;
  expertise: Record<string, boolean>;
  affiliations: Record<string, boolean>;
}

export interface ISearchScrolls {
  country: number;
  expertise: number;
  affiliations: number;
}

@Injectable()
export class ResultsDisplayFilterService {
  private filterInner: ISearchFilter = {
    country: {},
    expertise: {},
    affiliations: {},
  };
  private scrollsInner: ISearchScrolls = {
    country: 0,
    expertise: 0,
    affiliations: 0,
  };

  get filter(): ISearchFilter {
    return this.filterInner;
  }
  private set filter(val) {
    this.filterInner = val;
  }

  get scrolls(): ISearchScrolls {
    return this.scrollsInner;
  }
  private set scrolls(val) {
    this.scrollsInner = val;
  }

  set(filter: ISearchFilter): void {
    this.filter = filter;
  }

  setScroll(filter: keyof ISearchScrolls, value: number): void {
    this.scrolls = { ...this.scrolls, [filter]: value };
  }

  reset(): void {
    this.filter = {
      country: {},
      expertise: {},
      affiliations: {},
    };
    this.scrolls = {
      country: 0,
      expertise: 0,
      affiliations: 0,
    };
  }
}
