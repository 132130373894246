<div class="flex flex-col">
  <div class="flex justify-between w-full flex-row">
    <mat-card-title>Pod Members</mat-card-title>
    <div *ngIf="podMembers.length">
      <button mat-mini-fab color="primary" type="button" (click)="add()" data-test-id="pod-members-add-pod-member">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <table class="ct-noPaddingTable" mat-table [dataSource]="podMembers" data-test-id="pod-members-table">
    <ng-container [matColumnDef]="column" *ngFor="let column of podMembersColumns">
      <th mat-header-cell *matHeaderCellDef="let element" [style.width]="'50%'">
        {{element | podMembersColumn:column:true}}
      </th>
      <td mat-cell *matCellDef="let element" [attr.data-test-id]="'pod-members-table-' + column">
        {{element | podMembersColumn:column}}
      </td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" type="button"
          data-test-id="pod-members-pod-member-options-button">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="delete(element)" type="button"
            data-test-id="pod-members-pod-member-options-delete">
            <mat-icon>delete</mat-icon>
            <span data-test-id="pod-members-pod-member-options-delete-label">Delete</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="podMembersCells"></tr>
    <tr mat-row *matRowDef="let row; columns: podMembersCells;"
      [attr.data-test-id]="'pod-members-table-row-' + row.connectId"></tr>
  </table>

  <div *ngIf="!podMembers.length" class="flex flex-col">
    <div class="ct-tableEmptyState flex flex-col justify-center content-center items-center w-full gap-4 basis-full">
      <button mat-flat-button color="primary" type="button" (click)="add()"
        data-test-id="pod-members-add-first-pod-member">
        <span data-test-id="pod-members-add-first-pod-member-label">Add a Pod Member</span>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>