import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';

@Component({
  selector: 'app-dashboard-opportunity-list',
  templateUrl: './dashboard-opportunity-list.component.html',
  styleUrls: ['./dashboard-opportunity-list.component.scss'],
})
export class DashboardOpportunityListComponent {
  @Input() opportunities: IOpportunity[];
  @Input() showLoader: boolean = true;
  @Output() filterOpportunities = new EventEmitter<string>();

  searchTerm: string = '';
}
