import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOpportunities from './opportunities.reducer';
import { IOpportunityState } from './opportunities.state';

const selectState = createFeatureSelector<IOpportunityState>('opportunities');

export const selectAllOpportunities = createSelector(
  selectState,
  fromOpportunities.selectAllOpportunityies
);

export const selectOpportunityEntities = createSelector(
  selectState,
  fromOpportunities.selectOpportunityEntities
);

export const selectCurrentOpportunityId = createSelector(
  selectState,
  fromOpportunities.getSelectedOpportunityId
);

export const selectOpportunityLoadingState = createSelector(
  selectState,
  (state) => ({
    isLoaded: state.ids.some((s) => state.selectedOpportunityId === s),
    isLoading: state.isLoading.includes(state.selectedOpportunityId),
    loadError: state.loadError,
    updateErrors: state.updateErrors,
  })
);

export const selectCurrentOpportunity = createSelector(
  selectOpportunityEntities,
  selectCurrentOpportunityId,
  (opportunityEntities, opportunityId) =>
    opportunityId && opportunityEntities[opportunityId]
);
