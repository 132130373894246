<div class="flex flex-col">
  <div class="flex flex-1 justify-between">
    <mat-card-title>Segments</mat-card-title>
    <div>
      <button [disabled]="segmentSelection.isEmpty()" mat-mini-fab color="primary" (click)="bulkUpdateSegments()"
        data-test-id="opportunity-details-edit-segment" type="button">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>

  <table class="ct-noPaddingTable" mat-table [dataSource]="oppSegments"
    data-test-id="opportunity-details-segment-table">
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Quota </th>
      <td mat-cell *matCellDef="let element" data-test-id="opportunity-details-segment-amount">
        <span>{{element.amount}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="unitsPerEngagement">
      <th mat-header-cell *matHeaderCellDef> Unit Rate </th>
      <td mat-cell *matCellDef="let element" data-test-id="opportunity-details-segment-unitsPerEngagement">
        <span>{{element.unitsPerEngagement}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="segment">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element" data-test-id="opportunity-details-segment-segment">
        {{element.geography}} {{element.segment}}
      </td>
    </ng-container>

    <ng-container matColumnDef="conferenceTopic">
      <th mat-header-cell *matHeaderCellDef [style.width]="'20%'"> Topic </th>
      <td mat-cell *matCellDef="let element" data-test-id="opportunity-details-segment-topic">
        <span>{{element.conferenceTopic || 'NOT YET SET'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef [style.width]="'20%'"> owner </th>
      <td mat-cell *matCellDef="let element" data-test-id="opportunity-details-segment-owner">
        <span>{{element.owner.length ? element.owner : 'NOT YET SET' }} </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef> Select </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="segmentSelection.toggle(element)"
          [checked]="segmentSelection.isSelected(element)" data-test-id="opportunity-details-segment-select">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="oppSegmentsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: oppSegmentsColumns;" (click)="editSegment(row)"
      [attr.data-test-id]="'segment-form-table-segment-' + row.salesforceId"></tr>
  </table>

</div>