import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filenameFormat' })
export class FilenameFormatPipe implements PipeTransform {
  transform(filename: string): string {
    if (filename.includes('_')) {
      const [_, ...rest] = filename.split('_');

      return rest.join('_');
    }

    return filename;
  }
}
