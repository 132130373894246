<svg *ngIf="icon === 'opportunities'" width="34px" height="34px" viewBox="0 0 24 24" version="1.1">
  <g id="ic_opportunity-info" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle id="Oval-Copy-5" stroke="#B4BECB" stroke-width="2" cx="17" cy="15" r="5"></circle>
    <path d="M20,5 L6,5 L6,7 L20,7 L20,5 Z M4,5 L2,5 L2,7 L4,7 L4,5 Z" id="Combined-Shape-Copy" fill="#B4BECB"></path>
    <path d="M11,9 L6,9 L6,11 L11,11 L11,9 Z M4,9 L2,9 L2,11 L4,11 L4,9 Z" id="Combined-Shape-Copy-2" fill="#B4BECB">
    </path>
    <path d="M9,13 L6,13 L6,15 L9,15 L9,13 Z M4,13 L2,13 L2,15 L4,15 L4,13 Z" id="Combined-Shape-Copy-3" fill="#B4BECB">
    </path>
    <path d="M9,17 L6,17 L6,19 L9,19 L9,17 Z M4,17 L2,17 L2,19 L4,19 L4,17 Z" id="Combined-Shape-Copy-4" fill="#B4BECB">
    </path>
    <rect id="Rectangle" fill="#B4BECB" x="16" y="15" width="2" height="3"></rect>
    <circle id="Oval" fill="#B4BECB" cx="17" cy="13" r="1"></circle>
  </g>
</svg>

<svg *ngIf="icon === 'experts'" width="34px" height="34px" viewBox="0 0 24 24" version="1.1">
  <g id="ic_expert-info" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path
      d="M4,18 L4,17.35 C4,17.01 4.16,16.69 4.41,16.54 C6.1,15.53 8.03,15 10,15 C10.03,15 10.05,15 10.08,15.01 C10.18,14.31 10.38,13.64 10.67,13.03 C10.45,13.01 10.23,13 10,13 C7.58,13 5.32,13.67 3.39,14.82 C2.51,15.34 2,16.32 2,17.35 L2,20 L11.26,20 C10.84,19.4 10.51,18.72 10.29,18 L4,18 Z"
      id="Path" fill="#B4BECB"></path>
    <path
      d="M10,12 C12.21,12 14,10.21 14,8 C14,5.79 12.21,4 10,4 C7.79,4 6,5.79 6,8 C6,10.21 7.79,12 10,12 Z M10,6 C11.1,6 12,6.9 12,8 C12,9.1 11.1,10 10,10 C8.9,10 8,9.1 8,8 C8,6.9 8.9,6 10,6 Z"
      id="Shape" fill="#B4BECB"></path>
    <circle id="Oval-Copy-5" stroke="#B4BECB" stroke-width="2" cx="17" cy="16" r="5"></circle>
    <rect id="Rectangle" fill="#B4BECB" x="16" y="16" width="2" height="3"></rect>
    <circle id="Oval" fill="#B4BECB" cx="17" cy="14" r="1"></circle>
  </g>
</svg>

<mat-icon *ngIf="icon === 'solicitationExperts'">contact_mail</mat-icon>

<mat-icon *ngIf="icon === 'clients'">business</mat-icon>