<form [formGroup]="kickoffForm" (submit)="onSubmit()" class="flex flex-col gap-4">

  <mat-card>
    <mat-card-header>
      <mat-card-title class="pb-3" data-test-id="opportunity-details-project-type">
        Project Type: {{opportunity.projectType}}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col gap-1">
        <div class="flex flex-row">
          <p data-test-id="opportunity-details-start-date-label" class="basis-[120px]">Start Date:</p>
          <p data-test-id="opportunity-details-start-date">{{opportunity.startDate | date}}</p>
        </div>
        <div class="flex flex-row">
          <p data-test-id="opportunity-details-end-date-label" class="basis-[120px]">End Date:</p>
          <p data-test-id="opportunity-details-end-date">{{opportunity.closeDate | date}}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title class="pb-3">Settings</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <opp-settings-combined-form [isReadOnly]="isReadOnly"></opp-settings-combined-form>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="kickoffForm.value.projectType !== 'Quant' && !isReadOnly">
    <mat-card-header>
      <mat-card-title data-test-id="opportunity-settings-project-configuration-title">
        Project configuration
      </mat-card-title>
      <mat-card-subtitle>&nbsp;</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <opp-settings-form-input formControlName="settings" [defaultConferenceProvider]="defaultConferenceProvider">
      </opp-settings-form-input>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="isScreenerEnabled && kickoffForm.value.projectType === 'Qual' && !isReadOnly">
    <mat-card-header>
      <mat-card-title class="pb-3">Reuse Survey</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-clone-survey-form></app-clone-survey-form>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isReadOnly">
    <mat-card-header>
      <mat-card-title class="pb-3">Client-hosted conference link</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-client-hosted-url-form></app-client-hosted-url-form>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="kickoffForm.value.projectType === 'Quant'">
    <mat-card-header>
      <mat-card-title class="pb-3" data-test-id="survey-settings-title">Survey Settings</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <opp-survey-settings-form></opp-survey-settings-form>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="kickoffForm.value.projectType === 'Registration'">
    <mat-card-header>
      <mat-card-title>Redirect Link</mat-card-title>
    </mat-card-header>
    <mat-card-content formGroupName="redirectLinkConfig" class="flex flex-col gap-4">
      <mat-form-field class="flex-1">
        <mat-label>Redirect link</mat-label>
        <input matInput formControlName="link" placeholder="http://example.com">
        <mat-error> Required </mat-error>
        <mat-hint>The location to which the user will be redirected after registering</mat-hint>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <opp-client-contacts-input formControlName="clientContacts"
        [sendInviteEmailsByDefault]="opportunity.projectType === 'Qual'"
        [isReadOnly]="isReadOnly"></opp-client-contacts-input>
      <mat-error *ngIf="clientContactsCtrl.errors && isSubmitted" data-test-id="client-contacts-validation-error">
        <span *ngIf="clientContactsCtrl.errors.required">
          Client contacts are required
        </span>
        <span *ngIf="clientContactsCtrl.errors.hasDuplicates || clientContactsCtrl.errors.missingCountry">
          {{clientContactsCtrl.errors.hasDuplicates}}
          {{clientContactsCtrl.errors.missingCountry}}
        </span>
      </mat-error>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <opp-pod-members-input formControlName="opportunityAssignees">
      </opp-pod-members-input>
      <mat-error *ngIf="opportunityAssigneesCtrl.errors && isSubmitted"
        data-test-id="opportunity-details-project-managers-error">
        Pod members are required
      </mat-error>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <app-segment-form [isNDAssignment]="isReadOnly"></app-segment-form>
      <mat-error *ngIf="segmentsCtrl.errors?.segmentsOwnerValidator && isSubmitted"
        data-test-id="opportunity-details-segment-validation-error">
        {{segmentsCtrl.errors.segmentsOwnerValidator.join(', ')}}
      </mat-error>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!isReadOnly">
    <mat-card-header>
      <mat-card-title class="pb-3">Options</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-row flex-wrap gap-2.5">
        <mat-slide-toggle formControlName="createSlackChannel"
          matTooltip="Create a Slack Channel for the Opportunity and invite Pod Members"
          data-test-id="opportunity-details-options-create-slack-channel">
          <span data-test-id="slide-toggle-label">Create Slack Channels</span>
        </mat-slide-toggle>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="flex flex-1 justify-end gap-4">
    <mat-error *ngIf="isSubmitted && kickoffForm.invalid" data-test-id="opportunity-details-kick-off-errors">
      Form has errors
    </mat-error>
    <button mat-raised-button color="primary" [appOpportunityTimer]="opportunity.opportunityName" [disabled]="isLoading"
      type="submit" data-test-id="opportunity-details-save-start">
      {{ isReadOnly ? 'Save Opportunity' : 'Save and Start Opportunity' }}
    </button>

  </div>
</form>