import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { CognitoAuthService } from '@techspert-io/auth';
import {
  ClientActions,
  IClient,
  selectAllClients,
} from '@techspert-io/clients';
import {
  ExpertCallActionService,
  IExpertCallAction,
} from '@techspert-io/conferences';
import { ExpertActionStatusMap } from '@techspert-io/expert-actions';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ICalendarEvent } from '../../shared/components/shared-calendar/shared-calendar.component';
import {
  IViewConnectionInput,
  ViewConnectionComponent,
  viewConnectionDialogSize,
} from '../../shared/components/view-connection/view-connection.component';
import { IConferenceFilter } from './conference-filter/conference-filter.component';

export interface ICalendarCallEvent extends ICalendarEvent {
  extendedProps: IExpertCallAction;
}

@Component({
  selector: 'app-conference-management',
  templateUrl: './conference-management.component.html',
  styleUrls: ['./conference-management.component.scss'],
})
export class ConferenceManagementComponent implements OnInit {
  clients: IClient[] = [];
  calendarEvents: ICalendarCallEvent[] = [];
  displayCalendarEvents: ICalendarCallEvent[] = [];
  filterEvents$ = new BehaviorSubject<IConferenceFilter>(null);
  showLoader = true;

  constructor(
    public cognitoAuthService: CognitoAuthService,
    public dialog: MatDialog,
    private expertCallActionService: ExpertCallActionService,
    private store: Store
  ) {}

  ngOnInit(): void {
    const date = new Date();
    const offsetMonth = date.getMonth() - 2;
    const targetMonth = offsetMonth > 0 ? offsetMonth : 12 + offsetMonth;
    const targetYear =
      offsetMonth > 0 ? date.getFullYear() : date.getFullYear() - 1;

    this.store.dispatch(ClientActions.fetchAllClients());

    combineLatest([
      this.store.select(selectAllClients),
      this.expertCallActionService.queryCallActions({
        from: `${targetYear}-${
          targetMonth < 10 ? 0 : ''
        }${targetMonth}-01T00:00:00.000Z`,
        status: [
          ExpertActionStatusMap.Pending,
          ExpertActionStatusMap.Complete,
          ExpertActionStatusMap.CompleteConfirmed,
        ],
      }),
      this.filterEvents$,
    ])
      .pipe(
        tap(([clients, callActionResponse, filterData]) => {
          this.clients = clients;

          this.calendarEvents = callActionResponse.map((c) =>
            this.mapToCalendarEvent(c)
          );

          const loggedInUser = this.cognitoAuthService.loggedInUser;

          this.displayCalendarEvents = this.applyFilter(
            this.calendarEvents,
            filterData
          ).map((event) => {
            if (
              loggedInUser &&
              event.extendedProps.ownerUserId === loggedInUser.connectId
            ) {
              return event;
            }
            return {
              ...event,
              backgroundColor: '#ff4081',
              className: 'other-calendar-event',
            };
          });
        }),
        tap(() => (this.showLoader = false))
      )
      .subscribe();
  }

  public openViewConnectionDialog(event: ICalendarCallEvent): void {
    const dialogRef = this.dialog.open<
      ViewConnectionComponent,
      IViewConnectionInput
    >(ViewConnectionComponent, {
      ...viewConnectionDialogSize,
      data: { callAction: event.extendedProps },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  onFilterChange(filterData: IConferenceFilter): void {
    this.filterEvents$.next(filterData);
  }

  public eventDisplayChangeHandler(event: MatSelectChange): void {
    if (event.value === 'all') {
      this.displayCalendarEvents = this.calendarEvents;
    } else {
      this.displayCalendarEvents = this.calendarEvents.filter((e) => {
        return e.extendedProps?.clientId === event.value;
      });
    }
  }

  private applyFilter(
    events: ICalendarCallEvent[],
    filterData: IConferenceFilter
  ): ICalendarCallEvent[] {
    if (!filterData) {
      return events;
    }

    const { clientId, opportunityId, ownerUserId } = filterData;
    return events.filter((event) => {
      if (!clientId && !opportunityId && !ownerUserId) {
        return true;
      }

      return (
        (!clientId || event.extendedProps?.clientId === clientId) &&
        (!opportunityId ||
          event.extendedProps?.opportunityId === opportunityId) &&
        (!ownerUserId || event.extendedProps?.ownerUserId === ownerUserId)
      );
    });
  }

  private mapToCalendarEvent(
    callAction: IExpertCallAction
  ): ICalendarCallEvent {
    return {
      title: callAction.internalName || 'Conference X',
      date: callAction.datetime,
      id: callAction.expertActionId,
      extendedProps: callAction,
    };
  }
}
