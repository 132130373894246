import { Component, Input } from '@angular/core';
import { AppService } from '../../../../../../../shared/services/app.service';

@Component({
  selector: 'app-email-box',
  templateUrl: './email-box.component.html',
  styleUrls: ['./email-box.component.scss'],
})
export class EmailBoxComponent {
  @Input() emails: string[] = [];
  @Input() primaryEmail: string;

  constructor(public appService: AppService) {}
}
