import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@techspert-io/users';

@Component({
  selector: 'app-update-emergency-contact',
  template: `<div
    class="container"
    *ngIf="users$ | async as users"
    class="flex flex-col gap-3 p-6"
  >
    <div class="flex flex-row align-center">
      <h1 data-test-id="update-emergency-contact-title">
        Edit emergency contact
      </h1>
      <mat-icon [mat-dialog-close]>close</mat-icon>
    </div>

    <form
      [formGroup]="emergencyContactForm"
      (submit)="submit()"
      class="flex flex-col gap-2"
    >
      <mat-form-field appearance="outline">
        <mat-label
          data-test-id="update-emergency-contact-emergency-contact-label"
        >
          Emergency contact
        </mat-label>
        <mat-select
          formControlName="contact"
          data-test-id="update-emergency-contact-emergency-contact"
        >
          <mat-option
            *ngFor="let user of users"
            data-test-id="update-emergency-contact-emergency-contact-option"
            [value]="user.connectId"
          >
            {{ user.firstName }} {{ user.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="flex justify-end">
        <button
          mat-button
          type="submit"
          color="primary"
          data-test-id="emergency-contact-submit"
          [disabled]="!emergencyContactForm.valid"
        >
          Submit
        </button>
      </div>
    </form>
  </div>`,
  styles: [
    `
      .container {
        padding: 16px;
      }

      mat-icon {
        margin-left: auto;
        cursor: pointer;
      }

      mat-icon:hover {
        color: lightgrey;
      }
    `,
  ],
})
export class UpdateEmergencyContactComponent implements OnInit {
  emergencyContactForm = new FormGroup({
    contact: new FormControl(null, Validators.required),
  });

  users$ = this.userService.getAll({ userTypes: ['PM', 'ND', 'ND-Manager'] });

  constructor(
    @Inject(MAT_DIALOG_DATA) public emergencyContact: string,
    private dialogRef: MatDialogRef<UpdateEmergencyContactComponent>,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.emergencyContactForm.setValue({
      contact: this.emergencyContact || '',
    });
  }

  submit(): void {
    this.dialogRef.close(this.emergencyContactForm.get('contact').value);
  }
}
