<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav [opened]="sidebarOpen" [mode]="'side'">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="page">
      <mat-toolbar>
        <app-topbar [sidebarOpen]="sidebarOpen" (toggleFullScreenSidebar)="sidebarOpen = !sidebarOpen" class="w-full">
        </app-topbar>
      </mat-toolbar>
      <div class="page__content">
        <router-outlet class="appContainer"></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>