import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../shared/patterns/material-module';
import { NotificationActionsComponent } from './components/notification-actions/notification-actions.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsDialogComponent } from './components/notifications-dialog/notifications-dialog.component';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { NotificationService } from './services/notification.service';

@NgModule({
  imports: [RouterModule, CommonModule, MaterialModule],
  declarations: [
    NotificationsDialogComponent,
    NotificationComponent,
    MarkdownPipe,
    NotificationActionsComponent,
  ],
  exports: [
    NotificationsDialogComponent,
    NotificationComponent,
    NotificationActionsComponent,
  ],
})
export class NotificationsModule {
  static forRoot(): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [NotificationService],
    };
  }
}
