<ul class="emails">
  <li *ngFor="let recipient of solicitationService.recipients">
    <ng-container *ngFor="let email of recipient.emails">
      <span class="chip" data-test-id="chip-list-item">
        <span data-test-id="chip-list-item-text">
          {{email.address}}
        </span>
        <span *ngIf="recipient.type === 'unverified'" class="unverified-indicator">?</span>
        <mat-icon class="closebtn" (click)="removeFromList(recipient)" data-test-id="chip-list-item-remove">
          close
        </mat-icon>
      </span>
    </ng-container>
  </li>
</ul>