import { createReducer, on } from '@ngrx/store';
import { ConnectPhase } from '../models/experts.models';
import { ExpertAPIActions, ExpertActions } from './experts.actions';
import { IExpertState, expertsAdapter } from './experts.state';

export const initialState: IExpertState = expertsAdapter.getInitialState({
  isLoading: [],
  loadingState: [],
  totals: {},
  selectedExpertId: null,
  selectedOpportunityId: null,
});

export const expertsReducer = createReducer(
  initialState,
  on(ExpertActions.fetchExpertSearches, (state, { query }) => ({
    ...state,
    loadError: null,
    updateErrors: null,
    selectedOpportunityId: query.opportunityId,
    loadingState: query.searchIds.reduce((prev, searchId) => {
      const oldSearch = prev.find((s) => s.searchId === searchId);
      return prev
        .filter((s) => s.searchId !== searchId)
        .concat({
          searchId,
          opportunityId: query.opportunityId,
          phase: query.fromPhase,
          status:
            query.fromPhase === 'identified'
              ? 'loading'
              : oldSearch?.status || 'init',
        });
    }, state.loadingState),
  })),
  on(ExpertAPIActions.fetchExpertSearchesSuccess, (state, { response }) => {
    const searchIds = response.map((r) => r.searchId);
    const payloadTotals = response
      .flatMap((r) => ({
        searchId: r.searchId,
        totals: r.totals,
      }))
      .reduce<Record<string, Record<ConnectPhase, number>>>(
        (prev, curr) => ({ ...prev, [curr.searchId]: curr.totals }),
        state.totals[state.selectedOpportunityId] || {}
      );

    const outreachPhases: ConnectPhase[] = [
      'identified',
      'firstFollowUp',
      'secondFollowUp',
    ];
    return expertsAdapter.upsertMany(
      response.flatMap((e) =>
        e.experts.map((e) => ({
          ...e,
          isSelectable: !(
            e.campaignId && outreachPhases.includes(e.connectPhase)
          ),
        }))
      ),
      {
        ...state,
        loadingState: searchIds.reduce(
          (prev, searchId) =>
            prev.map((s) =>
              s.searchId === searchId
                ? {
                    ...s,
                    status:
                      s.phase === 'identified'
                        ? ('loaded' as const)
                        : ('partial' as const),
                  }
                : s
            ),
          state.loadingState
        ),
        totals: {
          ...state.totals,
          [state.selectedOpportunityId]: payloadTotals,
        },
      }
    );
  }),
  on(ExpertActions.fetchExpert, (state, { expertId }) => ({
    ...state,
    selectedExpertId: expertId,
    isLoading: state.isLoading.concat(expertId),
    loadError: null,
    updateErrors: null,
  })),
  on(ExpertAPIActions.fetchExpertSuccess, (state, { expert }) =>
    expertsAdapter.upsertOne(expert, {
      ...state,
      isLoading: state.isLoading.filter((id) => expert.expertId !== id),
    })
  ),
  on(ExpertAPIActions.fetchExpertFailure, (state, { error, expertId }) => ({
    ...state,
    loadError: error.join(', '),
    isLoading: state.isLoading.filter((id) => expertId !== id),
  })),
  on(ExpertActions.patchExpert, (state, { expert }) =>
    expertsAdapter.updateOne(
      {
        id: expert.expertId,
        changes: expert,
      },
      state
    )
  ),
  on(
    ExpertActions.createExperts,
    (state): IExpertState => ({
      ...state,
      loadError: null,
      updateErrors: [],
    })
  ),
  on(ExpertAPIActions.createExpertsSuccess, (state, { experts }) =>
    expertsAdapter.upsertMany(experts, {
      ...state,
    })
  ),
  on(ExpertAPIActions.createExpertsFailure, (state, { error }) => ({
    ...state,
    loadError: error.join(', '),
  })),
  on(
    ExpertActions.updateExpert,
    ExpertActions.blockExpert,
    (state, { update }) => ({
      ...state,
      isLoading: state.isLoading.concat(update.expertId),
      loadError: null,
      updateErrors: null,
    })
  ),
  on(
    ExpertAPIActions.updateExpertSuccess,
    ExpertAPIActions.blockExpertSuccess,
    (state, { expert }) =>
      expertsAdapter.upsertOne(expert, {
        ...state,
        isLoading: state.isLoading.filter((id) => expert.expertId !== id),
      })
  ),
  on(
    ExpertAPIActions.updateExpertFailure,
    ExpertAPIActions.blockExpertFailure,
    (state, { error, expertId }): IExpertState => ({
      ...state,
      updateErrors: error,
      isLoading: state.isLoading.filter((id) => expertId !== id),
    })
  ),
  on(ExpertActions.updateExperts, (state, { update }) => ({
    ...state,
    loadError: null,
    updateErrors: null,
    isLoading: state.isLoading.concat(update.map((e) => e.expertId)),
  })),
  on(
    ExpertAPIActions.updateExpertsSuccess,
    (state, { experts, resetIsSelected }) => {
      const expertIds = experts.map((e) => e.expertId);
      return expertsAdapter.updateMany(
        experts.map((u) => ({
          id: u.expertId,
          changes: {
            ...u,
            ...(resetIsSelected && { isSelected: false }),
          },
        })),
        {
          ...state,
          isLoading: state.isLoading.filter((id) => !expertIds.includes(id)),
        }
      );
    }
  ),
  on(
    ExpertAPIActions.updateExpertsFailure,
    (state, { error, expertIds }): IExpertState => ({
      ...state,
      updateErrors: error,
      isLoading: state.isLoading.filter((id) => !expertIds.includes(id)),
    })
  ),
  on(ExpertActions.setSelected, (state, { update }) =>
    expertsAdapter.updateMany(
      update.map((u) => ({
        id: u.expertId,
        changes: { isSelected: u.isSelected },
      })),
      state
    )
  )
);

export const getSelectedExpertId = (state: IExpertState): string =>
  state.selectedExpertId;
export const getSelectedOpportunityId = (state: IExpertState): string =>
  state.selectedOpportunityId;

const { selectIds, selectEntities, selectAll, selectTotal } =
  expertsAdapter.getSelectors();

export const selectExpertIds = selectIds;
export const selectExpertEntities = selectEntities;
export const selectAllExperts = selectAll;
export const selectExpertTotal = selectTotal;
