<form [formGroup]="ownersForm" (submit)="onSubmit()" data-test-id="segment-dialog">
  <h1 mat-dialog-title data-test-id="segment-dialog-segment-name"> {{segmentName}} </h1>

  <div mat-dialog-content>
    <form [formGroup]="ownersForm" class="flex flex-col gap-4">
      <opp-segment-owners-form [podMembersOptions]="assignees"></opp-segment-owners-form>
      <app-referral-fee-form></app-referral-fee-form>
    </form>
    <span>&nbsp;</span>
  </div>

  <div mat-dialog-actions>
    <div class="flex flex-1 justify-between">
      <button mat-raised-button mat-dialog-close data-test-id="segment-dialog-cancel">Close</button>
      <button mat-raised-button color="primary" type="submit" data-test-id="segment-dialog-submit">
        Update
      </button>
    </div>
  </div>
</form>