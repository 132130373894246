import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONNECT_API_BASE_URL } from './token';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(@Inject(CONNECT_API_BASE_URL) private baseUrl: string) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('http')) {
      return next.handle(request);
    }

    return next.handle(
      request.clone({
        url: `${this.baseUrl}${request.url}`,
      })
    );
  }
}
