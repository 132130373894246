import { Inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';

export const SALESFORCE_URL = new InjectionToken('PORTAL_URL');

@Pipe({
  name: 'salesforceLinks',
})
export class SalesforceLinksPipe implements PipeTransform {
  constructor(@Inject(SALESFORCE_URL) private salesforceUrl: string) {}

  public transform(opportunity: IOpportunity): string {
    const baseUrl = `${this.salesforceUrl}/lightning/r/Opportunity`;
    return `${baseUrl}/${opportunity.salesforceId}/view`;
  }
}
