import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IExpertCallAction } from '@techspert-io/conferences';

export interface IExtendedExpertCallAction extends IExpertCallAction {
  emergencyContactEmail: string;
}

@Component({
  selector: 'app-dashboard-upcoming-conferences',
  templateUrl: './dashboard-upcoming-conferences.component.html',
  styleUrls: ['./dashboard-upcoming-conferences.component.scss'],
})
export class DashboardUpcomingConferencesComponent {
  @Input() callActions: IExtendedExpertCallAction[] = [];
  @Input() showLoader = true;
  @Output() viewConnection = new EventEmitter<IExtendedExpertCallAction>();

  displayedColumns = [
    'internalName',
    'datetime',
    'callType',
    'emergencyContactEmail',
  ];

  onClick(callAction: IExtendedExpertCallAction): void {
    this.viewConnection.emit(callAction);
  }
}
