import { Pipe, PipeTransform } from '@angular/core';
import { IOpportunity } from '../models/opportunity.models';

@Pipe({
  name: 'opportunityName',
})
export class OpportunityNamePipe implements PipeTransform {
  transform(opportunity: IOpportunity): string {
    const prefix = opportunity.omnisearchHidden ? 'HIDDEN: ' : '';
    const postfix = opportunity.onHold ? ' [ON-HOLD]' : '';
    return `${prefix}${opportunity.opportunityName}${postfix}`;
  }
}
