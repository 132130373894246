import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ConnectPhase, IDisplayExpert, IExpert } from '@techspert-io/experts';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { OpportunityExpertsService } from '../services/opportunity-experts.service';

type SearchConfig = {
  searches: Set<string>;
};

@Component({
  selector: 'app-opportunity-top-bar',
  templateUrl: './opportunity-top-bar.component.html',
  styleUrls: ['./opportunity-top-bar.component.scss'],
})
export class OpportunityTopBarComponent implements OnInit, OnChanges {
  @Input() activePhase: string = 'identified';
  @Input() selectedSearches: string[] = [];
  @Output() selectedPhase = new EventEmitter<ConnectPhase>();

  private searchFilters$ = new BehaviorSubject<SearchConfig>({
    searches: new Set(this.selectedSearches),
  });
  phaseCounts$: Observable<Record<ConnectPhase | 'rejected', string>> = EMPTY;

  constructor(private opportunityExpertsService: OpportunityExpertsService) {}

  ngOnInit(): void {
    this.phaseCounts$ = combineLatest([
      this.opportunityExpertsService.expertTotals$,
      this.opportunityExpertsService.experts$,
      this.searchFilters$,
    ]).pipe(
      debounceTime(100),
      map(([totals, experts, filter]) => ({
        ...this.reduceTotals(totals, experts, filter),
        rejected: String(this.getRejected(experts, filter)),
      }))
    );
  }

  ngOnChanges(): void {
    this.searchFilters$.next({
      searches: new Set(this.selectedSearches),
    });
  }

  viewPhase(phase: ConnectPhase): void {
    this.selectedPhase.emit(phase);
  }

  noopSort(): number {
    return 0;
  }

  trackByFn(_: number, item: KeyValue<string, unknown>): string {
    return item.key;
  }

  private reduceTotals(
    totals: Record<string, Record<ConnectPhase, number>>,
    experts: IDisplayExpert[],
    filter: SearchConfig
  ): Record<ConnectPhase, string> {
    const searchCount = this.getCounts(
      this.filterRejected(experts).filter((e) => this.searchFilter(e, filter))
    );

    return this.buildDisplayCounts(this.buildCountMaps(totals, searchCount));
  }

  private buildDisplayCounts(
    counts: Record<ConnectPhase, { total: number; filtered: number }>
  ): Record<ConnectPhase, string> {
    return Object.entries(counts).reduce<Record<ConnectPhase, string>>(
      (prev, [key, val]) => ({
        ...prev,
        [key]: [val.filtered, val.total].filter((d) => d > -1).join(' / '),
      }),
      {
        identified: '0 / 0 / 0',
        firstFollowUp: '0 / 0 / 0',
        secondFollowUp: '0 / 0 / 0',
        outreachComplete: '0 / 0 / 0',
        outreach: '0 / 0',
        screener: '0 / 0',
        sentToClient: '0 / 0',
        accepted: '0 / 0',
        scheduled: '0 / 0',
        completed: '0 / 0',
      }
    );
  }

  private buildCountMaps(
    totals: Record<string, Record<ConnectPhase, number>>,
    searchCount: Record<ConnectPhase, number>
  ): Record<ConnectPhase, { total: number; filtered: number }> {
    return Object.values(totals).reduce(
      (prev, val) => ({
        ...prev,
        ...Object.entries(val).reduce(
          (p, [phase, count]) => ({
            ...p,
            [phase]: {
              total: prev[phase].total + (p[phase] || 0) + count,
              filtered: searchCount[phase],
            },
          }),
          {}
        ),
      }),
      {
        identified: { total: 0, filtered: 0 },
        firstFollowUp: { total: 0, filtered: 0 },
        secondFollowUp: { total: 0, filtered: 0 },
        outreachComplete: { total: 0, filtered: 0 },
        outreach: { total: 0, filtered: 0 },
        screener: { total: 0, filtered: 0 },
        sentToClient: { total: 0, filtered: 0 },
        accepted: { total: 0, filtered: 0 },
        scheduled: { total: 0, filtered: 0 },
        completed: { total: 0, filtered: 0 },
      }
    );
  }

  private getCounts(experts: IExpert[]): Record<ConnectPhase, number> {
    return experts.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.connectPhase]: (prev[curr.connectPhase] || 0) + 1,
      }),
      {
        identified: 0,
        firstFollowUp: 0,
        secondFollowUp: 0,
        outreachComplete: 0,
        outreach: 0,
        screener: 0,
        sentToClient: 0,
        accepted: 0,
        scheduled: 0,
        completed: 0,
      }
    );
  }

  private getRejected(experts: IDisplayExpert[], filter: SearchConfig): number {
    return experts
      .map(
        (expert) =>
          this.searchFilter(expert, filter) &&
          Object.values(expert.rejection).some(Boolean)
      )
      .filter((boolean) => boolean).length;
  }

  private filterRejected(experts: IExpert[]): IExpert[] {
    return experts.filter(
      (expert) =>
        Object.values(expert.rejection).every((r) => !r) && !expert.blocked
    );
  }

  private searchFilter(expert: IExpert, filter: SearchConfig): boolean {
    return filter.searches.has(expert.searchId);
  }
}
