export type EngagementType = 'call' | 'data-review' | 'survey' | 'payment';

export type EngagementPaymentProviders =
  | 'Arches'
  | 'BCC'
  | 'Dynata'
  | 'M3'
  | 'Opinion Health'
  | 'Reckner'
  | 'SHG'
  | 'JWW'
  | 'LYNK'
  | 'Healthcare Research Hub'
  | 'SIS International'
  | 'Transperfect'
  | 'Transcription Provider'
  | 'Techspert';

export type EngagementPaymentReasons =
  | 'Expert Payment Correction'
  | 'Additional Expert Payment'
  | 'Late Call Cancellation'
  | 'Late Call Rescheduling'
  | 'Processing Fee for Client Recruit'
  | 'Transcription Service'
  | 'Moderation'
  | 'In Project Referral'
  | 'Profile Update Payment'
  | 'Other';

export const paymentReasonsList: EngagementPaymentReasons[] = [
  'Expert Payment Correction',
  'Additional Expert Payment',
  'Late Call Cancellation',
  'Late Call Rescheduling',
  'Processing Fee for Client Recruit',
  'Transcription Service',
  'Moderation',
  'In Project Referral',
  'Profile Update Payment',
  'Other',
];

export interface IEngagementPaymentTypes {
  value: EngagementPaymentTypes;
  display: string;
}

export type EngagementPaymentTypes =
  | 'transferwise'
  | 'bankTransfer'
  | 'paypal'
  | 'cheque'
  | 'donateToCharity'
  | 'thirdParty';

export const engagementPaymentTypeSelectList: {
  value: EngagementPaymentTypes;
  display: string;
}[] = [
  { value: 'transferwise', display: 'Wise' },
  { value: 'bankTransfer', display: 'Bank transfer' },
  { value: 'paypal', display: 'Paypal' },
  { value: 'cheque', display: 'Cheque' },
  { value: 'donateToCharity', display: 'Donate to charity' },
  { value: 'thirdParty', display: 'Third party' },
];

export type PaymentStatus =
  | 'not-paid'
  | 'payment-sent'
  | 'payment-received'
  | 'not-claimed';

export interface IEngagement {
  engagementId?: string;
  engagementTitle: string;
  engagementType: EngagementType;
  /**ISO string.*/
  dateOfEngagement: string;
  paymentStatus: PaymentStatus;
  rate: number;
  quantityEngaged: number;
  unitsUsed: number;
  unitsUsedAdjustment: number;
  amountOwed: number;
  paymentType: EngagementPaymentTypes;
  notes: string;
  transactionId: string;
  currency: string;
  email: string;
  opportunityName: string;
  opportunityId: string;
  expertName: string;
  expertId: string;
  paymentActive: boolean;
  reason?: EngagementPaymentReasons;
  paymentProvider: EngagementPaymentProviders;
  dateCreated?: number;
  lastUpdated?: number;
  /**ISO string.*/
  lastAccepted: string;
}

export interface IExtendedPaymentEngagement extends IEngagement {
  owner: string;
}
