const timezoneLibrary = [
  {
    name: 'Europe/Belfast',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Europe/London',
    alphaTwoCode: 'GB',
    country: 'United Kingdom',
  },
  {
    name: 'GB',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Europe/London',
    alphaTwoCode: 'GB',
    country: 'United Kingdom',
  },
  {
    name: 'GB-Eire',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Europe/London',
    alphaTwoCode: 'GB',
    country: 'United Kingdom',
  },
  {
    name: 'Europe/London',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'GB',
    country: 'United Kingdom',
  },
  {
    name: 'America/Atka',
    utcOffset: -600,
    utcOffsetStr: '-10:00',
    dstOffset: -540,
    dstOffsetStr: '-09:00',
    aliasOf: 'America/Adak',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Aleutian',
    utcOffset: -600,
    utcOffsetStr: '-10:00',
    dstOffset: -540,
    dstOffsetStr: '-09:00',
    aliasOf: 'America/Adak',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Alaska',
    utcOffset: -540,
    utcOffsetStr: '-09:00',
    dstOffset: -480,
    dstOffsetStr: '-08:00',
    aliasOf: 'America/Anchorage',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Central',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Chicago',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Shiprock',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: 'America/Denver',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'Navajo',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: 'America/Denver',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Mountain',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: 'America/Denver',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Michigan',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Detroit',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indiana/Indianapolis',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Fort_Wayne',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indianapolis',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Fort_Wayne',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/East-Indiana',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Fort_Wayne',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Knox_IN',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Indiana/Knox',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Indiana-Starke',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Indiana/Knox',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Louisville',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Kentucky/Louisville',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Pacific',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: 'America/Los_Angeles',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Pacific-New',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: 'America/Los_Angeles',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Eastern',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/New_York',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Arizona',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: 'America/Phoenix',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'Pacific/Johnston',
    utcOffset: -600,
    utcOffsetStr: '-10:00',
    dstOffset: -600,
    dstOffsetStr: '-10:00',
    aliasOf: 'Pacific/Honolulu',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'US/Hawaii',
    utcOffset: -600,
    utcOffsetStr: '-10:00',
    dstOffset: -600,
    dstOffsetStr: '-10:00',
    aliasOf: 'Pacific/Honolulu',
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Adak',
    utcOffset: -600,
    utcOffsetStr: '-10:00',
    dstOffset: -540,
    dstOffsetStr: '-09:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Anchorage',
    utcOffset: -540,
    utcOffsetStr: '-09:00',
    dstOffset: -480,
    dstOffsetStr: '-08:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Boise',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Chicago',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Denver',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Detroit',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Fort_Wayne',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indiana/Knox',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indiana/Marengo',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indiana/Petersburg',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indiana/Tell_City',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indiana/Vevay',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indiana/Vincennes',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Indiana/Winamac',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Juneau',
    utcOffset: -540,
    utcOffsetStr: '-09:00',
    dstOffset: -480,
    dstOffsetStr: '-08:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Kentucky/Louisville',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Kentucky/Monticello',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Los_Angeles',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Menominee',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Metlakatla',
    utcOffset: -540,
    utcOffsetStr: '-09:00',
    dstOffset: -480,
    dstOffsetStr: '-08:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/New_York',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Nome',
    utcOffset: -540,
    utcOffsetStr: '-09:00',
    dstOffset: -480,
    dstOffsetStr: '-08:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/North_Dakota/Beulah',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/North_Dakota/Center',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/North_Dakota/New_Salem',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Phoenix',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Sitka',
    utcOffset: -540,
    utcOffsetStr: '-09:00',
    dstOffset: -480,
    dstOffsetStr: '-08:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'America/Yakutat',
    utcOffset: -540,
    utcOffsetStr: '-09:00',
    dstOffset: -480,
    dstOffsetStr: '-08:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'Pacific/Honolulu',
    utcOffset: -600,
    utcOffsetStr: '-10:00',
    dstOffset: -600,
    dstOffsetStr: '-10:00',
    aliasOf: null,
    alphaTwoCode: 'US',
    country: 'United States',
  },
  {
    name: 'Europe/Paris',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'FR',
    country: 'France',
  },
  {
    name: 'Europe/Busingen',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Zurich',
    alphaTwoCode: 'DE',
    country: 'Germany',
  },
  {
    name: 'Europe/Berlin',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'DE',
    country: 'Germany',
  },
  {
    name: 'Europe/Rome',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'IT',
    country: 'Italy',
  },
  {
    name: 'Africa/Ceuta',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'ES',
    country: 'Spain',
  },
  {
    name: 'Atlantic/Canary',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'ES',
    country: 'Spain',
  },
  {
    name: 'Europe/Madrid',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'ES',
    country: 'Spain',
  },
  {
    name: 'Asia/Kabul',
    utcOffset: 270,
    utcOffsetStr: '+04:30',
    dstOffset: 270,
    dstOffsetStr: '+04:30',
    aliasOf: null,
    alphaTwoCode: 'AF',
    country: 'Afghanistan',
  },
  {
    name: 'Europe/Mariehamn',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Europe/Helsinki',
    alphaTwoCode: 'AX',
    country: 'Åland Islands',
  },
  {
    name: 'Europe/Tirane',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'AL',
    country: 'Albania',
  },
  {
    name: 'Africa/Algiers',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'DZ',
    country: 'Algeria',
  },
  {
    name: 'Pacific/Samoa',
    utcOffset: -660,
    utcOffsetStr: '-11:00',
    dstOffset: -660,
    dstOffsetStr: '-11:00',
    aliasOf: 'Pacific/Pago_Pago',
    alphaTwoCode: 'AS',
    country: 'American Samoa',
  },
  {
    name: 'US/Samoa',
    utcOffset: -660,
    utcOffsetStr: '-11:00',
    dstOffset: -660,
    dstOffsetStr: '-11:00',
    aliasOf: 'Pacific/Pago_Pago',
    alphaTwoCode: 'AS',
    country: 'American Samoa',
  },
  {
    name: 'Pacific/Pago_Pago',
    utcOffset: -660,
    utcOffsetStr: '-11:00',
    dstOffset: -660,
    dstOffsetStr: '-11:00',
    aliasOf: null,
    alphaTwoCode: 'AS',
    country: 'American Samoa',
  },
  {
    name: 'Europe/Andorra',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'AD',
    country: 'AndorrA',
  },
  {
    name: 'Africa/Luanda',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'AO',
    country: 'Angola',
  },
  {
    name: 'America/Anguilla',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'AI',
    country: 'Anguilla',
  },
  {
    name: 'Antarctica/McMurdo',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 780,
    dstOffsetStr: '+13:00',
    aliasOf: 'Pacific/Auckland',
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/Casey',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/Davis',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/DumontDUrville',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/Mawson',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/Palmer',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/Rothera',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/Syowa',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/Troll',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'Antarctica/Vostok',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'AQ',
    country: 'Antarctica',
  },
  {
    name: 'America/Antigua',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'AG',
    country: 'Antigua and Barbuda',
  },
  {
    name: 'America/Buenos_Aires',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Argentina/Buenos_Aires',
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/ComodRivadavia',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Argentina/Catamarca',
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Catamarca',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Argentina/Catamarca',
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Cordoba',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Argentina/Cordoba',
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Rosario',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Argentina/Cordoba',
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Jujuy',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Argentina/Jujuy',
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Mendoza',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Argentina/Mendoza',
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Catamarca',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Cordoba',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Jujuy',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/La_Rioja',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Mendoza',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Salta',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/San_Juan',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/San_Luis',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Tucuman',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'America/Argentina/Ushuaia',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'AR',
    country: 'Argentina',
  },
  {
    name: 'Asia/Yerevan',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'AM',
    country: 'Armenia',
  },
  {
    name: 'America/Aruba',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Curacao',
    alphaTwoCode: 'AW',
    country: 'Aruba',
  },
  {
    name: 'Australia/South',
    utcOffset: 570,
    utcOffsetStr: '+09:30',
    dstOffset: 630,
    dstOffsetStr: '+10:30',
    aliasOf: 'Australia/Adelaide',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Queensland',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: 'Australia/Brisbane',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Yancowinna',
    utcOffset: 570,
    utcOffsetStr: '+09:30',
    dstOffset: 630,
    dstOffsetStr: '+10:30',
    aliasOf: 'Australia/Broken_Hill',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/North',
    utcOffset: 570,
    utcOffsetStr: '+09:30',
    dstOffset: 570,
    dstOffsetStr: '+09:30',
    aliasOf: 'Australia/Darwin',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Tasmania',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: 'Australia/Hobart',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/LHI',
    utcOffset: 630,
    utcOffsetStr: '+10:30',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: 'Australia/Lord_Howe',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Victoria',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: 'Australia/Melbourne',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/West',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Australia/Perth',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/ACT',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: 'Australia/Sydney',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Canberra',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: 'Australia/Sydney',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/NSW',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: 'Australia/Sydney',
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Antarctica/Macquarie',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Sydney',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Adelaide',
    utcOffset: 570,
    utcOffsetStr: '+09:30',
    dstOffset: 630,
    dstOffsetStr: '+10:30',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Brisbane',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Broken_Hill',
    utcOffset: 570,
    utcOffsetStr: '+09:30',
    dstOffset: 630,
    dstOffsetStr: '+10:30',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Currie',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Darwin',
    utcOffset: 570,
    utcOffsetStr: '+09:30',
    dstOffset: 570,
    dstOffsetStr: '+09:30',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Eucla',
    utcOffset: 525,
    utcOffsetStr: '+08:45',
    dstOffset: 525,
    dstOffsetStr: '+08:45',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Hobart',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Lord_Howe',
    utcOffset: 630,
    utcOffsetStr: '+10:30',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Lindeman',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Melbourne',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Australia/Perth',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'AU',
    country: 'Australia',
  },
  {
    name: 'Europe/Vienna',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'AT',
    country: 'Austria',
  },
  {
    name: 'Asia/Baku',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'AZ',
    country: 'Azerbaijan',
  },
  {
    name: 'America/Nassau',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'BS',
    country: 'Bahamas',
  },
  {
    name: 'Asia/Bahrain',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Asia/Qatar',
    alphaTwoCode: 'BH',
    country: 'Bahrain',
  },
  {
    name: 'Asia/Dacca',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: 'Asia/Dhaka',
    alphaTwoCode: 'BD',
    country: 'Bangladesh',
  },
  {
    name: 'Asia/Dhaka',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'BD',
    country: 'Bangladesh',
  },
  {
    name: 'America/Barbados',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'BB',
    country: 'Barbados',
  },
  {
    name: 'Europe/Minsk',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'BY',
    country: 'Belarus',
  },
  {
    name: 'Europe/Brussels',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'BE',
    country: 'Belgium',
  },
  {
    name: 'America/Belize',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'BZ',
    country: 'Belize',
  },
  {
    name: 'Africa/Porto-Novo',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'BJ',
    country: 'Benin',
  },
  {
    name: 'Atlantic/Bermuda',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BM',
    country: 'Bermuda',
  },
  {
    name: 'Asia/Thimbu',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: 'Asia/Thimphu',
    alphaTwoCode: 'BT',
    country: 'Bhutan',
  },
  {
    name: 'Asia/Thimphu',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'BT',
    country: 'Bhutan',
  },
  {
    name: 'America/La_Paz',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'BO',
    country: 'Bolivia',
  },
  {
    name: 'Europe/Sarajevo',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Belgrade',
    alphaTwoCode: 'BA',
    country: 'Bosnia and Herzegovina',
  },
  {
    name: 'Africa/Gaborone',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Maputo',
    alphaTwoCode: 'BW',
    country: 'Botswana',
  },
  {
    name: 'Brazil/West',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Manaus',
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'Brazil/DeNoronha',
    utcOffset: -120,
    utcOffsetStr: '-02:00',
    dstOffset: -120,
    dstOffsetStr: '-02:00',
    aliasOf: 'America/Noronha',
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Porto_Acre',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Rio_Branco',
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'Brazil/Acre',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Rio_Branco',
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'Brazil/East',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Sao_Paulo',
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Araguaina',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Bahia',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Belem',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Boa_Vista',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Campo_Grande',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Cuiaba',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Eirunepe',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Fortaleza',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Maceio',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Manaus',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Noronha',
    utcOffset: -120,
    utcOffsetStr: '-02:00',
    dstOffset: -120,
    dstOffsetStr: '-02:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Rio_Branco',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Porto_Velho',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Recife',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Santarem',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'America/Sao_Paulo',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'BR',
    country: 'Brazil',
  },
  {
    name: 'Indian/Chagos',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'IO',
    country: 'British Indian Ocean Territory',
  },
  {
    name: 'Asia/Brunei',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'BN',
    country: 'Brunei Darussalam',
  },
  {
    name: 'Europe/Sofia',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'BG',
    country: 'Bulgaria',
  },
  {
    name: 'Africa/Ouagadougou',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'BF',
    country: 'Burkina Faso',
  },
  {
    name: 'Africa/Bujumbura',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Maputo',
    alphaTwoCode: 'BI',
    country: 'Burundi',
  },
  {
    name: 'Asia/Phnom_Penh',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: 'Asia/Bangkok',
    alphaTwoCode: 'KH',
    country: 'Cambodia',
  },
  {
    name: 'Africa/Douala',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'CM',
    country: 'Cameroon',
  },
  {
    name: 'America/Coral_Harbour',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Atikokan',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Canada/Mountain',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: 'America/Edmonton',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Canada/Atlantic',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Halifax',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Canada/Saskatchewan',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: 'America/Regina',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Canada/Newfoundland',
    utcOffset: -210,
    utcOffsetStr: '-04:30',
    dstOffset: -150,
    dstOffsetStr: '-03:30',
    aliasOf: 'America/St_Johns',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Montreal',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Toronto',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Canada/Eastern',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Toronto',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Canada/Pacific',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: 'America/Vancouver',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Canada/Yukon',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: 'America/Whitehorse',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Canada/Central',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Winnipeg',
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Atikokan',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Blanc-Sablon',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Cambridge_Bay',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Creston',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Dawson_Creek',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Dawson',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Edmonton',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Fort_Nelson',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Glace_Bay',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Goose_Bay',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Halifax',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Inuvik',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Iqaluit',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Moncton',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Toronto',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Nipigon',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Pangnirtung',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Rainy_River',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Rankin_Inlet',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Regina',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Resolute',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/St_Johns',
    utcOffset: -210,
    utcOffsetStr: '-04:30',
    dstOffset: -150,
    dstOffsetStr: '-03:30',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Swift_Current',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Thunder_Bay',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Vancouver',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Whitehorse',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Winnipeg',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'America/Yellowknife',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'CA',
    country: 'Canada',
  },
  {
    name: 'Atlantic/Cape_Verde',
    utcOffset: -60,
    utcOffsetStr: '-01:00',
    dstOffset: -60,
    dstOffsetStr: '-01:00',
    aliasOf: null,
    alphaTwoCode: 'CV',
    country: 'Cape Verde',
  },
  {
    name: 'America/Cayman',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Panama',
    alphaTwoCode: 'KY',
    country: 'Cayman Islands',
  },
  {
    name: 'Africa/Bangui',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'CF',
    country: 'Central African Republic',
  },
  {
    name: 'Africa/Ndjamena',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'TD',
    country: 'Chad',
  },
  {
    name: 'Chile/Continental',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: 'America/Santiago',
    alphaTwoCode: 'CL',
    country: 'Chile',
  },
  {
    name: 'Chile/EasterIsland',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'Pacific/Easter',
    alphaTwoCode: 'CL',
    country: 'Chile',
  },
  {
    name: 'America/Punta_Arenas',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'CL',
    country: 'Chile',
  },
  {
    name: 'America/Santiago',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'CL',
    country: 'Chile',
  },
  {
    name: 'Pacific/Easter',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'CL',
    country: 'Chile',
  },
  {
    name: 'Asia/Chongqing',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Shanghai',
    alphaTwoCode: 'CN',
    country: 'China',
  },
  {
    name: 'Asia/Chungking',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Shanghai',
    alphaTwoCode: 'CN',
    country: 'China',
  },
  {
    name: 'Asia/Harbin',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Shanghai',
    alphaTwoCode: 'CN',
    country: 'China',
  },
  {
    name: 'PRC',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Shanghai',
    alphaTwoCode: 'CN',
    country: 'China',
  },
  {
    name: 'Asia/Kashgar',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: 'Asia/Urumqi',
    alphaTwoCode: 'CN',
    country: 'China',
  },
  {
    name: 'Asia/Shanghai',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'CN',
    country: 'China',
  },
  {
    name: 'Asia/Urumqi',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'CN',
    country: 'China',
  },
  {
    name: 'Etc/GMT-7',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: 'Indian/Christmas',
    alphaTwoCode: 'CX',
    country: 'Christmas Island',
  },
  {
    name: 'Indian/Christmas',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'CX',
    country: 'Christmas Island',
  },
  {
    name: 'Indian/Cocos',
    utcOffset: 390,
    utcOffsetStr: '+06:30',
    dstOffset: 390,
    dstOffsetStr: '+06:30',
    aliasOf: null,
    alphaTwoCode: 'CC',
    country: 'Cocos (Keeling) Islands',
  },
  {
    name: 'America/Bogota',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'CO',
    country: 'Colombia',
  },
  {
    name: 'Indian/Comoro',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'KM',
    country: 'Comoros',
  },
  {
    name: 'Africa/Brazzaville',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'CG',
    country: 'Congo',
  },
  {
    name: 'Africa/Kinshasa',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'CD',
    country: 'Congo',
  },
  {
    name: 'Africa/Lubumbashi',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Maputo',
    alphaTwoCode: 'CD',
    country: 'Congo',
  },
  {
    name: 'Pacific/Rarotonga',
    utcOffset: -600,
    utcOffsetStr: '-10:00',
    dstOffset: -600,
    dstOffsetStr: '-10:00',
    aliasOf: null,
    alphaTwoCode: 'CK',
    country: 'Cook Islands',
  },
  {
    name: 'America/Costa_Rica',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'CR',
    country: 'Costa Rica',
  },
  {
    name: 'Africa/Timbuktu',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'CI',
    country: "Cote D'Ivoire",
  },
  {
    name: 'Africa/Abidjan',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'CI',
    country: "Cote D'Ivoire",
  },
  {
    name: 'Europe/Zagreb',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Belgrade',
    alphaTwoCode: 'HR',
    country: 'Croatia',
  },
  {
    name: 'Cuba',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Havana',
    alphaTwoCode: 'CU',
    country: 'Cuba',
  },
  {
    name: 'America/Havana',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'CU',
    country: 'Cuba',
  },
  {
    name: 'Europe/Nicosia',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Asia/Nicosia',
    alphaTwoCode: 'CY',
    country: 'Cyprus',
  },
  {
    name: 'Asia/Famagusta',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'CY',
    country: 'Cyprus',
  },
  {
    name: 'Asia/Nicosia',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'CY',
    country: 'Cyprus',
  },
  {
    name: 'Europe/Prague',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'CZ',
    country: 'Czech Republic',
  },
  {
    name: 'Europe/Copenhagen',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'DK',
    country: 'Denmark',
  },
  {
    name: 'Africa/Djibouti',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'DJ',
    country: 'Djibouti',
  },
  {
    name: 'America/Dominica',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'DM',
    country: 'Dominica',
  },
  {
    name: 'America/Santo_Domingo',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'DO',
    country: 'Dominican Republic',
  },
  {
    name: 'America/Guayaquil',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'EC',
    country: 'Ecuador',
  },
  {
    name: 'Pacific/Galapagos',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'EC',
    country: 'Ecuador',
  },
  {
    name: 'Egypt',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Cairo',
    alphaTwoCode: 'EG',
    country: 'Egypt',
  },
  {
    name: 'Africa/Cairo',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'EG',
    country: 'Egypt',
  },
  {
    name: 'America/El_Salvador',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'SV',
    country: 'El Salvador',
  },
  {
    name: 'Africa/Malabo',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'GQ',
    country: 'Equatorial Guinea',
  },
  {
    name: 'Africa/Asmara',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'ER',
    country: 'Eritrea',
  },
  {
    name: 'Europe/Tallinn',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'EE',
    country: 'Estonia',
  },
  {
    name: 'Africa/Addis_Ababa',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'ET',
    country: 'Ethiopia',
  },
  {
    name: 'Atlantic/Stanley',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'FK',
    country: 'Falkland Islands (Malvinas)',
  },
  {
    name: 'Atlantic/Faeroe',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Atlantic/Faroe',
    alphaTwoCode: 'FO',
    country: 'Faroe Islands',
  },
  {
    name: 'Atlantic/Faroe',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'FO',
    country: 'Faroe Islands',
  },
  {
    name: 'Pacific/Fiji',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 780,
    dstOffsetStr: '+13:00',
    aliasOf: null,
    alphaTwoCode: 'FJ',
    country: 'Fiji',
  },
  {
    name: 'Europe/Helsinki',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'FI',
    country: 'Finland',
  },
  {
    name: 'America/Cayenne',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'GF',
    country: 'French Guiana',
  },
  {
    name: 'Pacific/Gambier',
    utcOffset: -540,
    utcOffsetStr: '-09:00',
    dstOffset: -540,
    dstOffsetStr: '-09:00',
    aliasOf: null,
    alphaTwoCode: 'PF',
    country: 'French Polynesia',
  },
  {
    name: 'Pacific/Marquesas',
    utcOffset: -570,
    utcOffsetStr: '-10:30',
    dstOffset: -570,
    dstOffsetStr: '-10:30',
    aliasOf: null,
    alphaTwoCode: 'PF',
    country: 'French Polynesia',
  },
  {
    name: 'Pacific/Tahiti',
    utcOffset: -600,
    utcOffsetStr: '-10:00',
    dstOffset: -600,
    dstOffsetStr: '-10:00',
    aliasOf: null,
    alphaTwoCode: 'PF',
    country: 'French Polynesia',
  },
  {
    name: 'Indian/Kerguelen',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'TF',
    country: 'French Southern Territories',
  },
  {
    name: 'Africa/Libreville',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'GA',
    country: 'Gabon',
  },
  {
    name: 'Africa/Banjul',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'GM',
    country: 'Gambia',
  },
  {
    name: 'Asia/Tbilisi',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'GE',
    country: 'Georgia',
  },
  {
    name: 'Africa/Accra',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'GH',
    country: 'Ghana',
  },
  {
    name: 'Europe/Gibraltar',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'GI',
    country: 'Gibraltar',
  },
  {
    name: 'Europe/Athens',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'GR',
    country: 'Greece',
  },
  {
    name: 'America/Danmarkshavn',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'GL',
    country: 'Greenland',
  },
  {
    name: 'America/Godthab',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -120,
    dstOffsetStr: '-02:00',
    aliasOf: null,
    alphaTwoCode: 'GL',
    country: 'Greenland',
  },
  {
    name: 'America/Scoresbysund',
    utcOffset: -60,
    utcOffsetStr: '-01:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'GL',
    country: 'Greenland',
  },
  {
    name: 'America/Thule',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'GL',
    country: 'Greenland',
  },
  {
    name: 'America/Grenada',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'GD',
    country: 'Grenada',
  },
  {
    name: 'America/Guadeloupe',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'GP',
    country: 'Guadeloupe',
  },
  {
    name: 'Pacific/Guam',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: null,
    alphaTwoCode: 'GU',
    country: 'Guam',
  },
  {
    name: 'America/Guatemala',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'GT',
    country: 'Guatemala',
  },
  {
    name: 'Europe/Guernsey',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Europe/London',
    alphaTwoCode: 'GG',
    country: 'Guernsey',
  },
  {
    name: 'Africa/Conakry',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'GN',
    country: 'Guinea',
  },
  {
    name: 'Africa/Bissau',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'GW',
    country: 'Guinea-Bissau',
  },
  {
    name: 'America/Guyana',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'GY',
    country: 'Guyana',
  },
  {
    name: 'America/Port-au-Prince',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'HT',
    country: 'Haiti',
  },
  {
    name: 'Europe/Vatican',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Rome',
    alphaTwoCode: 'VA',
    country: 'Holy See (Vatican City State)',
  },
  {
    name: 'America/Tegucigalpa',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'HN',
    country: 'Honduras',
  },
  {
    name: 'Hongkong',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Hong_Kong',
    alphaTwoCode: 'HK',
    country: 'Hong Kong',
  },
  {
    name: 'Asia/Hong_Kong',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'HK',
    country: 'Hong Kong',
  },
  {
    name: 'Europe/Budapest',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'HU',
    country: 'Hungary',
  },
  {
    name: 'Iceland',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Atlantic/Reykjavik',
    alphaTwoCode: 'IS',
    country: 'Iceland',
  },
  {
    name: 'Atlantic/Reykjavik',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'IS',
    country: 'Iceland',
  },
  {
    name: 'Asia/Calcutta',
    utcOffset: 330,
    utcOffsetStr: '+05:30',
    dstOffset: 330,
    dstOffsetStr: '+05:30',
    aliasOf: 'Asia/Kolkata',
    alphaTwoCode: 'IN',
    country: 'India',
  },
  {
    name: 'Asia/Kolkata',
    utcOffset: 330,
    utcOffsetStr: '+05:30',
    dstOffset: 330,
    dstOffsetStr: '+05:30',
    aliasOf: null,
    alphaTwoCode: 'IN',
    country: 'India',
  },
  {
    name: 'Asia/Ujung_Pandang',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Makassar',
    alphaTwoCode: 'ID',
    country: 'Indonesia',
  },
  {
    name: 'Asia/Jakarta',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'ID',
    country: 'Indonesia',
  },
  {
    name: 'Asia/Jayapura',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'ID',
    country: 'Indonesia',
  },
  {
    name: 'Asia/Makassar',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'ID',
    country: 'Indonesia',
  },
  {
    name: 'Asia/Pontianak',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'ID',
    country: 'Indonesia',
  },
  {
    name: 'Iran',
    utcOffset: 210,
    utcOffsetStr: '+03:30',
    dstOffset: 270,
    dstOffsetStr: '+04:30',
    aliasOf: 'Asia/Tehran',
    alphaTwoCode: 'IR',
    country: 'Iran',
  },
  {
    name: 'Asia/Tehran',
    utcOffset: 210,
    utcOffsetStr: '+03:30',
    dstOffset: 270,
    dstOffsetStr: '+04:30',
    aliasOf: null,
    alphaTwoCode: 'IR',
    country: 'Iran',
  },
  {
    name: 'Asia/Baghdad',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'IQ',
    country: 'Iraq',
  },
  {
    name: 'Eire',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Europe/Dublin',
    alphaTwoCode: 'IE',
    country: 'Ireland',
  },
  {
    name: 'Europe/Dublin',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'IE',
    country: 'Ireland',
  },
  {
    name: 'Europe/Isle_of_Man',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Europe/London',
    alphaTwoCode: 'IM',
    country: 'Isle of Man',
  },
  {
    name: 'Asia/Tel_Aviv',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Asia/Jerusalem',
    alphaTwoCode: 'IL',
    country: 'Israel',
  },
  {
    name: 'Israel',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Asia/Jerusalem',
    alphaTwoCode: 'IL',
    country: 'Israel',
  },
  {
    name: 'Asia/Jerusalem',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'IL',
    country: 'Israel',
  },
  {
    name: 'Jamaica',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Jamaica',
    alphaTwoCode: 'JM',
    country: 'Jamaica',
  },
  {
    name: 'America/Jamaica',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'JM',
    country: 'Jamaica',
  },
  {
    name: 'Japan',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: 'Asia/Tokyo',
    alphaTwoCode: 'JP',
    country: 'Japan',
  },
  {
    name: 'Asia/Tokyo',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'JP',
    country: 'Japan',
  },
  {
    name: 'Europe/Jersey',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Europe/London',
    alphaTwoCode: 'JE',
    country: 'Jersey',
  },
  {
    name: 'Asia/Amman',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'JO',
    country: 'Jordan',
  },
  {
    name: 'Asia/Almaty',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'KZ',
    country: 'Kazakhstan',
  },
  {
    name: 'Asia/Aqtau',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'KZ',
    country: 'Kazakhstan',
  },
  {
    name: 'Asia/Aqtobe',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'KZ',
    country: 'Kazakhstan',
  },
  {
    name: 'Asia/Atyrau',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'KZ',
    country: 'Kazakhstan',
  },
  {
    name: 'Asia/Oral',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'KZ',
    country: 'Kazakhstan',
  },
  {
    name: 'Asia/Qostanay',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'KZ',
    country: 'Kazakhstan',
  },
  {
    name: 'Asia/Qyzylorda',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'KZ',
    country: 'Kazakhstan',
  },
  {
    name: 'Africa/Asmera',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'KE',
    country: 'Kenya',
  },
  {
    name: 'Africa/Nairobi',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'KE',
    country: 'Kenya',
  },
  {
    name: 'Etc/GMT-12',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: 'Pacific/Tarawa',
    alphaTwoCode: 'KI',
    country: 'Kiribati',
  },
  {
    name: 'Pacific/Tarawa',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: null,
    alphaTwoCode: 'KI',
    country: 'Kiribati',
  },
  {
    name: 'Pacific/Enderbury',
    utcOffset: 780,
    utcOffsetStr: '+13:00',
    dstOffset: 780,
    dstOffsetStr: '+13:00',
    aliasOf: null,
    alphaTwoCode: 'KI',
    country: 'Kiribati',
  },
  {
    name: 'Pacific/Kiritimati',
    utcOffset: 840,
    utcOffsetStr: '+14:00',
    dstOffset: 840,
    dstOffsetStr: '+14:00',
    aliasOf: null,
    alphaTwoCode: 'KI',
    country: 'Kiribati',
  },
  {
    name: 'Asia/Pyongyang',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'KP',
    country: 'Korea',
  },
  {
    name: 'ROK',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: 'Asia/Seoul',
    alphaTwoCode: 'KR',
    country: 'Korea',
  },
  {
    name: 'Asia/Seoul',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'KR',
    country: 'Korea',
  },
  {
    name: 'Asia/Kuwait',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Asia/Riyadh',
    alphaTwoCode: 'KW',
    country: 'Kuwait',
  },
  {
    name: 'Asia/Bishkek',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'KG',
    country: 'Kyrgyzstan',
  },
  {
    name: 'Asia/Vientiane',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: 'Asia/Bangkok',
    alphaTwoCode: 'LA',
    country: "Lao People'S Democratic Republic",
  },
  {
    name: 'Europe/Riga',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'LV',
    country: 'Latvia',
  },
  {
    name: 'Asia/Beirut',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'LB',
    country: 'Lebanon',
  },
  {
    name: 'Africa/Maseru',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Johannesburg',
    alphaTwoCode: 'LS',
    country: 'Lesotho',
  },
  {
    name: 'Africa/Monrovia',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'LR',
    country: 'Liberia',
  },
  {
    name: 'Libya',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Tripoli',
    alphaTwoCode: 'LY',
    country: 'Libyan Arab Jamahiriya',
  },
  {
    name: 'Africa/Tripoli',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'LY',
    country: 'Libyan Arab Jamahiriya',
  },
  {
    name: 'Europe/Vaduz',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Zurich',
    alphaTwoCode: 'LI',
    country: 'Liechtenstein',
  },
  {
    name: 'Europe/Vilnius',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'LT',
    country: 'Lithuania',
  },
  {
    name: 'Europe/Luxembourg',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'LU',
    country: 'Luxembourg',
  },
  {
    name: 'Asia/Macao',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Macau',
    alphaTwoCode: 'MO',
    country: 'Macao',
  },
  {
    name: 'Asia/Macau',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'MO',
    country: 'Macao',
  },
  {
    name: 'Europe/Skopje',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Belgrade',
    alphaTwoCode: 'MK',
    country: 'Macedonia',
  },
  {
    name: 'Indian/Antananarivo',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'MG',
    country: 'Madagascar',
  },
  {
    name: 'Africa/Blantyre',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Maputo',
    alphaTwoCode: 'MW',
    country: 'Malawi',
  },
  {
    name: 'Singapore',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Kuala_Lumpur',
    alphaTwoCode: 'MY',
    country: 'Malaysia',
  },
  {
    name: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'MY',
    country: 'Malaysia',
  },
  {
    name: 'Asia/Kuching',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'MY',
    country: 'Malaysia',
  },
  {
    name: 'Indian/Maldives',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'MV',
    country: 'Maldives',
  },
  {
    name: 'Africa/Bamako',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'ML',
    country: 'Mali',
  },
  {
    name: 'Europe/Malta',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'MT',
    country: 'Malta',
  },
  {
    name: 'Kwajalein',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: 'Pacific/Kwajalein',
    alphaTwoCode: 'MH',
    country: 'Marshall Islands',
  },
  {
    name: 'Pacific/Kwajalein',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: null,
    alphaTwoCode: 'MH',
    country: 'Marshall Islands',
  },
  {
    name: 'Pacific/Majuro',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: null,
    alphaTwoCode: 'MH',
    country: 'Marshall Islands',
  },
  {
    name: 'America/Martinique',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'MQ',
    country: 'Martinique',
  },
  {
    name: 'Africa/Nouakchott',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'MR',
    country: 'Mauritania',
  },
  {
    name: 'Indian/Mauritius',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'MU',
    country: 'Mauritius',
  },
  {
    name: 'Indian/Mayotte',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'YT',
    country: 'Mayotte',
  },
  {
    name: 'Mexico/BajaSur',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: 'America/Mazatlan',
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'Mexico/General',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: 'America/Mexico_City',
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Ensenada',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: 'America/Tijuana',
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Santa_Isabel',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: 'America/Tijuana',
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'Mexico/BajaNorte',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: 'America/Tijuana',
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Bahia_Banderas',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Cancun',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Chihuahua',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Tijuana',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Hermosillo',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -420,
    dstOffsetStr: '-07:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Matamoros',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Mazatlan',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Merida',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Mexico_City',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Monterrey',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'America/Ojinaga',
    utcOffset: -420,
    utcOffsetStr: '-07:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'MX',
    country: 'Mexico',
  },
  {
    name: 'Pacific/Truk',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: 'Pacific/Chuuk',
    alphaTwoCode: 'FM',
    country: 'Micronesia',
  },
  {
    name: 'Pacific/Yap',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: 'Pacific/Chuuk',
    alphaTwoCode: 'FM',
    country: 'Micronesia',
  },
  {
    name: 'Pacific/Ponape',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: 'Pacific/Pohnpei',
    alphaTwoCode: 'FM',
    country: 'Micronesia',
  },
  {
    name: 'Pacific/Chuuk',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: null,
    alphaTwoCode: 'FM',
    country: 'Micronesia',
  },
  {
    name: 'Pacific/Kosrae',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'FM',
    country: 'Micronesia',
  },
  {
    name: 'Pacific/Pohnpei',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'FM',
    country: 'Micronesia',
  },
  {
    name: 'Europe/Tiraspol',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Europe/Chisinau',
    alphaTwoCode: 'MD',
    country: 'Moldova',
  },
  {
    name: 'Europe/Chisinau',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'MD',
    country: 'Moldova',
  },
  {
    name: 'Europe/Monaco',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'MC',
    country: 'Monaco',
  },
  {
    name: 'Asia/Ulan_Bator',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Ulaanbaatar',
    alphaTwoCode: 'MN',
    country: 'Mongolia',
  },
  {
    name: 'Asia/Choibalsan',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'MN',
    country: 'Mongolia',
  },
  {
    name: 'Asia/Hovd',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'MN',
    country: 'Mongolia',
  },
  {
    name: 'Asia/Ulaanbaatar',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'MN',
    country: 'Mongolia',
  },
  {
    name: 'America/Montserrat',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'MS',
    country: 'Montserrat',
  },
  {
    name: 'Africa/Casablanca',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'MA',
    country: 'Morocco',
  },
  {
    name: 'Africa/Maputo',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'MZ',
    country: 'Mozambique',
  },
  {
    name: 'Asia/Yangon',
    utcOffset: 390,
    utcOffsetStr: '+06:30',
    dstOffset: 390,
    dstOffsetStr: '+06:30',
    aliasOf: 'Asia/Rangoon',
    alphaTwoCode: 'MM',
    country: 'Myanmar',
  },
  {
    name: 'Asia/Rangoon',
    utcOffset: 390,
    utcOffsetStr: '+06:30',
    dstOffset: 390,
    dstOffsetStr: '+06:30',
    aliasOf: null,
    alphaTwoCode: 'MM',
    country: 'Myanmar',
  },
  {
    name: 'Africa/Windhoek',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'NA',
    country: 'Namibia',
  },
  {
    name: 'Pacific/Nauru',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: null,
    alphaTwoCode: 'NR',
    country: 'Nauru',
  },
  {
    name: 'Asia/Katmandu',
    utcOffset: 345,
    utcOffsetStr: '+05:45',
    dstOffset: 345,
    dstOffsetStr: '+05:45',
    aliasOf: 'Asia/Kathmandu',
    alphaTwoCode: 'NP',
    country: 'Nepal',
  },
  {
    name: 'Asia/Kathmandu',
    utcOffset: 345,
    utcOffsetStr: '+05:45',
    dstOffset: 345,
    dstOffsetStr: '+05:45',
    aliasOf: null,
    alphaTwoCode: 'NP',
    country: 'Nepal',
  },
  {
    name: 'Europe/Amsterdam',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'NL',
    country: 'Netherlands',
  },
  {
    name: 'Pacific/Noumea',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'NC',
    country: 'New Caledonia',
  },
  {
    name: 'Antarctica/South_Pole',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 780,
    dstOffsetStr: '+13:00',
    aliasOf: 'Pacific/Auckland',
    alphaTwoCode: 'NZ',
    country: 'New Zealand',
  },
  {
    name: 'NZ',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 780,
    dstOffsetStr: '+13:00',
    aliasOf: 'Pacific/Auckland',
    alphaTwoCode: 'NZ',
    country: 'New Zealand',
  },
  {
    name: 'NZ-CHAT',
    utcOffset: 765,
    utcOffsetStr: '+12:45',
    dstOffset: 825,
    dstOffsetStr: '+13:45',
    aliasOf: 'Pacific/Chatham',
    alphaTwoCode: 'NZ',
    country: 'New Zealand',
  },
  {
    name: 'Pacific/Auckland',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 780,
    dstOffsetStr: '+13:00',
    aliasOf: null,
    alphaTwoCode: 'NZ',
    country: 'New Zealand',
  },
  {
    name: 'Pacific/Chatham',
    utcOffset: 765,
    utcOffsetStr: '+12:45',
    dstOffset: 825,
    dstOffsetStr: '+13:45',
    aliasOf: null,
    alphaTwoCode: 'NZ',
    country: 'New Zealand',
  },
  {
    name: 'America/Managua',
    utcOffset: -360,
    utcOffsetStr: '-06:00',
    dstOffset: -360,
    dstOffsetStr: '-06:00',
    aliasOf: null,
    alphaTwoCode: 'NI',
    country: 'Nicaragua',
  },
  {
    name: 'Africa/Niamey',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Africa/Lagos',
    alphaTwoCode: 'NE',
    country: 'Niger',
  },
  {
    name: 'Africa/Lagos',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'NG',
    country: 'Nigeria',
  },
  {
    name: 'Pacific/Niue',
    utcOffset: -660,
    utcOffsetStr: '-11:00',
    dstOffset: -660,
    dstOffsetStr: '-11:00',
    aliasOf: null,
    alphaTwoCode: 'NU',
    country: 'Niue',
  },
  {
    name: 'Pacific/Norfolk',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: null,
    alphaTwoCode: 'NF',
    country: 'Norfolk Island',
  },
  {
    name: 'Pacific/Saipan',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: 'Pacific/Guam',
    alphaTwoCode: 'MP',
    country: 'Northern Mariana Islands',
  },
  {
    name: 'Atlantic/Jan_Mayen',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Oslo',
    alphaTwoCode: 'NO',
    country: 'Norway',
  },
  {
    name: 'Europe/Oslo',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'NO',
    country: 'Norway',
  },
  {
    name: 'Asia/Muscat',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: 'Asia/Dubai',
    alphaTwoCode: 'OM',
    country: 'Oman',
  },
  {
    name: 'Asia/Karachi',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'PK',
    country: 'Pakistan',
  },
  {
    name: 'Etc/GMT-9',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: 'Pacific/Palau',
    alphaTwoCode: 'PW',
    country: 'Palau',
  },
  {
    name: 'Pacific/Palau',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'PW',
    country: 'Palau',
  },
  {
    name: 'Asia/Gaza',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'PS',
    country: 'Palestinian Territory',
  },
  {
    name: 'Asia/Hebron',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'PS',
    country: 'Palestinian Territory',
  },
  {
    name: 'America/Panama',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'PA',
    country: 'Panama',
  },
  {
    name: 'Etc/GMT-10',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: 'Pacific/Port_Moresby',
    alphaTwoCode: 'PG',
    country: 'Papua New Guinea',
  },
  {
    name: 'Pacific/Port_Moresby',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: null,
    alphaTwoCode: 'PG',
    country: 'Papua New Guinea',
  },
  {
    name: 'Pacific/Bougainville',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'PG',
    country: 'Papua New Guinea',
  },
  {
    name: 'America/Asuncion',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'PY',
    country: 'Paraguay',
  },
  {
    name: 'America/Lima',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -300,
    dstOffsetStr: '-05:00',
    aliasOf: null,
    alphaTwoCode: 'PE',
    country: 'Peru',
  },
  {
    name: 'Asia/Manila',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'PH',
    country: 'Philippines',
  },
  {
    name: 'Pacific/Pitcairn',
    utcOffset: -480,
    utcOffsetStr: '-08:00',
    dstOffset: -480,
    dstOffsetStr: '-08:00',
    aliasOf: null,
    alphaTwoCode: 'PN',
    country: 'Pitcairn',
  },
  {
    name: 'Poland',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Warsaw',
    alphaTwoCode: 'PL',
    country: 'Poland',
  },
  {
    name: 'Europe/Warsaw',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'PL',
    country: 'Poland',
  },
  {
    name: 'Portugal',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: 'Europe/Lisbon',
    alphaTwoCode: 'PT',
    country: 'Portugal',
  },
  {
    name: 'Atlantic/Azores',
    utcOffset: -60,
    utcOffsetStr: '-01:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'PT',
    country: 'Portugal',
  },
  {
    name: 'Atlantic/Madeira',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'PT',
    country: 'Portugal',
  },
  {
    name: 'Europe/Lisbon',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'PT',
    country: 'Portugal',
  },
  {
    name: 'America/Puerto_Rico',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'PR',
    country: 'Puerto Rico',
  },
  {
    name: 'Asia/Qatar',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'QA',
    country: 'Qatar',
  },
  {
    name: 'Indian/Reunion',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'RE',
    country: 'Reunion',
  },
  {
    name: 'Europe/Bucharest',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'RO',
    country: 'Romania',
  },
  {
    name: 'W-SU',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Europe/Moscow',
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Anadyr',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Barnaul',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Chita',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Irkutsk',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Kamchatka',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Khandyga',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Magadan',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Novokuznetsk',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Novosibirsk',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Omsk',
    utcOffset: 360,
    utcOffsetStr: '+06:00',
    dstOffset: 360,
    dstOffsetStr: '+06:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Sakhalin',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Srednekolymsk',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Tomsk',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Ust-Nera',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Vladivostok',
    utcOffset: 600,
    utcOffsetStr: '+10:00',
    dstOffset: 600,
    dstOffsetStr: '+10:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Yakutsk',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Asia/Yekaterinburg',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Europe/Astrakhan',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Europe/Kaliningrad',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Europe/Kirov',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Europe/Moscow',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Europe/Samara',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Europe/Saratov',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Europe/Ulyanovsk',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Europe/Volgograd',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'RU',
    country: 'Russian Federation',
  },
  {
    name: 'Africa/Kigali',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Maputo',
    alphaTwoCode: 'RW',
    country: 'RWANDA',
  },
  {
    name: 'Atlantic/St_Helena',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'SH',
    country: 'Saint Helena',
  },
  {
    name: 'America/St_Kitts',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'KN',
    country: 'Saint Kitts and Nevis',
  },
  {
    name: 'America/St_Lucia',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'LC',
    country: 'Saint Lucia',
  },
  {
    name: 'America/Miquelon',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -120,
    dstOffsetStr: '-02:00',
    aliasOf: null,
    alphaTwoCode: 'PM',
    country: 'Saint Pierre and Miquelon',
  },
  {
    name: 'America/St_Vincent',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'VC',
    country: 'Saint Vincent and the Grenadines',
  },
  {
    name: 'Pacific/Apia',
    utcOffset: 780,
    utcOffsetStr: '+13:00',
    dstOffset: 840,
    dstOffsetStr: '+14:00',
    aliasOf: null,
    alphaTwoCode: 'WS',
    country: 'Samoa',
  },
  {
    name: 'Europe/San_Marino',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Rome',
    alphaTwoCode: 'SM',
    country: 'San Marino',
  },
  {
    name: 'Africa/Sao_Tome',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: null,
    alphaTwoCode: 'ST',
    country: 'Sao Tome and Principe',
  },
  {
    name: 'Asia/Riyadh',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'SA',
    country: 'Saudi Arabia',
  },
  {
    name: 'Africa/Dakar',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'SN',
    country: 'Senegal',
  },
  {
    name: 'Indian/Mahe',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'SC',
    country: 'Seychelles',
  },
  {
    name: 'Africa/Freetown',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'SL',
    country: 'Sierra Leone',
  },
  {
    name: 'Asia/Singapore',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Kuala_Lumpur',
    alphaTwoCode: 'SG',
    country: 'Singapore',
  },
  {
    name: 'Europe/Bratislava',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Prague',
    alphaTwoCode: 'SK',
    country: 'Slovakia',
  },
  {
    name: 'Europe/Ljubljana',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Belgrade',
    alphaTwoCode: 'SI',
    country: 'Slovenia',
  },
  {
    name: 'Pacific/Guadalcanal',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'SB',
    country: 'Solomon Islands',
  },
  {
    name: 'Africa/Mogadishu',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'SO',
    country: 'Somalia',
  },
  {
    name: 'Africa/Johannesburg',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'ZA',
    country: 'South Africa',
  },
  {
    name: 'Etc/GMT+2',
    utcOffset: -120,
    utcOffsetStr: '-02:00',
    dstOffset: -120,
    dstOffsetStr: '-02:00',
    aliasOf: 'Atlantic/South_Georgia',
    alphaTwoCode: 'GS',
    country: 'South Georgia and the South Sandwich Islands',
  },
  {
    name: 'Atlantic/South_Georgia',
    utcOffset: -120,
    utcOffsetStr: '-02:00',
    dstOffset: -120,
    dstOffsetStr: '-02:00',
    aliasOf: null,
    alphaTwoCode: 'GS',
    country: 'South Georgia and the South Sandwich Islands',
  },
  {
    name: 'Asia/Colombo',
    utcOffset: 330,
    utcOffsetStr: '+05:30',
    dstOffset: 330,
    dstOffsetStr: '+05:30',
    aliasOf: null,
    alphaTwoCode: 'LK',
    country: 'Sri Lanka',
  },
  {
    name: 'Africa/Khartoum',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'SD',
    country: 'Sudan',
  },
  {
    name: 'America/Paramaribo',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'SR',
    country: 'Suriname',
  },
  {
    name: 'Arctic/Longyearbyen',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Europe/Oslo',
    alphaTwoCode: 'SJ',
    country: 'Svalbard and Jan Mayen',
  },
  {
    name: 'Africa/Mbabane',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Johannesburg',
    alphaTwoCode: 'SZ',
    country: 'Swaziland',
  },
  {
    name: 'Europe/Stockholm',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'SE',
    country: 'Sweden',
  },
  {
    name: 'Europe/Zurich',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: null,
    alphaTwoCode: 'CH',
    country: 'Switzerland',
  },
  {
    name: 'Asia/Damascus',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'SY',
    country: 'Syrian Arab Republic',
  },
  {
    name: 'ROC',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: 'Asia/Taipei',
    alphaTwoCode: 'TW',
    country: 'Taiwan',
  },
  {
    name: 'Asia/Taipei',
    utcOffset: 480,
    utcOffsetStr: '+08:00',
    dstOffset: 480,
    dstOffsetStr: '+08:00',
    aliasOf: null,
    alphaTwoCode: 'TW',
    country: 'Taiwan',
  },
  {
    name: 'Asia/Dushanbe',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'TJ',
    country: 'Tajikistan',
  },
  {
    name: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'TZ',
    country: 'Tanzania',
  },
  {
    name: 'Asia/Bangkok',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'TH',
    country: 'Thailand',
  },
  {
    name: 'Asia/Dili',
    utcOffset: 540,
    utcOffsetStr: '+09:00',
    dstOffset: 540,
    dstOffsetStr: '+09:00',
    aliasOf: null,
    alphaTwoCode: 'TL',
    country: 'Timor-Leste',
  },
  {
    name: 'Africa/Lome',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 0,
    dstOffsetStr: '+00:00',
    aliasOf: 'Africa/Abidjan',
    alphaTwoCode: 'TG',
    country: 'Togo',
  },
  {
    name: 'Pacific/Fakaofo',
    utcOffset: 780,
    utcOffsetStr: '+13:00',
    dstOffset: 780,
    dstOffsetStr: '+13:00',
    aliasOf: null,
    alphaTwoCode: 'TK',
    country: 'Tokelau',
  },
  {
    name: 'Pacific/Tongatapu',
    utcOffset: 780,
    utcOffsetStr: '+13:00',
    dstOffset: 780,
    dstOffsetStr: '+13:00',
    aliasOf: null,
    alphaTwoCode: 'TO',
    country: 'Tonga',
  },
  {
    name: 'America/Virgin',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'TT',
    country: 'Trinidad and Tobago',
  },
  {
    name: 'America/Port_of_Spain',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'TT',
    country: 'Trinidad and Tobago',
  },
  {
    name: 'Africa/Tunis',
    utcOffset: 60,
    utcOffsetStr: '+01:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'TN',
    country: 'Tunisia',
  },
  {
    name: 'Asia/Istanbul',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Europe/Istanbul',
    alphaTwoCode: 'TR',
    country: 'Turkey',
  },
  {
    name: 'Turkey',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Europe/Istanbul',
    alphaTwoCode: 'TR',
    country: 'Turkey',
  },
  {
    name: 'Europe/Istanbul',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'TR',
    country: 'Turkey',
  },
  {
    name: 'Asia/Ashkhabad',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: 'Asia/Ashgabat',
    alphaTwoCode: 'TM',
    country: 'Turkmenistan',
  },
  {
    name: 'Asia/Ashgabat',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'TM',
    country: 'Turkmenistan',
  },
  {
    name: 'America/Grand_Turk',
    utcOffset: -300,
    utcOffsetStr: '-05:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'TC',
    country: 'Turks and Caicos Islands',
  },
  {
    name: 'Pacific/Funafuti',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: 'Pacific/Tarawa',
    alphaTwoCode: 'TV',
    country: 'Tuvalu',
  },
  {
    name: 'Africa/Kampala',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Africa/Nairobi',
    alphaTwoCode: 'UG',
    country: 'Uganda',
  },
  {
    name: 'Europe/Kiev',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'UA',
    country: 'Ukraine',
  },
  {
    name: 'Europe/Simferopol',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'UA',
    country: 'Ukraine',
  },
  {
    name: 'Europe/Uzhgorod',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'UA',
    country: 'Ukraine',
  },
  {
    name: 'Europe/Zaporozhye',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: null,
    alphaTwoCode: 'UA',
    country: 'Ukraine',
  },
  {
    name: 'Asia/Dubai',
    utcOffset: 240,
    utcOffsetStr: '+04:00',
    dstOffset: 240,
    dstOffsetStr: '+04:00',
    aliasOf: null,
    alphaTwoCode: 'AE',
    country: 'United Arab Emirates',
  },
  {
    name: 'Pacific/Midway',
    utcOffset: -660,
    utcOffsetStr: '-11:00',
    dstOffset: -660,
    dstOffsetStr: '-11:00',
    aliasOf: 'Pacific/Pago_Pago',
    alphaTwoCode: 'UM',
    country: 'United States Minor Outlying Islands',
  },
  {
    name: 'Pacific/Wake',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: 'Pacific/Tarawa',
    alphaTwoCode: 'UM',
    country: 'United States Minor Outlying Islands',
  },
  {
    name: 'America/Montevideo',
    utcOffset: -180,
    utcOffsetStr: '-03:00',
    dstOffset: -180,
    dstOffsetStr: '-03:00',
    aliasOf: null,
    alphaTwoCode: 'UY',
    country: 'Uruguay',
  },
  {
    name: 'Asia/Samarkand',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'UZ',
    country: 'Uzbekistan',
  },
  {
    name: 'Asia/Tashkent',
    utcOffset: 300,
    utcOffsetStr: '+05:00',
    dstOffset: 300,
    dstOffsetStr: '+05:00',
    aliasOf: null,
    alphaTwoCode: 'UZ',
    country: 'Uzbekistan',
  },
  {
    name: 'Pacific/Efate',
    utcOffset: 660,
    utcOffsetStr: '+11:00',
    dstOffset: 660,
    dstOffsetStr: '+11:00',
    aliasOf: null,
    alphaTwoCode: 'VU',
    country: 'Vanuatu',
  },
  {
    name: 'America/Caracas',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: null,
    alphaTwoCode: 'VE',
    country: 'Venezuela',
  },
  {
    name: 'Asia/Saigon',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: 'Asia/Ho_Chi_Minh',
    alphaTwoCode: 'VN',
    country: 'Viet Nam',
  },
  {
    name: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    utcOffsetStr: '+07:00',
    dstOffset: 420,
    dstOffsetStr: '+07:00',
    aliasOf: null,
    alphaTwoCode: 'VN',
    country: 'Viet Nam',
  },
  {
    name: 'America/Tortola',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'VG',
    country: 'Virgin Islands',
  },
  {
    name: 'America/St_Thomas',
    utcOffset: -240,
    utcOffsetStr: '-04:00',
    dstOffset: -240,
    dstOffsetStr: '-04:00',
    aliasOf: 'America/Port_of_Spain',
    alphaTwoCode: 'VI',
    country: 'Virgin Islands',
  },
  {
    name: 'Pacific/Wallis',
    utcOffset: 720,
    utcOffsetStr: '+12:00',
    dstOffset: 720,
    dstOffsetStr: '+12:00',
    aliasOf: 'Pacific/Tarawa',
    alphaTwoCode: 'WF',
    country: 'Wallis and Futuna',
  },
  {
    name: 'Africa/El_Aaiun',
    utcOffset: 0,
    utcOffsetStr: '+00:00',
    dstOffset: 60,
    dstOffsetStr: '+01:00',
    aliasOf: null,
    alphaTwoCode: 'EH',
    country: 'Western Sahara',
  },
  {
    name: 'Asia/Aden',
    utcOffset: 180,
    utcOffsetStr: '+03:00',
    dstOffset: 180,
    dstOffsetStr: '+03:00',
    aliasOf: 'Asia/Riyadh',
    alphaTwoCode: 'YE',
    country: 'Yemen',
  },
  {
    name: 'Africa/Lusaka',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Maputo',
    alphaTwoCode: 'ZM',
    country: 'Zambia',
  },
  {
    name: 'Africa/Harare',
    utcOffset: 120,
    utcOffsetStr: '+02:00',
    dstOffset: 120,
    dstOffsetStr: '+02:00',
    aliasOf: 'Africa/Maputo',
    alphaTwoCode: 'ZW',
    country: 'Zimbabwe',
  },
];
export default timezoneLibrary;
