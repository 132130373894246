<div class="side-dialog">
  <div class="top">
    <div class="flex justify-between content-center items-center flex-row">
      <h1 class="txp-h1">Engagement details</h1>
      <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
    <div class="basicInput">
      <mat-form-field appearance="outline" class="basis-1/2">
        <mat-label>Transaction Id</mat-label>
        <input matInput [(ngModel)]="engagement.transactionId" type="text">
      </mat-form-field>
    </div>
    <div class="basicInput" *ngIf="engagement.engagementType === 'payment'">
      <mat-form-field appearance="fill" class="basis-[23%]">
        <mat-label>Payment Reason</mat-label>
        <mat-select [(ngModel)]="engagement.reason">
          <mat-option *ngFor="let reason of paymentReasons" [value]="reason">
            {{reason}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="basicInput">
      <mat-form-field appearance="outline" class="basis-1/2">
        <mat-label>Notes</mat-label>
        <textarea matInput [(ngModel)]="engagement.notes"
          placeholder="Please add supporting notes here, including the chosen charity name, if funds are being donated."></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="bottom">
    <div class="copy-ids">
      <p [copy-clipboard]="engagement.engagementId">
        <span>Engagement id:</span>
        <a>
          {{engagement.engagementId}}
        </a>
      </p>
      <p [copy-clipboard]="engagement.expertId">
        <span>Expert id:</span>
        <a>
          {{engagement.expertId}}
        </a>
      </p>
      <p [copy-clipboard]="engagement.opportunityId">
        <span>Opportunity id:</span>
        <a>
          {{engagement.opportunityId}}
        </a>
      </p>
    </div>
    <div class="button-group">
      <button class="button" (click)="closeDialog()">Close</button>
      <button class="button" (click)="saveChanges()">Save changes</button>
    </div>
  </div>
</div>