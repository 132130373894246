import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expertNameSalesMode',
})
export class ExpertNameMaskedModePipe implements PipeTransform {
  transform(
    { firstName, lastName }: { firstName: string; lastName: string },
    salesMode: boolean
  ): string {
    const name = [firstName, lastName];

    return salesMode
      ? name
          .filter(Boolean)
          .map((n) => n.charAt(0))
          .join(' ')
      : name.join(' ');
  }
}
