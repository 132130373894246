import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { CurrencyService } from '../../../../../shared/services/currency.service';

@Component({
  selector: 'app-referral-fee-form',
  templateUrl: './referral-fee-form.component.html',
  styleUrls: ['./referral-fee-form.component.scss'],
})
export class ReferralFeeFormComponent {
  currencies = this.currencyService.currencyData.map((c) => c.code);

  get referralForm(): FormGroup {
    return this.rootFormGroup.form;
  }

  constructor(
    private rootFormGroup: FormGroupDirective,
    private currencyService: CurrencyService
  ) {}
}
