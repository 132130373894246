import { Component, Input } from '@angular/core';
import { IExpertAvailabilitiesActionTimeSlot } from '@techspert-io/expert-actions';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-availability-box',
  templateUrl: './availability-box.component.html',
  styleUrls: ['./availability-box.component.scss'],
})
export class AvailabilityBoxComponent {
  @Input() availabilities: IExpertAvailabilitiesActionTimeSlot[];
  @Input() callTime: string;
  @Input() timezoneName: string;
  @Input() requestedAnotherTime?: boolean;
  public moment = moment;
}
