import { Pipe, PipeTransform } from '@angular/core';
import { ExpertScreenerActionStatusMap } from '../models/expertScreenerActions.models';

@Pipe({
  name: 'screenerStatus',
})
export class ScreenerStatusPipe implements PipeTransform {
  transform(value: ExpertScreenerActionStatusMap): string {
    const statusMap: Record<ExpertScreenerActionStatusMap, string> = {
      [ExpertScreenerActionStatusMap.Voided]: 'Voided',
      [ExpertScreenerActionStatusMap.Pending]: 'Pending',
      [ExpertScreenerActionStatusMap.Complete]: 'Completed',
      [ExpertScreenerActionStatusMap.CompletePassed]: 'Passed',
      [ExpertScreenerActionStatusMap.CompleteFailed]: 'Failed',
      [ExpertScreenerActionStatusMap.CompleteNearPass]: 'Near Pass',
      [ExpertScreenerActionStatusMap.CompleteNearPassAccepted]: 'Accepted',
    };
    return statusMap[value] || 'Unknown';
  }
}
