import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject } from '@angular/core';
import { Raygun, RAY_GUN } from './tokens';

export class RaygunErrorHandler implements ErrorHandler {
  constructor(@Inject(RAY_GUN) private raygun: Raygun) {}

  handleError(e: Error): void {
    console.error(e);

    if (e instanceof HttpErrorResponse) {
      this.raygun('send', {
        error: new Error(e.message),
        customData: e,
      });
    } else if (e instanceof Error) {
      if (!e.message.startsWith('ResizeObserver')) {
        this.raygun('send', {
          error: e,
        });
      }
    } else {
      this.raygun('send', {
        error: new Error('Unknown Error'),
        customData: e,
      });
    }
  }
}
