import { Directive, Input, OnInit } from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Directive({
  selector: '[appSchedulingCallTimer]',
})
export class SchedulingCallTimerDirective implements OnInit {
  @Input('appSchedulingCallTimer') opportunity: IOpportunity;
  @Input() gtagCategory: string;

  private timeStart: number;

  constructor(private gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.timeStart = performance.now();
  }

  sendTimerEvent(): void {
    this.gaService.event(
      'timing',
      this.gtagCategory,
      this.opportunity.opportunityName,
      Math.round(performance.now() - this.timeStart)
    );
  }
}
