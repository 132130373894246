import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectUser } from '@techspert-io/auth';
import { takeWhile } from 'rxjs/operators';
import { IOpportunityAssignee } from '../../../models/opportunity.models';
import {
  IUserDialogInput,
  UsersDialogComponent,
} from '../../dialogs/users-dialog/users-dialog.component';

@Component({
  selector: 'opp-pod-members',
  templateUrl: './pod-members.component.html',
  styleUrls: ['./pod-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PodMembersComponent {
  @Input() podMembers: IOpportunityAssignee[];
  @Output() addPodMember = new EventEmitter<IOpportunityAssignee[]>();
  @Output() deletePodMember = new EventEmitter<IOpportunityAssignee>();

  podMembersColumns = ['name', 'email'];
  podMembersCells = [...this.podMembersColumns, 'options'];

  constructor(private dialog: MatDialog) {}

  add(): void {
    this.dialog
      .open<UsersDialogComponent, IUserDialogInput, ConnectUser[]>(
        UsersDialogComponent,
        {
          width: '840px',
          data: {
            filterEmails: this.podMembers.map((p) => p.email),
          },
        }
      )
      .afterClosed()
      .pipe(takeWhile((d) => !!d))
      .subscribe((item) =>
        this.addPodMember.emit(
          item.map((user) => ({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            connectId: user.connectId,
          }))
        )
      );
  }

  delete(item: IOpportunityAssignee): void {
    this.deletePodMember.emit(item);
  }
}
