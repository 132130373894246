import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ExpertSource } from '@techspert-io/experts';
import { ToastService } from '@techspert-io/user-alerts';
import { EMPTY, Observable, from, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, reduce } from 'rxjs/operators';
import { AppService } from '../../../shared/services/app.service';
import {
  ICommercialEnrichmentExpert,
  ICommercialEnrichmentResponse,
  ICommercialEnrichmentResultDict,
} from '../models/experts/search-response/commercial-enrichment';
import {
  ISearchExpertV3,
  ISearchResponse,
  v3SearchExpert,
} from '../models/experts/search-response/search-response';
import { INPIEnrichmentQuery, ISearchQuery } from '../models/query';
import {
  IExpertiseAutocompleteResponse,
  ISearchDisplayExpert,
  ISearchReturn,
} from '../models/search-models';

export const SEARCH_API_BASE_URL = new InjectionToken<string>(
  'SEARCH_API_BASE_URL',
  {
    providedIn: 'root',
    factory: (): string => '',
  }
);
export const SEARCH_API_BASE_2_URL = new InjectionToken<string>(
  'SEARCH_API_BASE_2_URL',
  {
    providedIn: 'root',
    factory: (): string => '',
  }
);

@Injectable()
export class SearchService {
  constructor(
    private http: HttpClient,
    private appService: AppService,
    private toastService: ToastService,
    @Inject(SEARCH_API_BASE_URL) private baseUrl: string,
    @Inject(SEARCH_API_BASE_2_URL) private base2Url: string
  ) {}

  search(payload: ISearchQuery): Observable<ISearchReturn> {
    return this.paginatedSearch(
      {
        ...payload,
        count: Math.min(payload.count, 300),
      },
      this.mapToConnectExpert.bind(this),
      payload.count,
      0,
      100000
    );
  }

  parseAndSearchNPI(
    payload: INPIEnrichmentQuery
  ): Observable<ICommercialEnrichmentResultDict> {
    return from(this.appService.chunkArray(100, payload.attributes)).pipe(
      mergeMap((urls) =>
        this.searchNPIEnrichment({
          ...payload,
          attributes: urls,
        })
      ),
      reduce(
        (prev, curr) => ({
          success: [...prev.success, ...curr.success],
          fail: [...prev.fail, ...curr.fail],
        }),
        {
          success: [],
          fail: [],
        }
      )
    );
  }

  autocomplete(
    term: string,
    type: 'roles' | 'expertise' | 'affiliations',
    limit = 10
  ): Observable<string[]> {
    const params = new URLSearchParams({
      term,
      type,
      limit: limit.toString(),
    }).toString();

    return this.http
      .get<IExpertiseAutocompleteResponse>(
        `${this.base2Url}/autocomplete/lookups?${params}`
      )
      .pipe(
        map((data) => data.results),
        catchError(() => of([]))
      );
  }

  private paginatedSearch(
    payload: ISearchQuery,
    expertMapper: (
      expert: v3SearchExpert,
      trackingId: string,
      service: ExpertSource
    ) => ISearchDisplayExpert,
    count: number,
    expertsFrom: number,
    pageSize: number
  ): Observable<ISearchReturn> {
    const base = `${this.base2Url}/expert-profile-query/search`;
    const serviceCount = count;
    const fullPages = Math.floor(serviceCount / pageSize);
    const rem = serviceCount % pageSize;

    const requests = [
      ...[...Array(fullPages).keys()].map((i) => ({
        from: expertsFrom + pageSize * i,
        pageSize: pageSize,
      })),
      ...(rem
        ? [
            {
              from: expertsFrom + pageSize * fullPages,
              pageSize: rem,
            },
          ]
        : []),
    ];

    const mapPayload = (payload: ISearchQuery) => {
      const { searchExpansion, ...rest } = payload;

      return { ...rest, enrich_terms: searchExpansion };
    };

    return from(this.appService.chunkArray(2, requests)).pipe(
      concatMap((batch) =>
        from(batch).pipe(
          mergeMap((r) =>
            this.http
              .post<ISearchResponse>(
                `${base}?size=${r.pageSize}&from=${r.from}`,
                mapPayload(payload)
              )
              .pipe(
                catchError((err) => {
                  this.toastService.sendMessage(
                    err.error?.message || err.message || JSON.stringify(err),
                    'error'
                  );
                  return EMPTY;
                })
              )
          )
        )
      ),
      map((res) => ({
        total: res.available_profiles_count || res.experts.length,
        experts: res.experts.map((e) =>
          expertMapper(e, res.tracking_id, payload.service)
        ),
      })),
      reduce<ISearchReturn, ISearchReturn>(
        (acc, curr) => ({
          ...acc,
          total: acc.total + curr.total,
          experts: acc.experts.concat(curr.experts),
        }),
        { experts: [], total: 0 }
      )
    );
  }

  private searchNPIEnrichment(
    payload: INPIEnrichmentQuery
  ): Observable<ICommercialEnrichmentResultDict> {
    return this.http
      .post<ICommercialEnrichmentResponse>(`${this.baseUrl}/v1/pdl/profiles`, {
        [payload.pdlEnrichmentService]: payload.attributes,
      })
      .pipe(
        map((data) =>
          data.experts.map((expert) =>
            this.mapCommercialEnrichmentToConnectExpert(expert)
          )
        ),
        map((data) => ({
          success: data,
          fail: [],
        })),
        catchError(() =>
          of({
            success: [],
            fail: payload.attributes,
          })
        )
      );
  }

  private mapToConnectExpert(
    expert: ISearchExpertV3,
    trackingId: string,
    service: ExpertSource
  ): ISearchDisplayExpert {
    return {
      id: this.appService.createUUID(),
      selected: false,
      positions: expert.positions || [],
      roles: expert.roles || [],
      source: {
        searchExpertData: {
          id: expert.id,
          trackingId: trackingId,
        },
        expertProfileId: expert.id,
        firstName: this.capitalise(expert.first_name),
        lastName: this.capitalise(expert.last_name),
        country: expert.countries?.length === 1 ? expert.countries[0] : '',
        source: service,
        expertise: this.removeDuplicates(
          (expert.specialities || []).map((s) => s.speciality)
        ),
        affiliations: this.removeDuplicates(
          (expert.positions || []).reduce(
            (acc, p) =>
              p.affiliation?.name ? [...acc, p.affiliation.name] : acc,
            []
          )
        ),
        positions: this.removeDuplicates(
          (expert.positions || []).reduce(
            (acc, p) => (p.title ? [...acc, p.title] : acc),
            []
          )
        ),
        qualifications: this.removeDuplicates(
          (expert.certifications || []).reduce(
            (acc, c) => (c.name ? [...acc, c.name] : acc),
            []
          )
        ),
        opportunityEmails: this.formatAndDedupeEmails([
          expert.poc_email,
          ...(expert.emails || []).map((e) => e.address),
        ]),
        primaryEmail: expert.poc_email || '',
        phoneNumbers: expert.phones || [],
      },
    };
  }

  private mapCommercialEnrichmentToConnectExpert(
    expert: ICommercialEnrichmentExpert
  ): ISearchDisplayExpert {
    const nameArray = expert.name.split(' ');
    return {
      id: this.appService.createUUID(),
      selected: false,
      roles: [],
      source: {
        searchExpertData: {
          id: expert.id,
          trackingId: expert.tracking_id,
        },
        expertProfileId: expert.id,
        firstName: this.capitalise(nameArray[0]),
        lastName: this.capitalise(nameArray[1]),
        country: expert.countries.length === 1 ? expert.countries[0] : '',
        source: 'pdl-enrichment',
        expertise: this.removeDuplicates(expert.specialities || []),
        affiliations: this.removeDuplicates(expert.affiliations || []),
        positions: this.removeDuplicates(expert.positions || []),
        qualifications: [],
        opportunityEmails: this.formatAndDedupeEmails(expert.emails || []),
        primaryEmail: '',
        phoneNumbers: this.removeDuplicates(expert.phone_numbers || []),
      },
    };
  }

  private capitalise(word?: string): string {
    return (word || '').replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  }

  private formatAndDedupeEmails(emailArray: string[], maxItems = 10): string[] {
    return [
      ...new Set(emailArray.filter(Boolean).map((e) => e.toLowerCase().trim())),
    ].slice(0, maxItems);
  }

  private removeDuplicates(array: string[], maxItems = 10): string[] {
    return Array.from(new Set(array)).slice(0, maxItems);
  }
}
