import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { BtxSharedModule } from '../shared/btx-shared.module';
import { AdminPortalComponent } from './admin-portal.component';
import { ConferenceManagementModule } from './conference-management/conference-management.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SearchModule } from './search/search.module';

@NgModule({
  declarations: [AdminPortalComponent],
  imports: [
    BtxSharedModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    CoreModule,
    ConferenceManagementModule,
    SearchModule,
  ],
  exports: [DashboardModule, AdminPortalComponent, ConferenceManagementModule],
})
export class AdminPortalModule {}
