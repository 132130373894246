<div class="search-input">
  <mat-icon class="search-input-icon">search</mat-icon>
  <input type="search" [(ngModel)]="searchTerm" data-test-id="omnisearch-input" (keyup)="submitSearch(searchTerm)"
    class="search-input-input" placeholder="Search for experts, projects or clients...">
</div>

<ng-container *ngIf="results$ | async as results">
  <div class="drop-down" *ngIf="results.length" (appClickOutside)="clearSearch()">
    <cdk-virtual-scroll-viewport itemSize="80">
      <app-omnisearch-result *ngFor="let data of results" [omnisearchResponse]="data" [searchTerm]="searchTerm"
        data-test-id="omnisearch-result-item">
      </app-omnisearch-result>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-container>