import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IExpertAction } from '../models/expertActions.models';
import {
  IExpertAvailabilitiesAction,
  IExpertAvailabilitiesActionBase,
} from '../models/expertAvailabilityActions.models';

export interface IExpertAvailabilitiesActionCreateReq
  extends Omit<IExpertAvailabilitiesActionBase, keyof IExpertAction> {
  expertActionId?: string;
  expertId: string;
}

export interface IExpertAvailabilitiesActionUpdateReq
  extends Omit<Partial<IExpertAvailabilitiesActionBase>, keyof IExpertAction> {
  expertActionId: string;
  expertId: string;
  status?: IExpertAction['status'];
}

@Injectable({
  providedIn: 'root',
})
export class ExpertAvailabilitiesService {
  private readonly baseUrl = '/experts';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  getById(expertId: string): Observable<IExpertAvailabilitiesAction[]> {
    return this.http.get<IExpertAvailabilitiesAction[]>(
      `${this.baseUrl}/${expertId}/availabilities`
    );
  }

  create(
    req: IExpertAvailabilitiesActionCreateReq
  ): Observable<IExpertAvailabilitiesAction> {
    const { expertId, ...payload } = req;
    return this.http
      .post<IExpertAvailabilitiesAction>(
        `${this.baseUrl}/${expertId}/availabilities`,
        payload
      )
      .pipe(
        catchError((err) => {
          this.toastService.sendMessage(err.message, 'error');
          return throwError(err);
        })
      );
  }

  update(
    req: IExpertAvailabilitiesActionUpdateReq
  ): Observable<IExpertAvailabilitiesAction> {
    const { expertId, ...payload } = req;
    return this.http
      .patch<IExpertAvailabilitiesAction>(
        `${this.baseUrl}/${expertId}/availabilities`,
        payload
      )
      .pipe(
        catchError((err) => {
          this.toastService.sendMessage(err.message, 'error');
          return throwError(err);
        })
      );
  }
}
