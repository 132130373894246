import { Injectable } from '@angular/core';
import { connectPhaseList, IExpert } from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';
import { IStatPhase, IStats } from '../models/opportunity';

export interface IAcceptanceRate {
  percentage: number;
  count: number;
}

@Injectable()
export class StatisticsService {
  // TODO: return is usally a number except for client-acceptance-rate where it's an object with the number and total nested underneath - however it seems parts of the app rely on this so refactor needed later
  public opportunityCalculator(action: string, opportunity: IOpportunity): any {
    switch (action) {
      case 'am-rejection-rate':
        return this.calculateAmRejectionRate(opportunity);
      case 'client-acceptance-rate':
        return this.calculateClientAcceptanceRate(opportunity);
      case 'client-rejection-rate':
        return this.calculateClientRejectionRate(opportunity);
      case 'expert-response-rate':
        return this.calculateExpertResponseRate(opportunity);
      case 'expert-count':
        return this.calculateExpertCount(opportunity);
      case 'new-experts':
        return this.calculateNewExperts(opportunity);
      case 'booked-connections':
        return this.calculateBookedConnections(opportunity);
      case 'completed':
        return this.calculateCompletedConnections(opportunity);
      case 'open-connections':
        return this.calculateOpenConnections(opportunity);
      case 'closed-connections':
        return (
          this.calculateCompletedConnections(opportunity) +
          this.calculateBookedConnections(opportunity)
        );
      default:
        return 0;
    }
  }

  public clientCalculator(action: string, opps: any): number {
    switch (action) {
      case 'rejection-rate':
        return Math.round(this.calculateOverallClientRejectionRate(opps));
      case 'acceptance-rate':
        return Math.round(this.calculateOverallClientAcceptanceRate(opps));
      default:
        return 0;
    }
  }

  public stageCounts(
    phase: IExpert['connectPhase'],
    opp: IOpportunity
  ): number {
    if (!opp.searches) return 0;
    return Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search ? this.calculateActiveExperts(search.stats, phase) : 0),
      0
    );
  }

  private calculateOpenConnections(opportunity: IOpportunity): number {
    return (
      opportunity.expertTargetQuantity -
        this.accumulatedClosedConnections(opportunity) || 0
    );
  }

  private accumulatedClosedConnections(opp: IOpportunity): number {
    return Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search
          ? this.getOverallExperts(search.stats, 'accepted') +
            this.getOverallExperts(search.stats, 'scheduled') +
            this.getOverallExperts(search.stats, 'completed')
          : 0),
      0
    );
  }

  private calculateOverallClientRejectionRate(opps: IOpportunity[]): number {
    if (!opps?.length) return 0;
    const rejectionRate = opps.reduce(
      (prev, opp) => prev + Math.round(this.calculateClientRejectionRate(opp)),
      0
    );
    return rejectionRate / opps.length;
  }

  private calculateOverallClientAcceptanceRate(opps: IOpportunity[]): number {
    if (!opps?.length) return 0;
    const acceptanceRate = opps.reduce(
      (prev, opportunity) =>
        prev + this.calculateClientAcceptanceRate(opportunity).count,
      0
    );
    return acceptanceRate / opps.length;
  }

  private calculateAmRejectionRate(opp: IOpportunity): number {
    if (!opp.searches) return 0;
    const totalRejected = Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search ? this.accumulateAdminRejected(search.stats) : 0),
      0
    );

    return (
      (totalRejected / this.opportunityCalculator('expert-count', opp)) * 100 ||
      0
    );
  }

  private calculateClientAcceptanceRate(opp: IOpportunity): IAcceptanceRate {
    if (opp.searches) {
      const acceptedCount = Object.values(opp.searches).reduce(
        (acc, search) =>
          (acc += search
            ? this.calculateActiveExperts(search.stats, 'accepted') +
              this.calculateActiveExperts(search.stats, 'scheduled') +
              this.calculateActiveExperts(search.stats, 'completed')
            : 0),
        0
      );
      const totalSentToClient = this.accumulatedSentToClient(opp);
      return {
        percentage: Math.round((acceptedCount / totalSentToClient) * 100) || 0,
        count: acceptedCount,
      };
    }
    return {
      percentage: 0,
      count: 0,
    };
  }

  private calculateClientRejectionRate(opp: IOpportunity): number {
    if (!opp.searches) return 0;
    const rejectedCount = Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search ? this.accumulateClientRejected(search.stats) : 0),
      0
    );
    const totalSentToClient = this.accumulatedSentToClient(opp);
    return totalSentToClient
      ? (rejectedCount / totalSentToClient) * 100
      : 0 || 0;
  }

  private calculateExpertResponseRate(opp: IOpportunity): number {
    if (!opp.searches) return 0;
    const expertCount = this.calculateExpertCount(opp);

    const responded = Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search
          ? // responded, accepted, in portal, scheduled, completed
            this.calculateActiveExperts(search.stats, 'outreach') +
            this.calculateActiveExperts(search.stats, 'screener') +
            this.calculateActiveExperts(search.stats, 'accepted') +
            this.calculateActiveExperts(search.stats, 'scheduled') +
            this.calculateActiveExperts(search.stats, 'sentToClient') +
            this.calculateActiveExperts(search.stats, 'completed')
          : 0),
      0
    );

    return Math.round((responded / expertCount) * 100) || 0;
  }

  public calculateExpertCount(opp: IOpportunity): number {
    if (!opp.searches) return 0;
    return Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search
          ? this.getOverallExperts(search.stats, 'identified') +
            this.getOverallExperts(search.stats, 'firstFollowUp') +
            this.getOverallExperts(search.stats, 'secondFollowUp') +
            this.getOverallExperts(search.stats, 'outreach') +
            this.getOverallExperts(search.stats, 'outreachComplete') +
            this.getOverallExperts(search.stats, 'accepted') +
            this.getOverallExperts(search.stats, 'scheduled') +
            this.getOverallExperts(search.stats, 'sentToClient') +
            this.getOverallExperts(search.stats, 'screener') +
            this.getOverallExperts(search.stats, 'completed')
          : 0),
      0
    );
  }

  public calculateNewExperts(opp: IOpportunity): number {
    if (!opp.searches) return 0;
    return Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search
          ? this.calculateActiveExperts(search.stats, 'sentToClient')
          : 0),
      0
    );
  }

  public calculateBookedConnections(opp: IOpportunity): number {
    if (!opp.searches) return 0;
    return Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search
          ? this.calculateActiveExperts(search.stats, 'accepted') +
            this.calculateActiveExperts(search.stats, 'scheduled')
          : 0),
      0
    );
  }

  public calculateCompletedConnections(opp: IOpportunity): number {
    if (!opp.searches) return 0;
    return Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search
          ? this.calculateActiveExperts(search.stats, 'completed')
          : 0),
      0
    );
  }

  public calculateClosedConnections(opp: IOpportunity): number {
    if (!opp.searches) return 0;
    return Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search
          ? this.calculateActiveExperts(search.stats, 'completed') +
            this.calculateActiveExperts(search.stats, 'scheduled') +
            this.calculateActiveExperts(search.stats, 'accepted')
          : 0),
      0
    );
  }

  private accumulateClientRejected(stats: IStats): number {
    if (Object.keys(stats).length === 0) return 0;
    return connectPhaseList.reduce(
      (acc, phase) =>
        (acc += this.accumulatePhase(stats, phase, 'clientRejected')),
      0
    );
  }

  private accumulateAdminRejected(stats: IStats): number {
    if (Object.keys(stats).length === 0) return 0;
    return connectPhaseList.reduce(
      (acc, phase) =>
        (acc += this.accumulatePhase(stats, phase, 'adminRejected')),
      0
    );
  }

  private accumulatedSentToClient(opp: IOpportunity): number {
    return Object.values(opp.searches).reduce(
      (acc, search) =>
        (acc += search
          ? this.getOverallExperts(search.stats, 'sentToClient') +
            this.getOverallExperts(search.stats, 'accepted') +
            this.getOverallExperts(search.stats, 'scheduled') +
            this.getOverallExperts(search.stats, 'completed')
          : 0),
      0
    );
  }

  private calculateActiveExperts(
    stats: IStats,
    phase: IExpert['connectPhase']
  ): number {
    if (Object.keys(stats).length === 0) return 0;
    return this.accumulatePhase(stats, phase, 'activeExperts');
  }

  private getOverallExperts(
    stats: IStats,
    phase: IExpert['connectPhase']
  ): number {
    if (Object.keys(stats).length === 0) return 0;
    return this.accumulatePhase(stats, phase, 'experts');
  }

  private accumulatePhase(
    stats: IStats,
    phase: IExpert['connectPhase'],
    statPhase: keyof IStatPhase
  ): number {
    return Object.keys(stats).reduce(
      (cAcc, country) =>
        (cAcc += Object.keys(stats[country]).reduce(
          (sAcc, segment) =>
            (sAcc += stats[country][segment][phase]
              ? stats[country][segment][phase][statPhase]
              : 0),
          0
        )),
      0
    );
  }

  // TODO: use IOpportunity type when all segments of the algorithm exist on model
  public getAmountOwed(fields: any): number {
    //     amountOwed = rate per call * duration of call
    // + data review rate * duration of data review
    // + survey rate * number of surveys
    const amountOwed =
      fields.callRate * fields.hoursEngaged +
      fields.dataReviewRate * fields.durationOfDataReview +
      fields.surveyRate * fields.numberOfSurveys;

    return amountOwed || 0;
  }
}
