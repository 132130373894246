import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight',
})
export class SearchHighlightPipe implements PipeTransform {
  transform(value: string, searchTerm: string): string {
    try {
      if (!searchTerm?.trim()) {
        return value;
      }

      const searchWords = [
        ...new Set(
          searchTerm
            .trim()
            .split(/\s+/g)
            .map((str) => str.toLowerCase().trim())
        ),
      ];

      return value.replace(
        new RegExp(`(?<=\\b)(${searchWords.join('|')})`, 'gi'),
        '<span style="color: #000000; font-weight: 700;">$1</span>'
      );
    } catch (error) {
      return value;
    }
  }
}
