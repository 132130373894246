import { Pipe, PipeTransform } from '@angular/core';
import { ConnectPhase } from '../models/experts.models';

type PhaseTypes = ConnectPhase | 'rejected';

@Pipe({
  name: 'phaseName',
})
export class PhaseNamePipe implements PipeTransform {
  transform(value: PhaseTypes): string {
    const connectPhases = new Map<PhaseTypes, string>([
      ['identified', 'Identified'],
      ['firstFollowUp', 'Follow up 1'],
      ['secondFollowUp', 'Follow up 2'],
      ['outreachComplete', 'Outreach complete'],
      ['outreach', 'Responded'],
      ['screener', 'Screener'],
      ['sentToClient', 'In portal'],
      ['scheduled', 'Scheduled'],
      ['accepted', 'Accepted'],
      ['completed', 'Completed'],
      ['rejected', 'Rejected'],
    ]);

    return connectPhases.get(value) || value;
  }
}
