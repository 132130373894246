import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ChipListStyle } from '../../../../shared/patterns/chip-list/chip-list.component';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-expertise-chip-list',
  template: `
    <ct-chip-list
      [list]="displayedTerms"
      [label]="fieldDisplay"
      [placeholder]="'Enter terms...'"
      [chipStyles]="styles"
      [disabled]="disabled"
      (addItemSignal)="addTerm($event)"
      (removeItemSignal)="removeTerm($event)"
      (listChange)="onListChange($event)"
      (inputChange)="inputChange($event)"
      [debounce]="200"
      [splitByPipe]="true"
      [autoCompleteOptions]="autoCompleteOptions"
      [filterAutoCompleteOptions]="false"
      data-test-id="query-creator-expertise-conditions-list"
    >
    </ct-chip-list>
  `,
})
export class ExpertiseChipListComponent implements OnInit, OnChanges {
  @Output() addItemSignal = new EventEmitter<string>();
  @Output() removeItemSignal = new EventEmitter<string>();
  @Output() listChange = new EventEmitter<string[]>();

  @Input() terms: string[] = [];
  @Input() disabled: boolean;
  @Input() fieldDisplay: string;

  styles: Record<string, ChipListStyle> = {};
  mainTerms: string[] = [];
  expandedTerms: string[] = [];
  displayedTerms: string[] = [];
  autoCompleteOptions: string[] = [];

  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.mainTerms = this.terms;
    this.displayedTerms = [...this.mainTerms];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.terms) {
      this.mainTerms = changes.terms.currentValue;
      this.displayedTerms = [...this.mainTerms, ...this.expandedTerms];
    }
  }

  addTerm(term: string): void {
    this.autoCompleteOptions = [];
    this.addItemSignal.emit(term.toLowerCase().trim());
  }

  removeTerm(removalTerm: string): void {
    this.expandedTerms = this.expandedTerms.filter(
      (term) => term !== removalTerm
    );

    this.removeItemSignal.emit(removalTerm);
  }

  onListChange(event: string[]): void {
    this.listChange.emit(event.filter((t) => !this.expandedTerms.includes(t)));
  }

  inputChange(event: string): void {
    if (!event || event.length < 4) {
      this.autoCompleteOptions = [];
      return;
    }

    this.searchService
      .autocomplete(event, 'expertise')
      .subscribe((autoCompleteOptions) => {
        this.autoCompleteOptions = autoCompleteOptions;
      });
  }
}
