import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  mergeMap,
  reduce,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  IResponseFailItem,
  IUpdateManyResponse,
} from '../../../shared/models/api';
import { AppService } from '../../../shared/services/app.service';
import {
  IExpert,
  IExpertUpdateRequest,
  ValidationError,
} from '../models/experts.models';

@Injectable({
  providedIn: 'root',
})
export class ExpertsUpdateService {
  private readonly baseUrl = '/data';
  private readonly expertUrl = '/experts';

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private toastService: ToastService
  ) {}

  /** Doesn't include side effects, take care if updating rejection or connect phase */
  updateOpportunityExpert(expert: IExpertUpdateRequest): Observable<IExpert> {
    return this.http.patch<IExpert>(
      `${this.expertUrl}/${expert.expertId}`,
      expert
    );
  }

  updateOne(expert: IExpertUpdateRequest): Observable<IExpert> {
    delete expert.prevOpportunities;

    return this.updateExpertsImpl(expert).pipe(
      switchMap((res) =>
        res.fail.length
          ? this.handleUpdateOneError(res.fail[0])
          : this.handleUpdateOneSuccess(res.success[0])
      ),
      take(1)
    );
  }

  updateMany(
    experts: IExpertUpdateRequest[],
    batchSize = 75
  ): Observable<IUpdateManyResponse<IExpert>> {
    return from(this.appService.chunkArray(batchSize, experts)).pipe(
      mergeMap((experts) => this.updateExpertsImpl(...experts)),
      reduce(
        (prev, curr) => ({
          success: [...prev.success, ...curr.success],
          fail: [...prev.fail, ...curr.fail],
        }),
        {
          success: [],
          fail: [],
        } as IUpdateManyResponse<IExpert>
      ),
      tap(
        (res) =>
          res.fail.length &&
          this.showErrorToast(`Updating ${res.fail.length} Expert(s) failed`)
      )
    );
  }

  private updateExpertsImpl(
    ...experts: IExpertUpdateRequest[]
  ): Observable<IUpdateManyResponse<IExpert>> {
    const updatePayload = experts.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.expertId]: this.appService.removeStrictExpertProps(
          curr,
          'screenerConfig'
        ),
      }),
      {}
    );

    return this.http
      .post<IUpdateManyResponse<IExpert>>(
        `${this.baseUrl}/experts/update`,
        updatePayload
      )
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            this.showErrorToast(err.error.message || err.message);
          } else {
            this.showErrorToast(err.message);
          }
          return throwError(err);
        })
      );
  }

  private handleUpdateOneSuccess(expert: IExpert): Observable<IExpert> {
    if (!expert) {
      return throwError(new Error('No experts in response'));
    } else {
      return of(expert);
    }
  }

  private handleUpdateOneError(
    err: IResponseFailItem<IExpert>
  ): Observable<IExpert> {
    this.showErrorToast(
      `Updating ${err.item.firstName} ${err.item.lastName} failed`
    );
    return throwError(new ValidationError(err));
  }

  private showErrorToast(message: string): void {
    this.toastService.sendMessage(message, 'error');
  }
}
