import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/patterns/material-module';
import { UserClientControlComponent } from './components/user-client-control/user-client-control.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserInviteComponent } from './components/user-invite/user-invite.component';
import { UsersFilterComponent } from './components/users-filter/users-filter.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UsersComponent } from './components/users/users.component';
import { JobTitlePipe } from './pipes/job-title.pipe';
import { UserRolePipe } from './pipes/user-role.pipe';
import { UsersRoutingModule } from './users-routing.modules';

@NgModule({
  declarations: [
    UsersComponent,
    UsersListComponent,
    UserFormComponent,
    UserInviteComponent,
    UserEditComponent,
    JobTitlePipe,
    UserRolePipe,
    UsersFilterComponent,
    UserClientControlComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UsersRoutingModule,
  ],
  exports: [JobTitlePipe],
})
export class UsersModule {}
