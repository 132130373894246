<div *ngIf="template" class="template-form">
  <div class="template-form-header">
    <h3>{{formType === 'new' ? 'New template' : 'Edit template'}}</h3>
    <mat-icon class="close-icon" (click)="closeForm()">close</mat-icon>
  </div>
  <div class="template-form-body">
    <div class="on-save-overlay" *ngIf="showActionConfirmOverlay">
      <p>{{ overlayText }}</p>
    </div>
    <div class="inputs">
      <div class="basicInput basicInput--width25 basicInput--marginRightMd">
        <span class="basicInput__label">Template name</span>
        <input class="basicInput__input" [(ngModel)]="template.title" id="template-name" type="text" />
      </div>
      <div class="basicInput basicInput--width75 basicInput--marginRightMd">
        <span class="basicInput__label">Template subject</span>
        <input class="basicInput__input" [(ngModel)]="template.subject" id="template-subject" type="text" />
      </div>
    </div>
    <div class="inputs" style="margin-top: 10px;">
      <div class="basicInput basicInput--width25 basicInput--marginRightMd">
        <span class="basicInput__label">Template description</span>
        <input class="basicInput__input" [(ngModel)]="template.description" id="template-description" type="text" />
      </div>
      <div class="basicInput basicInput--width75 basicInput--marginRightMd">
        <span class="basicInput__label">Template preheader</span>
        <input class="basicInput__input" [(ngModel)]="template.preheader" id="template-preheader" type="text" />
      </div>
    </div>
    <div class="form-editor">
      <pre style=" height: 100%; display: flex;" class="ngx-pre">
          <textarea class="form-editor-textarea" id="editor-2" *ngIf="solicitationService.activeTemplate" [(ngModel)]="template.content"></textarea>
        </pre>
    </div>
  </div>
  <div class="template-form-actions">
    <button class="button button--danger" *ngIf="formType === 'edit'" (click)="deleteTemplate()">
      Delete template</button>
    <button class="button button--secondary" (click)="closeForm()">Cancel</button>
    <button class="button button--secondary" *ngIf="formType === 'edit'" (click)="updateTemplate()">
      Update and apply</button>
    <button class="button button--secondary" *ngIf="formType === 'new'" (click)="saveTemplate()">
      Save and apply</button>
  </div>
</div>