<!--<div class="lds-ripple"><div></div><div></div></div>-->
<div data-test-id="scintillating-loader-loading-animation" class="lds-grid">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>