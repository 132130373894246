import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IFalconSearchExpert } from '../services/expert-falcon-search.service';

@Component({
  selector: 'app-results-display-falcon',
  templateUrl: './results-display-falcon.component.html',
  styleUrls: ['./results-display-falcon.component.scss'],
})
export class ResultsDisplayFalconComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() experts: IFalconSearchExpert[] = [];
  @Output() expertSelected = new EventEmitter<string[]>();

  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<IFalconSearchExpert>([]);
  selection = new SelectionModel<IFalconSearchExpert>(true, []);
  destroy$ = new Subject();
  displayedColumns = [
    'select',
    'name',
    'affiliations',
    'roles',
    'expertise',
    'emails',
    'segment',
    'opportunityName',
    'stage',
  ];

  filterForm = new FormGroup({
    filter: new FormControl<string>(''),
    segments: new FormControl<string[]>([]),
    expertSegments: new FormControl<string[]>([]),
    opportunites: new FormControl<string[]>([]),
    stages: new FormControl<string[]>([]),
  });

  get expertSegmentOptions(): string[] {
    return [
      ...new Set(this.dataSource.data.map((expert) => expert.expertSegment)),
    ].sort();
  }

  get segmentOptions(): string[] {
    return [
      ...new Set(
        this.dataSource.data.map((expert) => expert.opportunitySegment)
      ),
    ].sort();
  }

  get opportunityOptions(): string[] {
    return [
      ...new Set(this.dataSource.data.map((expert) => expert.opportunityName)),
    ].sort();
  }

  get stageOptions(): string[] {
    return [
      ...new Set(this.dataSource.data.map((expert) => expert.stage)),
    ].sort();
  }

  get filteredExpertCount(): number {
    return this.dataSource.filteredData.length;
  }

  ngOnInit(): void {
    this.dataSource.data = this.experts;
    this.selection.clear();

    this.filterForm.valueChanges
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.dataSource.filter = JSON.stringify(value);
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.expertsFilter;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  addSelected(): void {
    this.expertSelected.emit(
      this.selection.selected.map((expert) => expert.expertId)
    );
  }

  trackBy(idx: number, item?: IFalconSearchExpert): string | number {
    return item?.expertId || idx;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  private expertsFilter(
    expert: IFalconSearchExpert,
    filterIn: string
  ): boolean {
    const { filter, opportunites, stages, segments, expertSegments } =
      JSON.parse(filterIn);
    const filterPredicate =
      [
        expert.firstName,
        expert.lastName,
        expert.roles,
        expert.expertise,
        expert.affiliations,
        expert.opportunityEmails,
      ]
        .flatMap((field) => field)
        .join(' ')
        .toLowerCase()
        .indexOf(filter.toLowerCase()) > -1;

    const filterList = (list: Set<string>, field: string) =>
      list.size === 0 || list.has(field);

    return [
      filterPredicate,
      filterList(new Set(stages), expert.stage),
      filterList(new Set(expertSegments), expert.expertSegment),
      filterList(new Set(opportunites), expert.opportunityName),
      filterList(new Set(segments), expert.opportunitySegment),
    ].every(Boolean);
  }
}
