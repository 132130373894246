import { Injectable } from '@angular/core';
import { CurrencyService } from '../../../shared/services/currency.service';
import {
  ConnectPhase,
  connectPhaseList,
  IExpert,
} from '../models/experts.models';

@Injectable({
  providedIn: 'root',
})
export class ExpertValidationService {
  constructor(private currencyService: CurrencyService) {}

  isValid(experts: IExpert[], phase: ConnectPhase): boolean {
    const targetPhaseIndex = connectPhaseList.indexOf(phase);

    const phaseValidators = {
      4: this.validForResponded,
      5: this.validForScreener,
      6: this.validForInPortal,
    };

    return (
      experts.every((e) => this.expertHasValidCurrency(e)) &&
      Object.entries(phaseValidators)
        .filter(([k]) => +k <= targetPhaseIndex)
        .map(([, v]) => v(experts))
        .every(Boolean)
    );
  }

  private expertHasValidCurrency(e: IExpert): boolean {
    return e.currency
      ? this.currencyService.currencyCodes.includes(e.currency)
      : true;
  }

  private validForResponded(experts: IExpert[]): boolean {
    return experts.every((e) => e.primaryEmail || e.source === 'third-party');
  }

  private validForScreener(experts: IExpert[]): boolean {
    return experts.every((e) => e.opportunitySegmentId);
  }

  private validForInPortal(experts: IExpert[]): boolean {
    return experts.every((e) => {
      if (e.source === 'third-party' && e.expertSegment) {
        return true;
      }

      const [, decimal] = `${e.unitsPerHour}`.split('.');

      const validUnitRate =
        e.costBand === 'Standard' ||
        (e.unitsPerHour &&
          e.unitsPerHour > 0 &&
          (!decimal || decimal?.length < 3));

      return e.expertSegment && e.currency && validUnitRate;
    });
  }
}
