import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { IClient } from '@techspert-io/clients';
import { IOpportunity } from '@techspert-io/opportunities';
import { SearchQuery } from '../../../../../admin-portal/search/models/query';

@Component({
  selector: 'app-search-dialog',
  styleUrls: ['./search-dialog.component.scss'],
  templateUrl: './search-dialog.component.html',
})
export class SearchDialogComponent {
  @Output() updateOpportunitySignal = new EventEmitter<IOpportunity>();

  constructor(
    public dialogRef: MatDialogRef<SearchDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      opportunity: IOpportunity;
      client: IClient;
      searchName: string;
      query: SearchQuery;
    }
  ) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }

  emitUpdateOpportunity(updatedOpportunityPayload: {
    updatedOpportunity: IOpportunity;
    bubbleClose: boolean;
  }): void {
    if (updatedOpportunityPayload.updatedOpportunity)
      this.updateOpportunitySignal.emit(
        updatedOpportunityPayload.updatedOpportunity
      );
    if (updatedOpportunityPayload.bubbleClose) this.closeDialog();
  }
}
