<div class="keys-menu">
  <div class="keys-menu-header">
    <h3>Keys</h3>
    <mat-icon class="close-icon" (click)="closeMenu()">close</mat-icon>
  </div>
  <ul class="list">
    <li class="list-item"><span class="selectable copy">[expert_first_name]</span></li>
    <li class="list-item"><span class="selectable copy">[expert_last_name]</span></li>
    <li class="list-item"><span class="selectable copy">[admin_first_name]</span></li>
    <li class="list-item"><span class="selectable copy">[admin_last_name]</span></li>
    <li class="list-item"><span class="selectable copy">[phone_number]</span></li>
    <li class="list-item"><span class="selectable copy">[job_title]</span></li>
  </ul>
</div>