import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { IOutreachTemplate } from '../../../models/outreach-templates.models';
import { OutreachTemplatesService } from '../../../services/outreach-templates.service';

type LoadingState = {
  isLoaded: boolean;
  isLoading: boolean;
};

@Component({
  selector: 'app-outreach-templates-edit-page',
  templateUrl: './outreach-templates-edit-page.component.html',
  styleUrls: ['./outreach-templates-edit-page.component.scss'],
})
export class OutreachTemplatesEditPageComponent implements OnInit {
  template$: Observable<IOutreachTemplate> = EMPTY;
  loadingState$ = new BehaviorSubject<LoadingState>({
    isLoaded: false,
    isLoading: false,
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private outreachTemplatesService: OutreachTemplatesService
  ) {}

  ngOnInit(): void {
    this.template$ = this.route.paramMap.pipe(
      map((parms) => parms.get('templateId')),
      tap(() => this.loadingState$.next({ isLoaded: false, isLoading: true })),
      switchMap((id) => this.outreachTemplatesService.getById(id)),
      tap(() => this.loadingState$.next({ isLoaded: true, isLoading: false }))
    );
  }

  onEdit(template: IOutreachTemplate): void {
    this.loadingState$.next({ isLoaded: true, isLoading: true });

    this.outreachTemplatesService
      .update(template)
      .pipe(
        tap(() =>
          this.loadingState$.next({ isLoaded: true, isLoading: false })
        ),
        switchMap(() =>
          this.router.navigate(['../'], { relativeTo: this.route })
        )
      )
      .subscribe();
  }
}
