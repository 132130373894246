import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EngagementsCalculationsService } from './services/engagements-calculations.service';
import { EngagementsCreateDefaultsService } from './services/engagements-create-defaults.service';
import { EngagementsTitleService } from './services/engagements-title.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    EngagementsCalculationsService,
    EngagementsTitleService,
    EngagementsCreateDefaultsService,
  ],
})
export class EngagementsModule {}
