import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmationDialogData {
  title?: string;
  messages?: string[];
  positiveButtonText?: string;
  negativeButtonText?: string;
  isDangerous?: boolean;
}

@Component({
  selector: 'ct-confirmation-dialog-component',
  template: `
    <h3 mat-dialog-title data-test-id="confirmation-dialog-title">
      {{ data.title }}
    </h3>

    <div mat-dialog-content data-test-id="confirmation-dialog-messages">
      <div
        *ngFor="let message of data.messages"
        data-test-id="confirmation-dialog-message"
      >
        {{ message }}
      </div>
    </div>

    <div mat-dialog-actions>
      <div class="flex flex-row flex-1 gap-2 justify-end align-center">
        <button
          mat-flat-button
          mat-dialog-close
          data-test-id="confirmation-dialog-cancel-button"
        >
          {{ data.negativeButtonText }}
        </button>
        <button
          mat-flat-button
          [color]="data.isDangerous ? 'warn' : 'primary'"
          (click)="close()"
          data-test-id="confirmation-dialog-confirm-button"
        >
          {{ data.positiveButtonText }}
        </button>
      </div>
    </div>
  `,
})
export class ConfirmationDialogComponent {
  data: IConfirmationDialogData = {
    title: 'Confirm?',
    messages: ['Are you sure ?'],
    positiveButtonText: 'Yes',
    negativeButtonText: 'No',
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IConfirmationDialogData
  ) {
    this.data = {
      ...this.data,
      ...data,
    };
  }

  close(): void {
    this.dialogRef.close(true);
  }
}
