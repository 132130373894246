import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { EMPTY, Observable, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ITimezone } from '../../../../shared/models/country';
import {
  CountryService,
  ICountry,
} from '../../../../shared/services/country.service';
import { FormGroupExpertProfile } from '../../models/expert-profile.forms';

interface IProfileLocation {
  timezoneName: string;
  country: string;
  subdivision?: string;
}

class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(ctrl: FormControl, form: NgForm): boolean {
    return form.touched && ctrl.invalid;
  }
}

@Component({
  selector: 'app-expert-profile-location-form',
  templateUrl: './expert-profile-location-form.component.html',
  styleUrls: ['./expert-profile-location-form.component.scss'],
  providers: [{ provide: ErrorStateMatcher, useClass: FormErrorStateMatcher }],
})
export class ExpertProfileLocationFormComponent implements OnInit, OnDestroy {
  @Input() location: IProfileLocation;
  @Input() labelFontColor?: string = '';
  @Input() useParentValidation: boolean = false;
  @Input() includeSubdivision: boolean = false;

  private destroy$ = new Subject();

  locationForm = new FormGroup({
    country: new FormControl<string>(null),
    timezoneName: new FormControl<string>(null),
    subdivision: new FormControl<string>(null),
  });

  options$: Observable<{
    countries: string[];
    timezones: ITimezone[];
    subdivisions: string[];
  }> = EMPTY;

  get parentForm(): FormGroupExpertProfile {
    return this.rootFormGroup.form;
  }

  get country() {
    return this.parentForm.get('country').value;
  }

  constructor(
    private rootFormGroup: FormGroupDirective,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.parentForm.statusChanges
      .pipe(
        debounceTime(50),
        filter(() => this.useParentValidation),
        tap(() => this.locationForm.markAllAsTouched()),
        tap(() =>
          Object.entries(this.locationForm.controls).forEach(
            ([key, ctrl]) =>
              (key !== 'subdivision' || this.includeSubdivision) &&
              ctrl.setErrors(this.parentForm.controls[key].errors)
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.locationForm.valueChanges
      .pipe(
        map((form) => ({
          country: this.getCountryCode(form.country),
          timezoneName: this.getTimezone(form.country, form.timezoneName),
          subdivision: form.subdivision,
        })),
        tap((locationValues) =>
          this.parentForm.patchValue({
            timezoneName: locationValues.timezoneName,
            country: locationValues.country,
            ...(this.includeSubdivision && {
              subdivision: locationValues?.subdivision,
            }),
          })
        ),
        debounceTime(50),
        tap(() => this.parentForm.markAsDirty()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.locationForm.setValue({
      country: this.findCountryByAbbr(this.location?.country),
      timezoneName: this.location?.timezoneName || '',
      subdivision: this.location?.subdivision || '',
    });

    const countryAndTimezoneOptions$ =
      this.locationForm.controls.country.valueChanges.pipe(
        startWith(this.findCountryByAbbr(this.location?.country)),
        map((country) => this.buildOptions(country)),
        tap(({ timezones }) => this.resetTimezoneControl(timezones))
      );
    const subdivisionOptions$ =
      this.locationForm.controls.subdivision.valueChanges.pipe(
        startWith(this.location?.subdivision || ''),
        map((subdivision) =>
          this.country !== 'US'
            ? []
            : this.countryService
                .getUSStateNames()
                .filter(
                  (option) =>
                    option.toLowerCase().indexOf(subdivision.toLowerCase()) ===
                    0
                )
        )
      );
    this.options$ = combineLatest([
      countryAndTimezoneOptions$,
      subdivisionOptions$,
    ]).pipe(
      map(([countryAndTimezone, subdivisions]) => ({
        ...countryAndTimezone,
        subdivisions,
      }))
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private findCountryByAbbr(abbr: string): string {
    const country = this.countryService.countryList.find(
      (c) => c.abbreviation === abbr
    );

    return country ? country.country : '';
  }

  private getCountryCode(countryName: string): string {
    const country = this.findCountryByName(countryName);

    return country ? country.abbreviation : '';
  }

  private findCountryByName(name: string): ICountry | undefined {
    return this.countryService.countryList.find((c) => c.country === name);
  }

  private getTimezone(countryName: string, timezoneName: string) {
    if (!countryName) {
      return '';
    }
    const countryCode = this.getCountryCode(countryName);
    if (!countryCode) {
      return '';
    }

    return (
      this.countryService
        .getTimezonesForCountry(countryCode)
        .find((t) => t && t.name === timezoneName)?.name || ''
    );
  }

  private buildOptions(countryName: string) {
    return {
      countries: this.filterCountries(countryName),
      timezones: this.filterTimezones(countryName),
    };
  }

  private filterCountries(countryName: string): string[] {
    return this.countryService.countryList
      .map((c) => c.country)
      .filter(
        (option) =>
          option.toLowerCase().indexOf(countryName.toLowerCase()) === 0
      );
  }

  private filterTimezones(countryName: string): ITimezone[] {
    const country = this.findCountryByName(countryName);
    return country
      ? this.countryService.getTimezonesForCountry(country.abbreviation)
      : [];
  }

  private resetTimezoneControl(timezones: ITimezone[]): void {
    const tz =
      timezones.find((t) => t.name === this.location?.timezoneName)?.name ||
      null;

    this.locationForm.controls.timezoneName.patchValue(tz);
    if (!timezones.length) {
      this.locationForm.controls.timezoneName.disable();
    } else {
      this.locationForm.controls.timezoneName.enable();
    }
  }
}
