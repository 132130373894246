import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ConnectUser } from '@techspert-io/auth';
import * as moment from 'moment';
import { CurrencyService } from '../../../../../shared/services/currency.service';

@Component({
  selector: 'opp-segment-owners-form',
  templateUrl: './segment-owners-form.component.html',
  styleUrls: ['./segment-owners-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentOwnersFormComponent implements OnInit {
  @Input() podMembersOptions: ConnectUser[];
  @Input() userIdProp: keyof Pick<ConnectUser, 'connectId' | 'email'> = 'email';

  currencies = this.currencyService.currencyData.map((c) => c.code);

  get ownersForm(): FormGroup {
    return this.rootFormGroup.form;
  }

  get coverOptions(): ConnectUser[] {
    return this.podMembersOptions || [];
  }

  get startError(): string {
    return this.ownersForm.get('availabilityStart').errors?.dates;
  }

  get endError(): string {
    return this.ownersForm.get('availabilityEnd').errors?.dates;
  }

  constructor(
    private rootFormGroup: FormGroupDirective,
    private currencyService: CurrencyService
  ) {}

  ngOnInit(): void {
    this.ownersForm.addValidators(this.ownersFormValidator());
  }

  private ownersFormValidator(): ValidatorFn {
    return (ctrl): ValidationErrors => {
      const availabilityStart = ctrl.get('availabilityStart');
      const availabilityEnd = ctrl.get('availabilityEnd');

      if (!availabilityStart.value && !availabilityEnd.value) {
        availabilityStart.setErrors(null);
        availabilityEnd.setErrors(null);
        return null;
      }

      if (
        typeof availabilityStart.value === 'string' &&
        availabilityStart.value.length !== 10
      ) {
        availabilityStart.setErrors({
          dates: 'Invalid Date',
        });
        return {};
      }
      if (
        typeof availabilityEnd.value === 'string' &&
        availabilityEnd.value.length !== 10
      ) {
        availabilityEnd.setErrors({
          dates: 'Invalid Date',
        });
        return {};
      }

      const start = moment(availabilityStart.value);
      const end = moment(availabilityEnd.value);

      if (!start.isValid()) {
        availabilityStart.setErrors({
          dates: 'No start date',
        });
        return {};
      }

      if (start.isValid() && !end.isValid()) {
        availabilityEnd.setErrors({
          dates: 'No end date',
        });
        return {};
      }

      if (start.isAfter(end)) {
        availabilityEnd.setErrors({
          dates: 'End date is befoe start date',
        });
        return {};
      }

      availabilityStart.setErrors(null);
      availabilityEnd.setErrors(null);
      return null;
    };
  }
}
