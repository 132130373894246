import { Pipe, PipeTransform } from '@angular/core';
import { OmnisearchQueryResponse } from '../models/omnisearch.models';

@Pipe({
  name: 'omnisearchUrl',
})
export class UrlPipe implements PipeTransform {
  transform(data: OmnisearchQueryResponse, opts?: string): string[] {
    const baseUrl: string[] = ['/admin'];
    switch (data.entity) {
      case 'opportunities': {
        const { clientId, id } = data;
        return [...baseUrl, 'client', clientId, 'opportunity', id, opts].filter(
          Boolean
        );
      }
      case 'clients': {
        const { id } = data;
        return [...baseUrl, 'clients', id];
      }
      case 'experts':
      case 'solicitationExperts': {
        const { id } = data;
        return [...baseUrl, 'experts', id];
      }
    }
  }
}
