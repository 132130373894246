import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { EMPTY, Observable, debounceTime, filter, switchMap } from 'rxjs';
import { SearchService } from '../../../../admin-portal/search/services/search.service';
import {
  FormGroupExpertProfile,
  FormGroupProfileSpecialism,
} from '../../models/expert-profile.forms';
import { IExpertSpecialism } from '../../models/expert-profile.models';

@Component({
  selector: 'app-expert-specialism-form',
  templateUrl: './expert-specialism-form.component.html',
  styleUrls: ['./expert-specialism-form.component.scss'],
})
export class ExpertSpecialismFormComponent implements OnInit {
  @Input() specialismName: 'expertise' | 'affiliations' | 'roles';

  autoCompleteOptions$: Observable<string[]> = EMPTY;

  get parentForm(): FormGroupExpertProfile {
    return this.rootFormGroup.form;
  }

  get specialismForm(): FormArray<FormGroupProfileSpecialism> {
    return this.parentForm.controls[this.specialismName];
  }

  get specialismControls(): FormGroupProfileSpecialism[] {
    return this.specialismForm.controls;
  }

  specialsimInput = new FormControl(null, Validators.required);

  constructor(
    private rootFormGroup: FormGroupDirective,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.autoCompleteOptions$ = this.specialsimInput.valueChanges.pipe(
      debounceTime(200),
      filter((value) => value && value.length > 3),
      switchMap((value) =>
        this.searchService.autocomplete(value, this.specialismName)
      )
    );
  }

  add(): void {
    if (!this.specialsimInput.valid) {
      return;
    }

    const existingIndex = this.specialismForm.value.findIndex(
      (s) =>
        s.name.toLocaleLowerCase() ===
        this.specialsimInput.value.toLocaleLowerCase()
    );

    if (existingIndex >= 0) {
      if (
        this.specialsimInput.value !==
        this.specialismForm.at(existingIndex).value.name
      ) {
        this.specialismForm
          .at(existingIndex)
          .controls.name.setValue(this.specialsimInput.value, {
            emitEvent: false,
          });
      }

      this.specialismForm
        .at(existingIndex)
        .controls.status.setValue('confirmed');
    } else {
      this.specialismForm.insert(
        0,
        new FormGroup({
          name: new FormControl(
            this.specialsimInput.value,
            Validators.required
          ),
          status: new FormControl<IExpertSpecialism['status']>(
            'confirmed',
            Validators.required
          ),
        })
      );
    }

    this.specialsimInput.reset();
  }

  update(idx: number, status: IExpertSpecialism['status']): void {
    this.specialismForm.at(idx).controls.status.setValue(status);
  }

  removeOriginals(): void {
    this.specialismForm.controls
      .filter((c) => c.value.status === 'original')
      .forEach((c) => c.controls.status.setValue('removed'));
  }
}
