<div class="scheduler-container">
  <div class="scheduler-header">
    <h2 data-test-id="schedule-title">{{title}}</h2>
    <mat-icon (click)="closeScheduler()" style="cursor: pointer;" data-test-id="schedule-close-button">close</mat-icon>
  </div>

  <app-schedule-form *ngIf="this.data.action !== 'complete'" [formInputData]="data"
    (updatePayloadSignal)="assignUpdatePayload($event)">
  </app-schedule-form>

  <ng-container *ngIf="this.data.action === 'complete'" [ngTemplateOutlet]="completeCall"></ng-container>
</div>

<ng-template #completeCall>
  <div class="form-container">
    <div class="schedule-form">
      <div>
        <p>Please confirm that this call is now complete & this has been checked with the Expert/Client</p>
        <p>Activating engagements & moving the expert to completed will have to be done separately</p>
      </div>

      <div class="schedule-form-actions">
        <button class="button button--primary" (click)="confirmCallComplete(data.callAction.expertActionId)"
          data-test-id="schedule-form-confirm-button">
          Confirm Call Complete
        </button>
      </div>
    </div>
  </div>
</ng-template>