import { Pipe, PipeTransform } from '@angular/core';
import { IOpportunityAssignee } from '../models/opportunity.models';

@Pipe({
  name: 'podMembersColumn',
})
export class PodMembersColumnPipe implements PipeTransform {
  transform(
    assignee: IOpportunityAssignee,
    prop: string,
    isHeader?: boolean
  ): string {
    const getDefaultProp = (): string => assignee && (assignee[prop] ?? prop);
    const titleCase = (str: string): string =>
      str
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');

    switch (prop) {
      case 'name':
        return isHeader ? 'Name' : `${assignee.firstName} ${assignee.lastName}`;
      case 'email':
        return isHeader ? 'Email' : `${assignee.email}`;
      default:
        return isHeader ? titleCase(prop) : getDefaultProp();
    }
  }
}
