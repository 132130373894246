import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { catchError, Observable, of, OperatorFunction } from 'rxjs';

export const handleHttpError =
  <T extends Action>(
    cb: (message: string[]) => T
  ): OperatorFunction<Action, Action> =>
  (source): Observable<Action> =>
    source.pipe(
      catchError((err) => {
        const messages: string[] =
          err instanceof HttpErrorResponse &&
          typeof err.error === 'object' &&
          'errors' in err.error &&
          Array.isArray(err.error.errors)
            ? err.error.errors
            : err instanceof Error || err instanceof HttpErrorResponse
            ? [err.message]
            : [err.toString()];

        return of(cb(messages));
      })
    );
