import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ConnectPhase,
  connectPhaseList,
  ExpertValidationService,
  IExpert,
} from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';
import 'moment-timezone';

export interface IRespondedDialogInput {
  experts: IExpert[];
  opportunity: IOpportunity;
  targetPhase: ConnectPhase;
}

export type IRespondedDialogResponse = IExpert[];

@Component({
  selector: 'app-responded-dialog',
  templateUrl: './responded-dialog.component.html',
  styleUrls: ['./responded-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RespondedDialogComponent implements OnInit {
  public experts: IExpert[];
  public opportunity: IOpportunity;
  public error: boolean = false;
  public formValid: boolean = false;
  public targetPhaseIndex: number;

  constructor(
    private expertValidationService: ExpertValidationService,
    public dialogRef: MatDialogRef<RespondedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRespondedDialogInput
  ) {}

  public ngOnInit(): void {
    this.opportunity = this.data.opportunity;
    this.experts = this.data.experts.map((e) => {
      const opportunitySegment = (this.opportunity.segments || {})[
        e.opportunitySegmentId
      ];

      return {
        ...e,
        primaryEmail: this.getPocEmail(e),
        geographicTarget: opportunitySegment?.geography || '',
        profileType: opportunitySegment?.segment || '',
      };
    });
    this.targetPhaseIndex = connectPhaseList.indexOf(this.data.targetPhase);
    this.ensureValidForm();
  }

  private getPocEmail(d: IExpert): string {
    return d.primaryEmail
      ? d.primaryEmail
      : d.opportunityEmails.length === 1
      ? d.opportunityEmails.find(Boolean)
      : undefined;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    if (this.formValid) {
      this.dialogRef.close(
        this.experts.map((e) => ({ ...e, connectPhase: this.data.targetPhase }))
      );
    }
  }

  public ensureValidForm(): void {
    this.formValid = this.expertValidationService.isValid(
      this.experts,
      this.data.targetPhase
    );
  }
}
