import { InjectionToken } from '@angular/core';
import rg4js from 'raygun4js';

export type Raygun = typeof rg4js;
export const RAY_GUN = new InjectionToken<Raygun>('RAY_GUN');
export const RAY_GUN_API_KEY = new InjectionToken<Raygun>('RAY_GUN_API_KEY');
export const APPLICATION_VERSION = new InjectionToken<Raygun>(
  'APPLICATION_VERSION'
);

export const getRaygunFactory = (apiKey: string, version: string): Raygun => {
  rg4js('apiKey', apiKey);
  rg4js('setVersion', version);
  rg4js('enableCrashReporting', true);
  rg4js('options', { excludedHostnames: ['localhost'] });
  return rg4js;
};
