import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ct-slide-toggle',
  template: `<div
    class="ct-slide-toggle"
    [matTooltip]="tooltip"
    matTooltipPositionAtOrigin="matTooltipPositionAtOrigin"
    matTooltipPosition="{{ matTooltipPosition }}"
  >
    <mat-slide-toggle
      class="nav-link-icon"
      name="callRecording"
      [(ngModel)]="value"
      (ngModelChange)="toggleChangeSignal.emit(value)"
      [disabled]="disabled"
      data-test-id="slide-toggle-button"
    >
    </mat-slide-toggle>
    <span class="ct-slide-toggle__label" data-test-id="slide-toggle-label">
      {{ label }}
    </span>
  </div>`,
})
export class SlideToggleComponent {
  @Input() value: boolean;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() matTooltipPositionAtOrigin = true;
  @Input() matTooltipPosition = 'right';
  @Input() disabled = false;
  @Output() toggleChangeSignal = new EventEmitter();
}
