<div class="appMessages" data-test-id="toast-notification">
  <ng-container *ngFor="let message of messages$ | async">
    <div class="alert" *ngIf="!message.dismissed" [ngClass]="{
                    'alert-info': message.style === 'info',
                    'alert-warning alert-with-icon' : message.style === 'error', 'alert-with-icon' : message.style === 'error',
                    'alert-success' : message.style === 'success',
                    'alert-primary': message.style === 'primary',
                    'alert-danger alert-with-icon' : message.style === 'danger', 'alert-with-icon' : message.style === 'error'
                    }">

      <ng-container *ngIf="message.style === 'error' || message.style === 'danger'">
        <mat-icon class="imageIcon">notifications_active</mat-icon>
      </ng-container>

      <ng-container *ngIf="message.style === 'success'">
        <mat-icon class="imageIcon">check_circle</mat-icon>
      </ng-container>

      <span data-test-id="toast-notification-content" [innerHTML]="message.content"></span>

      <mat-icon class="closeIcon" (click)="message.dismissNotification()">close</mat-icon>

      <mat-progress-bar mode="determinate" [value]="message.countdown"></mat-progress-bar>
    </div>
  </ng-container>
</div>