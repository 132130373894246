import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IExpertScreenerAction } from '../models/expertScreenerActions.models';

@Injectable({ providedIn: 'root' })
export class SurveysService {
  private readonly baseUrl = '/survey';

  constructor(private http: HttpClient) {}

  createForExpert(expertId: string): Observable<IExpertScreenerAction> {
    return this.http.put<IExpertScreenerAction>(
      `${this.baseUrl}/expert/${expertId}`,
      {}
    );
  }

  getById(expertActionId: string): Observable<IExpertScreenerAction> {
    return this.http.get<IExpertScreenerAction>(
      `${this.baseUrl}/screener/${expertActionId}`
    );
  }

  update(
    update: Partial<IExpertScreenerAction> & { expertActionId: string }
  ): Observable<IExpertScreenerAction> {
    const { expertActionId, ...rest } = update;
    return this.http.patch<IExpertScreenerAction>(
      `${this.baseUrl}/screener/${expertActionId}`,
      rest
    );
  }
}
