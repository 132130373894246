import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { AppService } from 'app/shared/services/app.service';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, mergeMap, reduce, tap } from 'rxjs/operators';
import {
  ExpertSourceCreateRequest,
  ICreateCommercialUpload,
  IExpertCloneAsyncCreateReq,
  IExpertSearchFirstThoughtReq,
  IExpertSourceAsyncCreateReq,
  IExpertSourceCreateBaseRequest,
  IExpertSourceCreateResponse,
  IUploadManualExpertsPayload,
} from '../models/expert-source-requests.models';
import { IExpertSource } from '../models/expert-sources.models';

@Injectable({
  providedIn: 'root',
})
export class ExpertSourcesService {
  private readonly baseUrl = '/experts-search';

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private appService: AppService
  ) {}

  getById(expertSourceId: string): Observable<IExpertSource> {
    return this.http.get<IExpertSource>(`${this.baseUrl}/${expertSourceId}`);
  }

  getByOpportunity(opportunityId: string): Observable<IExpertSource[]> {
    return this.http.get<IExpertSource[]>(`${this.baseUrl}/query`, {
      params: { opportunityId },
    });
  }

  uploadManualExperts(
    { experts: allExperts, ...mappingData }: IUploadManualExpertsPayload,
    batchSize = 3000
  ): Observable<IExpertSource[]> {
    const expertBatches = batchSize
      ? this.appService.chunkArray(batchSize, allExperts)
      : [allExperts];

    return from(expertBatches).pipe(
      mergeMap((experts, i) =>
        this.http
          .post<IExpertSource[]>(
            `${this.baseUrl}/manual-upload?expertOffset=${
              i * (batchSize || 0)
            }`,
            {
              ...mappingData,
              experts,
            }
          )
          .pipe(
            catchError((err) => {
              this.toastService.sendMessage(
                err.error?.message || err.message || JSON.stringify(err),
                'error'
              );
              return EMPTY;
            })
          )
      ),
      reduce((prev, curr) => prev.concat(curr), [])
    );
  }

  createAsyncClone(
    req: IExpertCloneAsyncCreateReq
  ): Observable<IExpertSourceCreateResponse> {
    return this.http
      .post<IExpertSourceCreateResponse>(`${this.baseUrl}/clone`, req)
      .pipe(
        tap(() =>
          this.toastService.sendMessage(
            `Clone started for ${req.expertIds.length} experts`,
            'success'
          )
        )
      );
  }

  createFirstThought(
    req: IExpertSearchFirstThoughtReq
  ): Observable<IExpertSource> {
    return this.http
      .post<IExpertSource>(`${this.baseUrl}/first-thought`, req)
      .pipe(
        tap(() =>
          this.toastService.sendMessage(
            `Search started for ${req.expertIds.length} First Thought experts`,
            'success'
          )
        )
      );
  }

  createAsyncCognisearch(
    req: IExpertSourceAsyncCreateReq
  ): Observable<IExpertSourceCreateResponse> {
    return this.http
      .post<IExpertSourceCreateResponse>(this.baseUrl, req)
      .pipe(
        tap(() =>
          this.toastService.sendMessage(
            `Cognisearch started querying for ${req.requestCount} experts`,
            'success'
          )
        )
      );
  }

  createCommercialUpload(
    req: ICreateCommercialUpload
  ): Observable<IExpertSource> {
    return this.http
      .post<IExpertSource>(`${this.baseUrl}/commercial-upload`, req)
      .pipe(
        tap(() =>
          this.toastService.sendMessage(
            `Commercial upload started querying for ${req.requestCount} experts`,
            'success'
          )
        )
      );
  }

  create<T = ExpertSourceCreateRequest>(
    req: T & IExpertSourceCreateBaseRequest
  ): Observable<IExpertSourceCreateResponse> {
    return this.http.post<IExpertSourceCreateResponse>(`${this.baseUrl}`, req);
  }
}
