import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss'],
})
export class AccountSummaryComponent {
  @Input() activeProjectCount: number;
  @Input() openConnectionsForUser: number;
  @Input() emailStats: any = {};
  @Input() upcomingConferences: number;
}
