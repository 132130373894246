import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IOpportunitySegment } from '@techspert-io/opportunity-segments';
import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { SegmentKickOff } from '../../../models/segment-data.models';
import {
  ISegmentDialogData,
  SegmentDialogComponent,
} from '../../dialogs/segment-dialog/segment-dialog.component';

@Component({
  selector: 'app-segment-form',
  templateUrl: './segment-form.component.html',
  styleUrls: ['./segment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentFormKickOffComponent {
  @Input() isNDAssignment = false;

  oppSegmentsColumns = [
    'unitsPerEngagement',
    'amount',
    'segment',
    'conferenceTopic',
    'owner',
    'select',
  ];

  segmentSelection = new SelectionModel<SegmentKickOff>(true, []);

  get assigneesCtrlValue() {
    return this.rootFormGroup.control.value['opportunityAssignees'];
  }

  get segmentsCtrlValue() {
    return this.rootFormGroup.control.value['segments'];
  }

  get segmentConfigCtrlValue() {
    return this.rootFormGroup.control.value['segmentConfig'];
  }

  get defaultConferenceTopicCtrlValue() {
    return this.rootFormGroup.control.value['defaultConferenceTopic'];
  }

  get defaultProjectPitchCtrlValue() {
    return this.rootFormGroup.control.value['defaultProjectPitch'];
  }

  get segmentsControl() {
    return this.rootFormGroup.control.get('segments');
  }

  get oppSegments(): IOpportunitySegment[] {
    return this.segmentConfigCtrlValue
      ? this.mergeSegmentData(
          this.segmentsCtrlValue,
          this.segmentConfigCtrlValue,
          'salesforceId'
        )
      : this.segmentsCtrlValue;
  }

  constructor(
    private rootFormGroup: FormGroupDirective,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  bulkUpdateSegments(): void {
    this.openSegmentsModal(
      this.segmentSelection.selected.length === 1
        ? this.segmentSelection.selected[0]
        : null
    )
      .pipe(
        tap((seg) => {
          const selectMap = this.segmentSelection.selected.reduce(
            (prev, curr) => ({
              ...prev,
              [curr.salesforceId]: {
                ...curr,
                ...seg,
                salesforceId: curr.salesforceId,
              },
            }),
            {}
          );
          this.updateSegments((s) =>
            selectMap[s.salesforceId]
              ? { ...s, ...selectMap[s.salesforceId] }
              : s
          );
        })
      )
      .subscribe(() => {
        this.segmentSelection.clear();
        this.cdr.detectChanges();
      });
  }

  editSegment(data: SegmentKickOff): void {
    this.openSegmentsModal(data)
      .pipe(
        tap((seg) =>
          this.updateSegments((s) =>
            s.salesforceId === seg.salesforceId ? { ...s, ...seg } : s
          )
        )
      )
      .subscribe(() => this.cdr.detectChanges());
  }

  private mergeSegmentData = (
    segments: SegmentKickOff[],
    configs: SegmentKickOff[],
    fieldToCompare: string
  ) => {
    return segments.map((item) => {
      const matchedData = configs[item[fieldToCompare]];

      return matchedData
        ? {
            ...matchedData,
            ...item,
            owner: item.owner.length > 0 ? item.owner : matchedData.owner,
            cover: item.cover.length > 0 ? item.cover : matchedData.cover,
          }
        : item;
    });
  };

  private updateSegments(mapFn: (v: SegmentKickOff) => SegmentKickOff): void {
    const updatedValue = this.segmentsControl.value.map(mapFn);
    this.segmentsControl.setValue(updatedValue);
  }

  private openSegmentsModal(
    data?: SegmentKickOff
  ): Observable<ISegmentDialogData['segment']> {
    return this.dialog
      .open<
        SegmentDialogComponent,
        ISegmentDialogData,
        ISegmentDialogData['segment']
      >(SegmentDialogComponent, {
        width: '840px',
        data: {
          segment: data,
          assignees: this.assigneesCtrlValue,
          defaultConferenceTopic: this.defaultConferenceTopicCtrlValue,
          defaultPitch: this.defaultProjectPitchCtrlValue,
          isNDAssignment: this.isNDAssignment,
        },
      })
      .afterClosed()
      .pipe(takeWhile((d) => !!d));
  }
}
