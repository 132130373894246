import { IUserBase, IUserTimezone } from '@techspert-io/auth';
import { IClientSettings } from '@techspert-io/clients';
import {
  IOpportunitySegment,
  IOpportunitySegmentConfig,
  IOpportunitySegmentConfigExternalSurvey,
  IOpportunitySegmentOptions,
} from '@techspert-io/opportunity-segments';
import { IUpsertUserForClientContactRequest } from '@techspert-io/users';

export interface IOpportunityOptions extends IClientSettings {
  callTranscripts: boolean; // TODO: Not sure how/if this is used
  cepLog: boolean; // TODO: Not sure how/if this is used
  closedEmailSend: boolean;
  completedScreenerEmailSend?: boolean;
  conferenceReminderDefaultSettings: IConferenceReminderDefaultSettings;
  createExpertTranscriptSummary: boolean;
  createSegmentTranscriptSummary: boolean;
  enhancedTranscriptionEnabled: boolean;
  failedScreenerEmailSend?: boolean;
  omnisearchExpertNotification?: boolean;
  omnisearchHidden?: boolean;
  /** flag for if internal survey, external surveys will have this as false */
  screenerEnabled?: boolean;
  screenshare: boolean; // TODO: Not sure how/if this is used
}

export interface IClientContact {
  connectId?: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  primary: boolean;
  timezone: IUserTimezone;
}

export interface IStatPhase {
  activeExperts: number;
  experts: number;
  clientRejected: number;
  adminRejected: number;
  blocked: number;
}

export interface IDisplayStat {
  geography: string;
  segment: string;
  screening: number;
  inPortal: number;
  connectionsScheduled: number;
  connectionsCompleted: number;
  connectionsConfirmed: number;
  target: number;
  active?: boolean;
  hidden?: boolean;
  opportunitySegmentId?: string;
}

export interface ISearch {
  query: unknown;
  stats: IStats | {};
}

export interface ISearches {
  [key: string]: ISearch;
}

export interface IStats {
  [key: string]: {
    [key: string]: IBaseStats;
  };
}

export interface IBaseStats {
  identified?: IStatPhase;
  firstFollowUp?: IStatPhase;
  secondFollowUp?: IStatPhase;
  outreachComplete?: IStatPhase;
  outreach?: IStatPhase;
  screener?: IStatPhase;
  sentToClient?: IStatPhase;
  accepted?: IStatPhase;
  scheduled?: IStatPhase;
  completed?: IStatPhase;
}

export interface IAggregatedStats {
  geography: string;
  segment: string;
  stats: IBaseStats;
}

export interface IOpportunityAssignee {
  email: string;
  firstName: string;
  lastName: string;
  connectId?: string;
}

export interface IOpportunitySegmentWithId extends IOpportunitySegment {
  id: string;
}

export type IOpportunitySegments = Record<string, IOpportunitySegment>;
export type IOpportunitySegmentConfigs = Record<
  string,
  IOpportunitySegmentConfigLegacy
>;

type SalesforceStageName =
  | 'Not actively selling'
  | 'Actively selling'
  | 'Contract opportunity identified'
  | 'Initial discussion'
  | 'Outline proposal sent'
  | 'Formal contract negotiation'
  | 'Qualified'
  | 'Pre-Bid'
  | 'RFQ'
  | 'Repeat client, managed by PM team'
  | 'Project in progress'
  | 'To be invoiced'
  | 'First RFQ received'
  | 'Initial proposal sent'
  | 'First go ahead'
  | 'First project delivered - chasing for next'
  | 'Next projects being quoted and closed'
  | 'Stable Client - handed to PM and CS'
  | 'Closed Lost'
  | 'Closed Won'
  | 'Not relevant.';

type SalesforceFormOfConnection = 'Quant' | 'Qual' | 'Registration';
type SegmentSurveyProvider = 'External' | 'Qualtrics';

export enum OpportunityConferenceProviders {
  MeetTechspert = 'meet-techspert',
  ZoomTechspert = 'zoom-techspert',
  Other = 'other',
  ClientHosted = 'client-hosted',
}

export const OpportunityConferenceProviderOptionLabels = {
  [OpportunityConferenceProviders.MeetTechspert]: 'Techspert Meet',
  [OpportunityConferenceProviders.ZoomTechspert]: 'Zoom Conference',
  [OpportunityConferenceProviders.Other]: 'Manual',
  [OpportunityConferenceProviders.ClientHosted]: 'Client Hosted',
};

export interface IOpportunitySlackChannel {
  name: string;
  id: string;
  type: string;
}

export interface IOpportunityClientContact extends IUserBase {
  primary?: boolean;
}

export interface IOpportunity extends IOpportunityOptions {
  closeDate: string;
  countries: string[];
  startDate: string;
  endDate: string;
  lastUpdated: string;
  opportunityId: string;
  opportunityName: string;
  opportunityOwner: string;
  salesforceId: string;
  status: string;
  clientContactIds: string[];
  primaryContactId?: string;
  clientContacts: IOpportunityClientContact[];
  expertTargetQuantity?: number;
  searches: ISearches;
  opportunityAssigneeIds: string[];
  opportunityAssignees: IUserBase[];
  anticipatedCallTime?: number;
  clientId: string;
  notes?: string;
  segments: IOpportunitySegments;
  onHold: boolean;
  stageName: SalesforceStageName;
  publicDisplayId: string;
  projectType: SalesforceFormOfConnection;

  files: string[];
  defaultConferenceTopic?: string;
  defaultProjectPitch?: string;
  defaultSurveyProvider?: SegmentSurveyProvider;
  externalSurveyConfig?: IOpportunitySegmentConfigExternalSurvey;

  /* nullable due to legacy opps */
  segmentConfig?: IOpportunitySegmentConfigs;
  screenerConfig?: IOpportunityScreenerConfig;
  slackChannels: IOpportunitySlackChannel[];
  redirectLinkConfig?: {
    link: string;
    params?: Record<string, string>;
  };
  defaultConferenceProvider?: OpportunityConferenceProviders;
  initClientURL?: string;
}

export interface IOpportunityCreateResponse extends IOpportunity {
  failedUsers: IUserBase[];
}

export interface IConferenceReminderDefaultSettings {
  sendEmailBeforeSeconds: number[];
  enabled: boolean;
}

export interface ISalesForceOpportunity {
  sfOppId: string;
  sfAccountId: string;
  closeDate: string;
  clientContacts: IClientContact[];
  segments: IOpportunitySegments;
  countries: string[];
  name: string;
  ownerName: string;
  clientName: string;
  clientId: string;
  currency: string;
  lastModifiedDate: Date;
  blind: boolean;
  closedEmailSend: boolean;
  createSlackChannel: boolean;
  projectType: SalesforceFormOfConnection;
  defaultSurveyProvider: SegmentSurveyProvider;
  defaultConferenceProvider: OpportunityConferenceProviders;
}

export interface IOpportunityCreateRequest
  extends Partial<
    Omit<IOpportunity, 'clientContacts' | 'opportunityAssignees'>
  > {
  clientId: string;
  sfOpportunityId: string;
  createSlackChannel: boolean;
  pmEmail: string;
  opportunityName: string;
  countries: string[];
  closeDate: string;
  opportunityOwner: string;
  notes?: string;
  segments: IOpportunitySegments;
  clientContactRequests: Omit<
    IUpsertUserForClientContactRequest,
    'opportunityData' | 'senderEmail'
  >[];
  primaryContactEmail: string;
  initSurveyId?: string;
}

export interface IOpportunitySegmentConfigLegacy
  extends Omit<
    IOpportunitySegmentConfig & IOpportunitySegmentOptions,
    'ownerConnectIds' | 'coverConnectIds' | 'topic' | 'templateKeys'
  > {
  conferenceTopic?: string;
  templateKeys?: Record<string, string | number>;
  owner: string[];
  cover: string[];
}

export interface IOpportunityScreenerConfigItem {
  name: string;
  id: string;
}

export interface IOpportunityScreenerConfig {
  mailingListId: string;
  surveys: IOpportunityScreenerConfigItem[];
}

export interface IOpportunityUpdateClientContact {
  email: string;
  primary?: boolean;
  country: string;
  firstName: string;
  lastName: string;
  timezone: IUserTimezone;
}

export interface IOpportunityUpdateAssignee {
  email: string;
}

export interface IOpportunityUpdateRequest
  extends Partial<
    Omit<IOpportunity, 'clientContacts' | 'opportunityAssignees'>
  > {
  opportunityId: string;
}

export interface IClientContactDialogData {
  data?: IUserBase[];
}

export interface IOpportunityUpdateForm
  extends Omit<IOpportunityUpdateRequest, 'clientContacts'> {
  clientContacts: IUserBase[];
  opportunityAssignees?: IUserBase[];
}
