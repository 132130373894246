import { Component, Input } from '@angular/core';
import { IExpertScreenerAction } from '@techspert-io/surveys';
import 'moment-timezone';

@Component({
  selector: 'app-screener-box',
  templateUrl: './screener-box.component.html',
  styleUrls: ['./screener-box.component.scss'],
})
export class ScreenerBoxComponent {
  @Input() screenerConfig: IExpertScreenerAction;
}
