import { NgModule } from '@angular/core';
import { MaterialModule } from '../../shared/patterns/material-module';
import { OutreachTemplateContentFormComponent } from './components/forms/outreach-template-content-form/outreach-template-content-form.component';
import { OutreachTemplateFormComponent } from './components/forms/outreach-template-form/outreach-template-form.component';
import { OutreachTemplatesDiagnosticsComponent } from './components/layout/outreach-templates-diagnostics/outreach-templates-diagnostics.component';
import { OutreachTemplatesCreatePageComponent } from './components/pages/outreach-templates-create-page/outreach-templates-create-page.component';
import { OutreachTemplatesEditPageComponent } from './components/pages/outreach-templates-edit-page/outreach-templates-edit-page.component';
import { OutreachTemplatesLandingPageComponent } from './components/pages/outreach-templates-landing-page/outreach-templates-landing-page.component';
import { OutreachTemplatesPageComponent } from './components/pages/outreach-templates-page/outreach-templates-page.component';
import { OutreachTemplatesRoutingModule } from './outreach-templates-routing.module';

@NgModule({
  declarations: [
    OutreachTemplatesPageComponent,
    OutreachTemplateFormComponent,
    OutreachTemplatesCreatePageComponent,
    OutreachTemplatesLandingPageComponent,
    OutreachTemplatesEditPageComponent,
    OutreachTemplatesDiagnosticsComponent,
    OutreachTemplateContentFormComponent,
  ],
  imports: [MaterialModule, OutreachTemplatesRoutingModule],
  exports: [OutreachTemplateContentFormComponent],
})
export class OutreachTemplatesModule {}
