import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CognitoAuthService, IAuthRole } from '@techspert-io/auth';
import {
  ClientActions,
  IClient,
  selectAllClients,
} from '@techspert-io/clients';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { IAuthUserCreateRequest } from '../../models/user.models';
import { UserService } from '../../services/user.service';

interface IUserCreateVM {
  clients: IClient[];
  authRoles: IAuthRole[];
}

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss'],
})
export class UserInviteComponent implements OnInit {
  vmData$: Observable<IUserCreateVM> = EMPTY;
  isLoading: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private store: Store,
    private cognitoAuthService: CognitoAuthService
  ) {}

  ngOnInit(): void {
    this.vmData$ = combineLatest([
      this.store.select(selectAllClients),
      this.cognitoAuthService.listRoles(),
    ]).pipe(map(([clients, authRoles]) => ({ clients, authRoles })));

    this.store.dispatch(ClientActions.fetchAllClients());
  }

  inviteUser(payload: IAuthUserCreateRequest): void {
    this.isLoading = true;

    this.userService
      .create(payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => this.router.navigateByUrl('/admin/users'));
  }
}
