import { MessageStatusMap } from '../../../../shared/models/email';
import {
  ISolicitationHistory,
  ISolicitationOutreach,
} from '../../models/solicitation-outreach.models';

export const baseSolicitationOutreachMock: ISolicitationOutreach = {
  emailId: 'emailId',
  recipient: 'recipient',
  expertId: 'expertId',
  batchId: 'batchId',
  connectPhase: 'outreach',
  searchExpertId: 'searchExpertId',
  trackingId: 'trackingId',
  opportunityId: 'opportunityId',
  dateCreated: 1234,
};

export const baseSolicitationHistoryMock: ISolicitationHistory = {
  ...baseSolicitationOutreachMock,
  status: MessageStatusMap.providerUnsubscribe,
};
