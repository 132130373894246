import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  IClient,
  IClientDefaultTemplate,
  IClientFeatures,
} from '../models/clients.models';

export const ClientActions = createActionGroup({
  source: 'Clients',
  events: {
    'Fetch All Clients': emptyProps(),
    'Fetch Client': props<{ clientId: string }>(),
    'Update Client': props<{
      client: Partial<IClient> & {
        clientId: string;
        features?: Partial<IClientFeatures>;
        opportunitiesTemplate?: Partial<IClientDefaultTemplate>;
      };
    }>(),
    'Build Contract Report For Client': props<{ clientId: string }>(),
  },
});

export const ClientAPIActions = createActionGroup({
  source: 'Clients API',
  events: {
    'Fetch All Clients Success': props<{ clients: IClient[] }>(),
    'Fetch All Clients Failure': props<{ error: string[] }>(),
    'Fetch Client Success': props<{ client: IClient }>(),
    'Fetch Client Failure': props<{ error: string[]; clientId: string }>(),
    'Update Client Success': props<{ client: IClient }>(),
    'Update Client Failure': props<{ error: string[]; clientId: string }>(),
    'Build Contract Report For Client Success': emptyProps(),
    'Build Contract Report For Client Failure': props<{
      error: string[];
      clientId: string;
    }>(),
  },
});
