import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IClient } from '@techspert-io/clients';
import {
  ConnectPhase,
  ExpertValidationService,
  IDisplayExpert,
  IExpert,
  IExpertUpdateRequest,
  connectPhaseList,
  getExpertRejectionMock,
} from '@techspert-io/experts';
import { IOpportunity, PortalLinksPipe } from '@techspert-io/opportunities';
import {
  IExpertScreenerAction,
  ScreenerResultDialogComponent,
} from '@techspert-io/surveys';
import { EMPTY, Observable, Subject, fromEvent, merge, of } from 'rxjs';
import {
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from '../../../../shared/patterns/confirmation-dialog/confirmation-dialog.component';
import {
  IOpportunitySearchSelectDialogData,
  OpportunitySearchSelectDialogComponent,
} from './opportunity-search-select-dialog/opportunity-search-select-dialog.component';
import {
  IRespondedDialogInput,
  IRespondedDialogResponse,
  RespondedDialogComponent,
} from './responded-dialog/responded-dialog.component';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import {
  ISendSolicitationDialogData,
  SendSolicitationDialogComponent,
} from './send-solicitation-dialog/send-solicitation-dialog.component';
import { OpportunityExpertsService } from './services/opportunity-experts.service';

type OpportunityPhases = ConnectPhase | 'rejected';

@Component({
  selector: 'app-opportunity-results',
  templateUrl: './opportunity-results.component.html',
  styleUrls: ['./opportunity-results.component.scss'],
  providers: [PortalLinksPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityResultsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() client: IClient;
  @Input() opportunity: IOpportunity;
  @Input() selectedPhase: OpportunityPhases = 'identified';

  @ViewChild('phaseMenu')
  phaseMenu: TemplateRef<unknown>;

  private destory$ = new Subject();

  selectedSearch: string = 'all-searches';
  expertsTotal$: Observable<number> = EMPTY;
  overlayRef: OverlayRef | null;
  experts: IDisplayExpert[] = [];
  filteredExperts: IDisplayExpert[] = [];
  searchTerm = '';
  showLoader: boolean = false;
  selectAllToggle: boolean = false;
  selectedExpertsCount: number = 0;
  filterEmails: boolean = true;
  filterAutoExperts: boolean = true;
  openFilter: boolean = false;
  showAffiliationsToggle = false;
  showNoExperts: boolean = false;
  selectedSearches: string[] = [];
  eligiblePhaseMenuOptions: Array<KeyValue<string, string>> = [];

  get hasAutomatedExperts(): boolean {
    return this.experts.some((d) => !!d.campaignId);
  }

  private static readonly PHASES: Map<OpportunityPhases, string> = new Map([
    ['identified', 'Identified'],
    ['firstFollowUp', 'Follow up 1'],
    ['secondFollowUp', 'Follow up 2'],
    ['outreachComplete', 'Outreach Complete'],
    ['outreach', 'Responded'],
    ['screener', 'Screener'],
    ['sentToClient', 'In Portal'],
    ['accepted', 'Accepted'],
    ['scheduled', 'Scheduled'],
    ['completed', 'Completed'],
    ['rejected', 'Reject'],
  ]);
  static readonly NEXT = 'next';
  static readonly PREVIOUS = 'previous';

  constructor(
    private router: Router,
    private title: Title,
    private dialog: MatDialog,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private opportunityExpertsService: OpportunityExpertsService,
    private expertValidationService: ExpertValidationService
  ) {}

  ngOnInit(): void {
    this.initialiseData(this.opportunity);

    const loadingState$ =
      this.opportunityExpertsService.downloadedSearches$.pipe(
        tap(
          (res) =>
            (this.showLoader = Object.values(res).some((d) =>
              ['init', 'loading'].includes(d)
            ))
        )
      );

    const experts$ = this.opportunityExpertsService.experts$.pipe(
      tap((experts) => {
        this.experts = experts;
        this.applyFilter();
      })
    );

    merge(loadingState$, experts$).pipe(takeUntil(this.destory$)).subscribe();

    this.expertsTotal$ = this.opportunityExpertsService.expertTotals$.pipe(
      map((expertsTotal) =>
        Object.values(expertsTotal).reduce(
          (prev, val) => prev + Object.values(val).reduce((p, c) => p + c, 0),
          this.experts.filter((d) => Object.values(d.rejection).some(Boolean))
            .length
        )
      )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.opportunity &&
      !changes.opportunity.firstChange &&
      changes.opportunity.currentValue.opportunityId !==
        changes.opportunity.previousValue.opportunityId
    ) {
      this.searchTerm = '';
      this.selectedExpertsCount = 0;
      this.selectAllToggle = false;
      this.filterEmails = true;
      this.filterAutoExperts = true;
      this.initialiseData(changes.opportunity.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destory$.next(null);
    this.destory$.complete();
    this.title.setTitle('CONNECT');
  }

  addExpertiseToSearch(expertise: string): void {
    this.searchTerm = expertise;
    this.applyFilter();
  }

  navigateToExpert(expert: IExpert): void {
    this.selectPhase(
      Object.values(expert.rejection).some(Boolean) || expert.blocked
        ? 'rejected'
        : expert.connectPhase
    );
  }

  toggleBlockExpert(status: { index: number }): void {
    const expert = this.filteredExperts[status.index];
    this.opportunityExpertsService.blockExpert({
      expertId: expert.expertId,
      blocked: !expert.blocked,
    });
  }

  rejectExperts(): void {
    const experts = this.experts
      .filter((expert) => expert.isSelected)
      .map((expert) => ({
        rejection: {
          ...expert.rejection,
          admin: getExpertRejectionMock(Date.now()),
        },
        expertId: expert.expertId,
      }));

    this.updateExperts(experts).subscribe();
  }

  unrejectExperts(): void {
    const updatedExperts = this.experts.filter((expert) => expert.isSelected);

    const nonAdminRejected = updatedExperts.filter((e) =>
      Object.entries(e.rejection).some(([type, r]) => r && type !== 'admin')
    );

    const expertUpdates = updatedExperts.map(({ expertId }) => ({
      expertId,
      rejection: {},
    }));

    if (nonAdminRejected.length) {
      const expertText = nonAdminRejected.length > 1 ? 'experts' : 'expert';

      this.dialog
        .open<ConfirmationDialogComponent, IConfirmationDialogData, boolean>(
          ConfirmationDialogComponent,
          {
            width: '400px',
            height: '150px',
            data: {
              messages: [
                `This will overwrite the expert's and/or client's rejection for ${nonAdminRejected.length} ${expertText}. Are you sure?`,
              ],
              positiveButtonText: 'Yes',
              negativeButtonText: 'No',
              isDangerous: true,
            },
          }
        )
        .afterClosed()
        .pipe(
          takeWhile((result) => result),
          switchMap(() => this.updateExperts(expertUpdates))
        )
        .subscribe();
    } else {
      this.updateExperts(expertUpdates).subscribe();
    }
  }

  toggleAdminRejectedExpertStatus(expert: IExpert): void {
    if (Object.values(expert.rejection).every((r) => !r)) {
      this.updateExperts([
        {
          expertId: expert.expertId,
          rejection: {
            ...expert.rejection,
            admin: getExpertRejectionMock(Date.now()),
          },
        },
      ]).subscribe();
    } else if (
      Object.entries(expert.rejection).some(
        ([type, r]) => r && type !== 'admin'
      )
    ) {
      this.dialog
        .open<ConfirmationDialogComponent, IConfirmationDialogData, boolean>(
          ConfirmationDialogComponent,
          {
            width: '400px',
            minHeight: '150px',
            data: {
              messages: [
                "This will overwrite the expert's and/or client's rejection for the target expert. Are you sure?",
              ],
              positiveButtonText: 'Yes',
              negativeButtonText: 'No',
              isDangerous: true,
            },
          }
        )
        .afterClosed()
        .pipe(
          takeWhile((result) => result),
          switchMap(() =>
            this.updateExperts([
              {
                expertId: expert.expertId,
                rejection: {},
                reasonForRejection: 'NULL',
              },
            ])
          )
        )
        .subscribe();
    } else if (expert.rejection.admin) {
      this.updateExperts([
        {
          expertId: expert.expertId,
          rejection: {},
          reasonForRejection: 'NULL',
        },
      ]).subscribe();
    }
  }

  selectPhase(event: OpportunityPhases): void {
    this.selectedExpertsCount = 0;
    this.selectAllToggle = false;
    this.selectedPhase = event;

    this.opportunityExpertsService.setSelected(
      this.experts.map((expert) => ({
        expertId: expert.expertId,
        isSelected: false,
      }))
    );

    this.router.navigate([
      'admin',
      'client',
      this.client.clientId,
      'opportunity',
      this.opportunity.opportunityId,
      'search',
      this.selectedSearch,
      'phase',
      this.selectedPhase,
    ]);
  }

  selectExpert(selectedExpert: IDisplayExpert): void {
    this.opportunityExpertsService.setSelected([
      {
        expertId: selectedExpert.expertId,
        isSelected: !selectedExpert.isSelected,
      },
    ]);
  }

  selectAllExperts(): void {
    this.selectAllToggle = !this.selectAllToggle;
    this.setExpertSelectedStatus(this.selectAllToggle);
  }

  applyFilterEmails(): void {
    this.filterEmails = !this.filterEmails;
    this.applyFilter();
  }

  applyFilterAutoExperts(): void {
    this.filterAutoExperts = !this.filterAutoExperts;
    this.applyFilter();
  }

  applyFilter(): void {
    this.filteredExperts = this.experts.filter(
      (expert) =>
        this.searchFilter(expert) &&
        (expert.connectPhase === this.selectedPhase ||
          this.selectedPhase === 'rejected') &&
        (this.selectedPhaseNotRejected(expert) ||
          this.selectedPhaseRejected(expert)) &&
        (expert.primaryEmail ||
          expert.opportunityEmails.length ||
          !this.filterEmails) &&
        (!this.searchTerm || this.checkFields(expert)) &&
        (expert.isSelectable || !this.filterAutoExperts)
    );

    this.openFilter = false;
    if (!this.filteredExperts.length && this.searchTerm) {
      this.openFilter = true;
      this.filteredExperts = this.experts.filter(
        (expert) => !this.searchTerm || this.checkFields(expert)
      );
    }
    this.updateSelectedCount();
    this.showNoExperts = this.filteredExperts.length === 0 && !this.showLoader;
  }

  openEmailDialog(): void {
    if (this.selectedPhase === 'rejected') return;

    const reachableExperts = this.experts.filter(
      (expert) =>
        expert.isSelected &&
        (expert.primaryEmail || expert.opportunityEmails.length)
    );

    const reachableExpertMap = reachableExperts.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.expertId]: curr,
      }),
      {}
    );

    const experts = this.experts.map((expert) => ({
      ...expert,
      isSelected: !!reachableExpertMap[expert.expertId],
    }));
    this.opportunityExpertsService.setSelected(experts);

    this.dialog
      .open<
        SendSolicitationDialogComponent,
        ISendSolicitationDialogData,
        undefined
      >(SendSolicitationDialogComponent, {
        width: '1600px',
        height: '80%',
        data: {
          experts: reachableExperts,
          opportunity: this.opportunity,
          activePhase: this.selectedPhase,
          allExperts: experts,
        },
      })
      .afterClosed()
      .subscribe();
  }

  openRespondedDialog(targetPhase: ConnectPhase): void {
    this.dialog
      .open<
        RespondedDialogComponent,
        IRespondedDialogInput,
        IRespondedDialogResponse
      >(RespondedDialogComponent, {
        width: '750px',
        height: '80%',
        data: {
          experts: this.experts.filter((expert) => expert.isSelected),
          opportunity: this.opportunity,
          targetPhase: targetPhase,
        },
      })
      .afterClosed()
      .pipe(
        filter((experts) => !!experts && !!experts.length),
        switchMap((experts) => this.updateExperts(experts))
      )
      .subscribe();
  }

  getPhase(
    currentPhase: OpportunityPhases,
    direction: string
  ): OpportunityPhases {
    const phases = Array.from(OpportunityResultsComponent.PHASES.keys());
    switch (direction) {
      case OpportunityResultsComponent.PREVIOUS:
        return phases[phases.indexOf(currentPhase) - 1] || 'identified';
      case OpportunityResultsComponent.NEXT:
        if (currentPhase === 'completed') return currentPhase;
        // TODO: Below will never return rejected
        return phases[phases.indexOf(currentPhase) + 1] || 'rejected';
    }
  }

  openSearch(): void {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      width: '100%',
      height: '95%',
      data: {
        experts: this.experts,
        client: this.client,
        opportunity: this.opportunity,
      },
    });
    dialogRef.componentInstance.updateOpportunitySignal
      .pipe(filter((opportunity) => !!opportunity))
      .subscribe(
        (opportunity) =>
          (this.selectedSearches = Object.keys(opportunity.searches))
      );
    dialogRef
      .afterClosed()
      .pipe(
        finalize(() =>
          this.opportunityExpertsService.getSearches({
            opportunityId: this.opportunity.opportunityId,
            searchIds: this.selectedSearches,
            fromPhase: 'outreach',
          })
        )
      )
      .subscribe(() => this.initialiseData(this.opportunity));
  }

  attachPhaseMenuOverlay(event: MouseEvent, action: 'previous' | 'next'): void {
    this.closePhaseMenuOverlay();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(event)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.phaseMenu, this.viewContainerRef, {
        action,
      })
    );

    fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.closePhaseMenuOverlay());
  }

  showExpertScreenerResult(expert: IDisplayExpert): void {
    if (expert.screenerConfig.dateCompleted) {
      this.dialog
        .open<
          ScreenerResultDialogComponent,
          { expertActionId: string },
          IExpertScreenerAction
        >(ScreenerResultDialogComponent, {
          width: '900px',
          minHeight: '80%',
          data: {
            expertActionId: expert.screenerConfig.expertActionId,
          },
          disableClose: false,
        })
        .afterClosed()
        .pipe(
          takeWhile((screenerConfig) => !!screenerConfig),
          tap((screenerConfig) =>
            this.opportunityExpertsService.patchExpert({
              ...expert,
              screenerConfig,
            })
          )
        )
        .subscribe();
    }
  }

  showSearchesSelect(): void {
    this.dialog
      .open<
        OpportunitySearchSelectDialogComponent,
        IOpportunitySearchSelectDialogData,
        string[]
      >(OpportunitySearchSelectDialogComponent, {
        width: '900px',
        minHeight: '75%',
        data: {
          searches: this.selectedSearches,
          opportunityId: this.opportunity.opportunityId,
        },
      })
      .afterClosed()
      .pipe(
        takeWhile((res) => !!res),
        map((res) => {
          this.selectedSearches = res;
          this.applyFilter();
        })
      )
      .subscribe();
  }

  closePhaseMenuOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  forwardRightClick(event: MouseEvent): void {
    this.eligiblePhaseMenuOptions = this.getPhasesBeforeOrAfter(
      this.selectedPhase,
      false
    );
    this.attachPhaseMenuOverlay(event, 'next');
  }

  backwardRightClick(event: MouseEvent): void {
    this.eligiblePhaseMenuOptions = this.getPhasesBeforeOrAfter(
      this.selectedPhase,
      true
    );
    this.attachPhaseMenuOverlay(event, 'previous');
  }

  isBackButtonDisabled(): boolean {
    return (
      !this.selectedExpertsCount ||
      (this.selectedExpertsCount > 1 &&
        (this.selectedPhase === 'completed' ||
          this.selectedPhase === 'scheduled'))
    );
  }

  handlePhaseMenuSelect(
    action: 'previous' | 'next',
    phase?: OpportunityPhases
  ): void {
    this.moveExperts(
      phase || this.getPhase(this.selectedPhase, action),
      action
    );
  }

  private updateSelectedCount(): void {
    this.selectedExpertsCount = this.experts.filter(
      (expert) => expert.isSelected
    ).length;
  }

  private checkFields(expert: IDisplayExpert): boolean {
    return (
      this.checkName(expert) ||
      this.checkLastName(expert) ||
      this.checkEmail(expert) ||
      this.checkScreener(expert)
    );
  }

  private checkEmail(expert: IExpert): boolean {
    return (expert.opportunityEmails || []).some((email) =>
      email.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  private checkName(expert: IExpert): boolean {
    const expertFullName = `${expert.firstName} ${expert.lastName}`;
    return (
      expertFullName &&
      expertFullName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  private checkLastName(expert: IExpert): boolean {
    return (
      expert.lastName &&
      expert.lastName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  private checkScreener(expert: IDisplayExpert): boolean {
    return (
      expert.screenerConfig?.expertActionId &&
      expert.screenerConfig?.expertActionId === this.searchTerm.toLowerCase()
    );
  }

  private selectedPhaseNotRejected(expert: IExpert): boolean {
    return (
      this.selectedPhase !== 'rejected' &&
      Object.values(expert.rejection).every((r) => !r)
    );
  }

  private selectedPhaseRejected(expert: IExpert): boolean {
    return (
      this.selectedPhase === 'rejected' &&
      Object.values(expert.rejection).some(Boolean)
    );
  }

  private searchFilter(expert: IExpert): boolean {
    return this.selectedSearches.includes(expert.searchId);
  }

  private getPhasesBeforeOrAfter(
    currentPhase: OpportunityPhases,
    fetchPreceding: boolean
  ): Array<KeyValue<string, string>> {
    const phases = Array.from(OpportunityResultsComponent.PHASES.keys());

    const currentPhaseIndex = phases.indexOf(currentPhase);
    const outReachPhaseIndex = phases.indexOf('outreach');

    let start = 0;
    let end = currentPhaseIndex - 1;

    if (!fetchPreceding) {
      start = currentPhaseIndex + 1;
      end = phases.length - 1;
    } else if (currentPhaseIndex >= outReachPhaseIndex) {
      start = outReachPhaseIndex;
    }

    const result: Array<KeyValue<string, string>> = [];
    while (start <= end) {
      result.push({
        key: phases[start],
        value: OpportunityResultsComponent.PHASES.get(phases[start]),
      });
      start++;
    }

    if (fetchPreceding) {
      return result.reverse();
    } else {
      return result;
    }
  }

  private moveExperts(targetPhase: OpportunityPhases, action: string): void {
    this.closePhaseMenuOverlay();

    if (targetPhase === 'rejected') {
      this.rejectExperts();
    } else if (
      this.selectedExpertsAndInvalid(targetPhase) &&
      action !== 'previous'
    ) {
      this.openRespondedDialog(targetPhase);
    } else {
      this.updatePhase(targetPhase, action);
    }
  }

  private updatePhase(targetPhase: ConnectPhase, action: string): void {
    this.updateExperts(
      this.experts
        .filter((e) => e.isSelected)
        .map((e) => ({
          expertId: e.expertId,
          connectPhase: targetPhase,
        }))
    ).subscribe();
  }

  private selectedExpertsAndInvalid(targetPhase: ConnectPhase): boolean {
    return (
      this.experts.some((e) => e.isSelected) &&
      !this.expertValidationService.isValid(
        this.experts.filter((e) => e.isSelected),
        targetPhase
      )
    );
  }

  private setExpertSelectedStatus(toggle: boolean): void {
    const filteredExpertIds = new Set(
      this.filteredExperts
        .filter(
          (e) => connectPhaseList.indexOf(e.connectPhase) > 2 || !e.campaignId
        )
        .map((e) => e.expertId)
    );

    this.opportunityExpertsService.setSelected(
      this.experts.map((e) => ({
        expertId: e.expertId,
        isSelected: filteredExpertIds.has(e.expertId) ? toggle : e.isSelected,
      }))
    );
  }

  private updateExperts(
    experts: IExpertUpdateRequest[],
    resetIsSelected: boolean = true
  ): Observable<IExpert[]> {
    this.opportunityExpertsService.updateMany(experts, resetIsSelected);

    return of(experts as IExpert[]);
  }

  private initialiseData(opportunity: IOpportunity): void {
    this.selectedSearches = Object.keys(opportunity.searches);
    this.title.setTitle(opportunity.opportunityName);
  }
}
