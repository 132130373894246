import { Pipe, PipeTransform } from '@angular/core';
import { OmnisearchEntity } from '../models/omnisearch.models';
import { CategoryPipe } from './category.pipe';

@Pipe({
  name: 'detailsTooltip',
})
export class DetailsTooltipPipe implements PipeTransform {
  transform(entity: OmnisearchEntity): string {
    const pipe = new CategoryPipe();

    return `Open ${pipe.transform(entity)} details`;
  }
}
