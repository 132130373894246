<ul class="stepper" *ngIf="phaseCounts$ | async as phaseCounts">
  <li *ngFor="let val of phaseCounts | keyvalue:noopSort; trackBy: trackByFn; let last = last;" class="stepper-stage"
    (click)="viewPhase(val.key)" [ngClass]="{'stepper-stage-focused': activePhase === val.key }"
    data-test-id="opportunity-top-bar-connect-phase-tab">
    <span class="stepper-stage-count"> {{val.value}} </span>
    <span class="stepper-stage-title" [attr.data-test-id]="'opportunity-phase-' + val.key">
      {{val.key | phaseName}}
    </span>
    <span [class.stepper-directional-arrow]="!last" [class.stepper-directional-arrow-focused]="activePhase === val.key">
    </span>
  </li>
</ul>