<div data-test-id="users-dialog">
  <h1 mat-dialog-title data-test-id="users-dialog-title">Project Managers</h1>
  <div mat-dialog-content *ngIf="users$ | async as users; else loadingBlock">
    <app-users-form [usersList]="users" (submitUser)="submitUser($event)">
    </app-users-form>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="flex justify-center content-center items-center flex-1 h-full">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>