import { Injectable } from '@angular/core';
import { IEngagement } from '../models/engagements.models';

@Injectable()
export class EngagementsCalculationsService {
  public calculateOwed(engagement: IEngagement): number {
    if (!engagement.rate || isNaN(engagement.rate)) {
      engagement.rate = 0;
    }
    switch (engagement.engagementType) {
      case 'call':
      case 'survey':
      case 'data-review':
        if (engagement.quantityEngaged <= 0) {
          return 0;
        } else {
          return (
            Math.ceil(engagement.rate * engagement.quantityEngaged * 100) / 100
          );
        }
      case 'payment':
        return null;
      default:
        console.error('Unknown engagement type ', engagement);
        return null;
    }
  }

  public calculateUnitsUsed(engagement: IEngagement): number {
    switch (engagement.engagementType) {
      case 'call':
        if (engagement.quantityEngaged <= 0) {
          return 0;
        } else if (engagement.quantityEngaged <= 1) {
          // Up to one hour is one unit
          return 1;
        } else {
          // And for calls over 1 hour it's .25 units per 15 min, rounded up to the nearest 15 mins interval
          const intervals = 4; // number of intervals per quantityEngaged
          const rate = 0.25; // rate per interval
          return Math.ceil(engagement.quantityEngaged * intervals) * rate;
        }
      case 'survey': {
        if (engagement.quantityEngaged <= 0) {
          return 0;
        } else {
          const unitsUsed = Math.ceil(engagement.quantityEngaged) * 0.3;
          const unitsUsedToFixed1 = parseFloat(unitsUsed.toFixed(1));
          return unitsUsedToFixed1;
        }
      }
      case 'data-review':
        if (engagement.quantityEngaged <= 0) {
          return 0;
        } else {
          return Math.ceil(0.75 * engagement.quantityEngaged * 1000) / 1000;
        }
      case 'payment':
        return null;
      default:
        console.error('Unknown engagement type ', engagement);
        return null;
    }
  }
}
