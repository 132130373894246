import { createReducer, on } from '@ngrx/store';
import {
  ExpertSourcesActions,
  ExpertSourcesAPIActions,
} from './expert-sources.actions';
import {
  expertSourcesAdapter,
  IExpertSourcesState,
} from './expert-sources.state';

export const initialState: IExpertSourcesState =
  expertSourcesAdapter.getInitialState({
    selectedExpertSourceId: null,
    selectedOpportunityId: null,
    isLoading: false,
    isLoaded: false,
    updateErrors: null,
    loadError: null,
  });

export const expertSourcesReducer = createReducer(
  initialState,
  on(
    ExpertSourcesActions.fetchExpertSource,
    (state, { expertSourceId }): IExpertSourcesState => ({
      ...state,
      selectedExpertSourceId: expertSourceId,
      isLoading: true,
      isLoaded: false,
      loadError: null,
      updateErrors: null,
    })
  ),
  on(
    ExpertSourcesAPIActions.fetchExpertSourceSuccess,
    (state, { expertSource }) =>
      expertSourcesAdapter.addOne(expertSource, {
        ...state,
        isLoading: false,
        isLoaded: true,
        loadError: null,
        updateErrors: null,
      })
  ),
  on(
    ExpertSourcesAPIActions.fetchExpertSourceFailure,
    (state, { error }): IExpertSourcesState => ({
      ...state,
      isLoading: false,
      loadError: error.join(', '),
    })
  ),
  on(
    ExpertSourcesActions.queryExpertSourcesByOpportunity,
    (state, { opportunityId }): IExpertSourcesState => ({
      ...state,
      selectedOpportunityId: opportunityId,
      isLoading: true,
      isLoaded: false,
      loadError: null,
      updateErrors: null,
    })
  ),
  on(
    ExpertSourcesAPIActions.queryExpertSourcesByOpportunitySuccess,
    (state, { expertSources }) =>
      expertSourcesAdapter.addMany(expertSources, {
        ...state,
        isLoading: false,
        isLoaded: true,
        loadError: null,
        updateErrors: null,
      })
  ),
  on(
    ExpertSourcesAPIActions.queryExpertSourcesByOpportunityFailure,
    (state, { error }): IExpertSourcesState => ({
      ...state,
      isLoading: false,
      loadError: error.join(', '),
    })
  )
);

export const getSelectedExpertSourceId = (state: IExpertSourcesState): string =>
  state.selectedExpertSourceId;

export const getSelectedOpportunityId = (state: IExpertSourcesState): string =>
  state.selectedOpportunityId;

const { selectIds, selectEntities, selectAll, selectTotal } =
  expertSourcesAdapter.getSelectors();

export const selectExpertSourcesIds = selectIds;
export const selectExpertSourcesEntities = selectEntities;
export const selectAllExpertSources = selectAll;
export const selectExpertSourcesTotal = selectTotal;
