import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { handleHttpError } from '@techspert-io/errorHandling';
import { exhaustMap, map, switchMap } from 'rxjs/operators';
import { OpportunitiesService } from '../services/opportunities.service';
import {
  OpportunityActions,
  OpportunityAPIActions,
} from './opportunities.actions';

@Injectable()
export class OpportunitiesEffects {
  fetchOpportunity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.fetchOpportunity),
      exhaustMap(({ opportunityId, options }) =>
        this.opportunitiesService.get(opportunityId).pipe(
          map((opportunity) =>
            OpportunityAPIActions.fetchOpportunitySuccess({
              opportunity,
              ...(options && { options }),
            })
          ),
          handleHttpError((error) =>
            OpportunityAPIActions.fetchOpportunityFailure({
              error,
              opportunityId,
            })
          )
        )
      )
    );
  });

  queryOpportunities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.queryOpportunities),
      exhaustMap(({ query }) =>
        this.opportunitiesService.query(query).pipe(
          map((opportunities) =>
            OpportunityAPIActions.queryOpportunitiesSuccess({
              opportunities,
            })
          ),
          handleHttpError((error) =>
            OpportunityAPIActions.queryOpportunitiesFailure({
              error,
            })
          )
        )
      )
    );
  });

  updateOpportunity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.updateOpportunity),
      switchMap(({ update }) =>
        this.opportunitiesService.update(update).pipe(
          map((opportunity) =>
            OpportunityAPIActions.updateOpportunitySuccess({
              opportunity,
            })
          ),
          handleHttpError((error) =>
            OpportunityAPIActions.updateOpportunityFailure({
              error,
              opportunityId: update.opportunityId,
            })
          )
        )
      )
    );
  });

  appendOpportunitySearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.appendOpportunitySearch),
      switchMap(({ searches }) =>
        this.opportunitiesService.appendSearchToOpportunity(searches).pipe(
          map((opportunity) =>
            OpportunityAPIActions.appendOpportunitySearchSuccess({
              opportunity,
            })
          ),
          handleHttpError((error) =>
            OpportunityAPIActions.appendOpportunitySearchFailure({
              error,
              opportunityId: searches.opportunityId,
            })
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private opportunitiesService: OpportunitiesService
  ) {}
}
