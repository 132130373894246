import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CognitoAuthService, ConnectUser } from '@techspert-io/auth';
import { IExpert, connectPhaseList } from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';
import {
  IOutreachTemplate,
  OutreachTemplatesService,
} from '@techspert-io/outreachTemplates';
import {
  IRecipient,
  OutreachService,
  TemplatesService,
} from '@techspert-io/solicitation';
import { ToastService } from '@techspert-io/user-alerts';
import {
  JobTitlePipe,
  UserService,
  getOfficeLocationAddress,
} from '@techspert-io/users';
import { EMPTY, Observable, combineLatest, from } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  mergeMap,
  reduce,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  Email,
  IAutomatedSolicitationPayload,
  IEmailAddress,
  IEmailTracking,
  IPrepEmail,
  ISolicitationEmail,
} from '../../../../../shared/models/email';
import { AppService } from '../../../../../shared/services/app.service';
import { SolicitationService } from './solicitation.service';

export interface ISendSolicitationDialogData {
  activePhase: IExpert['connectPhase'];
  opportunity: IOpportunity;
  experts: IExpert[];
  allExperts: IExpert[];
}

export interface ITemplateForm {
  formType: string;
  template?: IOutreachTemplate;
}

export interface ITemplateFilter {
  searchTerm: string;
  configuration: 'adminTemplates' | 'allAdminTemplates';
}

@Component({
  selector: 'app-send-solicitation-dialog',
  templateUrl: 'send-solicitation-dialog.component.html',
  styleUrls: ['./send-solicitation-dialog.component.scss'],
  providers: [JobTitlePipe],
  encapsulation: ViewEncapsulation.None,
})
export class SendSolicitationDialogComponent implements OnDestroy, OnInit {
  @ViewChild('editor') elementView: ElementRef;
  @ViewChild('formContainer') formContainer: ElementRef;
  public experts: IExpert[] = [];
  public template: string = '';
  public templates: IOutreachTemplate[] = [];
  public previewEmail: ISolicitationEmail | false;
  public previewPreheader: string;
  public sideMenuToShow: string;
  public showPreviewEmail: boolean = false;
  public adminTemplates: IOutreachTemplate[] = [];
  public allAdminTemplates: IOutreachTemplate[] = [];
  public filteredTemplates: IOutreachTemplate[] = [];
  public activePhase: IExpert['connectPhase'];
  public opportunity: IOpportunity;
  public allExperts: IExpert[] = [];
  public allUserEmails: IEmailAddress[] = [];

  public connectPhases = connectPhaseList;
  public sendingEmail: boolean;
  public editingTemplate = false;

  //template form vars;
  public templateFormData: ITemplateForm;
  public showTemplateForm: boolean = false;
  public initSearchTerm: string = 'Master';

  constructor(
    public dialogRef: MatDialogRef<SendSolicitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ISendSolicitationDialogData,
    public cognitoAuthService: CognitoAuthService,
    private appService: AppService,
    private solicitationService: SolicitationService,
    private outreachTemplatesService: OutreachTemplatesService,
    private outreachService: OutreachService,
    private toastService: ToastService,
    private templatesService: TemplatesService,
    private usersService: UserService,
    private jobTitlePipe: JobTitlePipe
  ) {
    this.activePhase = this.data.activePhase;
    this.opportunity = this.data.opportunity;
    this.experts = this.data.experts || [];
    this.allExperts = this.data.allExperts || [];
    this.template = '';
  }

  public ngOnInit(): void {
    this.solicitationService.activeTemplate = {} as IOutreachTemplate;
    this.solicitationService.recipients =
      this.solicitationService.getValidEmails(this.experts);
    this.solicitationService.updateCounts();
    combineLatest([
      this.outreachTemplatesService.getAll(),
      this.usersService.getAll({ userTypes: ['PM', 'ND', 'ND-Manager'] }),
    ])
      .pipe(
        tap(([templates, users]) => {
          this.templates = templates;
          this.assignTemplates(this.templates);
          this.resetTemplatesFilter();
          this.setupSenders(users);
        })
      )
      .subscribe();
  }

  public openNewTemplateForm(): void {
    this.showTemplateForm = true;
    this.sideMenuToShow = 'keys';
    this.assignNewTemplateFormData();
    this.formContainer.nativeElement.style.top = '0';
  }

  public duplicateTemplate(template: IOutreachTemplate): void {
    template.parentId = template.templateId;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { templateId, ...duplicatedTemplate } = {
      ...template,
      parentId: template.templateId,
      title: template.title.replace(/MASTER COPY:/g, ''),
    };
    this.outreachTemplatesService.create(duplicatedTemplate).subscribe((t) => {
      this.templates.push(t);
      this.assignTemplates(this.templates);
      this.openEditTemplateForm(t);
    });
  }

  public openEditTemplateForm(template: IOutreachTemplate): void {
    this.showTemplateForm = true;
    this.sideMenuToShow = 'keys';
    this.assignEditTemplateFormData(template);
    this.formContainer.nativeElement.style.top = '0';
    this.editingTemplate = true;
  }

  public toggleKeys(): void {
    this.sideMenuToShow =
      this.sideMenuToShow === 'templates' || !this.sideMenuToShow ? 'keys' : '';
  }

  public toggleTemplates(): void {
    this.sideMenuToShow =
      this.sideMenuToShow === 'keys' || !this.sideMenuToShow ? 'templates' : '';

    this.sideMenuToShow === 'templates' && this.resetTemplatesFilter();
  }

  public handleMenuClose(): void {
    this.sideMenuToShow = '';
  }

  public closeTemplateForm(): void {
    const formContainer = document.querySelector(
      '.template-form-container'
    ) as HTMLElement;
    if (formContainer) {
      formContainer.style.top = '100%';
    }
    this.showTemplateForm = false;
    this.editingTemplate = false;
  }

  public initialiseViewChange(): void {
    this.outreachTemplatesService.getAll().pipe(
      tap((templates) => {
        this.templates = templates;
        this.assignTemplates(templates);
      }),
      finalize(() => {
        this.resetTemplatesFilter();
      })
    );

    this.closeTemplateForm();
    this.sideMenuToShow = 'templates';
  }

  public openTemplate(event: IOutreachTemplate): void {
    this.solicitationService.subject = event.subject;
    this.solicitationService.preheader = event.preheader;
    this.solicitationService.activeTemplate.templateId = event.templateId;
    this.solicitationService.activeTemplate.title = event.title;
    this.solicitationService.activeTemplate.description = event.description;
    this.solicitationService.activeTemplate.content = event.content;
    this.solicitationService.activeTemplate.parentId = event.parentId;

    this.closeTemplateForm();
  }

  private resetTemplatesFilter(): void {
    this.filterTemplates({
      configuration: 'allAdminTemplates',
      searchTerm: this.initSearchTerm,
    });
  }

  public filterTemplates(payload: ITemplateFilter): void {
    switch (payload.configuration) {
      case 'adminTemplates':
        this.filteredTemplates = this.adminTemplates.filter((templateObj) =>
          Object.values(templateObj).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(payload.searchTerm.toLowerCase())
          )
        );
        break;
      case 'allAdminTemplates':
        this.filteredTemplates = this.allAdminTemplates.filter((templateObj) =>
          Object.values(templateObj).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(payload.searchTerm.toLowerCase())
          )
        );
        break;
    }
  }

  public createAndDisplayPreviewEmail(): void {
    this.createEmail(
      {
        emails: [
          {
            address: this.cognitoAuthService.loggedInUser.email,
            type: 'transactional',
          },
        ],
        firstName: this.cognitoAuthService.loggedInUser.firstName,
        lastName: this.cognitoAuthService.loggedInUser.lastName,
        id: this.cognitoAuthService.loggedInUser.connectId,
      } as IRecipient,
      this.cognitoAuthService.loggedInUser.email,
      ''
    )
      .pipe(
        tap((email) => {
          this.displayPreview(email);
        })
      )
      .subscribe();
  }

  private displayPreview(email: IPrepEmail): void {
    this.previewEmail = email.email;
    this.previewPreheader = this.previewEmail.preheader;
  }

  public clearPreviewEmail(): void {
    this.previewEmail = false;
  }

  public confirmSendAllClickHandler(): void {
    this.showPreviewEmail = false;
    if (this.confirmEmailCanSend()) {
      const { validRecipients, invalidRecipients } =
        this.sortValidAndInvalidRecipients();
      if (invalidRecipients.length)
        this.toastService.sendMessage(
          `${invalidRecipients.length} duplicated recipients have not been emailed`,
          'error'
        );
      if (validRecipients.length) {
        this.createAllExpertEmails(validRecipients)
          .pipe(
            map((emails) => this.createSendEmailPayload(emails)),
            switchMap((payload) => this.sendEmails(payload))
          )
          .subscribe(() => {
            this.dialogRef.close();
          });
      }
    }
  }

  private createSendEmailPayload(
    emails: IPrepEmail[]
  ): IAutomatedSolicitationPayload {
    return {
      emails: [this.createAdminCopy(emails[0].email), ...emails],
    };
  }

  private createAllExpertEmails(
    recipients: IRecipient[]
  ): Observable<IPrepEmail[]> {
    const batchId = this.appService.createUUID();

    return from(recipients).pipe(
      mergeMap((r) => this.createEmails(r, batchId)),
      reduce<IPrepEmail[], IPrepEmail[]>((acc, curr) => [...acc, ...curr], [])
    );
  }

  public ngOnDestroy(): void {
    this.solicitationService.emailTemplate = '';
    this.solicitationService.experts = [];
    this.solicitationService.activeTemplate = null;
    this.solicitationService.subject = '';
    this.solicitationService.preheader = '';
    this.solicitationService.client = null;
    this.solicitationService.recipients = [];
    this.experts = [];
  }

  private assignNewTemplateFormData(): void {
    this.templateFormData = {
      formType: 'new',
      template: {
        title: '',
        description: '',
        subject: '',
        content: '',
        preheader: '',
      } as IOutreachTemplate,
    };
  }

  private assignEditTemplateFormData(template: IOutreachTemplate): void {
    this.templateFormData = {
      formType: 'edit',
      template,
    };
  }

  private setupSenders(users: ConnectUser[]): void {
    const mapUserToEmailAddress = (user: ConnectUser): IEmailAddress => ({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    });

    const otherUserEmails = users
      .filter(
        (user) => user.email !== this.cognitoAuthService.loggedInUser.email
      )
      .map(mapUserToEmailAddress)
      .sort((a, b) => a.email.localeCompare(b.email));

    this.allUserEmails = [
      mapUserToEmailAddress(this.cognitoAuthService.loggedInUser),
      ...otherUserEmails,
    ];
    this.solicitationService.replyToEmail = this.allUserEmails.find(Boolean);
  }

  private assignTemplates(templates: IOutreachTemplate[]): void {
    this.allAdminTemplates = templates;
    this.adminTemplates = templates.filter(
      (t) => t.createdBy === this.cognitoAuthService.loggedInUser.connectId
    );
  }

  private sendEmails(emailConfig: IAutomatedSolicitationPayload) {
    this.sendingEmail = true;
    return from(this.appService.chunkArray(100, emailConfig.emails)).pipe(
      mergeMap((emailsBatch) =>
        this.outreachService.send({ emails: emailsBatch })
      ),
      reduce(
        (prev, curr) => ({
          success: prev.success + curr.success.length,
          duplicate: prev.duplicate + curr.duplicate.length,
        }),
        { success: 0, duplicate: 0 }
      ),
      tap(
        ({ duplicate }) =>
          duplicate &&
          this.toastService.sendMessage(
            `Did not send ${duplicate} emails as experts already received outreach at this stage`,
            'error'
          )
      ),
      tap(
        ({ success }) =>
          success &&
          this.toastService.sendMessage(
            `${success} recipients have been emailed successfully`,
            'success'
          )
      ),
      tap(() => (this.sendingEmail = false)),
      catchError((err) => {
        this.toastService.sendMessage(
          `Failed to send, ${err.message}`,
          'error'
        );

        this.sendingEmail = false;
        return EMPTY;
      })
    );
  }

  private sortValidAndInvalidRecipients(): {
    validRecipients: IRecipient[];
    invalidRecipients: IRecipient[];
  } {
    const sortedRecipients = this.solicitationService.recipients.reduce(
      (acc, recipient) => {
        const duplicateExperts =
          this.findDuplicateExpertsViaSharedEmails(recipient);
        if (!duplicateExperts.length) {
          acc.validRecipients = [...acc.validRecipients, recipient];
          return acc;
        }
        if (
          duplicateExperts.length &&
          !this.sameStageDuplicateHasAlreadyBeenAdded(
            acc.validRecipients,
            recipient
          ) &&
          !this.duplicateExpertsExistInLaterStages(duplicateExperts, recipient)
        ) {
          acc.validRecipients = [...acc.validRecipients, recipient];
          return acc;
        }
        if (
          (duplicateExperts.length &&
            this.sameStageDuplicateHasAlreadyBeenAdded(
              acc.validRecipients,
              recipient
            )) ||
          this.duplicateExpertsExistInLaterStages(duplicateExperts, recipient)
        ) {
          acc.invalidRecipients = [...acc.invalidRecipients, recipient];
          return acc;
        }
        return acc;
      },
      {
        validRecipients: [],
        invalidRecipients: [],
      }
    );
    return sortedRecipients;
  }

  private sameStageDuplicateHasAlreadyBeenAdded(
    accumulator: IRecipient[],
    recipient: IRecipient
  ): boolean {
    return accumulator.some((accumulatorRecipient) =>
      accumulatorRecipient.emails.some((email) =>
        recipient.emails.includes(email)
      )
    );
  }

  private findDuplicateExpertsViaSharedEmails(
    recipient: IRecipient
  ): IExpert[] {
    return this.allExperts.filter((expert) =>
      [...expert.opportunityEmails, expert.primaryEmail].some((email) =>
        recipient.emails.map((e) => e.address).includes(email)
      )
    );
  }

  private duplicateExpertsExistInLaterStages(
    duplicateExperts: IExpert[],
    recipient: IRecipient
  ): boolean {
    return duplicateExperts.some(
      (expert) =>
        this.connectPhaseToInt(expert.connectPhase) >
        this.connectPhaseToInt(recipient.connectPhase)
    );
  }

  private connectPhaseToInt(phase: string): number {
    switch (phase) {
      case 'identified':
        return 0;
      case 'firstFollowUp':
        return 1;
      case 'secondFollowUp':
        return 2;
      case 'outreach':
        return 3;
      case 'outreachComplete':
        return 4;
      case 'screener':
        return 5;
      case 'sentToClient':
        return 6;
      case 'accepted':
        return 7;
      case 'scheduled':
        return 8;
      case 'completed':
        return 9;
    }
  }

  private createAdminCopy(email: Email): IPrepEmail {
    return {
      email: {
        ...email,
        recipient: this.cognitoAuthService.loggedInUser.email,
        subject: `[COPY] - ${email.subject}`,
        tags: ['admin'],
        emailType: 'internal-comms',
      },
      expertId: this.cognitoAuthService.loggedInUser.connectId,
      opportunityId: this.opportunity.opportunityId,
      connectPhase: '',
      batchId: '',
      source: '',
      isPoCEmail: true,
    };
  }

  private createEmail(
    recipient: IRecipient,
    email: string,
    batchId: string
  ): Observable<IPrepEmail> {
    const preheader = this.solicitationService.preheader
      ? this.replaceAll(this.solicitationService.preheader, recipient).replace(
          /\n/g,
          '<br />'
        )
      : '';
    const body = this.replaceAll(
      this.solicitationService.activeTemplate.content,
      recipient
    ).replace(/\n/g, '<br />');

    const respondedPhaseIndex = connectPhaseList.indexOf('outreach');

    return this.templatesService.footerLookUp(recipient).pipe(
      map((footer) => this.replaceAll(footer, recipient)),
      map((footer) => ({
        email: {
          tags: ['outreach', 'expert'],
          recipient: email,
          subject: this.replaceAll(this.solicitationService.subject, recipient),
          preheader,
          sender: this.solicitationService.replyToEmail,
          htmlContent: `${body} ${footer}`,
          textContent: this.replaceAll(
            this.solicitationService.activeTemplate.content,
            recipient
          ),
          emailType:
            connectPhaseList.indexOf(recipient.connectPhase) >=
            respondedPhaseIndex
              ? 'expert-comms'
              : 'solicitation',
        },
        batchId,
        ...this.mergeInTrackingData(recipient, email),
      }))
    );
  }

  private confirmEmailCanSend(): boolean {
    return (
      !!this.solicitationService.subject &&
      !!this.solicitationService.activeTemplate.content &&
      !!this.solicitationService.replyToEmail &&
      !!this.solicitationService.recipients.length
    );
  }

  private createEmails(
    recipient: IRecipient,
    batchId: string
  ): Observable<IPrepEmail[]> {
    return from(recipient.emails).pipe(
      mergeMap((em) => this.createEmail(recipient, em.address, batchId)),
      reduce((acc: IPrepEmail[], curr: IPrepEmail) => [...acc, curr], [])
    );
  }

  private mergeInTrackingData(
    recipient: IRecipient,
    email: string
  ): IEmailTracking {
    const expert = this.experts.find((e) => e.expertId === recipient.id);

    if (expert) {
      return {
        expertId: expert.expertId,
        opportunityId: this.opportunity.opportunityId,
        connectPhase: expert.connectPhase,
        source: recipient.source,
        isPoCEmail: recipient.emails.some(
          (e) => e.type === 'poc' && e.address === email
        ),
        ...(expert.expertProfileId
          ? { searchExpertId: expert.expertProfileId }
          : {}),
        ...(this.solicitationService.activeTemplate.templateId
          ? { templateId: this.solicitationService.activeTemplate.templateId }
          : {}),
        ...(this.solicitationService.activeTemplate.parentId
          ? {
              templateParentId:
                this.solicitationService.activeTemplate.parentId,
            }
          : {}),
      };
    }

    return {
      expertId: recipient.id,
      opportunityId: this.opportunity.opportunityId,
      connectPhase: recipient.connectPhase,
      source: recipient.source,
      isPoCEmail: recipient.emails.some(
        (e) => e.type === 'poc' && e.address === email
      ),
      ...(this.solicitationService.activeTemplate.templateId
        ? { templateId: this.solicitationService.activeTemplate.templateId }
        : {}),
      ...(this.solicitationService.activeTemplate.parentId
        ? {
            templateParentId: this.solicitationService.activeTemplate.parentId,
          }
        : {}),
    };
  }

  private replaceAll(template: string, recipient: IRecipient): string {
    return JSON.parse(JSON.stringify(template))
      .replace(/\[expert_first_name\]/g, recipient.firstName)
      .replace(/\[expert_last_name\]/g, recipient.lastName)
      .replace(
        /\[admin_first_name\]/g,
        this.cognitoAuthService.loggedInUser.firstName
      )
      .replace(
        /\[admin_last_name\]/g,
        this.cognitoAuthService.loggedInUser.lastName
      )
      .replace(
        /\[phone_number\]/g,
        this.cognitoAuthService.loggedInUser.profile?.phoneNumber || ''
      )
      .replace(
        /\[office_location\]/g,
        getOfficeLocationAddress(
          this.cognitoAuthService.loggedInUser.profile?.officeLocation
        )
      )
      .replace(
        /\[job_title\]/g,
        this.cognitoAuthService.loggedInUser.profile?.jobTitle
          ? this.jobTitlePipe.transform(
              this.cognitoAuthService.loggedInUser.profile.jobTitle
            )
          : ''
      );
  }
}
