import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOpportunityAssignee } from '../../../models/opportunity.models';

type cbChange = (c: IOpportunityAssignee[]) => void;
type cbTouch = () => void;

@Component({
  selector: 'opp-pod-members-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PodMembersInputComponent,
      multi: true,
    },
  ],
  template: `<opp-pod-members
    (addPodMember)="addPodMember($event)"
    (deletePodMember)="deletePodMember($event)"
    [podMembers]="opportunityAssignee"
  >
  </opp-pod-members>`,
})
export class PodMembersInputComponent implements ControlValueAccessor {
  touched = false;
  opportunityAssignee: IOpportunityAssignee[] = [];
  set assignees(val: IOpportunityAssignee[]) {
    this.opportunityAssignee = val;
  }

  onChange: cbChange = () => void 0;
  onTouch: cbTouch = () => void 0;

  writeValue(value: IOpportunityAssignee[]): void {
    this.assignees = value;
  }

  registerOnChange(fn: cbChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: cbTouch): void {
    this.onTouch = fn;
  }

  addPodMember(assignee: IOpportunityAssignee[]): void {
    this.handleUpdate([...this.opportunityAssignee, ...assignee]);
  }

  deletePodMember(assignee: IOpportunityAssignee): void {
    this.handleUpdate(
      this.opportunityAssignee.filter((d) => d.email !== assignee.email)
    );
  }

  private handleUpdate(assignees: IOpportunityAssignee[]): void {
    if (!this.touched) {
      this.onTouch();
      this.touched = true;
    }
    this.onChange(assignees);
    this.assignees = assignees;
  }
}
