import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BtxSharedModule } from '../../../../../shared/btx-shared.module';

import { KeyComponent } from './key/key.component';
import { RecipientListComponent } from './recipient-list/recipient-list.component';
import { SendSolicitationDialogComponent } from './send-solicitation-dialog.component';
import { SolicitationService } from './solicitation.service';
import { TemplateFormComponent } from './templates/template-form/template-form.component';
import { TemplateMenuComponent } from './templates/template-menu/template-menu.component';

@NgModule({
  declarations: [
    SendSolicitationDialogComponent,
    RecipientListComponent,
    KeyComponent,
    TemplateFormComponent,
    TemplateMenuComponent,
  ],
  imports: [
    BtxSharedModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    SendSolicitationDialogComponent,
    RecipientListComponent,
    KeyComponent,
    TemplateFormComponent,
    TemplateMenuComponent,
  ],
  providers: [SolicitationService],
})
export class SolicitationModule {}
