<div class="flex flex-col">
    <mat-form-field appearance="outline" class="basis-full">
        <mat-label data-test-id="currency-input-label">Expert currency</mat-label>

        <input type="text" placeholder="Experts currency e.g GBP" data-test-id="currency-input" aria-label="Currency"
            matInput (ngModelChange)="onInputChange($event)" [matAutocomplete]="auto" [ngModel]="val">

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredCurrencies" [value]="option.code" [title]="option.currency"
                data-test-id="currency-input-option">
                {{option.code}} - {{option.currency}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-error *ngIf="isInvalid" data-test-id="currency-input-error">
        Please provide a valid currency
    </mat-error>
</div>