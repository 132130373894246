<form *ngIf="downloadState$ | async as downloadState" [formGroup]="searchForm"
  (submit)="submitSearches(searchForm.value)">
  <div mat-dialog-title>
    <div class="flex flex-col gap-2">
      <h1 class="flex justify-between content-center items-center w-full flex-row">
        <span> Select Searches </span>
        <button mat-icon-button mat-dialog-close type="button">
          <mat-icon>close</mat-icon>
        </button>
      </h1>
    </div>

    <mat-form-field class="w-full">
      <mat-label>Filter...</mat-label>
      <input matInput formControlName="searchTerm">
    </mat-form-field>
  </div>

  <ng-container *ngIf="searches$ | async as searchGroups">
    <div mat-dialog-content>

      <div *ngIf="!downloadState.loaded" [style.height]="'80px'"
        class="flex justify-center content-center items-center flex-row">
        Loading Searches...
      </div>

      <mat-selection-list formControlName="searches" *ngIf="downloadState.loaded">
        <ng-container *ngFor="let searchGroup of searchGroups | keyvalue">

          <div class="group-title flex justify-between content-center items-center flex-row">
            <p>{{searchGroup.key}}</p>

            <div class="flex flex-row justify-end content-center items-center h-full">
              <p>({{searchGroup.value.total}})</p>

              <button mat-icon-button type="button" *ngIf="!downloadState.loading"
                (click)="downloadGroup(searchGroup.value.searches)" matTooltip="Download all in Group"
                matTooltipPosition="left">
                <mat-icon> system_update_alt </mat-icon>
              </button>
              <button mat-icon-button type="button" *ngIf="downloadState.loading" (click)="cancelDownloads($event)">
                <mat-icon matTooltip="click to cancel" matTooltipPosition="left">
                  system_update_alt
                </mat-icon>
              </button>
            </div>
          </div>

          <mat-list-option *ngFor="let search of searchGroup.value.searches | keyvalue" [value]="search.key"
            checkboxPosition="before">
            <div class="flex justify-between content-center items-center flex-row">
              <p class="flex-1"> {{search.key}} </p>
              <div class="flex flex-row justify-end content-center items-center h-full">
                <p> ({{search.value}}) </p>

                <button mat-icon-button type="button" *ngIf="downloadState.downloads[search.key] !== 'loading'"
                  (click)="downloadSearch($event, search.key)">
                  <mat-icon *ngIf="downloadState.downloads[search.key] === 'loaded'">
                    download_done
                  </mat-icon>
                  <mat-icon
                    *ngIf="!downloadState.downloads[search.key] || downloadState.downloads[search.key] === 'partial'">
                    download
                  </mat-icon>
                </button>
                <button mat-icon-button type="button" *ngIf="downloadState.downloads[search.key] === 'loading'"
                  (click)="cancelDownloads($event)">
                  <mat-icon class="loading" matTooltip="click to cancel" matTooltipPosition="left">
                    sync
                  </mat-icon>
                </button>
              </div>
            </div>
          </mat-list-option>
        </ng-container>

      </mat-selection-list>
    </div>

    <div mat-dialog-actions>
      <div class="flex flex-1 justify-end gap-2">
        <button mat-button type="button" (click)="toggleSelectAll({})">
          Deselect All
        </button>
        <button mat-button type="button" (click)="toggleSelectAll(searchGroups)">
          Select All
        </button>
        <button mat-raised-button color="primary" type="submit">
          Close
        </button>
      </div>
    </div>
  </ng-container>
</form>