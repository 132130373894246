import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectCurrentExpertSource,
  selectExpertSourcesLoadingState,
} from '@techspert-io/search';

@Component({
  selector: 'app-expert-search-info',
  templateUrl: './expert-search-info.component.html',
  styleUrls: ['./expert-search-info.component.scss'],
})
export class ExpertSearchInfoComponent {
  source$ = this.store.select(selectCurrentExpertSource);
  loading$ = this.store.select(selectExpertSourcesLoadingState);

  constructor(private store: Store) {}
}
