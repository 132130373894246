<div class="training-box" [ngClass]="complianceTraining?.is_valid ? 'valid' : 'invalid'">
  <mat-icon *ngIf="complianceTraining?.is_valid" class="icon" fontSet="material-icons-outlined">
    done
  </mat-icon>
  <mat-icon *ngIf="!complianceTraining?.is_valid && complianceTraining?.created_at" class="icon"
    fontSet="material-icons-outlined">
    hourglass_disabled
  </mat-icon>
  <mat-icon *ngIf="!complianceTraining?.is_valid && !complianceTraining?.created_at" class="icon"
    fontSet="material-icons-outlined">
    pending
  </mat-icon>

  <div class="training-info">
    <div *ngIf="!complianceTraining?.created_at">
      <span><b>Training missing</b></span>
    </div>
    <div *ngIf="complianceTraining?.created_at">
      <span>
        <b>Training {{complianceTraining.is_valid ? 'valid' : 'expired'}}</b>
        <br>
        last completed on:
      </span>
      {{complianceTraining.created_at | date:'mediumDate'}}
      {{complianceTraining.created_at | date:'shortTime'}}
    </div>
  </div>
</div>