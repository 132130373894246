import { registerLocaleData } from '@angular/common';
import localeENGB from '@angular/common/locales/en-GB';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const loadHotjarScript = (): void => {
  const node = document.createElement('script');
  node.innerHTML = `
  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    h._hjSettings = { hjid: 2213985, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`;
  document.getElementsByTagName('head')[0].appendChild(node);
};

registerLocaleData(localeENGB);

if (environment.production) {
  enableProdMode();
  loadHotjarScript();
}

platformBrowserDynamic().bootstrapModule(AppModule);
