<ng-container *ngIf="loadingState$ | async as loadingState">

  <div *ngIf="templates$ | async as templates; else isLoadingTmpl" class="h-full w-full">
    <div class="flex justify-between content-stretch items-stretch flex-row">
      <h1>Outreach Templates</h1>
      <a mat-raised-button color="primary" routerLink="./create">Create</a>
    </div>

    <hr>

    <div class="templates-container flex flex-row gap-4" *ngIf="selectedTemplate$ | async as selectedTemplate">
      <mat-card class="w-96">
        <mat-card-content>
          <div class="w-full">
            <mat-form-field class="flex-1">
              <mat-label>Search...</mat-label>
              <input matInput [formControl]="searchInput">
            </mat-form-field>
          </div>

          <div *ngFor="let template of templates" class="template-list-item"
            [class.selected]="selectedTemplate.templateId === template.templateId"
            (click)="selectedTemplate$.next(template)">
            <h3>
              {{template.title}}
            </h3>
            <p>
              {{template.description}}
            </p>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="flex flex-1">
        <mat-card class="w-full">
          <mat-card-content>

            <div>
              <div class="flex justify-between content-start items-start flex-row gap-2">
                <h2> {{selectedTemplate.title}} </h2>
                <a mat-flat-button color="accent" [routerLink]="selectedTemplate.templateId">Edit</a>
              </div>
              <p MatCardSubtitle> {{selectedTemplate.description}} </p>
            </div>

            <hr>

            <div>
              <h3>Subject</h3>
              <p>{{selectedTemplate.subject}}</p>
            </div>

            <hr>

            <div *ngIf="selectedTemplate.preheader">
              <h3>Preheader</h3>
              <p>{{selectedTemplate.preheader}}</p>
              <hr>
            </div>

            <p [style.white-space]="'pre-wrap'">{{selectedTemplate.content}}</p>
          </mat-card-content>

        </mat-card>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #isLoadingTmpl>
  <div [style.height]="'500px'" class="flex justify-center content-center items-center flex-col flex-1">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>